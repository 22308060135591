import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

export class TransactionInfo {
  constructor(obj?: any) {
    makeObservable(this, {
      totalAnnualSales: observable,
      averageTicketValue: observable,
      highTicketValue: observable,
      numberOfLocations: observable,
      amexmemberId: observable,
      amexAnnualVolume: observable,
      creditCardVolume: observable,
    });
    Object.assign(this, obj);
  }

  totalAnnualSales: number;

  averageTicketValue: number;

  highTicketValue: number;

  numberOfLocations: number;

  amexmemberId: number;

  amexAnnualVolume: number;

  creditCardVolume: number;
}
