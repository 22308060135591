<div class="modal-header row p-0">
  <div class="col-10 ps-0">
    <h1 *ngIf="title">{{ title }}</h1>
  </div>
  <div class="col-2 pe-0 d-flex justify-content-end">
    <button class="btn" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
</div>
<div class="modal-body px-0">
  <ng-container *ngTemplateOutlet="content; context: templateContext"></ng-container>
</div>
<div class="modal-footer p-0 d-flex justify-content-end" *ngIf="actionButtons.length > 0">
  <button
    type="button"
    *ngFor="let action of actionButtons"
    class="ps-3"
    [ngClass]="{ 'primary-button': action.type == 'primary', 'secondary-button': action.type == 'secondary' }"
    (click)="close(action.label)"
  >
    {{ action.label }}
  </button>
</div>
