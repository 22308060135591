import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { BaseModule } from '@app/base.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@eventhorizon/components/header/header.component';
import { FooterComponent } from '@eventhorizon/components/footer/footer.component';
import { DisclosureModule } from './disclosure/disclosure.module';
import { ErrorTimeoutComponent } from '@app/pages/error-timeout/error-timeout.component';
import { MobxAngularModule } from 'mobx-angular';
import { MaskitoDirective } from '@maskito/angular';
/**
 * Unfortunately to get this to build correctly it can't be a .ts file
 * or the prod build fails...
 */
@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, ErrorTimeoutComponent],
  imports: [
    routing,
    BaseModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    DisclosureModule,
    MobxAngularModule,
    MaskitoDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
