import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Content, CtaButton } from '@xup-payments/xup-frontend-utils/models';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-button-set',
  templateUrl: './button-set.component.html',
  styleUrls: ['./button-set.component.scss'],
})
export class ButtonSetComponent extends ContentBase {
  public buttons: CtaButton[];
  
  public buttonsAlignment?: string;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  setContent(content: Content) {
    this.buttonsAlignment = content?.alignment || '';
    this.buttons = content.buttons;
  }
}

export default ButtonSetComponent;
