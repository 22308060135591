import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { reaction } from 'mobx';
import { ProductService } from '@eventhorizon/services/product.service';
import { Subscription } from 'rxjs';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInterface } from '@eventhorizon/interfaces/modal-interface';
import { ProductFull } from '@eventhorizon/models/product.model';

@Component({
  selector: 'app-tg-product-detail-included-popup',
  templateUrl: './product-detail-included-popup.component.html',
  styleUrls: ['./product-detail-included-popup.component.scss'],
})
export class ProductDetailIncludedPopupComponent implements ModalInterface, OnInit, OnDestroy {
  @Input()
  public productId: string;

  public isLoading: boolean;

  private sub: Subscription;

  private productResult: ProductFull;

  private imagesURL: string[];

  constructor(
    private productService: ProductService,
    private applicationStore: ApplicationStore,
    private modalRef: BsModalRef,
  ) {
    reaction(
      () => !!this.applicationStore.isLoaded,
      () => {
        this.loadProductInfo();
      },
    );
  }

  ngOnInit() {
    if (this.applicationStore.id) {
      this.loadProductInfo();
    }
  }

  private loadProductInfo(): void {
    this.isLoading = true;
    this.sub = this.productService.getProductDetail(this.productId, this.applicationStore.id).subscribe(result => {
      this.productResult = result;
      this.isLoading = false;
    });
  }

  get productDetail(): ProductFull {
    return this.productResult;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public close(): void {
    this.modalRef.hide();
  }
}
