<app-container *mobxAutorun>
  <app-application-page #page
    [text]="text"
    [stepTrackerConfig]="stepTrackerConfig"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
    (cartsUpdated)="onCartsUpdated($event)"
  >
    <app-tg-product-search #innerSlide [cart]="cart" (openCart)="openCart()" (prev)="prev()" (next)="next()"></app-tg-product-search>
  </app-application-page>
</app-container>
