import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs/operators';
import { CartPopupComponent } from '../cart-popup/cart-popup.component';
import { CartPopupConstants } from '@eventhorizon/components/cart/cart-popup/cart-popup.constants';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Cart } from '@eventhorizon/models/cart.model';

@Component({
  selector: 'app-tg-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent implements OnInit {
  public isCartModalOpen = false;

  public productCount: number = 0;

  public isLoading: boolean = false;

  @Input() cartComponent = CartPopupComponent;

  @Output()
  public continue = new EventEmitter<void>();

  @Output()
  public updatedCarts = new EventEmitter<Cart[]>();

  constructor(private bsModalService: BsModalService, public cartStore: CartStore, public applicationStore: ApplicationStore) {}

  ngOnInit() {
    this.refreshProductCount();
  }

  public refreshProductCount() {
    this.isLoading = true;
    this.cartStore.getApplicationAllCarts().then(res => {
      if (res) {
        this.productCount = res.reduce((sum, cart) => sum += this.cartStore.getProductCount(cart), 0);
        this.updatedCarts.emit(res);
      }
      this.isLoading = false;
    });
  }

  public onClick() {
    this.isCartModalOpen = true;
    const modalRef: BsModalRef = this.bsModalService.show(this.cartComponent, {
      class: 'side-cart',
      animated: true,
      ariaLabelledBy: CartPopupConstants.ariaLabelledBy,
      initialState: {
        locations: this.applicationStore.businessLocations,
      },
    });
    modalRef.onHide
      .pipe(
        map(() => {
          this.isCartModalOpen = modalRef.content?.result;
          if (modalRef.content?.shouldContinue) this.continue.emit();
          else this.refreshProductCount();
        }),
        take(1),
      )
      .subscribe();
  }

}
