import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { randomComponentId } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-tg-input-spinner',
  templateUrl: './input-spinner.component.html',
  styleUrls: ['./input-spinner.component.scss'],
})
export class InputSpinnerComponent implements OnInit {
  @Input()
    mask: any;

  @Input()
    customClass: string;

  @Input()
    form: UntypedFormGroup;

  @Input()
    controlName: string;

  @Input()
    quantity = 1;

  @Input()
    control: UntypedFormControl;

  @Input()
    label;

  @Input()
    maxLength: number;

  @Output()
    changeQuantity = new EventEmitter<number>();

  @Input()
    incrementDisabled = false;

  @Input()
    decrementDisabled = false;

  @HostBinding('class.is-centered') isCentered = false;

  @Input()
    min = 0;

  // Supported types:
  types = {
    number: {
      containerClass: '',
    },
    percent: {
      containerClass: ' input-container-percent',
    },
  };

  @Input()
    type = 'number';

  @Input()
    step = 1;

  @Input()
    useInputTimeout = false;

  id = randomComponentId();

  inputChangeTimeout: any = null;

  ngOnInit() {
    this.buildForm();
  }

  public buildForm() {
    if (this.form) return;

    this.controlName = `'${this.id}_input`;
    this.control = new UntypedFormControl(this.quantity);
    const f = {};
    f[this.controlName] = this.control;
    this.form = new UntypedFormGroup(f);
  }

  public addQuantity(qty: number) {
    clearTimeout(this.inputChangeTimeout);
    let quantity = +this.control.value;
    if (quantity % qty) quantity -= quantity % qty;
    quantity += qty;
    if (quantity < this.min) {
      quantity = this.min;
    }

    this.quantity = quantity;
    this.control.setValue(quantity);

    this.changeQuantity.emit(this.quantity);
  }

  public onInputChange(quantity: string) {
    clearTimeout(this.inputChangeTimeout);
    this.inputChangeTimeout = setTimeout(
      () => {
        let quantityInt = parseInt(quantity, 10) || 0;
        if (quantityInt < this.min) {
          quantityInt = this.min;
          this.control.setValue(quantityInt);
        }
        this.changeQuantity.emit(quantityInt);
      },
      this.useInputTimeout ? 1000 : 0,
    );
  }

  public getContainerClass() {
    return `input-container${this.types[this.type].containerClass}`;
  }
}
