import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { Content } from '@eventhorizon/models/page.model';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarkdownComponent extends ContentBase {
  @Input() set data(data: string) {
    if (data) {
      this._data = this.sanitizer.bypassSecurityTrustHtml(data);
    }
  }

  _data: SafeHtml;

  constructor(protected sanitizer: DomSanitizer, public contentService: ContentServiceService) {
    super();
  }

  setContent(content: Content) {
    if (content.data) {
      this._data = this.sanitizer.bypassSecurityTrustHtml(content.data);
    }
  }
}

export default MarkdownComponent;
