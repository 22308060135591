<div class="row">
  <div class="col-md-12 mx-auto p-0">
    <form [formGroup]="form">
      <div class="row" [hidden]="hideBasedOnTinType()">
        <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
          <input
            [id]="id + 5"
            formControlName="legalName"
            class="form-control"
            type="text"
            autocomplete="on"
            [textMask]="masks.companyName"
          />
          <label class="form-label" [for]="id + 5"> Legal Business Name </label>

          <app-field-error [control]="form.controls.legalName"></app-field-error>
        </div>
        <div class="position-relative business-taxes-tin col-sm-12 col-md-6 mb-3 pb-0">
          <input
            [id]="id + 3"
            formControlName="tin"
            class="form-control"
            type="text"
            [textMask]="masks.taxIdAlt"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 3"> Tax Identification Number (TIN) </label>

          <app-field-error [control]="form.controls.tin"></app-field-error>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
          <span class="tooltip-span">
            <app-tg-tooltip
              [tooltipText]="
                'DBA stands for “doing business as.” This is also known as the assumed, trade, primary name your business operates under.'
              "
            ></app-tg-tooltip>
          </span>
          <input
            [id]="id + 6"
            formControlName="dbaName"
            class="form-control"
            type="text"
            autocomplete="on"
            [textMask]="masks.companyName"
          />
          <label class="form-label" [for]="id + 6"> DBA Name </label>

          <app-field-error [control]="form.controls.dbaName"></app-field-error>
        </div>

        <div class="col-sm-12 col-md-6 mb-3 pb-0 position-relative">
          <span class="calendar-icon">
            <i class="fal fa-calendar"></i>
          </span>
          <input
            [id]="id + 8"
            formControlName="startDate"
            class="form-control calendar-input"
            type="text"
            autocomplete="on"
            bsDatepicker
            [minDate]="minDateOfIncorporation"
            [maxDate]="today"
            appDateMask
            #incorDate
            [bsConfig]="brandDatePicker"
            (keyup)="onDateFieldKeyChangePatchValue($event, form)"
          />
          <label class="form-label" [for]="id + 8"> Date of Incorporation </label>
          <app-field-error [control]="form.controls.startDate"></app-field-error>
        </div>
      </div>

      <div class="row">
        <div class="col-12 p-0">
          <app-tg-address
            #legalAddress
            formGroupName="legalAddress"
            [(address)]="store.businessAddresses.legalAddress"
          ></app-tg-address>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 mb-3 p-0 position-relative">
          <input [id]="id + 11" formControlName="businessEmail" class="form-control" type="text" autocomplete="email" />
          <label class="form-label" [for]="id + 11"> Email </label>

          <app-field-error [control]="form.controls.businessEmail"></app-field-error>
        </div>
        <div class="col-sm-6 mb-3 pb-0 position-relative">
          <input
            [id]="id + 9"
            formControlName="businessPhone"
            class="form-control"
            type="text"
            autocomplete="tel"
            [textMask]="masks.phone"
          />
          <label class="form-label" [for]="id + 9"> Phone </label>

          <app-field-error [control]="form.controls.businessPhone"></app-field-error>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-3 ps-0 position-relative">
          <input [id]="id + 10" formControlName="website" class="form-control" type="text" autocomplete="url" />
          <label class="form-label" [for]="id + 10"> Website </label>

          <app-field-error [control]="form.controls.website"></app-field-error>
        </div>
      </div>

      <div class="col-12 p-0" *ngIf="displayPhysicalLocForm" id="physical-locations">
        <div class="row pb-2">
          <h3 class="physical-location pt-1">
            Physical Locations
            <span>
              <app-tg-tooltip
                [tooltipText]="
                  'Don’t worry if your business has more than one location. Only your primary location address needs to be shared at this time.'
                "
              ></app-tg-tooltip>
            </span>
          </h3>
        </div>
        <div class="row">
          <div class="col-sm-12 p-0">
            <app-tg-business-addresses
              #locationAddresses
              [displayLocationDBA]="displayLocationDBA"
              formGroupName="locationAddresses"
            ></app-tg-business-addresses>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="bankInfoConfig?.businessInfoDisclosures?.text" id="bank-info-disclosures">
        <div class="col-sm-12">
          <div [ngClass]="['info-message', bankInfoConfig?.bankName]">
            <i [ngClass]="['info-message-icon', bankInfoConfig?.businessInfoDisclosures?.icon]" alt="Information Icon">
            </i>
            <p class="info-message-text" [innerHTML]="bankInfoConfig?.businessInfoDisclosures?.text"></p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          *ngIf="errorMessage && errorMessage.length > 0"
          class="col-md-6 form-error-message error-message"
          id="error-messages"
        >
          Failed to save application
          <ul>
            <li *ngFor="let error of errorMessage">{{ error }}</li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>
