import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpCenterPopupComponent } from '@eventhorizon/components/help-center-popup/help-center-popup.component';
import { BUTTONS_STYLES } from '../../constants/content.constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-tg-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent implements OnInit {
  @Input() ctaStyle: string;

  @Input() ctaDisplayText: string;

  @Input() ctaAction: string;

  @Input() ctaSize: string;

  @Input() removeButtonRadius: boolean;

  @Input() url: string;

  public buttonClass: string = 'primary-button';

  constructor(protected router: Router, private bsModalService: BsModalService) {}

  ngOnInit(): void {
    if (this.ctaStyle) {
      this.buttonClass = BUTTONS_STYLES[this.ctaStyle];
    }

    if (this.ctaSize && this.ctaSize == 'small') {
      this.buttonClass += ' small-button';
    }
  }

  actionMap: { [key: string]: () => void } = {
    startApplication: () => {
      this.router.navigate([ROUTES.CONTACT_INFO]);
    },
    openHelpCenter: () => {
      this.bsModalService.show(HelpCenterPopupComponent, {
        class: 'modal-lg',
        ariaLabelledBy: 'help-center',
      });
    },
    goToProductsPage: () => {
      this.router.navigate(['/products']);
    },
  };

  public doAction(): void {
    if (this.url) {
      window.location.href = this.url;
    } else {
      const camelCaseAction = this.toCamelCase(this.ctaAction);
      const actionFn = this.actionMap[camelCaseAction];
      if (typeof actionFn !== 'undefined') {
        actionFn();
      }
    }
  }

  private toCamelCase(str): string {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}
