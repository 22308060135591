import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationType } from '@xup-payments/xup-frontend-utils/models';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  readonly BASE_URL = '/api/organizationtypes';

  constructor(private http: HttpClient) {}

  public getOrganizationTypes(): Observable<OrganizationType[]> {
    return this.http.get<OrganizationType[]>(`${this.BASE_URL}`);
  }
}
