import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { FormBuilder, FormControl, FormRecord } from '@angular/forms';
import { reaction } from 'mobx';
import type { CartPopupComponent } from '@eventhorizon/components/cart/cart-popup/cart-popup.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartController } from '@eventhorizon/controllers/cart.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkMonitorService } from '@eventhorizon/services/network-monitor.service';
import { Cart, LocationProducts, masks, Product } from '@xup-payments/xup-frontend-utils/models';
import { continuePage, defaultImage, ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tg-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements AfterViewInit, OnInit {
  public masks = masks;

  public errorMessage: string;

  public isLoading = false;

  public form: FormRecord<FormControl<number>>;

  public controls: Record<string, FormControl<number>> = {};

  public continueUrl: string;

  @Input()
  public modalDialogInstance: CartPopupComponent;

  @Input()
  public readonly: boolean;

  @Input()
  public showExtendedDisplay = false;

  @Input()
  public showPaymentTermAsColumn = false;

  @Input()
  public showComplexSubtotals = false;

  @Input()
  public showTransactions = true;

  public estimatedTaxRate = 0;

  public totalCost;

  public defaultImage = defaultImage;

  @Input() continuePage: string;

  @Input() cartDisclaimer?: string;

  @Input() dueToMessage?: string;

  @Input() isMultilocation?: boolean = false;

  public currentUrl;

  private multilocationCarts: Cart[] = [];

  private multilocationLocationProducts: LocationProducts[] = [];

  constructor(
    public cartStore: CartStore,
    public network: NetworkMonitorService,
    protected appStore: ApplicationStore,
    protected fb: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cartController: CartController,
  ) {
    reaction(
      () => this.cartStore.cart && this.cartStore.cart.id && !this.isMultilocation,
      data => {
        if (!data) return;
        this.buildForm();
        this.cartStore.loadCart().subscribe();
      },
    );
    this.totalCost = this.cartController.totalCost;
  }

  ngOnInit() {
    this.buildForm();
    this.currentUrl = this.router.url;
    this.continueUrl = this.activatedRoute.snapshot.queryParams['continue'];
  }

  ngAfterViewInit() {
    if (this.currentUrl?.includes(ROUTES.ORDER_STATUS)) {
      this.buildForm();
    }
  }

  get salesTaxRate() {
    return this.cartStore.cart.taxAmount !== 0 ? this.cartStore.cart.taxAmount : this.estimatedTaxRate;
  }

  get subTotal() {
    return this.cartStore.cart.oneTimeTotal || this.cartStore.cart.amount;
  }

  public getControl(productId: string) {
    return this.controls[`${productId}_numProduct`];
  }

  public goToProductsPage() {
    if (!this.showExtendedDisplay) {
      this.router.navigate(['/products']);
    } else {
      this.router.navigate(['/products'], {
        queryParams: {
          continue: '/checkout',
        },
      });
    }

    if (this.modalDialogInstance) {
      this.modalDialogInstance.closeModal();
    }
  }

  public getUnknownErrors() {
    const locationId =
      this.appStore.businessLocations &&
      this.appStore.businessLocations.length > 0 &&
      this.appStore.businessLocations[0].id;
    const locationErrors = this.cartStore.unknownErrorMessages[locationId]
      ? this.cartStore.unknownErrorMessages[locationId]
      : [];
    const errors = this.cartStore.unknownErrorMessages.generic ? [...this.cartStore.unknownErrorMessages.generic] : [];
    return [...errors, ...locationErrors];
  }

  setEstimatedTaxRate(value: number) {
    this.estimatedTaxRate = value;
  }

  public async goToContinue() {
    if (this.isMultilocation) return;
    await lastValueFrom(this.appStore.loadApplication());
    if (this.cartStore.hasPosError) {
      this.router.navigate([`/${ROUTES.PRODUCTS}`]);
    } else if (this.cartStore.hasSoftwareError) {
      this.router.navigate([`/${ROUTES.SOFTWARE}`], {
        queryParams: { offeringType: this.cartStore.softwareErrorType },
      });
    } else if (this.cartStore.hasAdditionalErrors) {
      this.router.navigate([`/${ROUTES.ADDITIONAL_PRODUCTS}`]);
    } else {
      this.continueUrl = this.activatedRoute.snapshot.queryParams['continue'];
      this.router.navigate([this.continuePage || this.continueUrl || continuePage]);
    }
    if (this.modalDialogInstance) {
      this.modalDialogInstance.closeModal();
    }
  }

  private async buildForm() {
    let products: Product[] = [];
    if (this.isMultilocation) {
      this.multilocationCarts = await this.cartStore.getApplicationAllCarts();
      this.multilocationCarts.forEach(cart => {
        products = products.concat(cart.products);
        const result = this.cartStore.groupCartProducts(cart);
        this.multilocationLocationProducts.push(result.locationProducts[0]);
      });
    } else {
      products = this.cartStore.getProducts();
      if (!products || !this.cartStore.cart) {
        return;
      }
    }

    products.forEach(p => {
      const fc = new FormControl<number>(p.quantity);
      this.controls[`${p.productId}_numProduct`] = fc;
    });
    this.form = this.fb.record(this.controls);
  }

  public getTotalCost() {
    let totalCost = 0;
    if (this.isMultilocation) {
      this.multilocationCarts.forEach(cart => {
        totalCost += this.totalCost(cart, this.estimatedTaxRate);
      });
    } else {
      totalCost = this.totalCost(this.cartStore.cart, this.estimatedTaxRate);
    }

    return totalCost;
  }

  public getLocationProducts() {
    return this.isMultilocation ? this.multilocationLocationProducts : this.cartStore.locationProducts;
  }

  public getMultilocationCarts() {
    return this.isMultilocation ? this.multilocationCarts : undefined;
  }
}
