import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/**
 *
 * @param formGroup
 * @param func
 * @param opts
 */
export function touchAll(formGroup: UntypedFormGroup | UntypedFormArray, func = 'markAsDirty', opts = { onlySelf: false }): void {
  Object.values(formGroup.controls).forEach(c => {
    if (c instanceof UntypedFormGroup || c instanceof UntypedFormArray) {
      touchAll(c, func, opts);
    } else {
      c[func](opts);
    }
  });
}

/**
 *
 * @param formGroup
 */
export function touchAndValidate(formGroup: UntypedFormGroup | UntypedFormArray): void {
  touchAll(formGroup, 'markAsDirty');
  touchAll(formGroup, 'markAsTouched');
  touchAll(formGroup, 'updateValueAndValidity');
}

/**
 * Check that there's at least one value inside a form that has something set
 * for now we're omiting the empty string since most controls are initialized that way rather than null/undefined
 * currently there's no way to know if a control has validators https://github.com/angular/angular/issues/13461
 * (i.e. check against empty string on required validators)
 * so we can just check for null/undefined for the time being
 *
 * @param formGroup the form group/array that we want to validate
 * @returns true for those forms that have at least one value set that is not null or undefined
 */
export function hasFormAnyValueSet(formGroup: UntypedFormGroup | UntypedFormArray): boolean {
  if (formGroup instanceof UntypedFormArray) {
    return Object.entries(formGroup.controls).some(([, value]) => {
      if (value instanceof UntypedFormControl) return value.value !== null && value.value !== undefined;
      if (value instanceof UntypedFormGroup) return hasFormAnyValueSet(value);
      return value !== null && value !== undefined;
    });
  }
  return Object.entries(formGroup.value).some(([, value]) => value !== null && value !== undefined);
}
