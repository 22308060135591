import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { AuthToken, UserToken } from '@eventhorizon/models/auth-token.model';
import { ProcessorName } from '@eventhorizon/models/processor.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public processor: ProcessorName = ProcessorName.FirstData; // Default by now

  public hasToken = new Subject();

  private bankId: string;

  constructor(protected http: HttpClient) {
    this.bankId = (window as any).bankId;
  }

  public authenticateWithEmailToken(token: string, reCaptchaToken: string): Observable<AuthToken> {
    const data = {
      accessToken: token,
      reCaptchaToken,
      // Only add the bankId if it's loaded
      ...(this.bankId && { bankId: this.bankId }),
    };

    return this.http.post('/api/v1/token/single-access', data).pipe(
      tap((resp: AuthToken) => {
        sessionStorage.setItem('auth_token', JSON.stringify(resp.userToken));
      }),
    );
  }

  public ensureGuestToken(): Observable<void> {
    return new Observable<void>(observer => {
      const authToken = this.getAuthToken();
      if (!this.isAuthTokenLocallyValid(authToken)) {
        this.generateGuestToken(observer);
      } else {
        this.hasToken.next(true);
        observer.next();
        observer.complete();
      }
    });
  }

  private generateGuestToken(observer: Subscriber<void>): void {
    this.http
      .post('/api/users/token/guest', {
        processor: ProcessorName[this.processor],
        // Only add the bankId if it's loaded
        ...(this.bankId && { bankId: this.bankId }),
      })
      .subscribe(
        (resp: AuthToken) => {
          sessionStorage.setItem('auth_token', JSON.stringify(resp.userToken));
          this.hasToken.next(true);
          observer.next();
          observer.complete();
        },
        error => {
          observer.error(error);
        },
        () => {
          observer.complete();
        },
      );
  }

  public authenticateWithEmailTokenNoCaptcha(token: string, processor?: string): Observable<AuthToken> {
    const data = {
      accessToken: token,
      // only add the processor if it comes
      ...((processor || ProcessorName[this.processor]) && { processor: processor || ProcessorName[this.processor] }),
      // Only add the bankId if it's loaded
      ...(this.bankId && { bankId: this.bankId }),
    };

    return this.http.post('/api/users/v2/token/single-access', data).pipe(
      tap((resp: AuthToken) => {
        sessionStorage.setItem('auth_token', JSON.stringify(resp.userToken));
      }),
    );
  }

  protected headers() {
    const userToken = this.getAuthToken();
    if (this.isAuthTokenLocallyValid(userToken)) {
      return { Authorization: `Bearer ${userToken.token}` };
    }
    return {};
  }

  public getAuthToken(): UserToken {
    const rawToken = sessionStorage.getItem('auth_token') || sessionStorage.getItem('$kb$_currentUser');
    if (!rawToken) return null;

    try {
      return JSON.parse(rawToken);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public renewAuthToken(newToken: UserToken): void {
    sessionStorage.setItem('auth_token', JSON.stringify(newToken));
  }

  public isAuthTokenLocallyValid(authToken: UserToken): boolean {
    if (!authToken) return false;

    const expiresAt = moment.utc(authToken.expiresAt);
    if (!expiresAt.isValid() || expiresAt.isBefore(moment())) {
      return false;
    }

    return true;
  }

  public removeAuthToken(): void {
    sessionStorage.removeItem('auth_token');
  }

  public authenticateCaptchaWithGuestToken(recaptchaToken: string) {
    return this.http.get(`/api/users/token/recaptcha?recaptchaToken=${recaptchaToken}`);
  }
}
