import { Component, Input, OnInit } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs/operators';
import { CartPopupComponent } from '../cart-popup/cart-popup.component';
import { CartPopupConstants } from '@eventhorizon/components/cart/cart-popup/cart-popup.constants';

@Component({
  selector: 'app-tg-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent implements OnInit {
  public isCartModalOpen = false;

  @Input() cartComponent = CartPopupComponent;

  constructor(private bsModalService: BsModalService, public cartStore: CartStore) {}

  ngOnInit() {
    this.cartStore.loadCart().subscribe();
  }

  public onClick() {
    this.isCartModalOpen = true;
    const modalRef: BsModalRef = this.bsModalService.show(this.cartComponent, {
      class: 'side-cart',
      animated: true,
      ariaLabelledBy: CartPopupConstants.ariaLabelledBy,
    });
    modalRef.onHide
      .pipe(
        map(() => {
          this.isCartModalOpen = modalRef.content?.result;
        }),
        take(1),
      )
      .subscribe();
  }
}
