import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

export function isAddressEmpty(a: Address): boolean {
  if (!a) return true;
  return !a.address1 && !a.address2 && !a.city && !a.state && !a.zip;
}

export function isAddressEqual(a: Address, b: Address): boolean {
  if (!a || !b) return false;
  const eq =
    a.address1 === b.address1 &&
    a.address2 === b.address2 &&
    a.city === b.city &&
    a.state === b.state &&
    a.zip === b.zip;
  return eq;
}

export function copyAddress(from: Address, to: Address) {
  if (!from || !to) return;
  to.address1 = from.address1;
  to.address2 = from.address2;
  to.city = from.city;
  to.state = from.state;
  to.zip = from.zip;
}

export function clearAddress(a: Address) {
  a.address1 = '';
  a.address2 = '';
  a.city = '';
  a.state = '';
  a.zip = '';
}

export class Address {
  address1: string;

  address2: string;

  city: string;

  state: string;

  zip: string;

  /*
  set address1(v: string) {
    this._address1 = v;
    console.log("address1 now: " + this._address1);
  }
  @computed get address1() {
    return this._address1;
  }
  */

  constructor(obj?: any) {
    Object.assign(this, obj);
    makeObservable(this, {
      address1: observable,
      address2: observable,
      city: observable,
      state: observable,
      zip: observable,
    });
  }

  /**
   * Is there enough data filled out in this address to try and validate?
   */
  public shouldTryValidation() {
    if (this.address1 && this.zip) {
      return true;
    }

    return this.address1.length > 0 && this.city.length > 0 && this.state.length > 0 && this.zip.length > 0;
  }

  public equals(address: Address): boolean {
    return isAddressEqual(this, address);
  }

  public copyFrom(address: Address) {
    copyAddress(address, this);
  }

  public clear() {
    clearAddress(this);
  }

  public isEmpty(): boolean {
    return isAddressEmpty(this);
  }
}
