import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInterface } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-software-details-modal',
  templateUrl: './software-details-modal.component.html',
  styleUrls: ['./software-details-modal.component.scss'],
})
export class SoftwareDetailsModalComponent implements ModalInterface {
  public title: string;

  public price: string;

  public description: string;

  public priceDescription: number;

  public addToCart: boolean;

  constructor(private modalRef: BsModalRef) {}

  public close(addToCart: boolean): void {
    this.addToCart = addToCart;
    this.modalRef.hide();
  }
}
