import { Component, OnDestroy } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@eventhorizon/services/auth.service';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { IReactionDisposer, reaction } from 'mobx';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { messages } from '@xup-payments/xup-frontend-utils/models';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-software-screen',
  templateUrl: './software-screen.component.html',
  styleUrls: ['./software-screen.component.scss'],
})
export class SoftwareScreenComponent extends BasePageDriverComponent implements OnDestroy {
  messages = messages;

  private applicationDisposer: IReactionDisposer;

  constructor(
    public store: ApplicationStore,
    private cartStore: CartStore,
    protected route: ActivatedRoute,
    private authService: AuthService,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);

    this.applicationDisposer = reaction(
      () => this.cartStore.cart && this.cartStore.cart.products,
      updated => {
        if (updated) lastValueFrom(this.store.loadApplication());
      },
    );
  }

  ngOnDestroy(): void {
    this.applicationDisposer();
  }
}
