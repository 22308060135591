import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { TwoFAService } from '@eventhorizon/services/two-factor-auth.service';
import { twoFATypes } from '@xup-payments/xup-frontend-utils/constants';
import { masks, TwoFAApplicantInfoResponse } from '@xup-payments/xup-frontend-utils/models';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
  @Input() public accessToken: string;

  @Output() public resumeApp: EventEmitter<void> = new EventEmitter<void>();

  @Output() public requestLink: EventEmitter<void> = new EventEmitter<void>();

  public applicantEmailPhone: TwoFAApplicantInfoResponse;

  public twoFactorTypes = twoFATypes;

  public isLoading = true;

  public codeSent = false;

  public requestSent: boolean;

  public errorMessage = '';

  public errorMessageHeading = '';

  public codeErrorMessage = '';

  public requestAppLinkError = '';

  private selectedType: string;

  private code: string;

  public form: FormGroup<ITwoFactorAuthForm>;

  public twoFactorAuthTypeControl: MobxFormControl<string>;

  public codeForm: FormGroup<ITwoFactorCodeForm>;

  public twoFactorAuthCodeControl: MobxFormControl<string>;

  public id: string = randomComponentId();

  public mask = masks.twoFactorAuth;

  public logo = '/assets/keybank/header-footer/KB-Logo.png';

  constructor(private twoFAService: TwoFAService, private fb: FormBuilder) {}

  ngOnInit() {
    this.getApplicantInfo();
    this.buildForm();
  }

  get typeValueFromSelected(): string {
    const getTypeSelected = this.twoFactorTypes.find(type => type.id === this.selectedType);
    if (!getTypeSelected) return '';
    return this.applicantEmailPhone[getTypeSelected.infoName];
  }

  isSelected(type: string): boolean {
    return this.twoFactorAuthTypeControl.value === type;
  }

  buildForm() {
    const codeValidators = [Validators.required, CommonValidator.twoFactorAuthCode];

    this.twoFactorAuthTypeControl = new MobxFormControl<string>(
      'type',
      () => this.selectedType,
      (v: string) => (this.selectedType = v),
      Validators.required,
    );

    this.twoFactorAuthCodeControl = new MobxFormControl<string>(
      'code',
      () => this.code,
      (v: string) => (this.code = v),
      Validators.compose(codeValidators),
    );

    this.form = this.fb.group({
      twoFAType: this.twoFactorAuthTypeControl,
    });

    this.codeForm = this.fb.group({
      twoFACode: this.twoFactorAuthCodeControl,
    });

    this.codeForm.valueChanges.subscribe(() => {
      this.codeErrorMessage = '';
    });
  }

  getApplicantInfo() {
    if (!this.accessToken) {
      console.log('No Token.');
      return;
    }
    this.twoFAService.getApplicantInfo(this.accessToken).subscribe(
      resp => {
        this.applicantEmailPhone = resp;
        this.isLoading = false;
      },
      err => {
        const status = err.status || err.StatusCode;
        if (status === 401 || status === 403 || status === 404) {
          this.errorMessageHeading = 'Resume Application link expired';
          this.errorMessage = 'Resume Application link expires in 24 hours and can only be used once.';
        } else {
          this.errorMessage = 'The server is unavailable, please try again later.';
        }
        this.isLoading = false;
      },
    );
  }

  selectType(id) {
    this.twoFactorAuthTypeControl.setValue(id);
    this.form.updateValueAndValidity();
  }

  submitTypeForCode() {
    if (this.form.valid) {
      this.isLoading = true;
      this.twoFAService.requestCode(this.accessToken, this.selectedType).subscribe(
        () => {
          this.isLoading = false;
          this.codeSent = true;
        },
        err => {
          const status = err.status || err.StatusCode;
          if (status === 401 || status === 403 || status === 404) {
            this.errorMessageHeading = 'Resume Application link expired';
            this.errorMessage = 'Resume Application link expires in 24 hours and can only be used once.';
          } else {
            this.errorMessage = 'The server is unavailable, please try again later.';
          }
          this.isLoading = false;
        },
      );
    }
  }

  back() {
    this.twoFactorAuthTypeControl.reset();
    this.codeSent = false;
    this.twoFactorAuthCodeControl.reset();
  }

  showTypeSelection(): boolean {
    return !this.isLoading && !this.codeSent && this.errorMessage === '';
  }

  showCodeInsertion(): boolean {
    return !this.isLoading && this.codeSent && this.errorMessage === '';
  }

  showErrorMessage(): boolean {
    return !this.isLoading && this.errorMessage !== '' && !this.requestSent;
  }

  showRequestErrorMessage(): boolean {
    return !this.isLoading && this.requestAppLinkError !== '';
  }

  async onRequestLink() {
    if (this.accessToken) {
      try {
        await this.twoFAService.requestNewLink(this.accessToken).toPromise();
        this.requestSent = true;
        this.requestLink.emit();
      } catch (err) {
        this.isLoading = false;
        this.requestAppLinkError = err.error.errors[0];
      }
    }
  }

  async submitCode() {
    try {
      this.isLoading = true;
      await this.twoFAService.validateCode(this.accessToken, this.selectedType, this.code).toPromise();
      this.resumeApp.emit();
    } catch (err) {
      this.isLoading = false;
      this.codeErrorMessage = 'The verification code you entered is incorrect or expired.';
    }
  }
}

export interface ITwoFactorAuthForm {
  twoFAType: FormControl<string>;
}

export interface ITwoFactorCodeForm {
  twoFACode: FormControl<string>;
}