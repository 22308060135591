import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatHandlerService } from '@eventhorizon/services/miramar/chatbox.service';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { formatErrorAsList, formatPhone, randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { masks, ModalInterface } from '@xup-payments/xup-frontend-utils/models';
import { BusinessNameValidator, CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-help-center-popup',
  templateUrl: './help-center-popup.component.html',
  styleUrls: ['./help-center-popup.component.scss'],
})
export class HelpCenterPopupComponent implements ModalInterface, OnInit {
  public isLoading = false;

  public errorMessage: string[];

  public isFormSelected = false;

  public isFormSubmitted = false;

  public form: FormGroup<IHelpCenterPopupForm>;

  public id = randomComponentId();

  public masks = masks;

  constructor(
    private modalRef: BsModalRef,
    private store: ApplicationStore,
    private fb: FormBuilder,
    public contentService: ContentServiceService,
    private chatService: ChatHandlerService,
  ) {}

  public get displayChat() {
    return this.chatService.displayChat;
  }

  public get shouldShowCallBack() {
    return this.store?.status?.progressCode < 100;
  }

  ngOnInit() {
    this.buildForm();
    this.syncLocations();
  }

  openChat() {
    const element = document.getElementById('chat-box-button').querySelector('button.chat-box-toggle');
    if (element instanceof HTMLElement) {
      element.click();
    }
    this.close();
  }

  public syncLocations() {
    this.store.transactionInfo.numberOfLocations = this.store.transactionInfo.numberOfLocations ?? 1;
    this.store.setBusinessLocations(this.store.transactionInfo.numberOfLocations, this.store.businessLocations || []);
  }

  private buildForm() {
    this.form = this.fb.group({
      name: new MobxFormControl<string>(
        'name',
        () => this.store.applicantInfo.name,
        (v: string) => (this.store.applicantInfo.name = v),
        CommonValidator.personalName(),
      ),
      lastName: new MobxFormControl<string>(
        'lastName',
        () => this.store.applicantInfo.lastName,
        (v: string) => (this.store.applicantInfo.lastName = v),
        CommonValidator.personalName(),
      ),
      phoneNumber: new MobxFormControl<string>(
        'phoneNumber',
        () => formatPhone(this.store.applicantInfo.phoneNumber),
        (v: string) => (this.store.applicantInfo.phoneNumber = formatPhone(v)),
        CommonValidator.phone(),
      ),
      email: new MobxFormControl<string>(
        'email',
        () => this.store.applicantInfo.email,
        (v: string) => (this.store.applicantInfo.email = v),
        CommonValidator.email(),
      ),
      legalName: new MobxFormControl<string>(
        'legalName',
        () => this.store.businessInfo.legalName,
        (v: string) => (this.store.businessInfo.legalName = v),
        BusinessNameValidator.legalName(true),
      ),
      recaptcha: new FormControl<string>(null, Validators.required),
    });
  }

  back() {
    this.isFormSelected = false;
    this.isFormSubmitted = false;
  }

  openForm() {
    this.isFormSelected = true;
  }

  async submitForm() {
    try {
      this.isLoading = true;
      this.store.callBackRequested = true;
      await lastValueFrom(
        this.store.save([
          this.store.saveSections.CONTACT_INFO_SAVE,
          this.store.saveSections.CALLBACK_REQUESTED,
          this.store.saveSections.BUSINESS_LOCATION_SAVE,
        ]),
      );

      this.isFormSelected = false;
      this.isFormSubmitted = true;
    } catch (errors) {
      this.errorMessage = formatErrorAsList(errors);
    } finally {
      this.isLoading = false;
    }
  }

  public close(): void {
    this.modalRef.hide();
  }
}

export interface IHelpCenterPopupForm {
  name: FormControl<string>;
  lastName: FormControl<string>;
  phoneNumber: FormControl<string>;
  email: FormControl<string>;
  legalName: FormControl<string>;
  recaptcha: FormControl<string>;
}
