
import { regexs } from '@eventhorizon/data/masks.data';
import { UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { regexValidator } from './common.validator';

export class BusinessNameValidator {
  
  static companyName(control: UntypedFormControl): ValidationErrors | null {
    return regexValidator(control, regexs.companyName, 'personal-name-invalid', 'personal-name-required');
  }

  static legalName(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(
        control,
        regexs.taxFilingName,
        'legal-name-invalid',
        isRequired ? 'legal-name-required' : undefined,
      );
  }

  static dbaName(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(control, regexs.companyName, 'dba-name-invalid', isRequired ? 'dba-name-required' : undefined);
  }

  static taxName(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(control, regexs.taxFilingName, 'tax-name-invalid', isRequired ? 'tax-name-required' : undefined);
  }

  static mcc(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(control, regexs.mcc, 'mcc-invalid', isRequired ? 'mcc-required' : undefined);
  }
}
