<ng-container *ngIf="form">
  <form [formGroup]="form" #formElem>
    <div class="row">
      <div class="col-12 p-0">
        <div class="row mb-3">
          <div class="col-12 p-0">
            <div class="tg-delivery-options-heading tg-delivery-options-bold">Sales Information</div>
          </div>
        </div>
        <section class="row">
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="annualSales"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="totalAnnualSales"
                  [textMask]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="annualSales">Gross Annual Sales ($)</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="totalAnnualSales"></app-field-error>
            </div>
          </div>
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="annualCardVolume"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="annualCardVolume"
                  [textMask]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="annualCardVolume">Annual Credit Card Volume</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="annualCardVolume"></app-field-error>
            </div>
          </div>
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="averageTicket"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="averageTicketValue"
                  [textMask]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="averageTicket">Average Ticket Size</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="averageTicketValue"></app-field-error>
            </div>
          </div>
        </section>
      </div>

      <div class="col-md-4 ps-0" [hidden]="!store.showAmexAnnualSales">
        <div class="tg-paymenttype-category">
          <div class="tg-paymenttype-percent">
            <div class="left-inner-addon position-relative">
              <span>$</span>
              <input
                id="amexAnnualVolume"
                class="form-control"
                type="text"
                inputmode="numeric"
                autocomplete="on"
                formControlName="amexAnnualVolume"
                [textMask]="masks.usCurrencyNoPrefix"
              />
              <label class="form-label" for="amexAnnualVolume">AMEX Annual Card Sales ($)</label>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <app-field-error [control]="amexAnnualVolume"></app-field-error>
        </div>
      </div>

      <div class="col-md-4 ps-0" [hidden]="!store.showAmexMemberId && !pIController.referralRequiresAmexMemberId">
        <div class="tg-paymenttype-category">
          <div class="tg-paymenttype-percent">
            <div class="left-inner-addon position-relative">
              <input
                id="amexMemberId"
                class="form-control ps-2"
                type="text"
                autocomplete="on"
                formControlName="amexMemberId"
                [textMask]="masks.amexMemberId"
              />
              <label class="form-label" for="amexMemberId">AMEX Membership Number</label>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <app-field-error [control]="amexMemberId"></app-field-error>
        </div>
      </div>
    </div>

    <div class="col-12 p-0 mt-4" [hidden]="!showDeliveryOptions()">
      <div class="row mb-3">
        <div class="col-12 p-0">
          <div class="tg-delivery-options-heading tg-delivery-options-bold">Timeframe from transaction to delivery</div>
          <div class="tg-delivery-options-heading pb-1">
            Provide the percentage of goods or services delivered within the timeframe. Your total must equal 100%.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 tg-paymenttype-category" *ngFor="let option of deliveryOptions; let i = index">
          <div class="mb-3 position-relative">
            <label class="fw-bold tg-deliveryOptions-label" [for]="option.controlName">
              {{ option.heading }}
            </label>
            <input
              [id]="option.controlName"
              class="form-control tg-delivery-options-input"
              type="text"
              [textMask]="masks.percentage"
              inputmode="numeric"
              autocomplete="on"
              maxlength="3"
              [formControlName]="option.controlName"
              (change)="onPercentageChange($event.target.value, option.controlName)"
              (input)="onPercentageChange($event.target.value, option.controlName)"
            />
            <span class="tg-delivery-options-percent">%</span>
          </div>
        </div>
        <input [id]="'percentage-valid' + id" type="hidden" formControlName="deliveryPercentageValid" />
        <div class="col-12 text-start" *ngIf="hideTotalOptionsError()">
          <app-field-error [control]="deliveryPercentageValid"></app-field-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
        {{ errorMessage }}
      </div>
    </div>
  </form>
</ng-container>
