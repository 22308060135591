import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { isNumber } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-bussiness-verification',
  templateUrl: './business-verification.component.html',
  styleUrls: ['./business-verification.component.scss'],
})
export class BusinessVerificationComponent implements OnInit {
  @Input() applicationId: string;

  public isNumber = isNumber;

  public legalName: string;

  public ownerName: string;

  constructor(public applicationService: ApplicationService) {}

  ngOnInit() {
    this.applicationService.getApplicationById(this.applicationId).subscribe(res => {
      this.legalName = res.businessInfo && res.businessInfo.legalName;
      const ownerObj = res.businessOwners.filter(owner => owner.primaryOwner).pop();

      this.ownerName = `${ownerObj.firstName} ${ownerObj.lastName}`;
    });
  }

  get displayBOSinformation() {
    return (window as any).displayBosInformation;
  }
}
