export const twoFATypes = [
  {
    id: 'sms',
    name: 'Text Message (SMS)',
    description: 'Text me at ',
    infoName: 'phoneNumber',
    iconClass: 'fal fa-mobile-screen-button',
  },
  {
    id: 'email',
    name: 'Email',
    description: 'Email me at ',
    infoName: 'emailAddress',
    iconClass: 'fal fa-envelope',
  },
  {
    id: 'phone',
    name: 'Phone Call',
    description: 'Call me at ',
    infoName: 'phoneNumber',
    iconClass: 'fal fa-phone-flip',
  },
];
