import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})

export class CheckoutComponent extends FormCarouselSlide {
  constructor(
    public cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
  ) {
    super(bsModalService, cd);
  }

  onOpen() {
    super.onOpen();
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}
