import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { CartStore } from '@eventhorizon/stores/cart.store';
import type { CartPopupComponent } from '@eventhorizon/components/cart/cart-popup/cart-popup.component';
import { CurrencyPipe } from '@angular/common';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Router } from '@angular/router';
import { masks, Product, UpdateProductRequest } from '@xup-payments/xup-frontend-utils/models';
import {
  defaultImage,
  mandatoryProductsIds,
  paymentTerms,
  productTypes,
  PURCHASE_TYPES_CODES,
  ROUTES,
} from '@xup-payments/xup-frontend-utils/constants';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tg-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss'],
})
export class CartProductComponent implements OnInit {
  public masks = masks;

  public mandatoryProductsIds = mandatoryProductsIds;

  public defaultImage = defaultImage;

  public cannotRemove = true;

  public showQuantity = true;

  public productsRoute = `/${ROUTES.PRODUCTS}/`;

  private _product: Product;

  @Input()
  set product(product: Product) {
    this._product = { ...new Product(), ...product };
    this.cannotRemove = this.mandatoryProductsIds.includes(this.product.productId) || this.isMonthlyFee;
  }

  get product(): Product {
    return this._product;
  }

  @Input()
    transactionRate: string;

  @Input()
    readonly: boolean;

  @Input()
    continue: string;

  @Input()
    alignCenter = false;

  @Input()
  public showExtendedDisplay = false;

  @Input()
  public showPaymentTermAsColumn = false;

  @Input()
  public modalDialogInstance: CartPopupComponent;

  public form: FormGroup<ICartProductForm>;

  public quantityControl: MobxFormControl<number>;

  public paymentTypesControl: MobxFormControl<string>;

  public paymentTermControl: MobxFormControl<string>;

  public paymentTypes;

  public termOptions;

  private selectedPaymentType: string;

  private selectedPaymentTerm: string;

  private previousSelectedPaymentType: string;

  private previousSelectedPaymentTerm: string;

  public leaseCode: string;

  constructor(
    public cartStore: CartStore,
    protected applicationStore: ApplicationStore,
    protected fb: FormBuilder,
    protected router: Router,
    protected currencyPipe: CurrencyPipe,
    public productService: ProductService,
  ) {}

  ngOnInit() {
    this.showQuantity = !this.productService.isSoftwareProduct(this.product) && !this.isMonthlyFee;
    this.determinePaymentOptions();
    this.buildForm();
  }

  get isOwned() {
    return this.product?.purchaseType === 'O';
  }

  get isMonthlyFee() {
    return this.product?.productType === productTypes.MONTHLY_FEE;
  }

  private buildForm(): void {
    this.quantityControl = new MobxFormControl<number>(
      'quantity',
      () => this.product?.quantity,
      (v: number) => (this.product.quantity = v),
      CommonValidator.number(),
    );

    this.selectedPaymentType = this.product?.paymentType;
    this.previousSelectedPaymentType = this.selectedPaymentType;

    this.paymentTypesControl = new MobxFormControl<string>(
      'paymentTypesControl',
      () => this.selectedPaymentType,
      (v: string) => (this.selectedPaymentType = v),
      Validators.required,
    );

    this.selectedPaymentTerm = this.product?.purchaseType;
    this.previousSelectedPaymentTerm = this.selectedPaymentTerm;
    this.paymentTermControl = new MobxFormControl<string>(
      'paymentTermControl',
      () => this.selectedPaymentTerm,
      (v: string) => (this.selectedPaymentTerm = v),
      Validators.required,
    );

    this.form = this.fb.group({
      productQuantity: this.quantityControl,
      paymentTypesControl: this.paymentTypesControl,
      paymentTermControl: this.paymentTermControl,
    });
    if (this.cannotRemove && this.showQuantity) {
      this.quantityControl.disable();
    }
  }

  public determinePaymentOptions(): boolean {
    this.paymentTypes = [];
    this.termOptions = {};
    if (!this.product || !this.product.pricingModel || !this.product.pricingModel.length) return;
    if (this.product.pricingModel) {
      for (const pricingModel of this.product.pricingModel) {
        if (pricingModel.paymentType === 'Purchased') {
          this.paymentTypes.push({ key: pricingModel.paymentType, value: 'One Time' });
        } else if (pricingModel.paymentType === 'Lease') {
          this.leaseCode = pricingModel.purchaseType;
        }

        if (['Lease', 'Installment'].includes(pricingModel.paymentType)) {
          this.paymentTypes.push({ key: pricingModel.paymentType, value: pricingModel.paymentType });
          if (typeof this.termOptions[pricingModel.paymentType] === 'undefined') {
            this.termOptions[pricingModel.paymentType] = [];
          }
          this.termOptions[pricingModel.paymentType].push({
            key: pricingModel.purchaseType,
            value: pricingModel.purchaseTypeDisplay,
          });
        }
      }
    }
  }

  public async onQuantityChange(quantity: number) {
    const locationId = this.applicationStore.firstBusinessLocationId;

    if (!locationId) {
      console.error('Unable to add product to cart');
      return;
    }

    const product: UpdateProductRequest = {
      id: this.product.productId,
      purchaseType: this.product.purchaseType,
      quantity,
      businessLocationId: locationId,
    };
    await lastValueFrom(this.cartStore.updateProducts([product]));
  }

  public getPaymentTerm(includePayments: boolean = true): string {
    if (this.showQuantity) {
      switch (this.selectedPaymentTerm) {
        case PURCHASE_TYPES_CODES.INSTALLMENT_3_PAYMENTS:
        case PURCHASE_TYPES_CODES.RENT:
        case PURCHASE_TYPES_CODES.LEASE_24_MONTHS:
        case PURCHASE_TYPES_CODES.LEASE_36_MONTHS:
          return includePayments
            ? `${this.product.paymentTerm} Payments of ${this.currencyPipe.transform(this.product.price)}`
            : `${this.product.paymentTerm} Installments`;
        case PURCHASE_TYPES_CODES.OWNED:
          return paymentTerms.OWNED;
        case PURCHASE_TYPES_CODES.PURCHASED:
          return paymentTerms.PURCHASED;
        case PURCHASE_TYPES_CODES.EXISTING:
          return paymentTerms.EXISTING;
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  public getTotalPrice(): number {
    return (this.product.quantity ? this.product.quantity : 1) * this.product.price;
  }

  public async removeProduct() {
    const locationId = this.applicationStore.firstBusinessLocationId;

    if (!locationId) {
      console.error('Unable to remove product from cart');
      return;
    }

    const product: UpdateProductRequest = {
      id: this.product.productId,
      purchaseType: this.product.purchaseType,
      quantity: 0,
      businessLocationId: locationId,
    };
    await lastValueFrom(this.cartStore.updateProducts([product]));
  }
}

export interface ICartProductForm {
  productQuantity: FormControl<number>;
  paymentTypesControl: FormControl<string>;
  paymentTermControl: FormControl<string>;
}
