import { Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { when } from 'mobx';
import { messages } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-verification-screen',
  templateUrl: './verification-screen.component.html',
  styleUrls: ['./verification-screen.component.scss'],
})
export class VerificationScreenComponent extends BasePageDriverComponent {
  messages = messages;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router);
    when(
      () => this.store.isLoaded,
      () => {
        this.messages.verificationSlide.backText = this.store.businessLocations.length <= 1 ? 'Back' : '';
      },
    );
  }
}
