import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ApplicationGuard } from './guards/application.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobxAngularModule } from 'mobx-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PagesModule } from '@app/pages/pages.module';
import { getBaseHref } from '@eventhorizon/utils/util';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha-2';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from '@eventhorizon/utils/auth.interceptor';
import { ApplicationInterceptor } from '@eventhorizon/utils/application.interceptor';
import { ContactInfoResolver } from './resolvers/contact-info.resolver';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaskitoDirective,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    MobxAngularModule,
    PagesModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ApplicationGuard,
    ContactInfoResolver,
  ],
})
export class BaseModule {}
