import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Content, ListItem } from '@xup-payments/xup-frontend-utils/models';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss'],
})
export class IconListComponent extends ContentBase {
  @Input() public iconList: ListItem[];

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setContent(content: Content) {
    this.iconList = content.listItems;
  }
}

export default IconListComponent;
