import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ContentHostDirective } from '../content-host.directive';
import { ContentBase } from '../content-components/base-content/content.base';
import { contentComponentsConfig } from '@app/pages/content-page/content-module/content-loader/content.config';
import { Content } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
})
export class ContentLoaderComponent {
  @ViewChild(ContentHostDirective, { static: true })
    contentHost: ContentHostDirective;

  @Input() set content(content: Content) {
    this.loadComponent(this.contentHost.viewContainerRef, content);
  }

  async loadComponent(vcr: ViewContainerRef, content: Content) {
    vcr.clear();
    let componentPath = contentComponentsConfig[content.contentType];
    if (componentPath) {
      const { default: contentComponent } = await import(
        /* webpackExclude:  /\.spec.ts$/ */ `./../content-components/${componentPath}.ts`
      );
      if (contentComponent) {
        const createdComponentRef = vcr.createComponent<ContentBase>(contentComponent);
        createdComponentRef.instance.setContent(content);
      }
    }
  }
}
