import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { Address } from './address.model';

export class ShippingLocations {
  firstName: string;

  lastName: string;

  companyName: string;

  address: Address = new Address();

  constructor(obj?: any) {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      companyName: observable,
      address: observable,
    });
    Object.assign(this, obj);
  }

  public isInitialized(): boolean {
    return !!this.firstName && !!this.lastName && !!this.companyName && this.companyName.length > 2;
  }
}

export interface InitialShippingLocationValues {
  firstName: string;
  lastName: string;
  companyName: string;
}
