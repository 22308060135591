<form *ngIf="columnedBankInfo" [formGroup]="form" #formElem>
  <div class="row">
    <div class="col-12 p-0">
      <div class="mb-3 position-relative">
        <label [for]="id + 4" class="yodlee-link ps-0">
          <a (click)="(yodleeAction)">
            <input
              [id]="id + 4"
              type="radio"
              [value]="true"
              formControlName="bankInfoMethod"
              (click)="yodleeAction()"
              class="btn-link" />
            Link Account With Instant Verification <i class="fa-solid fa-link"></i
          ></a>
        </label>
      </div>
    </div>
  </div>

  <div class="col-sm-12 mx-auto bank-row ps-0">
    <div class="col-sm-12 col-md-3 mb-3 position-relative ps-0">
      <input
        [id]="id"
        formControlName="routing"
        class="form-control"
        type="text"
        autocomplete="on"
        [maskito]="masks.routingAlt"
        [class.validation-failed]="validationFailed"
        (focus)="clearMaskedControl(routing)"
      />
      <label class="form-label" [for]="id"> Routing Number </label>

      <app-field-error [control]="routing"></app-field-error>
    </div>

    <div class="col-sm-12 col-md-3 mb-3 position-relative">
      <div class="right-inner-addon">
        <span>
          <i
            class="far"
            [ngClass]="{
              'fa-eye-slash': !fieldTextType,
              'fa-eye': fieldTextType
            }"
            (click)="showAccountNumber()"
          ></i>
        </span>
        <input
          [id]="id + 1"
          formControlName="accountNumber"
          class="form-control"
          type="password"
          autocomplete="new-password"
          [maskito]="masks.accountAlt"
          [type]="fieldTextType ? 'text' : 'password'"
          (focus)="clearMaskedControl(accountNumber)"
        />
        <label class="form-label" [for]="id + 1"> Account Number </label>
        <app-field-error [control]="accountNumber"></app-field-error>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 mb-3 position-relative">
      <input
        [id]="id + 2"
        formControlName="bankName"
        class="form-control bank-name-disabled"
        type="text"
        tabindex="-1"
        [readonly]="true"
        autocomplete="on"
      />
      <label class="form-label" [for]="id + 2"> Bank Name</label>
      <app-field-error [control]="bankName"></app-field-error>
    </div>
  </div>

  <div
    class="col-6 col-sm-8 col-md-9 mb-3 ps-0 position-relative mb-0 d-flex align-items-center"
    *ngIf="!location?.isPrimary"
  >
    <label class="checkbox-label" [for]="id + 3"
      ><input
        [id]="id + 1"
        type="checkbox"
        formControlName="usePrimary"
        class="form-option-input"
        (click)="changeSameAsPrimary($event.target.checked)"
      />&nbsp; Same as Primary Location bank account</label
    >
  </div>
</form>

<div class="row">
  <div class="col-sm-12 form-error-message error-message">
    {{ errorMessage }}
  </div>
</div>
