import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class MatchHundredValidator {
  static matchHundredControlValidator(otherControlName: string) {
    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return (control: UntypedFormControl) => {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;
        if (!otherControl) {
          throw new Error(`matchHundredValidate(): other control is not found in parent group: ${name}`);
        }
      }

      const total = +otherControl.value;

      if (total !== 100) {
        if (!(thisControl as any).subscribed) {
          (thisControl as any).subscribed = true;
          otherControl.valueChanges.subscribe(() => {
            thisControl.updateValueAndValidity();
          });
        }
        return { 'match-one-hundred': true };
      }

      return null;
    };
  }

  static matchHundredValidator(includeZero: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!includeZero && +control.value !== 100) {
        return {
          'match-one-hundred': true,
        };
      }
      if (includeZero && control.value && +control.value !== 100) {
        return {
          'match-one-hundred': true,
        };
      }
      return null;
    };
  }
}
