import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { Address } from './address.model';

export class BusinessOwner {
  id: string;

  firstName: string;

  lastName: string;

  address: Address = new Address();

  email: string;

  phone: string;

  socialSecurityNumber: string;

  title: string;

  dob: string;

  dateOfBirth: Date;

  percentageOwnership: number;

  moreOwners: boolean;

  primaryOwner: boolean;

  isKeyController = false;

  hasPoliticalTies?: boolean;

  ownerAddress: string;

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      address: observable,
      email: observable,
      phone: observable,
      socialSecurityNumber: observable,
      title: observable,
      dob: observable,
      dateOfBirth: observable,
      percentageOwnership: observable,
      moreOwners: observable,
      primaryOwner: observable,
      isKeyController: observable,
      hasPoliticalTies: observable,
    });
  }
}
