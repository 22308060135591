import { Component, Input, OnInit } from '@angular/core';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { ROUTES } from '@eventhorizon/constants/routes.constants';
import { CartStore } from '../../../stores/cart.store';

@Component({
  selector: 'app-your-solution',
  templateUrl: './your-solution.component.html',
  styleUrls: ['./your-solution.component.scss'],
})
export class YourSolutionComponent implements OnInit {
  @Input() applicationId: string;

  public isLoading = false;

  constructor(private cartStore: CartStore, private locationsService: LocationsService) {}

  ngOnInit() {
    this.isLoading = true;
    this.cartStore.destroyCart();

    this.cartStore
      .loadCart(true)
      .subscribe()
      .add(() => (this.isLoading = false));
  }

  public isApplicationMultilocation(): boolean {
    return this.locationsService.isMultiLocation();
  }

  public get routeToSummary() {
    return `/${ROUTES.ID_VERIFICATION}`;
  }
}
