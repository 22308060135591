import { Component, Input, OnInit } from '@angular/core';
import { Details, InterchangePlusRatesFees, SwipeNonSwipeRatesFees } from '@xup-payments/xup-frontend-utils/models';
import { elavonIds, fiServIds } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-rates-fees',
  templateUrl: './rates-fees.component.html',
  styleUrls: ['./rates-fees.component.scss'],
})
export class RatesFeesComponent implements OnInit {
  @Input() showRates: boolean;

  @Input() isSwiped: boolean;

  @Input() ratesAndFees: Details[];

  swipedExampleFees: SwipeNonSwipeRatesFees = {
    swipe: null,
    nonSwipe: null,
  };

  interchangePExampleFees: InterchangePlusRatesFees = {
    visaDebit: null,
    visaCredit: null,
  };

  fiservIds = fiServIds;

  elavonIds = elavonIds;

  ngOnInit() {
    if (this.isSwiped) {
      this.getSwipeNonSwipeExampleFees();
    } else {
      this.getInterchangePlusExampleFees();
    }
  }

  getSwipeNonSwipeExampleFees() {
    const swipeIdsFiserv = this.fiservIds.swipedNonSwiped;
    const swipe = this.ratesAndFees.find(rate => rate.productId === swipeIdsFiserv[0]);

    if (swipe) {
      this.swipedExampleFees.swipe = swipe;
      this.swipedExampleFees.nonSwipe = this.ratesAndFees.find(rate => rate.productId === swipeIdsFiserv[1]);
    } else {
      this.searchElavonSwipedNonSwipedRates();
    }
  }

  getInterchangePlusExampleFees() {
    const interchangeIdsFiserv = this.fiservIds.interchangePlus;

    this.interchangePExampleFees.visaDebit = this.ratesAndFees.find(rate => rate.productId === interchangeIdsFiserv[0]);
    this.interchangePExampleFees.visaCredit = this.ratesAndFees.find(
      rate => rate.productId === interchangeIdsFiserv[1],
    );
  }

  protected searchElavonSwipedNonSwipedRates() {
    const elavonIdPrefix = this.elavonIds.swipedNonSwiped.name;
    let swiped: Details = null;
    let swipedId: string;
    let nonSwipedId: string;
    const swipedType = this.elavonIds.swipedNonSwiped.types[0];
    const nonSwipedType = this.elavonIds.swipedNonSwiped.types[1];

    try {
      this.elavonIds.swipedNonSwiped.classes.forEach((rateClass: string) => {
        if (swiped) throw Error('Break');
        swipedId = this.elavonIdsGenerator(elavonIdPrefix, rateClass, swipedType);
        nonSwipedId = this.elavonIdsGenerator(elavonIdPrefix, rateClass, nonSwipedType);
        swiped = this.ratesAndFees.find(rate => rate.productId === swipedId);
      }, this);
    } catch (err: any) {
      if (err === 'Break') {
        this.swipedExampleFees.swipe = swiped;
        this.swipedExampleFees.nonSwipe = this.ratesAndFees.find(
          rate => rate.productId === nonSwipedId || rate.productId === '',
        );
      } else console.error(err);
    }
  }

  protected elavonIdsGenerator(name: string, rateClass: string, rateType: string): string {
    return `${name}_${rateClass}_${rateType}`;
  }

  public getFeeValue(fees: Details): number {
    if (fees.perAuthDefault) {
      return fees.perAuthDefault + fees.feeDefault;
    }
    return fees.feeDefault;
  }
}
