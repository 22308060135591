import { Component } from '@angular/core';
import { messages } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-order-status-screen',
  templateUrl: './order-status-screen.component.html',
  styleUrls: ['./order-status-screen.component.scss'],
})
export class OrderStatusScreenComponent {

  messages = messages;

  public errorMessage: string;
}
