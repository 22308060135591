import { Component, OnInit } from '@angular/core';
import { AuthService } from '@eventhorizon/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { RiskService } from '@eventhorizon/services/risk.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PrefillStartDialogComponent } from '@app/pages/prefill-screen/prefill-start-dialog/prefill-start-dialog.component';
import { ROUTES } from '@eventhorizon/constants/routes.constants';
import { lastValueFrom } from 'rxjs';
import { PartnersService } from '@eventhorizon/services/partners.service';
import { getQueryParamFromMalformedURL } from '@eventhorizon/utils/util';
import { CampaignInformation } from '@eventhorizon/models/referral.model';

@Component({
  selector: 'app-prefill-screen',
  templateUrl: './prefill-screen.component.html',
  styleUrls: ['./prefill-screen.component.scss'],
})
export class PrefillScreenComponent implements OnInit {
  private token: string;

  public isLoading = true;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public appStore: ApplicationStore,
    public appService: ApplicationService,
    private locationsService: LocationsService,
    private routingService: RoutingPathService,
    private riskService: RiskService,
    private bsModalService: BsModalService,
    private partnersService: PartnersService,
  ) {}

  ngOnInit() {
    const partnerSlug = this.route.snapshot.paramMap.get('partnerSlug') || '';
    let templateId = this.route.snapshot.paramMap.get('templateId') || '';
    templateId = decodeURIComponent(templateId);
    templateId = templateId.includes('?') ? templateId.substring(0, templateId.indexOf('?')) : templateId;
    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.token = params.token;
        this.prefill();
      } else if (!!partnerSlug && !!templateId) {
        this.saveCampaignInfo();
        this.prefillPartnerTemplate(partnerSlug, templateId);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  public async prefillPartnerTemplate(partnerSlug: string, templateId: string): Promise<any> {
    try {
      const partnerInformation = await lastValueFrom(this.partnersService.getPartnerBySlug(partnerSlug));
      if (partnerInformation?.length) {
        await lastValueFrom(
          this.partnersService.prefillPartnerApplication(
            this.appStore.id,
            partnerInformation[0].id,
            `${partnerSlug}/${templateId}`,
          ),
        );
        this.locationsService.initLocationsService();
        const partnerData = await lastValueFrom(this.partnersService.getPartner(partnerInformation[0].id));
        const storageInfo = {
          slug: partnerSlug,
          logo: partnerData.imageBase64 ? `data:image/png;base64,${partnerData.imageBase64}` : null,
        };
        sessionStorage.setItem('partner_info', JSON.stringify(storageInfo));

        this.router.navigate([ROUTES.CONTACT_INFO]);
      } else {
        sessionStorage.clear();
        this.router.navigate([ROUTES.INTRO_PAGE]);
      }
    } catch (error) {
      if (error.status) {
        switch (error.status) {
          case 404:
            this.router.navigate([`${ROUTES.PARTNER}/${partnerSlug}`]);
            break;
          case 400:
            this.createApplicationForPartnerPrefill(partnerSlug, templateId);
            break;
          case 401:
          case 403:
            sessionStorage.clear();
            this.router.navigate([ROUTES.INTRO_PAGE]);
            break;
        }
      } else {
        sessionStorage.clear();
        this.router.navigate([ROUTES.INTRO_PAGE]);
      }
    }
  }

  public async createApplicationForPartnerPrefill(partnerSlug: string, templateId: string) {
    sessionStorage.clear();
    try {
      await lastValueFrom(this.authService.ensureGuestToken());
      this.appStore.clearApplication();
      await lastValueFrom(this.appStore.createApplication());
      this.prefillPartnerTemplate(partnerSlug, templateId);
    } catch (error) {
      this.router.navigate([ROUTES.INTRO_PAGE]);
    }
  }

  public async prefill(): Promise<boolean> {
    try {
      await lastValueFrom(this.authService.authenticateWithEmailTokenNoCaptcha(this.token));
      await lastValueFrom(this.appStore.loadApplication());
      this.locationsService.initLocationsService();

      // Skip the default route, if there is one
      this.routingService.deactivateRoute('');
      const path = this.routingService.getRouteFromProgress(this.appStore.status.progressCode);
      this.routingService.activateRoute('');
      return await this.router.navigate([path]).then(didNavigate => {
        if (!didNavigate) return false;
        this.bsModalService.show(PrefillStartDialogComponent);
        return didNavigate;
      });
    } catch (e) {
      this.isLoading = false;
      return false;
    }
  }

  public startApplication() {
    const partnerInfoString = sessionStorage.getItem('partner_info');
    sessionStorage.clear();
    this.appStore = new ApplicationStore(this.appService, this.routingService, this.riskService);
    this.authService.ensureGuestToken().subscribe(() => {
      if (partnerInfoString !== null) {
        const partnerInfo = JSON.parse(partnerInfoString);
        this.router.navigate([`${ROUTES.PARTNER}/${partnerInfo.slug}`]);
      } else {
        window.location.href = '/';
      }
    });
  }

  private saveCampaignInfo() {
    const campaignInformation: CampaignInformation = {
      externalCustomerId: getQueryParamFromMalformedURL(this.router.url, 'externalCustomerId'),
      sourceType: getQueryParamFromMalformedURL(this.router.url, 'sourceType'),
      sourceId: getQueryParamFromMalformedURL(this.router.url, 'sourceId'),
      utmMedium: getQueryParamFromMalformedURL(this.router.url, 'utm_medium'),
      utmSource: getQueryParamFromMalformedURL(this.router.url, 'utm_source'),
      utmCampaign: getQueryParamFromMalformedURL(this.router.url, 'utm_campaign'),
      utmTerm: getQueryParamFromMalformedURL(this.router.url, 'utm_term'),
    };
    this.appStore.updateCampaignInformation(campaignInformation);
  }
}
