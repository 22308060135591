import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { IReactionDisposer, reaction } from 'mobx';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationQuizAnswer, GetQuizResponse, masks, QuizQuestion } from '@xup-payments/xup-frontend-utils/models';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { commonErrors } from '@xup-payments/xup-frontend-utils/constants';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tg-verification-quiz',
  templateUrl: './verification-quiz.component.html',
  styleUrls: ['./verification-quiz.component.scss'],
})
export class VerificationQuizComponent extends FormCarouselSlide<FormGroup<IVerificationQuizForm>> implements OnInit, OnDestroy {
  public masks = masks;

  public fieldTextType: boolean;

  public errorMessage: string;

  public id = randomComponentId();

  public quizQuestions: QuizQuestion[] = [];

  public questionsControls = new FormArray<FormGroup<IVerificationQuizQuestionForm>>([]);

  public questions = [];

  private disposer: IReactionDisposer;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: FormBuilder,
    private applicationService: ApplicationService,
  ) {
    super(bsModalService, cd);
    this.disposer = reaction(
      () => this.store?.isLoaded,
      () => this.onOpen(),
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  public onOpen() {
    super.onOpen();
    this.applicationService.getQuiz(this.store.id).subscribe((response: GetQuizResponse) => {
      if (this.quizQuestions.length != response.quizQuestions.length) {
        this.quizQuestions = response.quizQuestions;
        this.quizQuestions.forEach((question: QuizQuestion) => {
          const group = this.fb.group({
            answer: new FormControl<number>(null, Validators.required),
            questionNumber: new FormControl<number>(question.questionNumber, Validators.required),
          });
          this.questionsControls.push(group);
        });
        this.form.controls.quizLoaded.setValue(true);
      }
    });
  }

  public async save(): Promise<boolean> {
    try {
      const request: ApplicationQuizAnswer[] = this.form.controls.questions.value.map(
        (data: { answer: number; questionNumber: number }) =>
          ({ AnswerNumber: data.answer, QuestionNumber: data.questionNumber } as ApplicationQuizAnswer),
      );

      await lastValueFrom(this.store.submitQuiz(request)).then(() => {
        return lastValueFrom(this.store.loadApplication());
      });
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      questions: this.questionsControls,
      quizLoaded: new FormControl<boolean>(false, Validators.requiredTrue),
    });
  }

  public ngOnDestroy(): void {
    if (this.disposer) this.disposer();
  }
}

export interface IVerificationQuizForm {
  questions: FormArray<FormGroup<IVerificationQuizQuestionForm>>;
  quizLoaded: FormControl<boolean>;
}

export interface IVerificationQuizQuestionForm {
  answer: FormControl<number>;
  questionNumber: FormControl<number>;
}