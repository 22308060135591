import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@eventhorizon/services/auth.service';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { messages } from '@eventhorizon/data/messages.data';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipping-screen',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shipping-screen.component.html',
  styleUrls: ['./shipping-screen.component.scss'],
})
export class ShippingScreenComponent extends BasePageDriverComponent {
  messages = messages;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    private authService: AuthService,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);
  }

  public async next() {
    await lastValueFrom(this.store.save([]));
    super.next();
  }
}
