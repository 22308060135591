import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { OrganizationService } from '@eventhorizon/services/organization.service';
import { BusinessTaxesController } from '@eventhorizon/controllers/business-taxes.controller';
import { BsModalService } from 'ngx-bootstrap/modal';
import { commonErrors, TAX_CLASSIFICATION_OPTIONS } from '@xup-payments/xup-frontend-utils/constants';
import { masks, OrganizationType, tinTypes } from '@xup-payments/xup-frontend-utils/models';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tg-business-taxes',
  templateUrl: './business-taxes.component.html',
  styleUrls: ['./business-taxes.component.scss'],
})
export class BusinessTaxesComponent extends FormCarouselSlide implements OnInit {
  public id = randomComponentId();

  public masks = masks;

  public errorMessage: string;

  public isLoading = false;

  private autoPopulatedFields = new Set(['businessType', 'tinType', 'tin', 'taxClassification']);

  public taxClassOptions = TAX_CLASSIFICATION_OPTIONS;

  @ViewChild('focus') public focus: ElementRef = undefined;

  public tinTypes = tinTypes;

  public readonly TIN_ONLY = tinTypes[0].value;

  public readonly TIN_SSN = tinTypes[1].value;

  public readonly SSN_ONLY = tinTypes[2].value;

  public readonly SOLE_P_CODE = 'I';

  private orgTypeOrder = ['L', 'P', 'C', 'PRC', 'T', 'G'];

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected organizationService: OrganizationService,
    public bTController: BusinessTaxesController,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    this.buildForm();
    this.bTController.isOrgTypeAlreadySelectedAtInit();
  }

  public onOpen() {
    super.onOpen();
    this.bTController.loadData();
    this.updateTinTypeValue();
  }

  public initialFocus() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
  }

  private buildForm() {
    this.form = this.fb.group(this.bTController.buildForm());
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    try {
      if (this.form.controls.businessType.value === this.SOLE_P_CODE && this.store.owners.length) {
        // remove extra owners if sole is selected
        this.store.owners = this.store.owners.slice(0, 1);
      }
      await lastValueFrom(this.store.saveBusinessInformation());
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public preOnNext(): Promise<boolean> {
    return this.save();
  }

  public updateFormValidity() {
    Object.keys(this.form.controls).forEach(key => {
      if (this.autoPopulatedFields.has(key)) {
        this.form.controls[key].markAsTouched();
        this.form.controls[key].markAsDirty();
      }
    });
  }

  updateTinTypeValue() {
    this.bTController.updateTinTypeValue();
    this.cd.detectChanges();
  }

  public selectedType() {
    return this.bTController.selectedType();
  }

  public soleProprietorType(): OrganizationType {
    if (this.store.organizationTypes && this.store.organizationTypes.length) {
      const sp = this.store.organizationTypes.find(t => t.code === this.SOLE_P_CODE);
      if (sp) return sp;
    }
    return { code: this.SOLE_P_CODE, description: 'Sole Proprietor / Individual' };
  }

  public businessTypes() {
    const types = [];
    if (this.store.organizationTypes && this.store.organizationTypes.length) {
      this.store.organizationTypes.forEach(t => {
        if (t.code !== this.SOLE_P_CODE) types.push(t);
      });
    }
    types.sort((a, b) => this.orgTypeOrder.indexOf(a.code) - this.orgTypeOrder.indexOf(b.code));
    return types;
  }

  private cleanForm() {
    this.form.controls.businessType.markAsUntouched();
    this.form.controls.tinType.markAsUntouched();
    this.form.controls.tin.markAsUntouched();
    this.form.controls.taxClassification.markAsUntouched();
  }

  public select(code: string) {
    this.form.controls.businessType.setValue(code);
    this.cleanForm();
    this.bTController.evaluateSelectedType();
  }

  public unselect() {
    this.form.controls.businessType.setValue('');
    this.form.controls.tinType.setValue('');
    this.bTController.tinTypeValue = '';
    this.form.controls.businessType.markAsUntouched();
    this.form.markAsUntouched();
  }

  public isSelected(code: string): boolean {
    return this.form.controls.businessType.value === code;
  }

  public typeClass(code: string, className: string): string {
    return `${className}${this.isSelected(code) ? ' is-selected' : ''}`;
  }

  public colClassTinTypes(code: string): string {
    const businessTinTypes = this.bTController.getTinTypes(code);
    const numberTypes = businessTinTypes.length;
    let className = '';

    if (numberTypes < 2) {
      className = 'col-md-12';
    } else if (numberTypes === 2) {
      className = 'col-md-6';
    } else {
      className = 'col-md-4';
    }
    return className;
  }

  public selectTinType(v: string) {
    this.form.controls.tinType.setValue(v);
  }

  public isSelectedTinType(v: string): boolean {
    return this.form.controls.tinType.value === v;
  }

  public tinTypeClass(v: string, code: string, className: string): string {
    return `${className}${
      this.bTController.getTinTypes(code).length === 3
        ? ' business-taxes-tintype'
        : this.bTController.getTinTypes(code).length === 2
          ? ' business-taxes-subtype'
          : ''
    }${this.isSelectedTinType(v) ? ' is-selected' : ''}`;
  }

  /**
   *
   */
  public shouldAskTaxClass(): boolean {
    return this.bTController.shouldAskTaxClass();
  }
}
