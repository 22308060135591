import { Injectable } from '@angular/core';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BusinessLocationModel, DeliveryOptions } from '@xup-payments/xup-frontend-utils/models';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';

@Injectable({
  providedIn: 'root',
})
export class DeliveryOptionsController {
  deliveryOptionCategories: any[] = [
    {
      heading: '% 0 days',
      value: 0,
      controlName: 'timeFrame0',
    },
    {
      heading: '% 1-7 days',
      value: 1,
      controlName: 'timeFrame1To7',
    },
    {
      heading: '% 8-14 days',
      value: 2,
      controlName: 'timeFrame8To14',
    },
    {
      heading: '% 15-30 days',
      value: 3,
      controlName: 'timeFrame15To30',
    },
    {
      heading: '% 30+ days',
      value: 4,
      controlName: 'timeFrameOver30',
    },
  ];

  public isPercentageValid = true;

  public deliveryPercentageValid: MobxFormControl;

  public showTotalOptionsError = false;

  public total;

  constructor(private store: ApplicationStore) {}

  syncLocations() {
    this.store.transactionInfo.numberOfLocations = this.store.transactionInfo.numberOfLocations
      ? this.store.transactionInfo.numberOfLocations
      : 1;
    this.store.setBusinessLocations(this.store.transactionInfo.numberOfLocations, this.store.businessLocations || []);
  }

  buildForm(
    isAssisted: boolean = false,
    location?: BusinessLocationModel,
    locationIndex: number = 0,
  ): DeliveryOptionControls {
    const sBL = isAssisted && location ? location : this.store.businessLocations[locationIndex];
    this.deliveryPercentageValid = new MobxFormControl(
      'deliveryPercentageValid',
      () => (this.isPercentageValid ? 'true' : ''),
      (v: string) => {
        this.isPercentageValid = !!v;
      },
      CommonValidator.checkDeliveryOptionsPercentage(!isAssisted),
    );
    this.deliveryPercentageValid.setValue(this.total);
    this.deliveryPercentageValid.updateValueAndValidity({ onlySelf: true });
    const fbOptionGroup: DeliveryOptionControls = {
      deliveryPercentageValid: this.deliveryPercentageValid,
      timeFrame0: null,
      timeFrame1To7: null,
      timeFrame8To14: null,
      timeFrame15To30: null,
      timeFrameOver30: null,
    };

    if (!sBL.deliveryOptions) {
      sBL.deliveryOptions = new DeliveryOptions();
    }

    this.deliveryOptionCategories.forEach(c => {
      c.control = new MobxFormControl(
        c.controlName,
        () => sBL.deliveryOptions[c.controlName],
        v => (sBL.deliveryOptions[c.controlName] = v),
      );

      c.control.setValue(sBL.deliveryOptions[c.controlName]);
      c.control.updateValueAndValidity({ onlySelf: true });
      c.control.markAsPristine({ onlySelf: true });
      c.control.markAsUntouched({ onlySelf: true });
      fbOptionGroup[c.controlName] = c.control;
    });

    return fbOptionGroup;
  }

  onPercentageChange(value, controlName) {
    const category = this.deliveryOptionCategories.find(c => c.controlName === controlName);
    if (category) {
      category.control.setValue(value);
      this.deliveryPercentageValid.updateValueAndValidity({ onlySelf: true });
      this.total = 0;
      this.deliveryOptionCategories.forEach(c => {
        const controlVal = parseInt(c.control.value, 10);
        this.total += isNaN(controlVal) ? 0 : controlVal;
      });
      this.deliveryPercentageValid.setValue(this.total);
      this.deliveryPercentageValid.markAsTouched();
      this.deliveryPercentageValid.updateValueAndValidity({ onlySelf: true });
    }
  }

  setEmptyToZero() {
    this.deliveryOptionCategories.map(cat => {
      if (!cat.control.value || cat.control.value === '') {
        this.onPercentageChange(0, cat.controlName);
      }
    });
  }

  hideTotalOptionsError() {
    let show = false;
    this.deliveryOptionCategories.forEach(c => {
      const { control } = c;
      show = show || control.dirty;
    });

    return show;
  }
}

export interface DeliveryOptionControls {
  deliveryPercentageValid: MobxFormControl;
  timeFrame0: MobxFormControl;
  timeFrame1To7: MobxFormControl;
  timeFrame8To14: MobxFormControl;
  timeFrame15To30: MobxFormControl;
  timeFrameOver30: MobxFormControl;
}
