import { makeObservable, observable } from 'mobx';

/**
 * Taken form the InternalOrderStatuses table represented by the "Status" enum in the backend
 */
export type AppStatusStr =
  | 'InProgress'
  | 'Abandoned'
  | 'Pending'
  | 'Complete'
  | 'Declined'
  | 'OffRamped'
  | 'Canceled'
  | 'Error';

/**
 * Taken form the InternalOrderStatuses table represented by the "Status" enum in the backend
 * Id ofthe enum
 */
export type AppStatusNum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export enum DDAInterestStatus {
  UNKNOWN,
  YES,
  NO,
}

// 👆🏼 Those types can be updated from these when needed
// https://tinyurl.com/3nu9njb8

export class ExternalStatus {
  constructor(obj?: any) {
    makeObservable(this, {
      id: observable,
      orderProvider: observable,
      status: observable,
      description: observable,
    });

    Object.assign(this, obj);
  }

  id = 0;

  orderProvider = '';

  status = '';

  description = '';
}

export class InternalStatus {
  constructor(obj?: any) {
    makeObservable(this, {
      id: observable,
      status: observable,
      description: observable,
      reasons: observable,
      sendEmail: observable,
      orderStage: observable,
      externalOrderStatuses: observable,
    });

    Object.assign(this, obj);
  }

  id: AppStatusNum = 1;

  status: AppStatusStr = 'InProgress';

  description?: string = '';

  reasons?: string[] = [];

  sendEmail?: boolean = true;

  orderStage?: number = 0;

  externalOrderStatuses?: ExternalStatus[] = [];
}

export class Alert {
  id = 0;

  name = '';

  description = '';

  sendNotification = true;

  applicationAlerts: ApplicationAlert[];

  constructor() {
    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      sendNotification: observable,
      applicationAlerts: observable,
    });
  }
}

export class ApplicationAlert {
  id = 0;

  name = '';

  description = '';

  createdAt?: Date;

  businessLocationId = '';

  constructor() {
    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      createdAt: observable,
      businessLocationId: observable,
    });
  }
}

export class ApplicationStatus {
  name?: string = '';

  lastName?: string = '';

  email?: string = '';

  phoneNumber?: string = '';

  companyName?: string = '';

  applicationId?: string = '';

  orderId?: string = '';

  progressCode = 0;

  assistedProgressCode?: number = 0;

  ddaInterestId?: DDAInterestStatus;

  internalOrderStatus: InternalStatus;

  externalOrderStatus?: ExternalStatus;

  applicationAlerts: ApplicationAlert[];

  createdAt?: Date;

  lastUpdatedAt?: Date;

  constructor() {
    makeObservable(this, {
      name: observable,
      lastName: observable,
      email: observable,
      phoneNumber: observable,
      companyName: observable,
      applicationId: observable,
      orderId: observable,
      progressCode: observable,
      assistedProgressCode: observable,
      internalOrderStatus: observable,
      externalOrderStatus: observable,
      applicationAlerts: observable,
      createdAt: observable,
      lastUpdatedAt: observable,
    });
  }
}
