import { Component } from '@angular/core';
import { ContentBase } from '@app/pages/content-page/content-module/content-components/base-content/content.base';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Content } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-content-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends ContentBase {
  title: string;

  description: string;

  imageUrl: SafeUrl;

  linkUrl: string;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  setContent(content: Content) {
    this.title = content.title;
    this.description = content.description;
    this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(content.imageUrl);
    this.linkUrl = content.linkUrl;
  }
}
export default ImageComponent;
