import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { AverageTicketValueValidator } from '@eventhorizon/validation/average-ticket-value.validator';
import { IReactionDisposer, reaction } from 'mobx';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { PaymentInfoErrorService } from '@eventhorizon/services/payment-info-error.service';
import { PaymentInfoController } from '@eventhorizon/controllers/payment-info.controller';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { masks } from '@eventhorizon/data/masks.data';
import { TransactionInfo } from '@eventhorizon/models/transaction-info.model';
import { currencyToNumber, randomComponentId } from '@eventhorizon/utils/util';
import { formatError } from '@eventhorizon/utils/format-errors';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { lastValueFrom } from 'rxjs';
import { HighTicketValueValidator } from '@eventhorizon/validation/high-ticket-value.validator';
import { InvalidApplicationService } from '@eventhorizon/services/invalid-application.service';

interface AnnualCardVolume {
  display: string;
  value: number;
}

@Component({
  selector: 'app-tg-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent extends FormCarouselSlide implements OnInit, OnDestroy {
  public masks = masks;

  public annualCardVolumes: AnnualCardVolume[] = [
    { display: '$0 - $50K', value: 10000 }, // AK spec calls for 250K here, but need to distinct this in the DB
    { display: '$50K - $250K', value: 50000 },
    { display: '$250K - $500K', value: 250000 },
    { display: '$500K - $1M', value: 500000 },
    { display: '$1M - $2M', value: 1000000 },
    { display: '> $2M', value: 2000000 },
  ];

  public ccId = randomComponentId();

  public numId = randomComponentId();

  public id = randomComponentId();

  public totalAnnualSales: MobxFormControl;

  public annualCardVolume: MobxFormControl;

  public averageTicketValue: MobxFormControl;

  public highTicketValue: MobxFormControl;

  public amexMemberId: MobxFormControl;

  public amexAnnualVolume: MobxFormControl;

  public errorMessage: string;

  public mcc: string;

  public referralRequiresAmexMemberId: boolean;

  public total = 0;

  private disposers: IReactionDisposer[] = [];

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected cartStore: CartStore,
    protected router: Router,
    private paymentInfoErrorService: PaymentInfoErrorService,
    public pIController: PaymentInfoController,
    public contentService: ContentServiceService,
    private invalidApplication: InvalidApplicationService,
  ) {
    super(bsModalService, cd);
    this.disposers.push(
      reaction(
        () =>
          this.store.isLoaded &&
          this.store.businessLocations.length > 0 &&
          !this.store.businessLocations[0].transactionInfo,
        isNull => {
          if (!isNull) return;
          this.store.businessLocations[0].transactionInfo = new TransactionInfo(this.store.transactionInfo);
        },
        {
          fireImmediately: true,
        },
      ),
      reaction(
        () => this.store.mcc,
        (mcc: string) => {
          this.mcc = mcc;
          this.form.get('averageTicketValue').setValidators(this.getAverageTicketValidators());
        },
      ),
      reaction(
        () => this.store.isLoaded,
        (isLoaded: boolean) => {
          if (isLoaded) {
            this.initComponent();
          }
        },
      ),
      reaction(
        () => cartStore.cart && cartStore.cart.products,
        () => {
          this.pIController.getReferralRequiresAmexMemberId();
          if (this.pIController.referralRequiresAmexMemberId || this.pIController.hasAmexDirect) {
            this.validateAmexDirect();
          }
        },
      ),
    );
  }

  ngOnInit() {
    this.initComponent();
  }

  private getAverageTicketValidators() {
    return [
      CommonValidator.number(),
      AverageTicketValueValidator.averageTicketValue(this.mcc),
      AverageTicketValueValidator.averageTicketSmallerThanHighTicketValue('average'),
    ];
  }

  public syncLocations() {
    this.store.transactionInfo.numberOfLocations = this.store.transactionInfo.numberOfLocations
      ? this.store.transactionInfo.numberOfLocations
      : 1;
    this.store.setBusinessLocations(this.store.transactionInfo.numberOfLocations, this.store.businessLocations || []);
  }

  initComponent() {
    this.loadCart();
    this.buildForm();
    this.syncLocations();
    this.pIController.getReferralRequiresAmexMemberId();
    if (this.pIController.referralRequiresAmexMemberId || this.pIController.hasAmexDirect) {
      this.validateAmexDirect();
    }
    this.form.updateValueAndValidity();
  }

  loadCart(): void {
    lastValueFrom(this.cartStore.loadCart());
  }

  private validateAmexDirect() {
    this.amexMemberId.setEnabled(true);
    this.amexMemberId.setValidators(this.pIController.getAmexMemberValidators(true, false, this.amexMemberId));
  }

  private buildForm() {
    const s = this.store;
    this.mcc = this.store.mcc;
    const transactionInfoFromLocation = this.store.businessLocations[0].transactionInfo;
    this.annualCardVolume = new MobxFormControl(
      'annualCardVolume',
      () => this.store.transactionInfo.creditCardVolume,
      v => {
        if (v) {
          this.store.transactionInfo.creditCardVolume = currencyToNumber(v);
          transactionInfoFromLocation.creditCardVolume = currencyToNumber(v);
        }
      },
      Validators.required,
    );
    this.totalAnnualSales = new MobxFormControl(
      'totalAnnualSales',
      () => s.transactionInfo.totalAnnualSales,
      v => {
        if (v) {
          this.store.transactionInfo.totalAnnualSales = currencyToNumber(v);
          transactionInfoFromLocation.totalAnnualSales = currencyToNumber(v);
        }
      },
      Validators.compose([Validators.required, CommonValidator.gt(this.annualCardVolume, 'annual-sales-gt-cc')]),
    );
    this.averageTicketValue = new MobxFormControl(
      'averageTicketValue',
      () => s.transactionInfo.averageTicketValue,
      v => {
        if (v) {
          this.store.transactionInfo.averageTicketValue = currencyToNumber(v);
          transactionInfoFromLocation.averageTicketValue = currencyToNumber(v);
        }
      },
      Validators.compose(this.getAverageTicketValidators()),
    );
    this.highTicketValue = new MobxFormControl(
      'highTicketValue',
      () => s.transactionInfo.highTicketValue,
      v => {
        if (v) {
          this.store.transactionInfo.highTicketValue = currencyToNumber(v);
          transactionInfoFromLocation.highTicketValue = currencyToNumber(v);
        }
      },
    );
    this.amexAnnualVolume = new MobxFormControl(
      'amexAnnualVolume',
      () => s.transactionInfo.amexAnnualVolume,
      v => {
        if (v) {
          this.store.transactionInfo.amexAnnualVolume = currencyToNumber(v);
          transactionInfoFromLocation.amexAnnualVolume = currencyToNumber(v);
        }
      },
      Validators.compose([
        Validators.required,
        CommonValidator.ltWithZero(this.annualCardVolume, 'amex-lt-cc'),
        HighTicketValueValidator.higherThanZero('amex-cc-low'),
      ]),
    );
    this.amexMemberId = new MobxFormControl(
      'amexMemberId',
      () => this.store.transactionInfo.amexmemberId,
      v => {
        if (v) {
          this.store.transactionInfo.amexmemberId = currencyToNumber(v);
          transactionInfoFromLocation.amexmemberId = currencyToNumber(v);
        }
      },
      Validators.compose(
        this.pIController.getAmexMemberValidators(
          this.pIController.referralRequiresAmexMemberId || this.pIController.hasAmexDirect,
          false,
          this.amexMemberId,
        ),
      ),
    );

    this.form = this.fb.group({
      totalAnnualSales: this.totalAnnualSales,
      averageTicketValue: this.averageTicketValue,
      highTicketValue: this.highTicketValue,
      amexAnnualVolume: this.amexAnnualVolume,
      amexMemberId: this.amexMemberId,
      annualCardVolume: this.annualCardVolume,
    });

    this.form.valueChanges.subscribe(
      (change: {
        annualCardVolume: number;
        averageTicketValue: number;
        highTicketValue: number;
        totalAnnualSales: number;
        amexMemberId: number;
      }) => {
        this.paymentInfoErrorService.handlePaymentInformationErrors(change, this.form);
      },
    );

    this.amexAnnualVolume.setEnabled(this.store.showAmexAnnualSales);
    this.amexMemberId.setEnabled(
      this.store.showAmexMemberId || this.pIController.referralRequiresAmexMemberId || this.pIController.hasAmexDirect,
    );
    this.disposers.push(
      reaction(
        () => !!this.store.isLoaded && this.store.showAmexAnnualSales,
        (show: boolean) => {
          this.amexAnnualVolume.setEnabled(show);
        },
      ),
      reaction(
        () => !!this.store.isLoaded && this.store.showAmexMemberId,
        (show: boolean) => {
          this.amexMemberId.setEnabled(
            show || this.pIController.referralRequiresAmexMemberId || this.pIController.hasAmexDirect,
          );
        },
      ),
      reaction(
        () => !!this.store.isLoaded && !!this.store.transactionInfo && this.store.transactionInfo.creditCardVolume,
        () => {
          if (this.store.transactionInfo.creditCardVolume) this.totalAnnualSales.validate();
        },
      ),
    );
    this.invalidApplication.setForm(this.form);
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;

    if (this.amexAnnualVolume.disabled && !this.pIController.referralRequiresAmexMemberId) {
      this.store.transactionInfo.amexAnnualVolume = 0;
      this.store.transactionInfo.amexmemberId = 0;
      this.store.businessLocations[0].transactionInfo.amexAnnualVolume = 0;
      this.store.businessLocations[0].transactionInfo.amexmemberId = 0;
    }
    if (this.amexMemberId.disabled) {
      this.store.transactionInfo.amexmemberId = 0;
      this.store.businessLocations[0].transactionInfo.amexmemberId = 0;
    }
    this.store.transactionInfo.numberOfLocations = null;
    try {
      await lastValueFrom(
        this.store.updateSubmerchant(this.store.businessLocations[0].id, this.store.businessLocations[0]),
      );
      await lastValueFrom(this.store.save([this.store.saveSections.TRANSACTION_INFO_SAVE]));
      return true;
    } catch (e) {
      this.errorMessage = formatError(e);
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    this.store.businessSize = 'Small';
    return this.save();
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  ngOnDestroy(): void {
    for (const disposer of this.disposers) {
      if (disposer) disposer();
    }
  }
}
