import { BankingValidator } from '@eventhorizon/validation/banking.validator';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { BankService, ValidateRequest, ValidateResponse } from '@eventhorizon/services/bank.service';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction, when } from 'mobx';
import { catchError, filter, lastValueFrom, map, mergeMap, of, Subject, Subscription, take } from 'rxjs';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { YodleePopupComponent } from '@eventhorizon/components/yodlee-popup/yodlee-popup.component';
import { commonErrors } from '@eventhorizon/constants/general.constants';
import { masks } from '@eventhorizon/data/masks.data';
import { BankInfoModel } from '@eventhorizon/models/bank-info.model';
import { IYodleeBankAccount, IYodleeClose } from '@eventhorizon/interfaces/yodlee-interface';
import { randomComponentId } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-tg-bank-info',
  templateUrl: './base-bank-info.component.html',
  styleUrls: ['./base-bank-info.component.scss'],
})
export class BaseBankInfoComponent extends FormCarouselSlide implements OnInit, OnDestroy {
  public masks = masks;

  public fieldTextType: boolean;

  public routing: MobxFormControl;

  public bankName: MobxFormControl;

  public accountNumber: MobxFormControl;

  public errorMessage: string;

  public id = randomComponentId();

  public isYodleeSelected;

  @ViewChild('modalContent') public modalContent: ElementRef;

  public bankInfoMethod: MobxFormControl;

  public usePrimary: MobxFormControl;

  private firstExecution = true;

  private destroy: Subject<void> = new Subject();

  private subscriptions: Subscription = new Subscription();

  @Input() columnedBankInfo = true;

  @Input() validationFailed = false;

  @Input() location;

  @Input() primary;

  constructor(
    public applicationStore: ApplicationStore,
    protected bankService: BankService,
    public cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected locationsService: LocationsService,
  ) {
    super(bsModalService, cd);

    reaction(
      () =>
        !!this.applicationStore.isLoaded &&
        !!this.applicationStore.businessLocations &&
        !this.applicationStore.businessLocations[0].bankInfo,
      (isLoaded: boolean) => {
        if (isLoaded) {
          this.applicationStore.businessLocations[0].bankInfo = new BankInfoModel();
        }
      },
      {
        fireImmediately: true,
      },
    );
    this.initWhens();
  }

  ngOnInit(): void {
    this.syncLocations();
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.closeFastLink();
    this.destroy.next();
    this.destroy.complete();
  }

  public isMasked = (value: string): boolean => value.includes('*');

  public syncLocations(): void {
    this.applicationStore.transactionInfo.numberOfLocations = this.applicationStore.transactionInfo.numberOfLocations
      ? this.applicationStore.transactionInfo.numberOfLocations
      : 1;

    this.applicationStore.setBusinessLocations(
      this.applicationStore.transactionInfo.numberOfLocations,
      this.applicationStore.businessLocations || [],
    );
  }

  public yodleeAction() {
    if (this.applicationStore.id) {
      this.isYodleeSelected = true;
      this.getYodleeInfo(this.applicationStore.id);
    }
    const modalRef: BsModalRef = this.bsModalService.show(YodleePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'bank-yodlee',
      class: 'modal-md',
    });
    modalRef.onHide
      .pipe(
        map(() => {
          this.isYodleeSelected = false;
          this.closeFastLink();
        }),
        take(1),
      )
      .subscribe();
  }

  public doesBankInfoExistsAlready(): boolean {
    const bsl = this.applicationStore.businessLocations[0];
    return bsl?.bankInfo && !!bsl.bankInfo.routingNumber && !!bsl.bankInfo.accountNumber;
  }

  public onOpen(): void {
    super.onOpen();
  }

  public buildForm(): UntypedFormGroup {
    if (this.form) {
      return this.form;
    }
    this.routing = new MobxFormControl(
      'routingNumber',
      () => this.locationsService.currentLocation.bankInfo.routingNumber,
      v => {
        this.locationsService.currentLocation.bankInfo.routingNumber = v;
      },
      BankingValidator.routing(),
    );

    this.bankName = new MobxFormControl(
      'bankName',
      () => this.locationsService.currentLocation.bankInfo.bankName,
      v => {
        this.locationsService.currentLocation.bankInfo.bankName = v;
      },
    );

    this.accountNumber = new MobxFormControl(
      'accountNumber',
      () => this.locationsService.currentLocation.bankInfo.accountNumber,
      v => (this.locationsService.currentLocation.bankInfo.accountNumber = v),
      BankingValidator.accountNumber(),
    );

    this.bankInfoMethod = new MobxFormControl(
      'bankInfoMethod',
      () => this.isYodleeSelected,
      v => (this.isYodleeSelected = v),
      [],
      true,
    );

    this.usePrimary = new MobxFormControl(
      'usePrimary',
      () => this.locationsService.currentLocation.bankInfo.usePrimary,
      v => (this.locationsService.currentLocation.bankInfo.usePrimary = v),
    );

    this.checkBankInfo();

    this.bankName.updateValueAndValidity({ onlySelf: true });

    this.subscriptions.add(
      this.routing.valueChanges
        .pipe(
          filter(text => (text ? text.length === 9 : false)),
          mergeMap(routingNumber => {
            const request: ValidateRequest = { routingNumber };
            return this.bankService.validate(request).pipe(
              catchError(() => {
                this.routing.setErrors({ 'routing-invalid': true });
                return of(null);
              }),
            );
          }),
        )
        .subscribe((response: ValidateResponse) => {
          this.bankName.setValue(response ? response.bankName : null);
        }),
    );

    this.subscriptions.add(
      this.bankInfoMethod.valueChanges.subscribe(() => {
        if (this.applicationStore.isLoaded && this.bankInfoMethod.value) {
          this.getYodleeInfo(this.applicationStore.id);
        } else {
          this.closeFastLink();
        }
      }),
    );

    this.bankInfoMethod.updateValueAndValidity({ onlySelf: true });

    this.form = this.fb.group({
      routing: this.routing,
      bankName: this.bankName,
      accountNumber: this.accountNumber,
      bankInfoMethod: this.bankInfoMethod,
      usePrimary: this.usePrimary,
    });

    this.form.updateValueAndValidity();
  }

  public showAccountNumber(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  public showSaveAndContinue(): boolean {
    return true;
  }

  public clearMaskedControl = (control: FormControl) => {
    if (this.isMasked(control.value)) {
      control.reset();
      this.usePrimary.setValue(false);
    }
  };

  public async save(): Promise<boolean> {
    try {
      await lastValueFrom(
        this.applicationStore.updateSubmerchant(
          this.locationsService.currentLocationId,
          this.applicationStore.businessLocations[this.locationsService.currentLocationIndex],
        ),
      );
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public async onSecondaryAction(): Promise<void> {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  private checkBankInfo(): void {
    if (this.doesBankInfoExistsAlready()) {
      try {
        this.bankInfoMethod.setValue(false);
        this.firstExecution = false;
      } catch (e) {
        this.closeFastLink();
      }
    }
  }

  private closeFastLink(): void {
    if ((<any>window).fastlink?.close) {
      (<any>window).fastlink.close();
    }
  }

  private openYoddlee(fastLinkUrl: string, accessToken: string): void {
    const url = fastLinkUrl || 'https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink';
    this.subscriptions.add(
      this.bankService.yodleeIFrameOpen(url, accessToken).subscribe(
        resp => {
          if ((<IYodleeClose>resp).action) {
            const closeResp = <IYodleeClose>resp;
            const site = closeResp.sites[0];
            if (site.additionalStatus && site.additionalStatus === 'AVAILABLE_DATA_RETRIEVED') {
              this.bankService
                .yodleeFastLink(this.applicationStore.id, site.providerAccountId, site.requestId, site.accountId)
                .subscribe(() => {
                  this.firstExecution = true;
                  this.getYodleeInfo(this.applicationStore.id);
                });
            }
          }
        },
        error => {
          console.error(error);
        },
      ),
    );
  }

  private setAccountFromYodlee(account: IYodleeBankAccount): void {
    this.bsModalService.hide();
    this.routing.setValue(account.routingNumber);
    this.accountNumber.setValue(account.accountNumber);
    this.bankInfoMethod.setValue(false);
    this.firstExecution = false;
  }

  public getYodleeInfo(applicationId: string): void {
    this.subscriptions.add(
      this.bankService.yodleeAccounts(applicationId).subscribe(resp => {
        if (!resp.accounts || !this.firstExecution) {
          this.openYoddlee(resp.fastLinkUrl, resp.accessToken);
        } else {
          const accountSelected = resp.accounts.find(account => account.id === resp.selectedAccountId);
          this.setAccountFromYodlee(accountSelected);
        }
      }),
    );
  }

  public changeSameAsPrimary(value: boolean) {
    if (value) {
      this.usePrimary.setValue(true);
      this.routing.setValue(this.primary.bankInfo.routingNumber, { emitEvent: false });
      this.accountNumber.setValue(this.primary.bankInfo.accountNumber);
      this.bankName.setValue(this.primary.bankInfo.bankName);
    } else {
      this.form.reset();
      this.usePrimary.setValue(false);
    }
  }

  private initWhens(): void {
    when(
      () => this.applicationStore.isLoaded,
      () => {
        this.checkBankInfo();
        if (this.bankInfoMethod) this.bankInfoMethod.updateValueAndValidity();
      },
    );
  }
}
