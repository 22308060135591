import { Component, EventEmitter, Input, Output } from '@angular/core';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';

@Component({
  selector: 'app-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.component.scss'],
})
export class AccordionGroupComponent {
  protected id: string = randomComponentId();

  @Input() isDisabled = false;

  @Input() class = '';

  @Input()
  get isOpen(): boolean {
    return this._isOpen;
  }

  /** Emits when the opened state changes */
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter();

  set isOpen(value: boolean) {
    if (value !== this.isOpen) {
      this._isOpen = value;
      Promise.resolve().then(() => {
        this.isOpenChange.emit(value);
      });
    }
  }

  protected _isOpen = false;

  toggleItem() {
    if (!this.isDisabled) {
      this.isOpen = !this.isOpen;
    }
  }
}
