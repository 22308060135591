import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { constants } from '@eventhorizon/constants/general.constants';

export class BankingValidator {
  static routing(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /\*{5}\d{4}|\d{9}/;
        if (!regex.test(control.value)) {
          return {
            'routing-invalid': true,
          };
        }
      } else if (isRequired) {
        return {
          'routing-required': true,
        };
      }

      return null;
    };
  }

  static accountNumber(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /\*{1,13}\d{4}|\d{4,17}/;
        if (!regex.test(control.value)) {
          return {
            'account-number-invalid': true,
          };
        }
      } else if (isRequired) {
        return {
          'account-number-required': true,
        };
      }

      return null;
    };
  }

  static bankName(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        // this should only be autocompleted from FirstData... so, no validation?
        return null;
      }
      if (isRequired) {
        return {
          'bank-name-required': true,
        };
      }
    };
  }

  static annualCCVol(control: UntypedFormControl): ValidationErrors | null {
    let val = null;
    if (!isNaN(control.value)) {
      if (control.value < constants.ANNUAL_CC_VOL_MIN) val = { 'annual-ccvol-low': true };
      if (control.value >= constants.ANNUAL_CC_VOL_MAX) val = { 'annual-ccvol-high': true };
    }
    return val;
  }

  static grossAnnualVolume(control: UntypedFormControl): ValidationErrors | null {
    let val = null;
    if (!isNaN(control.value)) {
      if (control.value > constants.GROSS_ANNUAL_VOLUME_MAX) val = { 'annual-gross-volume-max': true };
    }
    return val;
  }
}
