import { Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { autorun } from 'mobx';
import { HelpCenterPopupComponent } from '@eventhorizon/components/help-center-popup/help-center-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { messages } from '@eventhorizon/data/messages.data';
import { constants } from '@eventhorizon/constants/general.constants';

@Component({
  selector: 'app-terms-screen',
  templateUrl: './terms-screen.component.html',
  styleUrls: ['./terms-screen.component.scss'],
})
export class TermsScreenComponent extends BasePageDriverComponent {
  messages = messages.termsAndConditionsSlide;

  public submitLimit: boolean = false;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    private bsModalService: BsModalService,
    private contentService: ContentServiceService,
  ) {
    super(rs, router, route);
    autorun(() => {
      // if (this.store === undefined) {
      //   return;
      // }
      if (this.store) {
        this.submitLimit = this.store.submitAttempts >= constants.ONBOARDING_MAX_SUBMISSIONS;
      }
    });
    const title = this.contentService?.bankInfoConfig?.agreementPageConfig?.title;
    this.messages.smallHeadline = title || this.messages.smallHeadline;
  }

  public openHelpCenter() {
    this.bsModalService.show(HelpCenterPopupComponent, {
      class: 'modal-lg',
      ariaLabelledBy: 'help-center',
    });
  }
}
