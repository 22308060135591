import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AddressComponent, IAddressForm } from '@eventhorizon/components/address/address.component';
import { observable } from 'mobx-angular';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { makeObservable, reaction, IReactionDisposer } from 'mobx';
import moment from 'moment';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import {
  clearAddress,
  copyAddress,
  isAddressEmpty,
  isAddressEqual,
} from '@eventhorizon/models/address.model';
import { brandDatePicker } from '@eventhorizon/data/date-picker-config';
import { BusinessOwner } from '@eventhorizon/models/business-owner.model';
import { masks } from '@eventhorizon/data/masks.data';
import { organizationTypes } from '@eventhorizon/data/organization-types.data';
import { formatPhone, randomComponentId, onDateFieldKeyChangePatchValue } from '@eventhorizon/utils/util';
import { touchAndValidate } from '@eventhorizon/utils/forms';
import {
  CommonValidator,
} from '@eventhorizon/validation/common.validator';
import { OwnershipValidator } from '@eventhorizon/validation/ownership.validator';
import { altSsnRegex, SSNValidator } from '@eventhorizon/validation/ssn.validator';
import { messages } from '@eventhorizon/data/messages.data';

@Component({
  selector: 'app-tg-owner',
  templateUrl: './base-owner.component.html',
  styleUrls: ['./base-owner.component.scss'],
})
export class BaseOwnerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('focus') public focus: ElementRef;

  @Input() applicant: BusinessOwner;

  @Input() index = '0';

  @Output() isPrimaryChanged = new EventEmitter<boolean>();

  @Input() assistedOnboarding = false;

  @Input() isEditing = false;

  @Input() allowLessThan50Percentage = false;

  @ViewChild(AddressComponent)
  private personalAddress: AddressComponent;

  public sameAsBusiness: FormControl<boolean>;

  public form: FormGroup<IBaseOwnerForm>;

  public today = new Date();

  public minDate = moment().subtract(90, 'years').toDate();

  public masks = masks;

  public brandDatePicker = brandDatePicker;

  public onDateFieldKeyChangePatchValue = onDateFieldKeyChangePatchValue;

  public messages = messages.ownerInformationSlide;

  public id = randomComponentId();

  public isPercentageValid = true;

  public percentageValid: MobxFormControl<string>;

  public fieldTextType: boolean;

  public firstName: MobxFormControl<string>;

  public lastName: MobxFormControl<string>;

  public phone: MobxFormControl<string>;

  public email: MobxFormControl<string>;

  public dob: MobxFormControl<string>;

  public ssn: MobxFormControl<string>;

  public title: MobxFormControl<string>;

  public ownership: MobxFormControl<number>;

  public hasPoliticalTies: MobxFormControl<boolean>;

  public hidePoliticallyExposedPersons: boolean;

  private disposer: IReactionDisposer;

  constructor(
    public store: ApplicationStore,
    protected contentService: ContentServiceService,
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
  ) {
    makeObservable(this, {
      applicant: observable,
    });
    this.reactionsHandler();
  }

  private reactionsHandler() {
    this.disposer = reaction(
      () => this.store.ownershipTotal,
      (value: number) => {
        if (!this.store.isLoaded) return;
        this.checkPercentage(value);
      },
      {
        fireImmediately: true,
      },
    );
  }

  ngOnInit() {
    this.hidePoliticallyExposedPersons = !!this.contentService.bankInfoConfig?.hidePoliticallyExposedPersons || false;
    this.buildForm();
  }

  ngAfterViewInit() {
    // Augment the form once the address component renders

    this.form.setControl('personalAddress', this.personalAddress.form);
    if (
      this.personalAddress &&
      !isAddressEmpty(this.personalAddress.address) &&
      !isAddressEmpty(this.store.businessAddresses.legalAddress)
    ) {
      const sal = isAddressEqual(this.store.businessAddresses.legalAddress, this.personalAddress.address);
      this.sameAsBusiness.setValue(sal);
      this.personalAddress.readonly = sal;
    }
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    this.checkPercentage(this.store.ownershipTotal);
    if (this.isSoleProprietor()) {
      this.title.setValue('Owner');
    }
  }

  isSoleProprietor(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.soleProprietor.code
    ) {
      return false;
    }

    this.setFullOwnership();
    return true;
  }

  public isTaxExempt(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.taxExempt.code
    ) {
      return false;
    }

    this.setFullOwnership();
    return true;
  }

  private setFullOwnership() {
    if (this.ownership.value !== 100) {
      this.ownership.setValue(100);
    }
  }

  public changeSameAsBusiness(value: boolean) {
    if (value) {
      this.personalAddress.readonly = true;
      copyAddress(this.store.businessAddresses.legalAddress, this.personalAddress.address);
    } else {
      this.personalAddress.readonly = false;
      clearAddress(this.personalAddress.address);
    }
  }

  checkPercentage(percentage: number): void {
    if (!this.percentageValid) return;
    if (this.assistedOnboarding && this.store.ownershipTotal === 0 && this.ownership.value === '') {
      this.isPercentageValid = true;
      this.percentageValid.setValue(this.isPercentageValid);
      return;
    }

    if (this.form && !this.form.touched && this.form.controls.ownership.value === '') {
      return;
    }

    if (this.isSoleProprietor() || this.isTaxExempt()) {
      if (percentage >= 0) {
        this.isPercentageValid = percentage === 100;
        this.percentageValid.setValidators(OwnershipValidator.ownership100);
      } else {
        this.isPercentageValid = true;
      }
    } else if (percentage > 100) {
      this.isPercentageValid = false;
      this.percentageValid.setValidators(OwnershipValidator.ownershipOver100);
    } else if (percentage >= 0) {
      if (this.allowLessThan50Percentage && percentage < 50) {
        this.isPercentageValid = true;
      } else {
        this.isPercentageValid = percentage >= 50 && percentage <= 100;
        this.percentageValid.setValidators(OwnershipValidator.ownership50);
      }
    } else {
      this.isPercentageValid = true;
    }

    this.percentageValid.setValue(this.isPercentageValid || '');
  }

  public onOpen() {
    this.cd.detectChanges();
  }

  public validate() {
    touchAndValidate(this.form);
    this.cd.detectChanges();
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public isDirty(): boolean {
    return this.form.dirty;
  }

  public isTouched(): boolean {
    return this.form.touched;
  }

  public allDirty(): boolean {
    for (const control in this.form.controls) {
      const actualControl = this.form.get(control);

      if (!actualControl.dirty) {
        return false;
      }
    }
    return true;
  }

  private buildForm() {
    const s = this.applicant;
    this.isEditing = this.isEditing && altSsnRegex.test(s.socialSecurityNumber);
    const isRequired = this.assistedOnboarding ? undefined : Validators.required;

    this.hasPoliticalTies = new MobxFormControl<boolean>(
      'hasPoliticalTies',
      () => this.store.hasPoliticalTies,
      (v: boolean) => {
        this.store.hasPoliticalTies = v;
      },
      isRequired,
      true,
    );
    this.firstName = new MobxFormControl<string>(
      'firstName',
      () => s.firstName,
      (v: string) => (s.firstName = v),
      CommonValidator.personalName(!this.assistedOnboarding),
    );
    this.lastName = new MobxFormControl<string>(
      'lastName',
      () => s.lastName,
      (v: string) => (s.lastName = v),
      CommonValidator.personalName(!this.assistedOnboarding),
    );
    this.phone = new MobxFormControl<string>(
      'phone',
      () => formatPhone(s.phone),
      (v: string) => (s.phone = v),
      CommonValidator.phone(!this.assistedOnboarding),
    );
    this.email = new MobxFormControl<string>(
      'email',
      () => s.email,
      (v: string) => (s.email = v),
      [CommonValidator.email(!this.assistedOnboarding)],
    );
    this.dob = new MobxFormControl<string>(
      'dob',
      () => s.dob,
      (v: string) => {
        s.dob = v;
        const timestamp = Date.parse(v);
        if (!isNaN(timestamp)) s.dateOfBirth = new Date(timestamp);
      },
      Validators.compose([
        CommonValidator.dob(!this.assistedOnboarding),
        CommonValidator.dobOver18Under90(!this.assistedOnboarding),
      ]),
    );
    this.ssn = new MobxFormControl<string>(
      'ssn',
      () => s.socialSecurityNumber,
      (v: string) => (s.socialSecurityNumber = v),
      SSNValidator.ssnAlt(!this.assistedOnboarding),
    );
    this.title = new MobxFormControl<string>(
      'title',
      () => (s.title ? s.title : ''),
      (v: string) => (s.title = v),
      isRequired,
    );
    this.ownership = new MobxFormControl<number>(
      'ownership',
      () => s.percentageOwnership,
      (v: number) => {
        s.percentageOwnership = v;
        this.checkPercentage(this.store.ownershipTotal);
      },
      OwnershipValidator.ownership(!this.assistedOnboarding),
    );

    this.percentageValid = new MobxFormControl<string>(
      'percentageValid',
      () => (this.isPercentageValid ? 'true' : ''),
      (v: string) => {
        this.isPercentageValid = !!v;
      },
      OwnershipValidator.ownership100,
    );

    this.sameAsBusiness = new FormControl(false);

    // To set applicant info by default only to the first owner.
    if (this.index.toString() === '0') {
      const applicantInfo =
        this.store.applicantInfo && this.store.applicantInfo.isKeyController ? this.store.applicantInfo : undefined;
      this.firstName.setValue(applicantInfo && (!s.firstName || s.firstName === '') ? applicantInfo.name : s.firstName);
      this.lastName.setValue(applicantInfo && (!s.lastName || s.lastName === '') ? applicantInfo.lastName : s.lastName);
      this.phone.setValue(
        applicantInfo && (!s.phone || s.phone === '') ? formatPhone(applicantInfo.phoneNumber) : formatPhone(s.phone),
      );
      this.email.setValue(applicantInfo && (!s.email || s.email === '') ? applicantInfo.email : s.email);
    }

    this.form = this.fb.group({
      firstName: this.firstName,
      lastName: this.lastName,
      personalAddress: new FormGroup<IAddressForm>({
        address1: new FormControl<string>(null),
        address2: new FormControl<string>(null),
        city: new FormControl<string>(null),
        state: new FormControl<string>(null),
        zip: new FormControl<string>(null),
      }),
      phone: this.phone,
      email: this.email,
      dob: this.dob,
      ssn: this.ssn,
      title: this.title,
      ownership: this.ownership,
      percentageValid: this.percentageValid,
      sameAsBusiness: this.sameAsBusiness,
    });

    if (!this.hidePoliticallyExposedPersons) {
      this.form.addControl('hasPoliticalTies', this.hasPoliticalTies);
    }

    this.ssn.valueChanges.subscribe(data => {
      if (this.isEditing && data.includes('*')) {
        this.ssn.setValue('');
        this.isEditing = false;
      }
    });
  }

  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  ngOnDestroy(): void {
    if (this.disposer) this.disposer();
  }
}

export interface IBaseOwnerForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  personalAddress: FormGroup<IAddressForm>;
  phone: FormControl<string>;
  email: FormControl<string>;
  dob: FormControl<string>;
  ssn: FormControl<string>;
  title: FormControl<string>;
  ownership: FormControl<number | string>;
  percentageValid: FormControl<boolean>;
  sameAsBusiness: FormControl<boolean>;
  hasPoliticalTies?: FormControl<boolean | string>;
}
