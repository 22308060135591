import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CartPopupConstants } from '@eventhorizon/components/cart/cart-popup/cart-popup.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationsListComponent } from '@eventhorizon/components/locations-list/locations-list.component';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { routes } from '@eventhorizon/data/routes.data';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { Cart } from '@eventhorizon/models/cart.model';

@Component({
  selector: 'app-tg-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('focus') public focus: ElementRef;

  @ViewChild('modalContent') public modalContent: ElementRef;

  @ViewChild('locationsList') public locationsList: LocationsListComponent;

  public ariaLabelledByValue = CartPopupConstants.ariaLabelledBy;

  public continuePage: string;

  public result: boolean;

  public locations: BusinessLocationModel[];

  public shouldContinue: boolean;

  public isLoading: boolean = true;

  constructor(
    private modalRef: BsModalRef, 
    public cd: ChangeDetectorRef, 
    public activatedRoute: ActivatedRoute,
    public cartStore: CartStore,
    public rs: RoutingPathService,
    public router: Router,
  ) { }

  ngAfterViewInit() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    this.cd.detectChanges();
  }

  public get locationIndexQuery(): number {
    let param = this.activatedRoute?.snapshot?.queryParams['location'];
    return isNaN(param) ? -1 : Number(param);
  }

  public get totalProductCount(): number {
    return this.locationsList?.totalProductCount || 0;
  }

  public get carts(): Cart[] {
    return this.locationsList?.carts || [];
  }

  public get disableContinue(): boolean {
    let currentRoute = this.router.url.split('?')[0];
    if (currentRoute === '/location-addons') return this.cartStore.hasErrors(this.carts[this.locationIndexQuery]);
    return this.cartStore.hasErrors(this.carts[this.locationIndexQuery], this.cartStore.isPinpadOrSoftwareError);
  }

  closeModal() {
    this.close(false);
  }

  public ngOnDestroy() {
    this.closeModal();
  }

  public close(result: boolean): void {
    this.result = result;
    this.modalRef.hide();
  }

  onContinue() {
    let needAddons = this.carts[this.locationIndexQuery]?.errors?.some(e => this.cartStore.isPinpadOrSoftwareError(e));
    if (needAddons) this.rs.activateRoute(routes.LOCATION_ADDONS);
    this.shouldContinue = true;
    this.modalRef.hide();
  }

  onAddMoreProducts() {
    this.closeModal();
    this.router.navigate([routes.LOCATION_PRODUCTS], { queryParamsHandling: 'preserve' });
  }
}
