import { Injectable } from '@angular/core';
import { AcquiringPricing, Details } from '@eventhorizon/models/acquiring-pricing.model';
import { Product } from '@eventhorizon/models/product.model';
import { productTypes } from '@eventhorizon/constants/general.constants';

@Injectable({
  providedIn: 'root',
})
export class ProductController {
  private productTypeOrder = ['NET_FEE', 'OCCUR_FEE', 'MO_FEE', 'CHGBK_FEE', 'SETUP_FEE', 'Shipping'];

  isSoftwareProduct(product: Product) {
    return product.productType === productTypes.SOLUTION_FEE || product.productType === productTypes.SOFTWARE;
  }

  private isRateAndFeeZero(details: Details) {
    return details.rateDefault + details.feeDefault + details.rateMax + details.feeMax > 0;
  }

  private isHidden(details: Details) {
    return !details.isHidden;
  }

  filterAndSortPricing(ap: AcquiringPricing, removeZeroValues: boolean, isHidden: boolean): Details[] {
    let mergeAcquiringPricing = []
      .concat(
        ap.cardPresentDiscountRates || [],
        ap.cartTransactionRates || [],
        ap.discountRates || [],
        ap.globalPricing || [],
        ap.productFees || [],
      )
      .reduce((r, detail) => {
        r[detail.productId] = detail;
        return r;
      }, {});
    // Converting acquiring pricing object
    // to array of acquiring pricing objects
    mergeAcquiringPricing = Object.values(mergeAcquiringPricing);
    const filteredResults = removeZeroValues
      ? mergeAcquiringPricing.filter(this.isRateAndFeeZero)
      : mergeAcquiringPricing;

    const filterHiddenResults = isHidden ? filteredResults.filter(this.isHidden) : filteredResults;

    return (
      filterHiddenResults.sort(
        (a, b) => this.productTypeOrder.indexOf(a.productType) - this.productTypeOrder.indexOf(b.productType),
      ) || []
    );
  }
}
