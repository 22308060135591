<p class="content-page-card-heading">CONTACT US TO GET STARTED</p>
<form [formGroup]="form" (ngSubmit)="submitContactForm()" class="content-page-form">
  <div [class.d-none]="onSecondPart">
    <div class="row mb-3">
      <div class="col-md-6 form-group">
        <input
          id="name"
          formControlName="name"
          class="form-control"
          type="text"
          [maskito]="masks.personalName"
          placeholder="Name"
        />
        <label class="form-label" for="name">Name</label>
        <app-field-error [control]="name"></app-field-error>
      </div>
      <div class="col-md-6 form-group">
        <input
          id="lastName"
          formControlName="lastName"
          class="form-control"
          type="text"
          [maskito]="masks.personalName"
          placeholder="Last Name"
        />
        <label class="form-label" for="lastName">Last Name</label>
        <app-field-error [control]="lastName"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <input
          id="legalName"
          formControlName="legalName"
          class="form-control"
          type="text"
          [maskito]="masks.companyName"
          placeholder="Business Name"
        />
        <label class="form-label" for="legalName">Legal Business Name</label>
        <app-field-error [control]="legalName"></app-field-error>
        <span class="referred-tooltip">
          <app-tg-tooltip
            [tooltipText]="
              'The legal business name is the formal and officially registered name of your business that appears on the formation document of your business entity. If you are doing business as a sole proprietorship, then the legal business name will be the first and last name of the sole proprietor.'
            "
          ></app-tg-tooltip>
        </span>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <p class="form-radios form-label">Information Annual Credit/Debit Processing Volume</p>
        <div *ngFor="let option of annualCDProcessingValues; let i = index" class="form-check form-check-inline">
          <input
            [id]="'annualCDProcessing' + i"
            name="annualCreditDebitProcessingVolume"
            formControlName="annualCreditDebitProcessingVolume"
            class="form-check-input"
            type="radio"
            [value]="option.value"
          />
          <label class="form-check-label" [for]="'annualCDProcessing' + i">{{ option.label }}</label>
        </div>
        <app-field-error [control]="annualCreditDebitProcessingVolume"></app-field-error>
      </div>
    </div>
    <div class="mb-4">
      <div class="form-group col-md-12">
        <p class="form-radios form-label mb-3">{{ representativeText }}</p>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 form-group">
          <input
            id="repName"
            formControlName="repName"
            class="form-control"
            type="text"
            [maskito]="masks.personalName"
            placeholder="Representative Name"
          />
          <label class="form-label" for="repName">Representative First Name</label>
          <app-field-error [control]="repName"></app-field-error>
        </div>
        <div class="col-md-6 form-group">
          <input
            id="repLastName"
            formControlName="repLastName"
            class="form-control"
            type="text"
            [maskito]="masks.personalName"
            placeholder="Representative Last Name"
          />
          <label class="form-label" for="repLastName">Representative Last Name</label>
          <app-field-error [control]="repLastName"></app-field-error>
        </div>
      </div>
      <div class="row mb-3">
        <div class="form-group col-md-12">
          <input
            id="repEmail"
            formControlName="repEmail"
            class="form-control"
            type="text"
            placeholder="Representative Email"
          />
          <label class="form-label" for="repEmail">Representative Email</label>
          <app-field-error [control]="repEmail"></app-field-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <button type="button" (click)="resetContactForm()" class="border-button">Back</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" class="primary-button m-0" [disabled]="disableNext()" (click)="toggleParts()">
          Next
        </button>
      </div>
    </div>
  </div>
  <div [class.d-none]="!onSecondPart">
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <input
          id="email"
          formControlName="email"
          class="form-control"
          type="text"
          placeholder="Email"
          maxlength="70"
          autocomplete="email"
        />
        <label class="form-label" for="email">Email</label>
        <app-field-error [control]="email"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <input
          id="phone"
          formControlName="phoneNumber"
          class="form-control"
          type="text"
          [maskito]="masks.phone"
          placeholder="Phone"
        />
        <label class="form-label" for="phone">Phone</label>
        <app-field-error [control]="phoneNumber"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <select id="timezone" class="form-control form-select" formControlName="timezone">
          <option *ngFor="let timezone of timeZoneOptions" [value]="timezone.value">
            {{ timezone.name }}
          </option>
        </select>
        <label class="form-label" for="timezone">Time Zone</label>
        <app-field-error [control]="timezone"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-6">
        <p class="form-radios form-label">Preferred Language</p>
        <div
          *ngFor="let option of languageOptions; let i = index"
          class="form-check form-check-inline"
          [ngClass]="{ 'me-0': i === languageOptions.length - 1, 'me-3': i === 0 }"
        >
          <input
            [id]="'preferredLanguage' + i"
            name="preferredLanguage"
            formControlName="preferredLanguage"
            class="form-check-input"
            type="radio"
            [value]="option.value"
          />
          <label class="form-check-label" [for]="'preferredLanguage' + i">{{ option.label }}</label>
        </div>
        <app-field-error [control]="preferredLanguage"></app-field-error>
      </div>
      <div class="form-group col-md-6">
        <p class="form-radios form-label">Preferred Time to Call</p>
        <div *ngFor="let option of timeToCallOptions; let i = index" class="form-check form-check-inline">
          <input
            [id]="'preferredTimeCall' + i"
            class="form-check-input"
            type="checkbox"
            [formControlName]="option.name"
            [value]="option.value"
          />
          <label class="form-check-label" [for]="'preferredTimeCall' + i">{{ option.label }}</label>
        </div>
        <app-field-error [control]="preferredTimeToCall"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div class="form-group col-md-12">
        <textarea id="notes" formControlName="notes" class="form-control" placeholder="Notes"></textarea>
        <label class="form-label" for="notes">Notes</label>
        <app-field-error [control]="notes"></app-field-error>
      </div>
    </div>
    <div class="row mb-3">
      <div
        #recaptchaContainer
        *ngIf="!disableCaptcha"
        class="d-flex justify-content-center form-group col-md-12 g-recaptcha"
      >
        <re-captcha formControlName="recaptcha" *ngIf="recaptchaContainerWidth < 300" size="compact"></re-captcha>
        <re-captcha formControlName="recaptcha" *ngIf="recaptchaContainerWidth >= 300" size="normal"></re-captcha>
      </div>
    </div>
    <div class="row mb-3" *ngIf="errorMessage">
      <div class="col-12 col-md-6">
        <div class="form-error-message">{{ errorMessage }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <button type="button" (click)="toggleParts()" class="border-button">Back</button>
      </div>
      <div class="col-12 col-md-6">
        <button type="button" [disabled]="!form.valid" class="primary-button m-0" (click)="submitContactForm()">
          Submit
        </button>
      </div>
    </div>
  </div>
</form>
