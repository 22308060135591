import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AdditionalProductsScreenComponent } from '@app/pages/additional-products-screen/additional-products-screen.component';
import { AdditionalProductsComponent } from '@app/pages/additional-products/additional-products.component';
import { ApplicationConfirmationScreenComponent } from '@app/pages/application-confirmation-screen/application-confirmation-screen.component';
import { ApplicationConfirmationComponent } from '@app/pages/application-confirmation/application-confirmation.component';
import { ApplicationPageComponent } from '@app/pages/application-page/application-page.component';
import { BankScreenComponent } from '@app/pages/bank-screen/bank-screen.component';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { BaseTermsAndConditionsComponent } from '@app/pages/base-terms-and-conditions/base-terms-and-conditions.component';
import { BusinessAddressesScreenComponent } from '@app/pages/business-addresses-screen/business-addresses-screen.component';
import { BusinessInfoScreenComponent } from '@app/pages/business-info-screen/business-info-screen.component';
import { BaseBusinessInfoComponent } from '@app/pages/business-info/base-business-info.component';
import { BusinessTaxesScreenComponent } from '@app/pages/business-taxes-screen/business-taxes-screen.component';
import { BusinessTypeScreenComponent } from '@app/pages/business-type-screen/business-type-screen.component';
import { CheckoutScreenComponent } from '@app/pages/checkout-screen/checkout-screen.component';
import { ContactInfoScreenComponent } from '@app/pages/contact-info-screen/contact-info-screen.component';
import { ContactInfoComponent } from '@app/pages/contact-info-screen/contact-info/contact-info.component';
import { ContentModule } from '@app/pages/content-page/content-module/content.module';
import { ContentPageComponent } from '@app/pages/content-page/content-page.component';
import { ErrorServerDownComponent } from '@app/pages/error-server-down/error-server-down.component';
import { Error404Component } from '@app/pages/error404/error404.component';
import { IdVerificationScreenComponent } from '@app/pages/id-verification-screen/id-verification-screen.component';
import { IndustryScreenComponent } from '@app/pages/industry-screen/industry-screen.component';
import { IndustrySelectorComponent } from '@app/pages/industry-selector/industry-selector.component';
import { IndustrySubcategoryScreenComponent } from '@app/pages/industry-subcategory-screen/industry-subcategory-screen.component';
import { IndustrySubcategorySelectorComponent } from '@app/pages/industry-subcategory-selector/industry-subcategory-selector.component';
import { LocationsScreenComponent } from '@app/pages/locations-screen/locations-screen.component';
import { OrderConfirmationScreenComponent } from '@app/pages/order-confirmation-screen/order-confirmation-screen.component';
import { OrderStatusScreenComponent } from '@app/pages/order-status-screen/order-status-screen.component';
import { OwnersScreenComponent } from '@app/pages/owners-screen/owners-screen.component';
import { PaymentInfoScreenComponent } from '@app/pages/payment-info-screen/payment-info-screen.component';
import { PaymentTypesScreenComponent } from '@app/pages/payment-types-screen/payment-types-screen.component';
import { PrefillScreenComponent } from '@app/pages/prefill-screen/prefill-screen.component';
import { PrefillStartDialogComponent } from '@app/pages/prefill-screen/prefill-start-dialog/prefill-start-dialog.component';
import { ProductShippingLocationsComponent } from '@app/pages/product-shipping-locations/product-shipping-locations.component';
import { ProductsScreenComponent } from '@app/pages/products-screen/products-screen.component';
import { ReferredMerchantContactFormComponent } from '@app/pages/referred-merchant-screen/referred-merchant-contact-form/referred-merchant-contact-form.component';
import { ReferredMerchantScreenComponent } from '@app/pages/referred-merchant-screen/referred-merchant-screen.component';
import { ResumeScreenComponent } from '@app/pages/resume-screen/resume-screen.component';
import { RiskVerificationScreenComponent } from '@app/pages/risk-verification-screen/risk-verification-screen.component';
import { ShippingScreenComponent } from '@app/pages/shipping-screen/shipping-screen.component';
import { SoftwareScreenComponent } from '@app/pages/software-screen/software-screen.component';
import { TermsScreenComponent } from '@app/pages/terms-screen/terms-screen.component';
import { VerificationScreenComponent } from '@app/pages/verification-screen/verification-screen.component';
import { VerticalProgressWizardComponent } from '@app/pages/vertical-progress-wizard/vertical-progress-wizard.component';
import { EventhorizonModule } from '@eventhorizon/eventhorizon.module';
import { TextMaskModule } from 'angular2-text-mask';
import { MobxAngularModule } from 'mobx-angular';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { environment } from '../../environments/environment';
import { BaseBankInfoComponent } from './bank-screen/base-bank-info/base-bank-info.component';
import { BusinessTaxesComponent } from './business-taxes/business-taxes.component';
import { CallbackComponent } from './call-back/call-back.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderConfirmationDetailsComponent } from './order-confirmation-details/order-confirmation-details.component';
import { BasePersonalInfoComponent } from './owners-screen/base-personal-info/base-personal-info.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { PaymentTypesComponent } from './payment-types/payment-types.component';
import { PhysicalLocationsComponent } from './physical-locations/physical-locations.component';
import { ProductDetailIncludedPopupComponent } from './product-detail/product-detail-included-popup/product-detail-included-popup.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductNavigationComponent } from './product-navigation/product-navigation.component';
import { ProductSearchFiltersComponent } from './product-search/product-search-filters/product-search-filters.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductShippingLocationsBaseComponent } from './product-shipping-locations-base/product-shipping-locations-base.component';
import { ProductComponent } from './product/product.component';
import { InactivePartnerPopupComponent } from './referred-merchant-screen/inactive-partner-popup/inactive-partner-popup.component';
import { ChipsComponent } from './risk-verification/multiple-selection-dropdown/chips/chips.component';
import { MultipleSelectionDropdownComponent } from './risk-verification/multiple-selection-dropdown/multiple-selection-dropdown.component';
import { RiskVerificationComponent } from './risk-verification/risk-verification.component';
import { SoftwareDetailsModalComponent } from './software-screen/software/software-details-modal/software-details-modal.component';
import { SoftwareOptionComponent } from './software-screen/software/software-option/software-option.component';
import { SoftwarePopupComponent } from './software-screen/software/software-popup/software-popup.component';
import { SoftwareComponent } from './software-screen/software/software.component';
import { StatusPopupComponent } from './status-popup/status-popup.component';
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { VerificationQuizComponent } from './verification-quiz/verification-quiz.component';
import { StatusLineComponent } from './verification/status-line/status-line.component';
import { VerificationComponent } from './verification/verification.component';

@NgModule({
  declarations: [
    BaseTermsAndConditionsComponent,
    BusinessAddressesScreenComponent,
    BusinessTaxesScreenComponent,
    BusinessInfoScreenComponent,
    BusinessTypeScreenComponent,
    CheckoutScreenComponent,
    ContactInfoComponent,
    ContactInfoScreenComponent,
    IdVerificationScreenComponent,
    IndustryScreenComponent,
    IndustrySubcategoryScreenComponent,
    IndustrySubcategorySelectorComponent,
    IndustrySelectorComponent,
    OwnersScreenComponent,
    BankScreenComponent,
    TermsScreenComponent,
    ApplicationConfirmationScreenComponent,
    ProductsScreenComponent,
    OrderStatusScreenComponent,
    OrderConfirmationScreenComponent,
    PaymentTypesScreenComponent,
    PaymentInfoScreenComponent,
    ResumeScreenComponent,
    ShippingScreenComponent,
    SoftwareScreenComponent,
    Error404Component,
    ErrorServerDownComponent,
    ReferredMerchantScreenComponent,
    LocationsScreenComponent,
    AdditionalProductsScreenComponent,
    VerificationScreenComponent,
    RiskVerificationScreenComponent,
    PrefillScreenComponent,
    PrefillStartDialogComponent,
    ContentPageComponent,
    ReferredMerchantContactFormComponent,
    VerticalProgressWizardComponent,
    ApplicationPageComponent,
    BasePageDriverComponent,
    ProductShippingLocationsComponent,
    ApplicationConfirmationComponent,
    AdditionalProductsComponent,
    BaseBusinessInfoComponent,
    VerificationQuizComponent,
    RiskVerificationComponent,
    PaymentInfoComponent,
    PhysicalLocationsComponent,
    CheckoutComponent,
    BusinessTaxesComponent,
    CallbackComponent,
    StatusPopupComponent,
    TwoFactorAuthComponent,
    ProductDetailComponent,
    ProductDetailIncludedPopupComponent,
    OrderConfirmationDetailsComponent,
    BaseBankInfoComponent,
    BasePersonalInfoComponent,
    VerificationComponent,
    StatusLineComponent,
    SoftwareComponent,
    SoftwarePopupComponent,
    SoftwareDetailsModalComponent,
    SoftwareOptionComponent,
    ProductShippingLocationsBaseComponent,
    ProductNavigationComponent,
    ProductComponent,
    ProductSearchComponent,
    ProductSearchFiltersComponent,
    PaymentTypesComponent,
    ChipsComponent,
    MultipleSelectionDropdownComponent,
    InactivePartnerPopupComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    NgxExtendedPdfViewerModule,
    TextMaskModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    RouterModule,
    ContentModule,
    RecaptchaFormsModule,
    EventhorizonModule.forRoot(environment),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule,
    BsDropdownModule,
  ],
  exports: [],
})
export class PagesModule {}
