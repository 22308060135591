<form [formGroup]="form">
  <div class="row row-container">
    <div class="product-image d-none d-md-block col-md-1 p-0">
      <img
        [alt]="product?.productName"
        class="w-100"
        [src]="product?.imageUrls && product.imageUrls.length ? product.imageUrls[0] : defaultImage"
      />
    </div>
    <div class="product-name col-8" [ngClass]="{ 'col-md-5': !showExtendedDisplay, 'col-md-4': showExtendedDisplay }">
      <div>
        <div>
          {{ product?.productName }}
        </div>
      </div>
      <div
        *ngIf="
          showExtendedDisplay && !productService.isSoftwareProduct(product) && !isMonthlyFee && !showPaymentTermAsColumn
        "
      >
        <div class="product-payment-term-mobile">
          {{ getPaymentTerm() }}
        </div>
      </div>
      <span
        *ngIf="productService.isSoftwareProduct(product) && product.priceDescription.length && showExtendedDisplay"
        class="price-description"
      >
        <p *ngFor="let description of product.priceDescription">{{ description }}</p>
      </span>
    </div>
    <div
      class="col-4 row d-flex align-items-center ps-0"
      [ngClass]="{ 'col-md-6 pe-3': !showExtendedDisplay, 'col-md-7 pe-1': showExtendedDisplay }"
    >
      <label
        class="col-12 order-12 order-md-1 px-0 text-md-center text-end"
        *ngIf="!transactionRate"
        [class.col-md-5]="!showExtendedDisplay"
        [class.col-md-4]="showExtendedDisplay"
      >
        <app-tg-input-spinner
          customClass="small-input"
          *ngIf="showQuantity && !readonly"
          [form]="form"
          [maskito]="masks.productQty"
          [controlName]="'productQuantity'"
          [control]="quantityControl"
          (changeQuantity)="onQuantityChange($event)"
          [quantity]="product?.quantity"
          [decrementDisabled]="cartStore.isLoading || cannotRemove || readonly"
          [incrementDisabled]="cartStore.isLoading || cannotRemove || readonly"
          [min]="0"
          [useInputTimeout]="true"
        >
        </app-tg-input-spinner>
        <p class="fw-bold" *ngIf="showQuantity && readonly">{{ product?.quantity }}</p>
        <div *ngIf="!showQuantity && !cannotRemove && !readonly" class="product-remove-container">
          <button class="transparent-button product-remove" (click)="removeProduct()" [disabled]="cartStore.isLoading">
            Remove
          </button>
        </div>
      </label>
      <div
        class="col-12 order-12 order-md-1 px-0 col-md-4 text-md-center text-end"
        *ngIf="showExtendedDisplay && showPaymentTermAsColumn"
      >
        {{ getPaymentTerm(false) }}
      </div>
      <div
        *ngIf="product?.price || isOwned || product?.price === 0"
        class="text-end col-12 order-1 order-md-12 px-0"
        [class.col-md-7]="!showExtendedDisplay"
        [class.col-md-8]="showExtendedDisplay && !showPaymentTermAsColumn"
        [class.col-md-4]="showExtendedDisplay && showPaymentTermAsColumn"
      >
        <span class="product-price">{{ getTotalPrice() | currency }}</span>
        <span
          *ngIf="
            product?.isLeaseInstallmentOrRent() ||
            product.isChargedMonthly() ||
            isMonthlyFee ||
            productService.isSoftwareProduct(product) ||
            product?.paymentType === 'Installment'
          "
          class="product-per-month"
          >/mo</span
        >
      </div>
    </div>
    <div
      *ngIf="transactionRate"
      class="text-end"
      [ngClass]="{ 'col-md-6 cart-price-calculated': !showExtendedDisplay, 'col-md-8': showExtendedDisplay }"
    >
      {{ transactionRate }}
    </div>

    <div
      *ngIf="
        !product?.price &&
        !showExtendedDisplay &&
        product?.paymentType !== 'Purchased' &&
        !transactionRate &&
        !isOwned &&
        product?.price !== 0
      "
      class="cart-price-calculated"
      class="text-end secondary-text col-md-3"
    >
      calculated at checkout
    </div>
  </div>
</form>
