import { Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { messages } from '@xup-payments/xup-frontend-utils/models';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-bank-screen',
  templateUrl: './bank-screen.component.html',
  styleUrls: ['./bank-screen.component.scss'],
})
export class BankScreenComponent extends BasePageDriverComponent {
  public messages = messages;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    private contentService: ContentServiceService,
  ) {
    super(rs, router, route);

    messages.bankInformationSlide.subText = !!this.contentService.bankInfoConfig.bankAccountSubHeader
      ? this.contentService.bankInfoConfig.bankAccountSubHeader
      : messages.bankInformationSlide.subText;
  }

  public async next() {
    await lastValueFrom(this.store.save([]));
    super.next();
  }
}
