import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { progressCodes } from '@eventhorizon/data/progress-codes.data';
import { routes } from '@eventhorizon/data/routes.data';

export interface Route {
  route: string;
  active: boolean;
  progressCode: number;
}

@Injectable({
  providedIn: 'root',
})
export class RoutingPathService {
  public routePaths = [
    { route: '', active: true, progressCode: progressCodes.GET_STARTED },
    { route: routes.GET_STARTED, active: true, progressCode: progressCodes.GET_STARTED },
    { route: routes.CONTACT_INFO, active: true, progressCode: progressCodes.CONTACT_INFO },
    { route: routes.INDUSTRY_CATEGORY, active: true, progressCode: progressCodes.INDUSTRY_CATEGORY },
    { route: routes.INDUSTRY_SUBCATEGORY, active: false, progressCode: progressCodes.INDUSTRY_SUBCATEGORY },
    { route: routes.INDUSTRY_MCC, active: true, progressCode: progressCodes.INDUSTRY_MCC },
    { route: routes.BUSINESS_STRUCTURE, active: true, progressCode: progressCodes.BUSINESS_STRUCTURE },
    { route: routes.BUSINESS_INFO, active: true, progressCode: progressCodes.BUSINESS_INFO },
    { route: routes.LOCATION_INFO, active: false, progressCode: progressCodes.LOCATIONS },
    { route: routes.LOCATION_PRODUCTS, active: false, progressCode: progressCodes.LOCATIONS },
    { route: routes.LOCATION_ADDONS, active: false, progressCode: progressCodes.LOCATIONS },
    { route: routes.LOCATIONS, active: true, progressCode: progressCodes.LOCATIONS },
    { route: routes.REVIEW_CART, active: true, progressCode: progressCodes.REVIEW_CART },
    { route: routes.OWNERS, active: true, progressCode: progressCodes.OWNERS },
    { route: routes.SHIPPING, active: true, progressCode: progressCodes.SHIPPING },
    { route: routes.FINAL_REVIEW, active: true, progressCode: progressCodes.FINAL_REVIEW },
    { route: routes.AGREEMENT, active: true, progressCode: progressCodes.AGREEMENT },
    { route: routes.APPLICATION_CONFIRMATION, active: true, progressCode: progressCodes.APPLICATION_CONFIRMATION },
  ];

  protected currentIndex = 0;

  constructor() {
    const routePath = JSON.parse(sessionStorage.getItem('routes'));
    if (routePath) {
      this.setRoutes(routePath);
    }
  }

  public urlToRoute(url: string): string {
    let route = url.replace('/', '');
    if (url.includes('?')) {
      route = route.split('?')[0];
    }
    return route;
  }

  public getSnapshot(route: ActivatedRoute) {
    return route.snapshot;
  }

  public getNextActiveRoute(router: Router, urlPath?: string): string {
    const path = urlPath || this.urlToRoute(router.url);
    this.currentIndex = this.routePaths.findIndex(({ route }) => route === path);
    for (let i = this.currentIndex; i <= this.routePaths.length - 1; i++) {
      if (this.routePaths[i + 1]?.active) {
        return this.routePaths[i + 1].route;
      }
    }
    return '404';
  }

  public getPrevActiveRoute(router: Router): string {
    const path = this.urlToRoute(router.url);
    this.currentIndex = this.routePaths.findIndex(({ route }) => route === path);
    for (let i = this.currentIndex; i >= 0; i--) {
      if (this.routePaths[i - 1]?.active) {
        return this.routePaths[i - 1].route;
      }
    }
    return '404';
  }

  public isActive(router: Router): boolean {
    const path = this.urlToRoute(router.url);
    try {
      return this.routePaths.find(({ route }) => route === path).active;
    } catch (e) {
      console.error('Route not found.');
      return false;
    }
  }

  public isRouteActive(path: String): boolean {
    try {
      return this.routePaths.find(({ route }) => route === path).active;
    } catch (e) {
      console.error('Route not found.');
      return false;
    }
  }

  public deactivateRoute(routeToDeactivate: string) {
    try {
      this.routePaths.find(({ route }) => route === routeToDeactivate).active = false;
      sessionStorage.setItem('routes', JSON.stringify(this.routePaths));
    } catch (e) {
      console.error('Route not found.');
    }
  }

  public activateRoute(routeToActivate: string) {
    try {
      this.routePaths.find(({ route }) => route === routeToActivate).active = true;
      sessionStorage.setItem('routes', JSON.stringify(this.routePaths));
    } catch (e) {
      console.error('Route not found.');
    }
  }

  public setRoutes(routePath: Route[]) {
    this.routePaths = routePath;
  }

  public getRouteProgress(route: string): number {
    const path = this.urlToRoute(route);
    try {
      return this.routePaths.find(rp => rp.route === path).progressCode;
    } catch (e) {
      console.error('Route not found.');
      return 0;
    }
  }

  public getNextProgressCode(currentProgressCode: number): number {
    try {
      const index = this.routePaths.findIndex(({ progressCode }) => progressCode === currentProgressCode);
      for (let i = index + 1; i < this.routePaths.length; i++) {
        // Return next active progress code.
        if (this.routePaths[i].active) {
          return this.routePaths[i].progressCode;
        }
      }
      return this.routePaths[index].progressCode;
    } catch (e) {
      console.error('Route not found.');
      return 0;
    }
  }

  public getRouteFromProgress(progressCode: number): string {
    const getRouteFromProgress = this.routePaths.find(route => route.progressCode >= progressCode && route.active);
    return getRouteFromProgress ? getRouteFromProgress.route : '';
  }
}
