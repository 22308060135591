import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { AuthService } from '@eventhorizon/services/auth.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { goToTop } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-products-screen',
  templateUrl: './products-screen.component.html',
  styleUrls: ['./products-screen.component.scss'],
})
export class ProductsScreenComponent extends BasePageDriverComponent implements OnInit {
  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    private authService: AuthService,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);
  }

  ngOnInit() {
    goToTop();
  }
}
