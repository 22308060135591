import { TemplateRef } from '@angular/core';
import { RateFee } from '../interfaces/rates-fees.interface';

export class Product {
  productId: string;

  name?: string;

  productShortDescription: string;

  productLongDescription: string;

  type?: string;

  imageUrls: string[];

  price: number;

  productType: string;

  productTypeGroup?: ProductTypeGroup;

  defaultPurchaseType: string;

  pricePerAdditionalDevice?: string;

  businessLocationId: string;

  // used in the cart
  productName: string;

  quantity: number;

  purchaseType: string;

  public paymentType: string;

  paymentTerm: string;

  pricingModel: PricingModel[];

  priceDescription: string[];

  recommended?: boolean;

  enabled?: boolean;

  id?: string;

  externalId?: string;

  disableSelection?: boolean;

  order?: number;

  selected: boolean;

  required?: boolean;

  terminalId?: string[];

  public isPurchased? = () => {
    return this.paymentType === 'Purchased';
  };

  public isLeaseInstallmentOrRent? = () => {
    return ['Lease', 'Installment', 'Rent'].includes(this.paymentType);
  };

  public isChargedMonthly = () => {
    return this.productId === '74842';
  };
}

export interface ProductTypeGroup {
  id: string;
  name: string;
  productTypes: string[];
}

export interface ProductFull {
  productId: string;
  name: string;
  imageUrls: string[];
  description: string;
  type: string;
  productTypeGroup: string;
  productTypeGroupId: string;
  price: number;
  productShortDescription: string;
  productLongDescription: string;
  shortFeatures: string[];
  faq: FrequentlyAskedQuestion[];
  features: string[];
  specifications: Specifications;
  initialQuantity: number;
  includedProducts: Product[];
  recommendedProducts: Product[];
  otherProducts: null;
  categoryIds: number[];
  pricingModel: PricingModel[];
  showOnCart: boolean;
  pinPad: boolean;
  supplierProdId: string;
  supplierProductName: string;
  supplierName: string;
  productType: string;
  productName: string;
  defaultPurchaseType: string;
  status: string;
  productWithOptions: boolean;
  productFamilyId: string;
  offeringTypes: string[];
  tags: string[];
  sponsor: Sponsor;
  pricingTypeFeeModel: [];
  options: null;
  id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string | Date;
  updatedAt: string | Date;
}

interface Specifications {
  [key: string]: string[];
}

export interface PricingModel {
  id: string;
  purchaseType: string;
  price: number;
  occurrence: string;
  purchaseTypeLabel: string;
  paymentType: string;
  paymentTerm: string;
  purchaseTypeDisplay: string;
  enabled?: boolean;
  minimumPrice?: number;
  maximumPrice?: number;
}

export interface ExtendedPricingModel extends PricingModel {
  selected?: boolean;
  quantity?: number;
  error?: string;
}

interface Sponsor {
  sponsorId: number;
  sponsorName: string;
  createdBy: string;
  billingName: string;
  billingEmail: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
}

interface FrequentlyAskedQuestion {
  header: string;
  shortAnswer: string;
  url: string;
  sortId: number;
}

export interface MerchantCategoryCodes {
  mcc: string;
  mccList: string;
}

export interface MCCSearchResult {
  mcc: string;
  description: string;
  industryDescription: string;
}

export interface ConfigurableProduct {
  enabled?: boolean;
  id?: string;
  productId: string;
  quantity: number;
  name?: string;
  type?: string;
  purchaseTypes?: string;
  purchaseType: string;
  price: number;
  pricingModel: PricingModel[];
  productName?: string;
  productType?: string;
  productTypeGroupName?: string;
  productTypeGroup?: ProductTypeGroup;
  order?: number;
  terminalId?: string[];
}

export interface ProductEditParams {
  change: string;
  product?: Product;
  purchaseTypeObj?: ExtendedPricingModel;
}

export interface ProductConfiguration {
  property: string;
  width: number;
  name: string;
  sortable: boolean;
  template?: TemplateRef<any>;
  hasTemplate?: boolean;
}

export interface PayfacSettingsProductTable {
  enabled: boolean;
  id: string;
  externalId: string;
  name: string;
  type: string;
  purchaseType: string;
  price: number;
  purchaseTypesObj?: any;
  pricingModel: PricingModel[];
  required?: boolean;
}

export interface PartnerTableProduct {
  enabled: boolean;
  id: string;
  externalId: string;
  name: string;
  type: string;
  purchaseTypes: string;
  price: number;
  purchaseTypesObj: any;
  pricingModel: PricingModel[];
  recommended: boolean;
}

export interface FeesRatesGroup {
  [key: string]: IterableRateFeeObject;
}

export interface PricingTypeModel {
  pricingDetailId: number;
  pricingTypeId: number;
  productId: string;
  description: string;
  feeMin: number;
  feeDefault: number;
  feeMax: number;
  quantity: number;
  rateMin: number;
  rateDefault: number;
  rateMax: number;
  productName: string;
  productType: string;
  isOverride: true;
  showoncart: true;
  purchaseType: string;
  occurrence: {
    type: string;
  };
  groupName: string;
  pricingTypeCategory: string;
  parentOrder: number;
  parentProduct: {
    name: string;
    id: string;
    rate: number;
    fee: number;
  };
  paymentType: string;
  paymentTerm: string;
  category: string;
  longDescription: string;
}

export interface PaymentType {
  productId: string;
  name: string;
  description: string;
  defaultPurchaseType: string;
  price: number;
  imageUrls: string[];
  pricingModel: PricingModel[];
  pricingTypeFeeModel: PricingTypeModel[];
  enabled: boolean;
}

export interface AcquiringTypes {
  productId: string;
  name: string;
  description: string;
  enabled: boolean;
  paymentTypes: PaymentType[];
  selected?: boolean;
}

export interface IterableRateFeeObject {
  expanded: boolean;
  items: RateFee[];
}
