import { Category } from './category.model';
import { TransactionInfo } from './transaction-info.model';
import { ApplicationStatus } from './application-status.model';
import { BusinessLocationModel } from './business-location.model';
import { InternalLead } from './internal-lead.model';
import { Progress } from './progress.model';
import { ApplicantInfo } from './applicant-info.model';
import { AppStatus } from '@eventhorizon/constants/appStatus.constants';
import { BankInfoModel } from './bank-info.model';
import { BusinessAddressesModel } from './business-addresses.model';
import { BusinessInfoModel } from './business-info.model';
import { BusinessOwner } from './business-owner.model';
import { CreatedBy } from './created-by.model';

export class Application {
  id: string;

  progress: Progress[];

  industry: string;

  mccAdditionalDetails: string;

  mcc: string;

  mccDescription: string;

  annualRevenue: number;

  submitAttempts: number;

  businessCategory: Category;

  applicantInfo: ApplicantInfo;

  businessOwners: BusinessOwner[];

  partnerReferenceId: string;

  businessInfo: BusinessInfoModel;

  businessAddresses: BusinessAddressesModel;

  businessLocations: BusinessLocationModel[];

  bankInfo: BankInfoModel;

  transactionInfo: TransactionInfo;

  businessSize: string;

  businessPurchaseType: number;

  status: ApplicationStatus;

  threatMetrixGuid: string;

  hasPoliticalTies: boolean;

  shouldDisplaySecurityQuiz: boolean;

  hasMultipleDocuments: boolean;

  hasGroupedAuthFees: boolean;

  orderId: string;

  prefilled: boolean;

  shouldGetLLCType: boolean;

  merchantId: string;

  partnerId: string;

  groupId: string;

  createdBy: CreatedBy;

  createdAt: string;

  updatedAt: string;

  callBackRequested: boolean;

  customerId?: string;

  sourceType?: string;

  source?: string;

  internalLead?: InternalLead;
}

export class ApplicationResponse extends Application {}

export class ApplicationRequest {
  progressCode = 0;

  application: Application = new Application();

  public fromStore(store: any): ApplicationRequest {
    const req = new ApplicationRequest();

    req.application.id = store.id;
    req.application.progress = store.progress;
    req.application.industry = store.industry;
    req.application.mcc = store.mcc;
    req.application.annualRevenue = store.annualRevenue;
    req.application.applicantInfo = store.applicantInfo;
    req.application.businessOwners = store.owners;
    req.application.businessInfo = store.businessInfo;
    req.application.businessAddresses = store.businessAddresses;
    req.application.businessLocations = store.businessLocations;
    req.application.bankInfo = store.bankInfo;
    req.application.businessCategory = store.category;
    req.application.transactionInfo = store.transactionInfo;
    req.application.businessSize = store.businessSize;
    req.application.status = store.status;
    req.application.hasPoliticalTies = store.hasPoliticalTies;
    req.application.shouldDisplaySecurityQuiz = store.shouldDisplaySecurityQuiz;
    req.application.hasMultipleDocuments = store.hasMultipleDocuments;
    req.application.hasGroupedAuthFees = store.hasGroupedAuthFees;
    req.application.threatMetrixGuid = store.threatMetrixGuid;
    req.application.orderId = store.orderId;
    req.application.mccAdditionalDetails = store.mccAdditionalDetails;
    req.application.mccDescription = store.mccDescription;
    req.application.partnerId = store.partnerId;
    req.application.groupId = store.groupId;
    req.application.createdBy = store.createdBy;
    req.application.createdAt = store.createdAt;
    req.application.callBackRequested = store.callBackRequested;
    req.application.source = store.source;
    req.application.sourceType = store.sourceType;
    req.application.customerId = store.customerId;
    req.application.internalLead = store.internalLead;

    return req;
  }
}

export enum EmailType {
  saveAndReturn = 'saveAndReturn',
  assistedOnboardingCreate = 'assistedOnboardingCreate',
  assistedOnboardingUpdate = 'assistedOnboardingUpdate',
}

export class ApplicationSaveReturnRequest {
  email: string;

  phoneNumber?: string;

  type?: EmailType;

  businessLocationId?: string;
}

export class LocationApplicationUpdateRequest {
  applicationId: string;

  businessLocationId: string;

  transitionTo: AppStatus;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}
