<ng-container *ngIf="showHeader">
  <div class="row" [ngClass]="{ 'one-time-cost-header': !showExtendedDisplay }">
    <div
      class="col-8 cart-section-header p-0"
      [ngClass]="{ 'col-md-6': !showExtendedDisplay, 'col-md-5': showExtendedDisplay }"
    >
      <span [ngClass]="{ 'text-uppercase': !showExtendedDisplay }">Your Solution</span>
    </div>
    <div
      class="col-4 row d-flex align-items-center px-0"
      [ngClass]="{ 'col-md-6': !showExtendedDisplay, 'col-md-7': showExtendedDisplay }"
    >
      <div
        class="d-none d-md-block p-0 text-center"
        [ngClass]="{ 'col-md-5': !showExtendedDisplay, 'col-md-4': showExtendedDisplay }"
      >
        <span class="quantity-header">Quantity</span>
      </div>
      <div
        *ngIf="showExtendedDisplay && showPaymentTermAsColumn"
        class="d-none d-md-block col-md-4 p-0 text-center"
      >
        <span class="quantity-header">Payment Term</span>
      </div>
      <div
        class="col-12 p-0 text-end"
        [class.col-md-7]="!showExtendedDisplay"
        [class.col-md-8]="showExtendedDisplay && !showPaymentTermAsColumn"
        [class.col-md-4]="showExtendedDisplay && showPaymentTermAsColumn"
      >
        <span class="total-header" [ngClass]="{ 'total-header-margin': !showExtendedDisplay }">Total</span>
      </div>
    </div>
  </div>
  <div class="header-separator"></div>
</ng-container>

<!-- TODO: Consolidate into one list of errors -->
<div *ngIf="!hasProducts" class="col-12 cart-warning form-error-message">
  <div class="add-items-error">Add items to your cart to continue your application</div>
</div>
<div *ngIf="incompatibleMccErrorMessage" class="col-12 cart-warning form-error-message">
  <div class="row add-items-error">{{ incompatibleMccErrorMessage }}</div>
</div>
<div *ngIf="posErrorMessage" class="col-12 cart-warning form-error-message">
  <div class="row add-items-error">{{ posErrorMessage }}</div>
</div>
<div *ngIf="network.isOffline$ | async" class="col-12 cart-warning form-error-message">
  <div class="add-items-error">You are currently offline, please check your device's network status.</div>
</div>

<div *ngFor="let location of locationProducts; let index = index" class="cart-location-container">
  <h2 class="cart-location-title" *ngIf="locationProducts.length > 1">
    {{ this.appStore.businessLocations[index]?.name || 'Location ' + (index + 1) }}
  </h2>
  <div
    class="text-start purchased-products"
    *ngFor="let product of location.purchaseProducts"
    [ngClass]="{ 'cart-products-container': !showExtendedDisplay }"
  >
    <app-tg-cart-product
      [modalDialogInstance]="modalDialogInstance"
      [readonly]="(network.isOffline$ | async) || readonly"
      [product]="product"
      [showExtendedDisplay]="showExtendedDisplay"
      [showPaymentTermAsColumn]="showPaymentTermAsColumn"
      [locationId]="locationId"
      (update)="onUpdate($event)"
    ></app-tg-cart-product>
  </div>
  <div
    class="text-start cart-products-container recurring-products"
    *ngFor="let product of location.recurringProducts"
    [ngClass]="{ 'cart-products-container': !showExtendedDisplay }"
  >
    <app-tg-cart-product
      [modalDialogInstance]="modalDialogInstance"
      [readonly]="readonly"
      [product]="product"
      [showExtendedDisplay]="showExtendedDisplay"
      [showPaymentTermAsColumn]="showPaymentTermAsColumn"
      [locationId]="locationId"
      (update)="onUpdate($event)"
    ></app-tg-cart-product>
  </div>
</div>