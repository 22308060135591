import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction } from 'mobx';
import { Subject } from 'rxjs';
import { MCCSearchResult, MerchantCategoryCodes } from '@eventhorizon/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessTypeController implements OnDestroy {
  private mccAdditionalDetails: MobxFormControl;

  private mcc: MobxFormControl;

  private mccDescription: MobxFormControl;

  public searchMCC: UntypedFormControl;

  public mccCodes: MerchantCategoryCodes[];

  public industry: MobxFormControl;

  public isLoading = false;

  public mccOptions: MCCSearchResult[];

  private destroy: Subject<void> = new Subject();

  constructor(public store: ApplicationStore, private productService: ProductService) {}

  public buildForm(isAssisted: boolean = false): BusinessTypeControls {
    this.searchMCC = new UntypedFormControl(undefined, isAssisted ? undefined : Validators.required);
    this.mccOptions = [];
    this.mcc = new MobxFormControl(
      'mcc',
      () => this.store.mcc,
      v => (this.store.mcc = v),
      isAssisted ? undefined : Validators.required,
    );
    this.mccDescription = new MobxFormControl(
      'mccDescription',
      () => this.store.mccDescription,
      v => {
        this.store.mccDescription = v;
        this.searchMCC.setValue(v, { emitEvent: false });
      },
      isAssisted ? undefined : Validators.required,
    );
    this.mccAdditionalDetails = new MobxFormControl(
      'mccAdditionalDetails',
      () => this.store.mccAdditionalDetails,
      v => (this.store.mccAdditionalDetails = v),
      Validators.required,
    );
    this.mccAdditionalDetails.setEnabled(this.store.showMccDescription);
    this.industry = new MobxFormControl(
      'industry',
      () => this.store.industry,
      v => (this.store.industry = v),
    );

    reaction(
      () => !!this.store.isLoaded && this.store.mcc,
      (mcc: string) => {
        if (mcc && mcc !== '') {
          this.search(mcc);
        }
      },
      {
        fireImmediately: true,
      },
    );

    reaction(
      () => !!this.store.isLoaded && this.store.showMccDescription,
      (show: boolean) => {
        this.mccAdditionalDetails.setEnabled(show);
      },
    );

    reaction(
      () => !!this.store.isLoaded && this.store.mccDescription,
      (mccDescription: string) => {
        if (!mccDescription) return;
        this.searchMCC.setValue(mccDescription, { emitEvent: false });
      },
      {
        fireImmediately: true,
      },
    );

    this.searchMCC.valueChanges
      .pipe(distinctUntilChanged(), delay(500))
      .subscribe((searchTerm: string) => this.search(searchTerm));
    return {
      mcc: this.mcc,
      mccDescription: this.mccDescription,
      mccAdditionalDetails: this.mccAdditionalDetails,
      industry: this.industry,
    };
  }

  selectOption(option: MCCSearchResult): void {
    this.mcc.setValue(option.mcc);
    this.mccDescription.setValue(option.description);
    this.industry.setValue(option.industryDescription);
  }

  private search(term: string) {
    this.productService
      .getMCC(term)
      .pipe(takeUntil(this.destroy))
      .subscribe((results: MCCSearchResult[]) => {
        if (results.length) this.mccOptions = results;
        else
          this.mccOptions = [
            {
              mcc: null,
              description: 'No results found',
              industryDescription: 'Please try another search term',
            },
          ];
      });
  }

  public preventScroll(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

export interface BusinessTypeControls {
  mcc: MobxFormControl;
  mccDescription: MobxFormControl;
  mccAdditionalDetails: MobxFormControl;
  industry: MobxFormControl;
}
