import { Component, Input } from '@angular/core';
import { messages } from '@eventhorizon/data/messages.data';
import { defaultImage, phonePaymentIds } from '@eventhorizon/constants/general.constants';
import { OrderDetails } from '@eventhorizon/models/order-details.model';

@Component({
  selector: 'app-tg-order-confirmation-details',
  templateUrl: './order-confirmation-details.component.html',
  styleUrls: ['./order-confirmation-details.component.scss'],
})
export class OrderConfirmationDetailsComponent {
  @Input()
    title: string;

  @Input()
    subTitle = '';

  @Input()
    trackingUrl = '';

  @Input()
    orderDetails: OrderDetails;

  @Input()
    cancelledOrder = '';

  strings = messages.orderConfirmation;

  showOrder = false;

  public defaultImage = defaultImage;

  public phonePaymentIds = phonePaymentIds;

  expandDetails() {
    this.showOrder = !this.showOrder;
  }
}
