import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { Address } from './address.model';
import { TransactionDistribution } from './transaction-distribution.model';
import { TransactionInfo } from './transaction-info.model';
import { BankInfoModel } from './bank-info.model';
import { InternalStatus } from './application-status.model';
import { ShippingLocations } from './shipping-locations.model';
import { DeliveryOptions } from './delivery-options.model';
import { Category } from './category.model';
import { BusinessInfoModel } from './business-info.model';

export class BusinessLocationModel {
  constructor(obj?: any) {
    makeObservable(this, {
      id: observable,
      name: observable,
      isPrimary: observable,
      orderId: observable,
      merchantId: observable,
      address: observable,
      status: observable,
      productShippingAddress: observable,
      transactionDistribution: observable,
      transactionInfo: observable,
      bankInfo: observable,
      businessCategory: observable,
      mcc: observable,
      mccDescription: observable,
      deliveryOptions: observable,
      sameAsBusinessLoc: observable,
      sameAsPrimaryBank: observable,
      progressCode: observable,
      updatedAt: observable,
      externalMid: observable,
      nashvilleMid: observable,
      offeringDescription: observable,
      businessInfo: observable,
    });
    if (obj) {
      Object.assign(this, obj);
      this.address = new Address(obj.address);
      this.transactionDistribution = obj.transactionDistribution
        ? new TransactionDistribution(obj.transactionDistribution)
        : null;
      this.status = obj.status ? new InternalStatus(obj.status) : new InternalStatus();
      this.productShippingAddress = obj.productShippingAddress
        ? new ShippingLocations(obj.productShippingAddress)
        : new ShippingLocations();
      this.transactionInfo = obj.transactionInfo ? new TransactionInfo(obj.transactionInfo) : new TransactionInfo();
      this.bankInfo = obj.bankInfo ? new BankInfoModel(obj.bankInfo) : new BankInfoModel();
      this.deliveryOptions = obj.deliveryOptions ? new DeliveryOptions(obj.deliveryOptions) : new DeliveryOptions();
      this.businessInfo = obj.businessInfo ? new BusinessInfoModel(obj.businessInfo) : new BusinessInfoModel();
    }
  }

  id?: string;

  name = '';

  isPrimary = false;

  orderId?: string;

  merchantId?: string;

  address: Address = new Address();

  status?: InternalStatus;

  productShippingAddress: ShippingLocations;

  transactionDistribution: TransactionDistribution = new TransactionDistribution();

  transactionInfo?: TransactionInfo;

  bankInfo?: BankInfoModel = new BankInfoModel();

  businessCategory?: Category;

  mcc?: string;

  mccDescription?: string;

  deliveryOptions: DeliveryOptions = new DeliveryOptions();

  sameAsBusinessLoc?: boolean = false;

  sameAsPrimaryBank?: boolean = false;

  progressCode: number;

  updatedAt?: string | Date;

  externalMid?: string;

  nashvilleMid?: string;

  offeringDescription?: string;

  businessInfo?: BusinessInfoModel;
}

export class BusinessLocationResponse extends BusinessLocationModel {
}

export class BusinessLocationModelResponse {
  businessLocation: BusinessLocationModel;

  cartId: string;
}

export interface IBusinessLocationConfig {
  displayLocationCategory?: boolean;
  displayPrimaryLocationRb?: boolean;
  displayMccConfiguration?: boolean;
  displayMccDescription?: boolean;
  displayPhoneNumber?: boolean;
  disableReadonlyShippingAddress?: boolean;
  disableShippingAddressPrefilling?: boolean;
}
