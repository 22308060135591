import { Component } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Product } from '@xup-payments/xup-frontend-utils/models';
import { purchaseTypePriority } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-software-popup',
  templateUrl: './software-popup.component.html',
  styleUrls: ['./software-popup.component.scss'],
})
export class SoftwarePopupComponent implements SoftwarePopupInterface {
  private purchaseTypePriority = purchaseTypePriority;

  product: Product;

  constructor(public cartStore: CartStore, private applicationStore: ApplicationStore, private modalRef: BsModalRef) {}

  public async addToCart() {
    await this.cartStore.updateSoftwareProducts(this.applicationStore.firstBusinessLocationId, this.product);
  }

  public close(): void {
    this.modalRef.hide();
  }
}

export interface SoftwarePopupInterface {
  product: Product;
}
