import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { UntypedFormBuilder } from '@angular/forms';
import { IndustryController } from '@eventhorizon/controllers/industry.controller';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { categories, Category } from '@xup-payments/xup-frontend-utils/models';
import { commonErrors, ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationPageComponent } from '../application-page/application-page.component';
import { lastValueFrom } from 'rxjs';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-industry-selector',
  templateUrl: './industry-selector.component.html',
  styleUrls: ['./industry-selector.component.scss'],
})
export class IndustrySelectorComponent extends FormCarouselSlide implements OnInit {
  public categoriesMetadata = categories;

  public categories: Category[];

  public errorMessage: string;

  private routes = ROUTES;

  public category: MobxFormControl;

  @Input() public disableNextOnError? = false;

  constructor(
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    private store: ApplicationStore,
    protected productService: ProductService,
    private applicationPageComponent: ApplicationPageComponent,
    protected fb: UntypedFormBuilder,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private industryController: IndustryController,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    this.loadCategories();
  }

  private loadCategories() {
    this.form = this.fb.group({});
    this.applicationPageComponent.loading = true;
    this.productService.getCategories().subscribe({
      next: async result => {
        this.categories = result
          .sort((a, b) => a.rank - b.rank)
          .map(c => {
            if (!c.fa) {
              const meta = this.categoriesMetadata.find(cat => cat.id === c.id);
              if (meta && meta.fa) c.fa = meta.fa;
              // if (meta && meta.description) c.description = meta.description;
            }
            return c;
          });
        this.applicationPageComponent.loading = false;
        this.buildForm();
        if (this.store.subCategory && this.activatedRoute.snapshot.queryParams.continue && (await this.save())) {
          this.router.navigate([`/${this.routes.INDUSTRY_SUBCATEGORY}`], {
            queryParams: this.activatedRoute.snapshot.queryParams,
          });
        }
      },
      error: () => {
        this.errorMessage = commonErrors.errorEncountered;
      },
    });
  }

  private buildForm() {
    const s = this.store;
    this.category = new MobxFormControl(
      'category',
      () => (s.category ? this.industryController.getCategoryId(s.category.id, this.categories) : -1),
      v => this.industryController.select(v, this.categories),
      CommonValidator.number(),
    );

    this.form.setControl('category', this.category);
  }

  public select(idCategory: number) {
    this.category.setValue(idCategory);
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    try {
      await lastValueFrom(this.store.saveCategory());
      return true;
    } catch {
      this.errorMessage = commonErrors.errorEncountered;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public isSelected(index: number): boolean {
    if (!this.store.category) {
      return false;
    }

    if (this.category.value === this.categories[index].id) {
      this.store.category.id = this.categories[index].id;
      return true;
    }

    return false;
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  override isNextDisabled(): boolean {
    return !!this.disableNextOnError && this.errorMessage === commonErrors.errorEncountered;
  }
}
