import { Input, ChangeDetectorRef, Directive } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BankerPopupInterface } from '@xup-payments/xup-frontend-utils/models';
import { BankerPopupComponent } from './banker-popup/banker-popup.component';
import { CarouselSlide, FormTypes } from './carousel-slide';
import { UntypedFormGroup } from '@angular/forms';

@Directive()
export class BankerCarouselSlide<FormType extends FormTypes = UntypedFormGroup> extends CarouselSlide<FormType> {
  @Input()
  public popup: BankerPopupInterface;

  constructor(protected bsModalService: BsModalService, protected cd: ChangeDetectorRef) {
    super(cd);
  }

  public onOpen() {
    super.onOpen();

    // This should be mixin'd out someday...
    if (this.popup) {
      const initialState = { ...this.popup };
      this.bsModalService.show(BankerPopupComponent, { initialState });
    }
  }
}
