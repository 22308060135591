import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

export class DeliveryOptions {
  constructor(obj?: any) {
    makeObservable(this, {
      timeFrame0: observable,
      timeFrame1To7: observable,
      timeFrame8To14: observable,
      timeFrame15To30: observable,
      timeFrameOver30: observable,
    });
    Object.assign(this, obj);
  }

  timeFrame0 = 0;

  timeFrame1To7 = 0;

  timeFrame8To14 = 0;

  timeFrame15To30 = 0;

  timeFrameOver30 = 0;
}
