import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { OrganizationType } from './organization-type.model';
import { Category } from './category.model';

export class BusinessInfoModel {
  taxId: string;

  taxIdConfirmation: string;

  legalName: string;

  dbaName: string;

  taxFilingName: string;

  organizationType: OrganizationType = { code: '', description: '' };

  phone: string;

  email: string;

  incDate: string;

  startDate: Date;

  website: string;

  tinType: number;

  taxClassification: number;

  mcc: string;

  businessCategory: Category;

  constructor(obj?: any) {
    makeObservable(this, {
      taxId: observable,
      taxIdConfirmation: observable,
      legalName: observable,
      dbaName: observable,
      taxFilingName: observable,
      organizationType: observable,
      phone: observable,
      email: observable,
      incDate: observable,
      startDate: observable,
      website: observable,
      tinType: observable,
      taxClassification: observable,
      mcc: observable,
      businessCategory: observable,
    });
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
