<div class="panel text-center">
  <div class="modal-header">
    <button type="button" class="btn pull-right" aria-label="Close" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="product" class="software">
      <div class="software-image-wrapper">
        <img
          [alt]="product.productName"
          class="w-100"
          [src]="product.imageUrls && product.imageUrls.length ? product.imageUrls[0] : defaultImage"
        />
      </div>
      <span class="software-name">{{ product.name }}</span>

      <div>{{ product.description }}</div>

      <button class="btn primary-button btn-sm software-add-cart" (click)="addToCart()">Add to Cart</button>
    </div>

    <div *ngIf="cartStore.isLoading" class="lds-dual-ring"></div>
  </div>
</div>
