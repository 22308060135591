import { Injectable } from '@angular/core';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BusinessLocationModel, TransactionDistribution } from '@xup-payments/xup-frontend-utils/models';
import { MatchHundredValidator } from '@xup-payments/xup-frontend-utils/validation';
import { ControllerModel } from '@eventhorizon/models/controller.model';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PaymentTypesController extends ControllerModel<PaymentTypesControls> {
  paymentCategories: any[] = [
    {
      heading: 'In Store',
      subtext: 'Accept card payments face-to-face at a counter',
      icon: 'fa-bag-shopping',
      value: 0,
      controlName: 'faceToFace',
    },
    {
      heading: 'Ecommerce',
      subtext: 'Accept card payments easily through your website',
      icon: 'fa-tv',
      value: 1,
      controlName: 'internet',
    },
    {
      heading: 'On The Go',
      subtext: 'Accept card payments where ever your business takes you',
      icon: 'fa-person-running',
      value: 2,
      controlName: 'onTheGo',
    },
    {
      heading: 'Phone or Mail',
      subtext:
        'Process card payments on behalf of your customers when services are paid for via telephone, mail, or internet communication.',
      icon: 'fa-phone',
      value: 3,
      controlName: 'phoneOrEmail',
    },
  ];

  totalChannels: MobxFormControl;

  constructor(private store: ApplicationStore, private fb: FormBuilder) {
    super();
  }

  syncLocations() {
    this.store.transactionInfo.numberOfLocations = this.store.transactionInfo.numberOfLocations
      ? this.store.transactionInfo.numberOfLocations
      : 1;
    this.store.setBusinessLocations(this.store.transactionInfo.numberOfLocations, this.store.businessLocations || []);
  }

  buildForm(isAssisted: boolean = false, location?: BusinessLocationModel): PaymentTypesControls {
    const sBL = isAssisted && location ? location : this.store.businessLocations[0];

    this.totalChannels = new MobxFormControl(
      'totalChannels',
      () => sBL.transactionDistribution?.totalPercentage,
      v => {
        if (sBL.transactionDistribution !== null) sBL.transactionDistribution.totalPercentage = v;
        else sBL.transactionDistribution = new TransactionDistribution({ totalPercentage: v });
      },
      MatchHundredValidator.matchHundredValidator(isAssisted),
    );
    this.totalChannels.setValue(sBL.transactionDistribution?.totalPercentage);
    this.totalChannels.updateValueAndValidity({ onlySelf: true });

    const fbGroup: PaymentTypesControls = {
      totalChannels: this.totalChannels,
      faceToFace: null,
      internet: null,
      onTheGo: null,
      phoneOrEmail: null,
    };

    this.paymentCategories.forEach(c => {
      c.control = new MobxFormControl(
        c.controlName,
        () => sBL.transactionDistribution?.[c.controlName],
        v => {
          if (sBL.transactionDistribution !== null) sBL.transactionDistribution[c.controlName] = v;
          else sBL.transactionDistribution = new TransactionDistribution({ [c.controlName]: v });
        },
      );
      c.control.setValue(sBL.transactionDistribution?.[c.controlName]);
      c.control.updateValueAndValidity({ onlySelf: true });
      c.control.markAsPristine({ onlySelf: true });
      c.control.markAsUntouched({ onlySelf: true });
      fbGroup[c.controlName] = c.control;
    });

    return fbGroup;
  }

  onPercentageChange(value, controlName) {
    const category = this.paymentCategories.find(c => c.controlName === controlName);
    if (category) {
      category.control.setValue(value);
      this.totalChannels.updateValueAndValidity({ onlySelf: true });
      let total = 0;
      this.paymentCategories.forEach(c => {
        const controlVal = parseInt(c.control.value, 10);
        total += isNaN(controlVal) ? 0 : controlVal;
      });
      this.totalChannels.setValue(total);
      this.totalChannels.markAsTouched();
      this.totalChannels.updateValueAndValidity({ onlySelf: true });
    }
  }

  setEmptyToZero() {
    this.paymentCategories.map(cat => {
      if (!cat.control.value) {
        this.onPercentageChange(0, cat.controlName);
      }
    });
  }

  hideTotalChannelsError() {
    let show = false;
    this.paymentCategories.forEach(c => {
      const { control } = c;
      show = show || control.dirty;
    });

    return show;
  }
}

export interface PaymentTypesControls {
  totalChannels: MobxFormControl;
  faceToFace: MobxFormControl;
  internet: MobxFormControl;
  onTheGo: MobxFormControl;
  phoneOrEmail: MobxFormControl;
}
