import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@eventhorizon/services/auth.service';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { reaction } from 'mobx';
import { messages, OwnershipSlideText } from '@eventhorizon/data/messages.data';
import { organizationTypes } from '@eventhorizon/data/organization-types.data';

@Component({
  selector: 'app-owners-screen',
  templateUrl: './owners-screen.component.html',
  styleUrls: ['./owners-screen.component.scss'],
})
export class OwnersScreenComponent extends BasePageDriverComponent implements AfterViewInit {
  public ownerMessages: OwnershipSlideText;

  readonly orgTypeData = organizationTypes;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    private authService: AuthService,
    protected router: Router,
    private cd: ChangeDetectorRef,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);
    reaction(
      () => this.store.isLoaded,
      isLoaded => {
        if (!isLoaded) return;
        this.taxExemptText();
      },
      {
        fireImmediately: true,
      },
    );
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.cd.detectChanges();
  }

  taxExemptText() {
    this.ownerMessages =
      this.store.businessInfo!.organizationType!.code === this.orgTypeData.taxExempt.code
        ? messages.ownerInformationTaxExemptSlide
        : messages.ownerInformationSlide;
  }

  public get tooltipTitleText(): string {
    return this.store.businessInfo!.organizationType!.code === this.orgTypeData.taxExempt.code
      ? messages.ownerInformationTaxExemptSlide.tooltipTitleText
      : '';
  }
}
