import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { BaseOwnerComponent } from '@eventhorizon/components/base-owner/base-owner.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { reaction } from 'mobx';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BusinessOwner, organizationTypes } from '@xup-payments/xup-frontend-utils/models';
import { commonErrors, constants } from '@xup-payments/xup-frontend-utils/constants';
import { lastValueFrom } from 'rxjs';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-personal-info',
  templateUrl: './base-personal-info.component.html',
  styleUrls: ['./base-personal-info.component.scss'],
})
export class BasePersonalInfoComponent extends FormCarouselSlide implements OnInit, AfterViewInit {
  public errorMessage: string | string[];

  @ViewChildren(BaseOwnerComponent)
  protected ownerComponents: QueryList<BaseOwnerComponent>;

  protected isPrimarySelected = true;

  protected primarySelected: MobxFormControl;

  public disableAddOwners = false;

  public selectedOwner = 0;

  public allowLessThan50Percentage = false;

  constructor(
    public store: ApplicationStore,
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: UntypedFormBuilder,
  ) {
    super(bsModalService, cd);
    reaction(
      () => this.store.ownershipTotal,
      (total: number) => {
        this.disableAddOwners = total >= 100;
      },
    );

    reaction(
      () => this.store.isLoaded,
      isLoaded => {
        if (!isLoaded) return;
        this.shouldDisableAddOwners();
      },
      {
        fireImmediately: true,
      },
    );
  }

  get showRemoveOwners() {
    return this.selectedOwner > 0;
  }

  ngOnInit() {
    this.buildForm();
  }

  ngAfterViewInit() {
    this.ownerComponents.changes.subscribe(() => {
      this.isPrimarySelected = false;
      this.ownerComponents.map((owner, i) => {
        this.form.setControl(`owner${i}`, owner.form);
        const businessOwner = this.store.owners[i];
        if (businessOwner) this.isPrimarySelected = this.isPrimarySelected;
      });
      this.cd.detectChanges();
    });
    this.cd.detectChanges();
  }

  public onOpen() {
    super.onOpen();
    this.ownerComponents.forEach((owner, i) => {
      owner.onOpen();
      this.form.setControl(`owner${i}`, owner.form);
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    // Override the parent submit to validate and switch to invalid tabs
    let valid = true;

    this.ownerComponents.forEach((owner, index) => {
      if (index === 0 || owner.isTouched()) {
        owner.validate();
      }

      if (!owner.isValid() && valid) {
        valid = false;
        this.selectedOwner = index;
      }
    });

    if (!valid) {
      return false;
    }

    try {
      const sections = [this.store.saveSections.OWNERS_SAVE];
      if (!this.store.businessInfo.taxId) {
        const subTaxId = this.store.owners[0].socialSecurityNumber;
        const subName = `${this.store.owners[0].firstName} ${this.store.owners[0].lastName}`;
        this.store.businessInfo.taxId = subTaxId;
        this.store.businessInfo.taxIdConfirmation = subTaxId;
        this.store.businessInfo.legalName = subName;
        this.store.businessInfo.taxFilingName = subName;
        sections.push(this.store.saveSections.BUSINESS_INFORMATION_SAVE);
      }
      await lastValueFrom(this.store.save(sections));
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    try {
      return await this.save();
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public addOwner() {
    if (!this.disableAddOwners) {
      this.store.owners.push(new BusinessOwner());
      this.selectedOwner = this.store.owners.length - 1;
    }
    this.shouldDisableAddOwners();
  }

  public removeOwner() {
    const activeTab = this.selectedOwner;
    this.form.removeControl(`owner${this.selectedOwner}`);
    this.store.owners.splice(activeTab, 1);
    this.selectedOwner = activeTab - 1;
    this.shouldDisableAddOwners();
  }

  public setOwner(index: number) {
    this.selectedOwner = index;
  }

  isSoleProprietor(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.soleProprietor.code
    ) {
      return false;
    }
    return true;
  }

  public isTaxExempt(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.taxExempt.code
    ) {
      return false;
    }
    return true;
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  protected buildForm() {
    this.primarySelected = new MobxFormControl(
      'primarySelected',
      () => (this.isPrimarySelected ? 'true' : ''),
      (v: string) => {
        this.isPrimarySelected = !!v;
      },
      Validators.required,
    );

    const f: any = {
      primarySelected: this.primarySelected,
    };
    this.form = this.fb.group(f);
  }

  protected shouldDisableAddOwners() {
    this.disableAddOwners =
      this.store.owners?.length >= constants.MAX_OWNERS || this.isSoleProprietor() || this.isTaxExempt();
  }

  primaryChanged() {}

  public ownerTitle(index: number): string {
    let title = `Owner ${index + 1}`;

    if (this.isSoleProprietor()) title = 'Primary Owner';
    if (this.isTaxExempt()) title = 'Controlling Party';

    return title;
  }
}
