import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BankInfoConfigModel, Page, PartnerProductsCarouselConfig } from '@xup-payments/xup-frontend-utils/models';

@Injectable({ providedIn: 'root' })
export class ContentServiceService {
  readonly BASE_URL = '/api/content/';

  public bankInfoConfig;

  private formattedBankName;

  constructor(
    private http: HttpClient,
    private router: Router,
    private routingPathService: RoutingPathService,
    public authService: AuthService,
  ) {
    this.bankInfoConfig = (window as any).bankInfoConfig || ((window as any).config && (window as any).config.bankInfo);
    if (this.bankInfoConfig) {
      this.formattedBankName = this.bankInfoConfig.name.toLowerCase().split(' ').join('');
    }
  }

  public getLandingPage(url?: string): Observable<Page> {
    const routeTo = url || this.routingPathService.urlToRoute(this.router.url);
    // eslint-disable-next-line max-len
    return this.http.get<Page>(`${this.BASE_URL}content/page/${this.formattedBankName}_${routeTo}`);
  }

  /**
   * Loads the bank configs.
   *
   * @returns Observable<BankInfoConfigModel>
   */
  public loadBankConfigs(): Observable<BankInfoConfigModel> {
    const storedConfig = sessionStorage.getItem('bank_config');
    // Checking if the respond is cached.
    if (storedConfig) {
      const parseonfig = JSON.parse(storedConfig);
      this.bankInfoConfig = { ...this.bankInfoConfig, ...parseonfig };
      return of(this.bankInfoConfig);
    }
    // Fetching the bank configs and cacheing.
    return this.getBankConfigFromService().pipe(
      map(res => {
        sessionStorage.setItem('bank_config', JSON.stringify(res));
        this.bankInfoConfig = { ...this.bankInfoConfig, ...res };
        return this.bankInfoConfig;
      }),
      catchError(() => {
        this.initUnsetProperties();
        return of(this.bankInfoConfig);
      }),
    );
  }

  /**
   * Fetches products carousel content
   *
   * @returns Observable<PartnerProductsCarouselConfig>
   */
  public getPartnerCarouselContent(partnerName: string): Observable<PartnerProductsCarouselConfig> {
    return this.http.get<PartnerProductsCarouselConfig>(`${this.BASE_URL}content/partner/${partnerName}`);
  }

  private initUnsetProperties(): void {
    this.bankInfoConfig.assistivePhoneNumber = this.bankInfoConfig.phoneNumber;
    this.bankInfoConfig.returnLink = '/';
    this.bankInfoConfig.hasChat = false;
  }

  private getBankConfigFromService(): Observable<BankInfoConfigModel> {
    return this.http.get<BankInfoConfigModel>(`/api/content/content/bank/${this.formattedBankName}`);
  }

  public getPageFromID(pageID: string): Observable<Page> {
    return this.http.get<Page>(`${this.BASE_URL}content/page/${pageID}`);
  }
}
