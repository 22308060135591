import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatchHundredValidator } from '@xup-payments/xup-frontend-utils/validation';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { PaymentTypesController } from '@eventhorizon/controllers/payment-types.controller';
import { IReactionDisposer, reaction } from 'mobx';
import { BsModalService } from 'ngx-bootstrap/modal';
import { masks, TransactionDistribution } from '@xup-payments/xup-frontend-utils/models';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { commonErrors } from '@xup-payments/xup-frontend-utils/constants';
import { lastValueFrom } from 'rxjs';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-payment-types',
  templateUrl: './payment-types.component.html',
  styleUrls: ['./payment-types.component.scss'],
})
export class PaymentTypesComponent extends FormCarouselSlide<FormGroup<IPaymentTypesForm>> implements OnInit, OnDestroy {
  public errorMessage = '';

  public masks = masks;

  public id = randomComponentId();

  @Input()
  public categories: any[];

  public channelControls: MobxFormControl<number>[];

  public totalChannels: MobxFormControl<number>;

  public faceToFace: MobxFormControl<number>;

  public internet: MobxFormControl<number>;

  public onTheGo: MobxFormControl<number>;

  public phoneOrEmail: MobxFormControl<number>;

  public isSaving = false;

  private disposer: IReactionDisposer;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected bsModalService: BsModalService,
    protected routingService: RoutingPathService,
    protected paymentTypesController: PaymentTypesController,
  ) {
    super(bsModalService, cd);
    this.disposer = reaction(
      () => this.store.isLoaded,
      () => {
        if (!this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution = new TransactionDistribution({
            faceToFace: 0,
            phoneOrEmail: 0,
            internet: 0,
            onTheGo: 0,
            totalPercentage: 0,
          });
        }
        this.buildForm();
      },
    );
    this.categories = this.paymentTypesController.paymentCategories;
  }

  ngOnInit() {
    if (!this.store.isLoaded) return;
    this.buildForm();
  }

  getTotalPercentage(): number {
    return this.categories.reduce((prevValue, currCategory) => {
      return (prevValue += this.form.controls[currCategory.controlName].value);
    }, 0);
  }

  protected buildForm() {
    this.totalChannels = new MobxFormControl<number>(
      'totalChannels',
      () => {
        if (this.store.businessLocations.length > 0 && this.store.businessLocations[0].transactionDistribution) {
          return this.store.businessLocations[0].transactionDistribution.totalPercentage;
        }
      },
      (v: number) => {
        if (this.store.businessLocations.length > 0 && this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution.totalPercentage = v;
        }
      },
      MatchHundredValidator.matchHundredValidator(),
    );
    this.totalChannels.setValue(this.store.businessLocations[0].transactionDistribution.totalPercentage);
    this.totalChannels.updateValueAndValidity({ onlySelf: true });

    this.faceToFace = new MobxFormControl<number>(
      'faceToFace',
      () => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          return this.store.businessLocations[0].transactionDistribution.faceToFace;
        }
        return 0;
      },
      (v: number) => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution.faceToFace = v;
        }
      },
    );
    this.internet = new MobxFormControl<number>(
      'internet',
      () => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          return this.store.businessLocations[0].transactionDistribution.internet;
        }
        return 0;
      },
      (v: number) => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution.internet = v;
        }
      },
    );
    this.onTheGo = new MobxFormControl<number>(
      'onTheGo',
      () => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          return this.store.businessLocations[0].transactionDistribution.onTheGo;
        }
        return 0;
      },
      (v: number) => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution.onTheGo = v;
        }
      },
    );
    this.phoneOrEmail = new MobxFormControl<number>(
      'phoneOrEmail',
      () => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          return this.store.businessLocations[0].transactionDistribution.phoneOrEmail;
        }
        return 0;
      },
      (v: number) => {
        if (this.store.businessLocations[0] && this.store.businessLocations[0].transactionDistribution) {
          this.store.businessLocations[0].transactionDistribution.phoneOrEmail = v;
        }
      },
    );

    this.form = this.fb.group<IPaymentTypesForm>({
      totalChannels: this.totalChannels,
      faceToFace: this.faceToFace,
      internet: this.internet,
      onTheGo: this.onTheGo,
      phoneOrEmail: this.phoneOrEmail,
    });

    for (const c of this.categories) {
      this.form.controls[c.controlName].setValue(
        this.store.businessLocations[0].transactionDistribution[c.controlName],
      );
      this.form.controls[c.controlName].updateValueAndValidity({ onlySelf: true });
      this.form.controls[c.controlName].markAsPristine({ onlySelf: true });
      this.form.controls[c.controlName].markAsUntouched({ onlySelf: true });
    }
    this.form.updateValueAndValidity();
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    let result: boolean;
    try {
      await lastValueFrom(
        this.store.updateSubmerchant(this.store.businessLocations[0].id, this.store.businessLocations[0]),
      );
      await lastValueFrom(this.store.saveTransactionInfo());
      result = true;
    } catch (e) {
      console.error(e);
      this.errorMessage = commonErrors.failedToSaveInfo;
      result = false;
    } finally {
      this.isSaving = false;
    }
    return result;
  }

  private setTransactionsValues() {
    if (!this.store.businessLocations[0].transactionDistribution) {
      this.store.businessLocations[0].transactionDistribution = new TransactionDistribution({
        faceToFace: (this.form.get('faceToFace').value as number) || 0,
        phoneOrEmail: (this.form.get('phoneOrEmail').value as number) || 0,
        internet: (this.form.get('internet').value as number) || 0,
        onTheGo: (this.form.get('onTheGo').value as number) || 0,
        totalPercentage: (this.form.get('totalChannels').value as number) || 0,
      });
    }
  }

  protected transactionDistributionHasInStore() {
    const hasInStore = this.store.businessLocations.find(location => location.transactionDistribution.faceToFace > 0);

    if (!hasInStore) {
      if (this.routingService.isRouteActive('locations')) {
        this.store.hideLocations = true;
        this.routingService.deactivateRoute('locations');
      }
      this.store.transactionInfo.numberOfLocations = 1;
    } else if (hasInStore) {
      this.store.hideLocations = false;
      this.routingService.activateRoute('locations');
    }
  }

  public async preOnNext(): Promise<boolean> {
    this.isSaving = true;
    this.setTransactionsValues();
    this.transactionDistributionHasInStore();
    this.setEmptyToZero();
    return this.save();
  }

  public onPercentageChange(value, controlName) {
    const category = this.categories.find(c => c.controlName === controlName);
    if (category) {
      this.form.controls[category.controlName].setValue(value);
      this.totalChannels.updateValueAndValidity({ onlySelf: true });
      let total = 0;
      this.categories.forEach(c => {
        const controlVal = parseInt(this.form.controls[c.controlName].value, 10);
        total += isNaN(controlVal) ? 0 : controlVal;
      });
      this.totalChannels.setValue(total);
      this.totalChannels.markAsTouched();
      this.totalChannels.updateValueAndValidity({ onlySelf: true });
    }
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  setEmptyToZero() {
    this.categories.map(cat => {
      if (!this.form.controls[cat.controlName].value || this.form.controls[cat.controlName].value === '') {
        this.onPercentageChange(0, cat.controlName);
      }
    });
  }

  public hideTotalChannelsError() {
    let show = false;
    this.categories.forEach(c => {
      const control = this.form.controls[c.controlName];
      show = show || control.dirty;
    });

    return show && !this.isSaving;
  }

  public ngOnDestroy(): void {
    if (this.disposer) this.disposer();
  }
}

export interface IPaymentTypesForm {
  totalChannels: FormControl<number>;
  faceToFace: FormControl<number>;
  internet: FormControl<number>;
  onTheGo: FormControl<number>;
  phoneOrEmail: FormControl<number>;
}