import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clone } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-multiple-selection-dropdown',
  templateUrl: './multiple-selection-dropdown.component.html',
  styleUrls: ['./multiple-selection-dropdown.component.scss'],
})
export class MultipleSelectionDropdownComponent implements OnInit {
  @Input() dropdownOptions: { id: string; value: string }[];

  @Input() selectedOptions: { id: string; value: string }[];

  @Output() optionClick = new EventEmitter();

  @Output() removeElement = new EventEmitter();

  public searchString: string;

  public filteredDropdownOptions: { id: string; value: string }[];

  ngOnInit() {
    this.filteredDropdownOptions = clone(this.dropdownOptions);
  }

  isOptionSelected(option) {
    return this.selectedOptions.map(e => e.id).indexOf(option.id) >= 0;
  }

  search() {
    if (this.searchString.length >= 3 || this.searchString.length === 0) {
      this.filteredDropdownOptions = this.dropdownOptions.filter(
        option => option.value.indexOf(this.searchString) >= 0,
      );
    }
  }
}
