<div class="modal-header">
  <button type="button" class="btn" aria-label="Back" (click)="setIncludedProductIdToShow('')" *ngIf="includedProductIdToShow">
    <i class="fa fa-arrow-left"></i>
  </button>
  <button type="button" class="btn" aria-label="Close" (click)="close()">
    <i class="far fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!includedProductIdToShow">
    <app-tg-product-detail #productDetail
      [productId]="productId" 
      (additionalProductClicked)="setIncludedProductIdToShow($event)"
      (productUpdate)="updateCart($event)"
    ></app-tg-product-detail>
  </ng-container>
  <ng-container *ngIf="includedProductIdToShow">
    <app-tg-product-detail-included-popup [productId]="includedProductIdToShow"></app-tg-product-detail-included-popup>
  </ng-container>
</div>