import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CartPopupConstants } from '@eventhorizon/components/cart/cart-popup/cart-popup.constants';

@Component({
  selector: 'app-tg-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('focus') public focus: ElementRef;

  @ViewChild('modalContent') public modalContent: ElementRef;

  public ariaLabelledByValue = CartPopupConstants.ariaLabelledBy;

  public continuePage: string;

  public result: boolean;

  constructor(private modalRef: BsModalRef, public cd: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    this.cd.detectChanges();
  }

  closeModal() {
    this.close(false);
  }

  public ngOnDestroy() {
    this.closeModal();
  }

  public close(result: boolean): void {
    this.result = result;
    this.modalRef.hide();
  }
}
