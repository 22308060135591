import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStore } from '@eventhorizon/stores/auth.store';
import { Observable } from 'rxjs';
import { BosInformationModel } from '@xup-payments/xup-frontend-utils/models';

@Injectable({ providedIn: 'root' })
export class BosInformationService {
  private BASE_URL;

  public constructor(private http: HttpClient, private authStore: AuthStore) {
    this.BASE_URL = this.authStore.getPayfacId() ? '/api/merchants/' : '/api/applications/';
  }

  public getBOSinformation(id: string): Observable<BosInformationModel> {
    return this.http.get<BosInformationModel>(`${this.BASE_URL}${id}/bos-results`);
  }
}
