import { computed, observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { Address } from './address.model';

export class BusinessAddressesModel {
  constructor(obj?: any) {
    makeObservable(this, {
      legalAddress: observable,
      mailingAddress: observable,
      physicalAddressAlt: computed,
      physicalAddress: computed,
      billingAddress: computed,
    });
    if (obj) {
      Object.assign(this, obj);
      this.legalAddress = new Address(obj.legalAddress);
    }
  }

  legalAddress: Address = new Address();

  mailingAddress: Address = new Address();

  get physicalAddressAlt() {
    return this.legalAddress;
  }

  get physicalAddress(): Address {
    return this.legalAddress;
  }

  get billingAddress(): Address {
    return this.legalAddress;
  }
}
