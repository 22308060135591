import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { concat, lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthService } from '@eventhorizon/services/auth.service';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UUID } from 'angular2-uuid';
import { MessagesService } from '@eventhorizon/services/messages.service';
import { Route, RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { configure, when } from 'mobx';
import { ChatHandlerService } from '@eventhorizon/services/miramar/chatbox.service';
import { ProcessorName } from '@xup-payments/xup-frontend-utils/models';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { CanonicalService } from './canonical.service';
import { environment } from 'src/environments/environment';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('threatmetrix', { static: true })
  private threatMetrix: ElementRef;

  public threatMetrixUrl: SafeResourceUrl;

  public threatMetrixId: string;

  public loaded = false;

  private routes: Route[];

  private currentRoute: Route;

  private threatMetrixBaseUrl: string;

  private currentPageUrl: string;

  private path: string;

  public constructor(
    private errorsService: MessagesService,
    protected route: ActivatedRoute,
    private authService: AuthService,
    private store: ApplicationStore,
    private router: Router,
    private sanitizer: DomSanitizer,
    private routingPath: RoutingPathService,
    private contentService: ContentServiceService,
    private locationsService: LocationsService,
    private chatService: ChatHandlerService,
    private canonicalService: CanonicalService,
  ) {
    configure({
      enforceActions: 'never',
    });
    // eslint-disable-next-line @typescript-eslint/dot-notation
    window['store'] = store;
    when(
      () => this.store.isLoaded && !this.locationsService.currentLocation,
      () => this.locationsService.initLocationsService(),
    );
  }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(async (event: NavigationStart) => {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(async (event: NavigationEnd) => {
      try {
        gtag('event', 'page_view', {
          page_path: event.url,
        });
      } catch (e) {
        console.error('gtag failed', e);
      }

      this.path = this.routingPath.urlToRoute(event.url);
      this.routes = this.routingPath.routePath;

      try {
        await this.initializeApplication();
        if (this.contentService.bankInfoConfig.hasChat && !environment.turnOffBoomtown) {
          this.chatService.setChatboxDisplayMode();
        }
        this.store.threatMetrixGuid = this.threatMetrixId;
      } catch (error) {
        console.error('Error initing application', error);
      } finally {
        this.loaded = true;
      }

      try {
        this.currentRoute = this.routes.find(({ route }) => route === this.path);
        if (this.currentRoute && !this.currentRoute.active) {
          this.router.navigate([this.routingPath.getPrevActiveRoute(this.router)]);
        }
      } catch (e) {
        console.error('Route not found in routing list', e);
      } finally {
        // The partner referral page will handle hiding the splash screen itself
        if (!this.router.url.includes('/partner')) {
          setTimeout(() => {
            let splash = document.getElementById('splashScreen');
            if (splash) {
              splash.style.opacity = '0';
              setTimeout(() => {
                splash.remove();
              }, 500);
            }
          }, 1000);
        }
      }
    });
    this.threatMetrixBaseUrl = 'https://h.online-metrix.net/tags?org_id=huumuzel&session_id=';
    this.threatMetrixId = UUID.UUID();
    this.threatMetrixUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.threatMetrixBaseUrl + this.threatMetrixId,
    );

    this.route.queryParams.subscribe(params => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.authService.processor = ProcessorName[params['processor'] as keyof typeof ProcessorName];
    });
  }

  private applicationLoads() {
    this.currentPageUrl = this.getPageUrl();
    const isResuming =
      this.currentPageUrl.includes(`/${ROUTES.PREFILL}`) ||
      this.currentPageUrl.includes(`/${ROUTES.RESUME}`) ||
      this.route.snapshot.queryParams.token;
    if (isResuming) {
      return concat(this.errorsService.load());
    } else {
      return concat(
        this.errorsService.load(),
        this.authService.ensureGuestToken(),
        this.contentService.loadBankConfigs(),
        this.store.loadApplication(),
      );
    }
  }

  private getPageUrl() {
    return window.location.href;
  }

  private initializeApplication() {
    return lastValueFrom(this.applicationLoads());
  }

  public skipToContentSpacebar(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    document.getElementById('appSlide').focus();
  }

  public skipToContent() {
    document.getElementById('appSlide').focus();
  }
}
