<div class="panel" id="help-center">
  <div class="modal-body help-center">
    <section class="help-center-actions">
      <div>
        <button *ngIf="isFormSelected || isFormSubmitted" type="button" class="btn btn-link" (click)="back()">
          <i class="far fa-angle-left"></i>&nbsp;Back
        </button>
      </div>
      <div>
        <button type="button" class="btn btn" aria-label="Close Button" (click)="close()">
          <i class="far fa-xmark-large"></i>
        </button>
      </div>
    </section>
    <section class="help-center-contents" *ngIf="!isFormSelected && !isFormSubmitted">
      <header class="row">
        <div class="help-center-header">
          <h1 class="help-center-header-title">{{ contentService?.bankInfoConfig?.helpModalTitle }}</h1>
          <p class="help-center-header-subtitle">
            {{ contentService?.bankInfoConfig?.helpModalSubtitle }}
          </p>
        </div>
      </header>
      <section class="d-flex">
        <div id="call-us-wrapper" class="help-center-item">
          <div class="help-center-item-icon">
            <i class="far fa-phone-flip"></i>
          </div>
          <h2 class="help-center-item-title">Call Us!</h2>
          <p class="help-center-item-description">
            <span class="help-center-item-description-phone">{{ contentService?.bankInfoConfig?.phoneNumber }}</span>
            <span *ngIf="contentService?.bankInfoConfig?.weekdayPhoneHours">
              Our team is available {{ contentService?.bankInfoConfig?.weekdayPhoneHours }} for calls
            </span>
          </p>
          <div class="help-center-item-button-container">
            <a
              target="_blank"
              class="btn btn-link help-center-item-button"
              [href]="'tel:' + contentService?.bankInfoConfig?.phoneNumber"
            >
              Call Us <i class="far fa-angle-right"></i>
            </a>
          </div>
        </div>
        <div id="chat-wrapper" class="help-center-item" *ngIf="contentService?.bankInfoConfig?.hasChat && displayChat">
          <div class="help-center-item-icon">
            <i class="far fa-messages"></i>
          </div>
          <h2 class="help-center-item-title">Live Chat</h2>
          <p class="help-center-item-description">Chat online with a Merchant Services specialist</p>
          <div class="help-center-item-button-container">
            <button type="button" class="btn btn-link help-center-item-button" (click)="openChat()">
              Chat <i class="far fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div
          *ngIf="shouldShowCallBack"
          id="request-call-wrapper"
          class="help-center-item"
          [ngClass]="{
            'col-md-4': contentService?.bankInfoConfig?.hasChat && displayChat,
            'col-md-3': !contentService?.bankInfoConfig?.hasChat || !displayChat
          }"
        >
          <div class="help-center-item-icon">
            <i class="far fa-messages"></i>
          </div>
          <h2 class="help-center-item-title">Request a Call</h2>
          <p class="help-center-item-description">Have one of our Merchant specialists contact you</p>
          <div class="help-center-item-button-container">
            <button type="button" class="btn btn-link help-center-item-button" (click)="openForm()">
              Request a Call Back<i class="far fa-angle-right"></i>
            </button>
          </div>
        </div>
      </section>
    </section>
    <section class="help-center-contents" *ngIf="isFormSelected && !isFormSubmitted">
      <header class="row">
        <div class="col-12 help-center-header" *ngIf="!isLoading">
          <h1 class="help-center-header-title">Request a Call Back</h1>
          <p class="help-center-header-subtitle">
            Enter your contact information and one of our Merchant Services specialists will reach out to you.
          </p>
        </div>
      </header>
      <div *ngIf="isLoading" class="col-12 text-center">
        <div class="pb-4 pt-4">Submitting call back request</div>
        <div class="lds-dual-ring"></div>
      </div>
      <form *ngIf="!isLoading" [formGroup]="form" class="row help-center-form">
        <div class="col-sm-12 col-md-6 mb-3 position-relative">
          <input
            [id]="id"
            formControlName="name"
            class="form-control qa-contact-info-first-name"
            type="text"
            autocomplete="given-name"
            [maskito]="masks.personalName"
          />
          <label class="form-label" [for]="id">First Name </label>
          <app-field-error [control]="form.controls.name"></app-field-error>
        </div>

        <div class="col-sm-12 col-md-6 mb-3 position-relative">
          <input
            [id]="id + 1"
            formControlName="lastName"
            class="form-control qa-contact-info-last-name"
            type="text"
            autocomplete="family-name"
            [maskito]="masks.personalName"
          />
          <label class="form-label" [for]="id + 1">Last Name </label>
          <app-field-error [control]="form.controls.lastName"></app-field-error>
        </div>
        <div class="col-12 mb-3 position-relative">
          <input
            [id]="id + 2"
            formControlName="legalName"
            class="form-control qa-contact-info-company-name"
            type="text"
            autocomplete="on"
            [maskito]="masks.companyName"
          />
          <label class="form-label" [for]="id + 2">Legal Business Name</label>
          <app-field-error [control]="form.controls.legalName"></app-field-error>
          <span class="help-center-tooltip">
            <app-tg-tooltip
              [tooltipText]="
                'The legal business name is the formal and officially registered name of your business that appears on the formation document of your business entity. If you are doing business as a sole proprietorship, then the legal business name will be the first and last name of the sole proprietor.'
              "
            ></app-tg-tooltip>
          </span>
        </div>
        <div class="col-12 mb-3 position-relative">
          <input
            [id]="id + 4"
            formControlName="email"
            class="form-control qa-contact-info-email"
            type="text"
            maxlength="70"
            autocomplete="email"
          />
          <label class="form-label" [for]="id + 3">Email</label>
          <app-field-error [control]="form.controls.email"></app-field-error>
        </div>
        <div class="col-12 mb-3 position-relative">
          <input
            [id]="id + 3"
            formControlName="phoneNumber"
            class="form-control qa-contact-info-phone-number"
            type="text"
            autocomplete="tel"
            [maskito]="masks.phone"
          />
          <label class="form-label" [for]="id + 4">Phone</label>
          <app-field-error [control]="form.controls.phoneNumber"></app-field-error>
        </div>

        <div class="d-flex justify-content-center mb-3 position-relative col-md-12">
          <re-captcha formControlName="recaptcha"></re-captcha>
        </div>
        <div *ngIf="errorMessage && errorMessage.length > 0" class="col-12 form-error-message error-message">
          Failed to save application
          <ul>
            <li *ngFor="let error of errorMessage">{{ error }}</li>
          </ul>
        </div>
        <div class="col-12 help-center-form-button-container">
          <button
            type="button"
            class="btn primary-button help-center-form-button"
            [disabled]="form.invalid"
            (click)="submitForm()"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
    <section class="help-center-contents" *ngIf="isFormSubmitted">
      <header class="row">
        <div class="col-12 help-center-header hc-submitted">
          <h1 class="help-center-header-title">Thank You!</h1>
          <p class="help-center-header-subtitle">
            One of our Merchant Services team members will contact you within 24 business hours to assist with your
            application.
          </p>
        </div>
      </header>
      <div class="row">
        <div class="col-12 help-center-submitted">
          <p>
            In the meantime, if you have any questions please
            <span id="form-submitted-chat-option" *ngIf="contentService?.bankInfoConfig?.hasChat && displayChat"
              >click the chat button or </span
            >call us at
          </p>
          <p class="help-center-item-description-phone">
            <strong>{{ contentService?.bankInfoConfig?.phoneNumber }}</strong
            >.
          </p>
          <p class="help-center-submitted-business-hours">
            {{ contentService?.bankInfoConfig?.businessDaytime?.days }}:
            {{ contentService?.bankInfoConfig?.businessDaytime?.time }}
          </p>
        </div>
      </div>
    </section>
  </div>
</div>
