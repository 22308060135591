<div class="row tax-and-shipping" *ngIf="showExtendedDisplay || showComplexSubtotals">
  <div
    class="col-8 totals-text"
    [ngClass]="{ 'col-md-9 subtotal-align': showComplexSubtotals, 'col-md-7': showExtendedDisplay }"
  >
    <div>Due Today</div>
  </div>
  <div
    class="col-4 totals-amount text-end"
    [ngClass]="{ 'col-md-3': showComplexSubtotals, 'col-md-5 pe-1': showExtendedDisplay }"
  >
    <div>$0.00</div>
  </div>
</div>

<div class="row tax-and-shipping" *ngIf="showExtendedDisplay || showComplexSubtotals">
  <div
    class="col-8 totals-text"
    [ngClass]="{ 'col-md-9 subtotal-align': showComplexSubtotals, 'col-md-7': showExtendedDisplay }"
  >
    <div>Monthly Charges</div>
  </div>
  <div
    class="col-4 totals-amount text-end"
    [ngClass]="{ 'col-md-3': showComplexSubtotals, 'col-md-5 pe-1': showExtendedDisplay }"
  >
    <div>{{ recurringTotalAmount | currency }}</div>
  </div>
</div>

<div class="row tax-and-shipping" *ngIf="showExtendedDisplay || showComplexSubtotals">
  <div
    class="col-8 totals-text"
    [ngClass]="{ 'col-md-9 subtotal-align': showComplexSubtotals, 'col-md-7': showExtendedDisplay }"
  >
    <div>One-Time Total</div>
  </div>
  <div
    class="col-4 totals-amount text-end"
    [ngClass]="{ 'col-md-3': showComplexSubtotals, 'col-md-5 pe-1': showExtendedDisplay }"
  >
    <div>{{ oneTimeTotal | currency }}</div>
  </div>
</div>

<div class="row tax-and-shipping" *ngIf="showExtendedDisplay || showComplexSubtotals">
  <div
    class="col-8 totals-text"
    [ngClass]="{ 'col-md-9 subtotal-align': showComplexSubtotals, 'col-md-7': showExtendedDisplay }"
  >
    <div>Shipping</div>
  </div>
  <div
    class="col-4 totals-amount text-end"
    [ngClass]="{ 'col-md-3': showComplexSubtotals, 'col-md-5 pe-1': showExtendedDisplay }"
  >
    <div>{{ shippingAmount | currency }}</div>
  </div>
</div>

<div *ngIf="showExtendedDisplay || showComplexSubtotals" class="row tax-and-shipping">
  <div
    class="col-7 totals-text"
    [ngClass]="{ 'col-md-9 subtotal-align': showComplexSubtotals, 'col-md-8': showExtendedDisplay }"
  >
    <div>
      Est. Tax<span>{{ readonly || showComplexSubtotals ? ':' : ' for:' }}</span>
      <button
        class="transparent-button zipcode-estimated"
        *ngIf="!readonly && !showComplexSubtotals"
        (click)="toggleTaxEstimateZipCode()"
      >
        {{ zipCodeEstimation ? zipCodeEstimation : '00000' }}
        <i class="fas" [ngClass]="{ 'fa-caret-right': !showZipCodeInput, 'fa-caret-down': showZipCodeInput }"></i>
      </button>
      <app-tg-tooltip
        *ngIf="!showComplexSubtotals"
        [tooltipText]="estimatedTaxTooltipText"
        [isInsideAnotherContainer]="isInsideAnotherContainer"
      ></app-tg-tooltip>
    </div>
  </div>
  <div
    class="col-5 totals-amount text-end"
    [ngClass]="{ 'col-md-3': showComplexSubtotals, 'col-md-4 pe-1': showExtendedDisplay }"
  >
    <div *ngIf="!salesTaxRate">TBD</div>
    <div *ngIf="!isLoadingZipCode && salesTaxRate">{{ salesTaxRate | currency }}</div>
  </div>
</div>
<div class="row zipcode-container" *ngIf="showZipCodeInput">
  <div class="mb-3 position-relative zipcode-mb-3 position-relative">
    <input
      id="zipcode"
      type="text"
      class="zipcode-input"
      [ngClass]="{ 'form-control': true, 'is-invalid': zipCodeError !== '' }"
      [formControl]="zipCodeControl"
      [maskito]="masks.zip"
    />
    <label class="form-label" for="zipcode">Zipcode</label>
    <button class="transparent-button zipcode-apply" (click)="estimateTax()" [disabled]="!zipCodeControl.valid">
      Apply
    </button>
  </div>
</div>
<div class="row" *ngIf="zipCodeError !== ''">
  <div class="col-md-8">
    <p class="form-error-message">
      {{ zipCodeError }}
    </p>
  </div>
</div>
<div *ngIf="showExtendedDisplay" class="row cart-section-total">
  <div class="col-7 col-md-6 due-text">
    <div>{{ dueToMessage || 'Due on 1st payment' }}</div>
  </div>
  <div class="col-5 col-md-6 totals-amount text-end">
    <div>{{ totalCost | currency }}</div>
  </div>
</div>
