import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

export class BankInfoModel {
  routingNumber: string;

  bankName: string;

  accountNumber: string;

  usePrimary: boolean = false;

  constructor(obj?: any) {
    Object.assign(this, obj);
    makeObservable(this, {
      routingNumber: observable,
      bankName: observable,
      accountNumber: observable,
      usePrimary: observable,
    });
  }

  public isEmpty(): boolean {
    return this.isBankInfoEmpty();
  }

  private isBankInfoEmpty(): boolean {
    return !this.bankName && !this.accountNumber && !this.routingNumber;
  }
}
