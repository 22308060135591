import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { ApplicationSaveReturnRequest } from '@eventhorizon/models/application.model';
import { masks } from '@eventhorizon/data/masks.data';
import { formatPhone } from '@eventhorizon/utils/util';
import { CommonValidator } from '@eventhorizon/validation/common.validator';

@Component({
  selector: 'app-save-popup',
  templateUrl: './save-popup.component.html',
  styleUrls: ['./save-popup.component.scss'],
})
export class SavePopupComponent implements OnInit {
  public form: FormGroup<ISavePopupForm>;

  public email: MobxFormControl<string>;

  public phone: MobxFormControl<string>;

  private emailAddress: string;

  private phoneNumber: string;

  public errorMessage: string;

  public successMessage: string;

  public masks = masks;

  constructor(
    protected fb: FormBuilder,
    protected store: ApplicationStore,
    protected applicationService: ApplicationService,
    private modalRef: BsModalRef,
  ) {}

  ngOnInit() {
    this.setEmail();
    this.setPhone();

    this.form = this.fb.group({
      email: this.email,
      phone: this.phone,
      agree: new FormControl<boolean>(false, [Validators.requiredTrue]),
    });

    this.email.patchValue(this.store.applicantInfo.email);
    this.phone.patchValue(formatPhone(this.store.applicantInfo.phoneNumber));
  }

  private setEmail() {
    this.email = new MobxFormControl<string>(
      'email',
      () => this.emailAddress,
      (v: string) => (this.emailAddress = v),
      CommonValidator.email(),
    );
  }

  private setPhone() {
    this.phone = new MobxFormControl<string>(
      'phone',
      () => formatPhone(this.phoneNumber),
      (v: string) => (this.phoneNumber = formatPhone(v)),
      CommonValidator.phone(),
    );
  }

  public submit() {
    const request: ApplicationSaveReturnRequest = {
      email: this.emailAddress,
      phoneNumber: this.phoneNumber,
    };

    const applicationId = this.store.id;

    if (!applicationId) {
      console.log('cannot save application without an id:', this.store);
      this.errorMessage = 'Unable to save application.';
      return;
    }

    this.applicationService.sendReturningEmail(applicationId, request).subscribe(
      () => {
        this.successMessage = `An email has been sent to ${this.emailAddress}.`;
      },
      () => (this.errorMessage = 'Unable to save application.'),
    );
  }

  public close(): void {
    this.modalRef.hide();
  }
}

export interface ISavePopupForm {
  email: FormControl<string>;
  phone: FormControl<string>;
  agree: FormControl<boolean>;
}
