import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  IYodleeAuthResponse,
  IYodleeClose,
  IYodleeFastlinkRequest,
  IYodleeVerificationSuccess,
} from '@eventhorizon/interfaces/yodlee-interface';

@Injectable({ providedIn: 'root' })
export class BankService {
  yodleeIFrameHeight = 500;

  yodleeIFrameFooterHeight = 56;

  readonly BASE_URL = '/api/bank';

  constructor(private http: HttpClient) {}

  public validate(request: ValidateRequest): Observable<ValidateResponse> {
    return this.http.post<ValidateResponse>(`${this.BASE_URL}/validate`, request);
  }

  yodleeAccounts(applicationId: string): Observable<IYodleeAuthResponse> {
    return this.http.get<IYodleeAuthResponse>(`${this.BASE_URL}/accounts/${applicationId}`);
  }

  yodleeIFrameOpen(fastLinkUrl: string, accessToken: string): Observable<IYodleeVerificationSuccess | IYodleeClose> {
    if (window.addEventListener) {
      window.addEventListener('message', this.yodleeEventHandler.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.yodleeEventHandler.bind(this));
    }

    return new Observable<IYodleeVerificationSuccess | IYodleeClose>(observer => {
      (<any>window).fastlink.open(
        {
          fastLinkURL: fastLinkUrl,
          accessToken,
          params: {
            configName: 'Verification',
          },
          onSuccess(data: IYodleeVerificationSuccess) {
            observer.next(data);
          },
          onError(data) {
            // will be called on error. For list of possible message, refer to onError(data) Method.
            observer.error(data);
          },
          onClose(data: IYodleeClose) {
            observer.next(data);
            observer.complete();
          },
        },
        'container-fastlink',
      );
    });
  }

  yodleeFastLink(
    applicationId: string,
    providerAccountId: number,
    requestId: string,
    accountId: number,
  ): Observable<any> {
    const selectedAccountId = +accountId;
    const requestBody: IYodleeFastlinkRequest = {
      applicationId,
      providerAccountId,
      requestId,
      selectedAccountId,
    };
    return this.http.post<any>(`${this.BASE_URL}/fastlink/`, requestBody);
  }

  yodleeEventHandler(event) {
    if (event.data.height) {
      this.yodleeIFrameHeight = event.data.height;
    }
    var frame = document.getElementById('fl-frame');
    if (frame) {
      frame.title = 'instant verification selection';
      frame.style.height = this.yodleeIFrameHeight - this.yodleeIFrameFooterHeight + 'px';
    }
  }
}

export interface ValidateRequest {
  routingNumber: string;
}

export interface ValidateResponse {
  bankName: string;
}
