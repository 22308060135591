<div class="panel">
  <article *ngIf="!isSent">
    <div class="modal-header text-center">
      <h1 class="modal-title w-100">Send Application</h1>
    </div>
    <div class="modal-body">
      <section class="row">
        <div class="col-12 text-center">
          <p class="send-app-text">
            Please confirm you want to send <strong>{{ legalBusinessName }}</strong> their application to complete. A
            unique link to this application will be sent to the merchant contact email address below.
          </p>
          <p class="send-app-text send-app-email">{{ email }}</p>
          <p class="send-app-text">
            Need to send this application to a different email? Contact information can only be updated within the
            merchant's profile.
          </p>
        </div>
      </section>
      <section class="row">
        <div class="col-12 d-flex justify-content-center">
          <button type="button" [disabled]="isLoading" class="btn primary-button send-app-cancel" (click)="close()">
            <strong>Cancel</strong>
          </button>
          <button
            type="button"
            [disabled]="isLoading"
            class="btn primary-button send-app-button"
            (click)="sendApplication()"
          >
            <strong *ngIf="!isLoading">Confirm</strong>
            <i class="fas fa-spinner-third fa-spin" *ngIf="isLoading"></i>
          </button>
        </div>
        <div *ngIf="errorMessage && errorMessage !== ''" class="col-12 text-center">
          <p class="send-app-text">
            <small *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</small>
          </p>
        </div>
      </section>
    </div>
  </article>
  <article *ngIf="isSent">
    <div class="modal-header text-center">
      <i class="text-success fas fa-check-circle send-app-icon w-100"></i>
      <button #focus tabindex="0" (click)="close(false)" (keydown)="close()" class="btn pull-right send-app-close">
        <i class="far fa-xmark-large"></i>
      </button>
    </div>
    <div class="w-100 text-center">
      <h1 class="modal-title">Application Sent</h1>
    </div>
    <div class="modal-body">
      <section class="row">
        <div class="col-12 text-center">
          <p class="send-app-text-sent">
            A unique link to this application was sent to <strong>{{ legalBusinessName }}</strong> using the email
            address below.
          </p>
          <p class="send-app-text send-app-email">{{ email }}</p>
        </div>
      </section>
    </div>
  </article>
</div>


<!-- SIGNER WORK - DO NOT DELETE -->
<!-- <ng-container *ngIf="!isSent">
  <div class="modal-header row p-0">
    <div class="col-10 ps-0">
      <h2 class="send-app-title">
        Let's send the application to the
        <ng-container *ngIf="isAssisted; else signer">merchant</ng-container>
        <ng-template #signer>signer</ng-template>.
      </h2>
    </div>
    <div class="col-2 pe-0 d-flex justify-content-end">
      <button class="btn" (click)="close()">
        <i class="far fa-xmark-large"></i>
      </button>
    </div>
  </div>
  <div class="modal-body px-0 py-4">
    <div class="row pb-4">
      <div class="col-12 px-0">
        <p class="send-app-text">
          Select the appropriate
          <ng-container *ngIf="isAssisted; else signer">recipient</ng-container>
          <ng-template #signer>signer</ng-template>
          and an email with the application link will be sent to the selected individual.
        </p>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isLoadingSigners" class="col-12 px-0 d-flex justify-content-center">
        <div class="lds-dual-ring loading"></div>
      </div>
      <div *ngIf="!isLoadingSigners" class="col-12 px-0">
        <form #form>
          <app-signers-dropdown
            #signerDropdown
            [options]="options"
            [contact]="contact"
            [isAssisted]="isAssisted"
            [businessOwners]="businessOwners"
            hiddenTooltip="true"
            removeNoSigner="true"
            (dataEvent)="handleDataFromSignerForm($event)"
          ></app-signers-dropdown>
        </form>
      </div>
      <div *ngIf="errorMessage && errorMessage !== ''" class="col-12 px-0">
        <p class="send-app-text">
          <small class="text-danger">{{ errorMessage }}</small>
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" [disabled]="isLoading" class="ps-3 kds-secondary-button" (click)="close()">Cancel</button>
    <button type="button" [disabled]="!email || isLoading" class="ps-3 kds-primary-button" (click)="sendApplication()">
      <ng-container *ngIf="!isLoading">Send Application</ng-container>
      <i class="fas fa-spinner-third fa-spin" *ngIf="isLoading"></i>
    </button>
  </div>
</ng-container>
<ng-container *ngIf="isSent">
  <div class="modal-header row p-0">
    <div class="col-12 px-0 d-flex justify-content-end">
      <button class="btn" (click)="close()">
        <i class="far fa-xmark-large"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 d-flex justify-content-center mb-4">
        <lottie-player
          src="/assets/keybank/green-check.json"
          background="transparent"
          speed="1"
          class="lottie-size"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="col-12 text-center mb-2">
        <h2 class="send-app-title">Application Sent!</h2>
      </div>
      <div class="col-12 text-center">
        <p class="send-app-text mb-1">An application link was successfully sent to</p>
        <p class="send-app-text">
          <b>{{ email }}</b>
        </p>
        <p *ngIf="!isAssisted" class="send-app-text mt-1 mb-5">
          You can close the browser or have the signer review and sign the agreement on your device.
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="isAssisted" class="modal-footer d-flex justify-content-end">
    <button type="button" [disabled]="isLoading" class="ps-3 kds-primary-button" (click)="close()">Continue</button>
  </div>
</ng-container> -->
