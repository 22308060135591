export const progressCodes = {
  GET_STARTED: 0,
  
  CONTACT_INFO: 0,
  INDUSTRY_CATEGORY: 10, INDUSTRY_SUBCATEGORY: 10, INDUSTRY_MCC: 10,
  
  BUSINESS_STRUCTURE: 25, BUSINESS_INFO: 25,
  LOCATIONS: 40,
  REVIEW_CART: 60, 
  OWNERS: 60,
  
  SHIPPING: 70,
  FINAL_REVIEW: 90,
  AGREEMENT: 90,
  
  APPLICATION_CONFIRMATION: 100,
};

export const locationProgressCodes = {
  LOCATION_INFO: 0,
  LOCATION_PRODUCTS: 25,
  LOCATION_ADDONS: 33,
  LOCATIONS: 100,
};