import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { formatError } from '@xup-payments/xup-frontend-utils/utils';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { lastValueFrom } from 'rxjs';
import * as KeyCodes from '@angular/cdk/keycodes';

interface NumberOfLocations {
  display: string;
  value: number;
}

@Component({
  selector: 'app-tg-physical-locations',
  templateUrl: './physical-locations.component.html',
  styleUrls: ['./physical-locations.component.scss'],
})
export class PhysicalLocationsComponent extends FormCarouselSlide<FormGroup<IPhysicalLocationsForm>> implements OnInit {
  numOfLocations: NumberOfLocations[] = [
    { display: '1', value: 1 },
    { display: '2', value: 2 },
    { display: '3+', value: 3 },
  ];

  public errorMessage: string;

  public numberOfLocations: MobxFormControl;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected bsModalService: BsModalService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    this.buildForm();
    this.initialFocus();
  }

  private buildForm() {
    this.numberOfLocations = new MobxFormControl<number>(
      'numberOfLocations',
      () => this.store.transactionInfo.numberOfLocations,
      (v: number) => (this.store.transactionInfo.numberOfLocations = v),
      CommonValidator.number(),
    );

    this.form = this.fb.group<IPhysicalLocationsForm>({
      numberOfLocations: this.numberOfLocations,
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;

    try {
      await lastValueFrom(this.store.saveTransactionInfo());
      await this.offrampMerchant();
      return true;
    } catch (e) {
      this.errorMessage = formatError(e);
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  private async offrampMerchant() {
    const appAlerts = this.store.status.applicationAlerts;
    if (appAlerts.length > 0) {
      appAlerts.forEach(a => {
        if (a.id === 3) {
          //Just offramp due to high location number
          this.router.navigate([`/${ROUTES.OFFRAMP}`]);
        }
      });
    }
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public initialFocus() {
    document.querySelector('label')?.focus?.();
    this.cd.detectChanges();
  }

  public keyboardSelectCard(event: number, key: number) {
    if (key === KeyCodes.ENTER || key === KeyCodes.SPACE) {
      this.numberOfLocations.setValue(event);
    }
  }
}

export interface IPhysicalLocationsForm {
  numberOfLocations: FormControl<number>;
}