import { Injectable } from '@angular/core';
import { observable, makeObservable } from 'mobx';

@Injectable({ providedIn: 'root' })
export class InternalLead {
  firstName: string; // This should be first name

  lastName: string;

  email: string;

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      email: observable,
    });
  }
}
