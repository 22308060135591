/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LocationListRow } from '@eventhorizon/models/location-list.model';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { Cart, UpdateProductRequest } from '@eventhorizon/models/cart.model';
import { CartService } from '@eventhorizon/services/cart.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'lib-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
})
export class LocationsListComponent implements OnChanges {

  @Input()
  public locations: BusinessLocationModel[] = [];

  @Input()
  public locationCarts: Cart[] = null;

  @Input()
  public isLoading: boolean = true;

  @Input()
  public showLocationIcon: boolean = false;

  @Input()
  public showPrimaryIndicator: boolean = false;

  @Input()
  public showStatusLabel: boolean = false;

  @Input()
  public showMcc: boolean = false;

  @Input()
  public showEditButton: boolean = false;

  @Input()
  public showDeleteButton: boolean = false;

  @Input()
  public showCartHeader: boolean = false;

  @Input()
  public showCartPaymentColumn: boolean = false;

  @Input()
  public activeIndex: number = -1;

  @Output()
  public edit = new EventEmitter<number>();

  @Output()
  public delete = new EventEmitter<number>();

  @Output()
  public loading = new EventEmitter<boolean>();

  public rows: LocationListRow[] = [];

  constructor(public cartService: CartService, public cartStore: CartStore, public router: Router) {}

  public get totalProductCount(): number {
    return this.rows.reduce((sum, row) => sum += this.getProductCount(row), 0);
  }

  public get carts(): Cart[] {
    return this.rows.map(x => x.cart);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locations']?.currentValue) {
      this.initializeRows();
    }
  }

  async initializeRows() {
    this.onLoad(true);
    const newRows: LocationListRow[] = [];
    for (let i = 0; i < this.locations.length; i++) {
      let location = this.locations[i];
      try {
        let cart: Cart = null;
        if (this.locationCarts?.length) {
          cart = this.locationCarts.find(x => x.locationId === location.id);
        } else {
          cart = await lastValueFrom(this.cartService.getCartByLocationId(location.id));
        }
        let newRow = new LocationListRow(location, cart);
        newRow.locationProducts = this.cartStore.groupCartProducts(cart)?.locationProducts;
        newRow.isExpanded = this.activeIndex === i;
        newRows.push(newRow);
      } catch (error) {
        console.error(error);
      }
    }
    this.rows = newRows;
    this.onLoad(false);
  }

  getProductCount(row: LocationListRow) {
    return this.cartStore.getProductCount(row.cart);
  }

  onEdit(index: number) {
    this.onLoad(true);
    this.edit.emit(index);
  }

  onDelete(index: number) {
    this.onLoad(true);
    this.delete.emit(index);
  }

  onLoad(loading: boolean) {
    this.isLoading = loading;
    this.loading.emit(loading);
  }

  onCartUpdate(request: UpdateProductRequest) {
    this.loading.emit(true);
    let row = this.rows.find(x => x.id === request.businessLocationId);
    let cart = row.cart;
    lastValueFrom(this.cartStore.updateProducts([request], false, cart.id)).then(res => {
      row.cart = res;
      row.locationProducts = this.cartStore.groupCartProducts(row.cart)?.locationProducts;
      this.loading.emit(false);
    });
  }
}