import { Injectable } from '@angular/core';
import { ProductService } from '@eventhorizon/services/product.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Category } from '@eventhorizon/models/category.model';

@Injectable({
  providedIn: 'root',
})
export class IndustryController {
  public selectedCategory: Category;

  constructor(
    private store: ApplicationStore,
    private routingPathService: RoutingPathService,
    private productService: ProductService,
  ) {}

  public select(idCategory: number, categories: any[]) {
    const category = categories.find(categoryObject => categoryObject.id === idCategory);

    if (category && category.subCategories && category.subCategories.length) {
      this.routingPathService.activateRoute('industry-subcategory');
    } else {
      this.store.subCategory = null;
      this.routingPathService.deactivateRoute('industry-subcategory');
    }

    if (this.store.category && category && this.store.category.id !== +category.id) {
      this.store.mcc = undefined;
      this.store.industry = undefined;
    }

    if (idCategory !== -1) {
      if (category) {
        this.store.category = {
          ...category,
          id: +category.id,
        };
        this.selectedCategory = this.store.category;
      } else {
        this.store.category = {
          id: idCategory,
          name: 'unknown',
          displayName: 'unknown',
          description: 'unknown',
          rank: 0,
          imageUrl: 'unknown',
          fa: 'unknown',
          subCategories: [],
        };
      }
    }

    return true;
  }

  public getCategoryId(categoryId, categories: any[]) {
    let subcategoriesList = [];
    let responseCategoryId;
    categories.forEach(category => {
      if (category.subCategories) {
        const categoryWithParentList = category.subCategories.map(cat => ({ ...cat, ...{ parentId: category.id } }));
        subcategoriesList = [...subcategoriesList, ...categoryWithParentList];
      }
      if (category.id === categoryId) {
        responseCategoryId = categoryId;
      }
    });

    if (!responseCategoryId) {
      subcategoriesList.forEach(subcategory => {
        if (categoryId === subcategory.id) {
          responseCategoryId = subcategory.parentId;
          this.store.subCategory = subcategory;
        }
      });
    }
    return responseCategoryId;
  }

  public transformCategories(items: Category[]): Category[] {
    const categoriesWithSubcategories: Category[] = [];

    items.map(item => {
      if (item.subCategories.length) {
        const subcategories = item.subCategories;
        subcategories.map(subcategory => categoriesWithSubcategories.push(subcategory));
      } else {
        categoriesWithSubcategories.push(item);
      }
    });

    return categoriesWithSubcategories;
  }

  public async industrySubCategories(): Promise<Category[]> {
    if (!!this.selectedCategory) {
      return this.selectedCategory.subCategories;
    } else {
      const products = await this.productService.getCategories().toPromise();
      for (let index = 0; index < products.length; index++) {
        if (products[index].id === this.store.category.id) {
          this.selectedCategory = products[index];
          return this.selectedCategory.subCategories;
        } else {
          const subCategoryFound = products[index].subCategories.find(sub => sub.id === this.store.category.id);
          if (!!subCategoryFound) {
            this.selectedCategory = products[index];
            return this.selectedCategory.subCategories;
          }
        }
      }
    }
  }
}
