// This was pulled from /application/getBusinessTypes... and could
//  eventually be served up
export const rules = {
  percentageOwner: {
    'Sole Proprietorship': false,
    'Limited Liability Company (LLC)': true,
    'Tax Exempt': false,
    'Partnerships': true,
    'Private Corporation': true,
    'Government': false,
    'Public Corporation': true,
  },
  possibleBeneficialOwners: {
    'Sole Proprietorship': false,
    'Limited Liability Company (LLC)': true,
    'Tax Exempt': false,
    'Partnerships': true,
    'Private Corporation': true,
    'Government': false,
    'Public Corporation': false,
  },
  titles: {
    'Sole Proprietorship': [
      'Owner',
    ],
    'Limited Liability Company (LLC)': [
      'President',
      'Vice President',
      'Member LLC',
      'Secretary',
      'Treasurer',
      'CEO',
      'CFO',
      'COO',
    ],
    'Tax Exempt': [
      'President',
      'Vice President',
      'Member LLC',
      'Secretary',
      'Treasurer',
      'CEO',
      'CFO',
      'COO',
    ],
    'Partnerships': [
      'Partner',
    ],
    'Private Corporation': [
      'President',
      'Vice President',
      'Secretary',
      'Treasurer',
      'CEO',
      'CFO',
      'COO',
    ],
    'Government': [
      'President',
      'Vice President',
      'Member LLC',
      'Secretary',
      'Treasurer',
      'CEO',
      'CFO',
      'COO',
      'Owner',
      'Partner',
    ],
    'Public Corporation': [
      'President',
      'Vice President',
      'Secretary',
      'Treasurer',
      'CEO',
      'CFO',
      'COO',
    ],
  },
};
