<div class="row">
  <h3 class="physical-location pt-1 mb-3">Business Information</h3>
  <div class="col-md-12 mx-auto p-0">
    <form [formGroup]="form">
      <div class="row" [hidden]="hideBasedOnTinType()">
        <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
          <input
            [id]="id + 5"
            formControlName="legalName"
            class="form-control"
            type="text"
            autocomplete="on"
            [maskito]="masks.companyName"
          />
          <label class="form-label" [for]="id + 5"> Legal Business Name </label>

          <app-field-error [control]="form.controls.legalName"></app-field-error>
        </div>
        <div class="position-relative business-taxes-tin col-sm-12 col-md-6 mb-3 pb-0">
          <input
            [id]="id + 3"
            formControlName="tin"
            class="form-control"
            type="text"
            [maskito]="masks.taxIdAlt"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 3"> Tax Identification Number (TIN) </label>

          <app-field-error [control]="form.controls.tin"></app-field-error>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
          <span class="calendar-icon">
            <i class="fal fa-calendar"></i>
          </span>
          <input
            [id]="id + 8"
            formControlName="startDate"
            class="form-control calendar-input"
            type="text"
            autocomplete="on"
            bsDatepicker
            [minDate]="minDateOfIncorporation"
            [maxDate]="today"
            [maskito]="masks.date"
            #incorDate
            [bsConfig]="brandDatePicker"
            (keyup)="onDateFieldKeyChangePatchValue($event, form)"
          />
          <label class="form-label" [for]="id + 8"> Date of Incorporation </label>
          <app-field-error [control]="form.controls.startDate"></app-field-error>
        </div>

        <div class="col-sm-6 mb-3 pb-0 position-relative">
          <input
            [id]="id + 9"
            formControlName="businessPhone"
            class="form-control"
            type="text"
            autocomplete="tel"
            [maskito]="masks.phone"
          />
          <label class="form-label" [for]="id + 9"> Phone </label>

          <app-field-error [control]="form.controls.businessPhone"></app-field-error>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 mb-3 p-0 position-relative">
          <input [id]="id + 11" formControlName="businessEmail" class="form-control" type="text" autocomplete="email" />
          <label class="form-label" [for]="id + 11"> Email </label>

          <app-field-error [control]="form.controls.businessEmail"></app-field-error>
        </div>

        <div class="col-sm-6 mb-3 pb-0 position-relative">
          <input [id]="id + 10" formControlName="website" class="form-control" type="text" autocomplete="url" />
          <label class="form-label" [for]="id + 10"> Website </label>

          <app-field-error [control]="form.controls.website"></app-field-error>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="row pb-2">
          <h3 class="physical-location pt-1 mb-2">Legal Business Address</h3>
        </div>
        <div class="row">
          <div class="col-12 p-0">
            <app-tg-address
              #legalAddress
              formGroupName="legalAddress"
              [(address)]="store.businessAddresses.legalAddress"
            ></app-tg-address>
          </div>
        </div>
      </div>

      <!--Sales information-->
      <app-tg-payment-info #paymentInfo #regForm></app-tg-payment-info>

      <div class="row" *ngIf="bankInfoConfig?.businessInfoDisclosures?.text" id="bank-info-disclosures">
        <div class="col-sm-12">
          <div [ngClass]="['info-message', bankInfoConfig?.bankName]">
            <i [ngClass]="['info-message-icon', bankInfoConfig?.businessInfoDisclosures?.icon]" alt="Information Icon">
            </i>
            <p class="info-message-text" [innerHTML]="bankInfoConfig?.businessInfoDisclosures?.text"></p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          *ngIf="errorMessage && errorMessage.length > 0"
          class="col-md-6 form-error-message error-message"
          id="error-messages"
        >
          Failed to save application
          <ul>
            <li *ngFor="let error of errorMessage">{{ error }}</li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>
