<div class="application-slide row">
  <ng-container *ngIf="showCart">
    <div class="cart-wrapper text-end">
      <app-tg-cart-icon></app-tg-cart-icon>
    </div>
  </ng-container>

  <div *ngIf="showProgressWizard" class="col-12 col-lg-4">
    <div class="app-slide-progress" [ngClass]="{ 'progress-cart-margin': showCart }">
      <app-vertical-progress-wizard #progressCtrl></app-vertical-progress-wizard>
      <i
        *ngIf="showSaveAndContinue && isNotPrefilled()"
        class="fas fa-save app-slide-save-icon d-none d-lg-inline-block me-2"
      ></i>
      <a
        *ngIf="showSaveAndContinue && isNotPrefilled()"
        class="navigation-link app-slide-secondary-action d-none d-lg-inline"
        (click)="onSecondaryAction()"
        tabindex="0"
        >Save & Continue Later
      </a>
    </div>
  </div>

  <div
    class="col-12 col-lg-8 px-0 mx-auto"
    id="appSlide"
    tabindex="0"
    [ngClass]="{ 'app-container': !showProgressWizard }"
  >
    <div class="app-slide-flex-container">
      <div class="row app-slide-flex-container-row">
        <div *ngIf="hasHeadline()" class="app-slide-heading-container">
          <h1 class="app-slide-subheading">
            {{ smallHeadline }}
            <app-tg-tooltip *ngIf="tooltipTitleText !== ''" [tooltipText]="tooltipTitleText"></app-tg-tooltip>
          </h1>
          <div *ngIf="text.subText" class="app-slide-heading">{{ text.subText }}</div>
          <div *ngIf="text.noteText" class="app-slide-heading app-slide-heading-note">{{ text.noteText }}</div>
          <div *ngIf="orderInfo" class="app-slide-heading app-slide-order-number">Order #{{ orderId() }}</div>
        </div>
        <div class="app-slide-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-offset-6 text-danger fs-6 text-center" *ngIf="network.isOffline$ | async">
        <p>You are currently offline, please check your device's network status.</p>
      </div>
    </div>

    <div class="row d-none d-md-flex justify-content-between align-items-top app-slide-button-container">
      <div class="col-md-6 p-0">
        <div
          [tooltip]="getBackButtonTooltipText()"
          [tooltipEnable]="hasBackTootltip()"
          triggers="mouseenter:mouseleave"
        >
          <button
            *ngIf="hasBackButton()"
            class="border-button app-slide-button app-slide-button--back"
            [disabled]="isBackDisabled()"
            (click)="onPrev()"
          >
            {{ text.backText }}
          </button>
        </div>
      </div>

      <div class="col col-md-6 text-end app-slide-next-actions">
        <div class="lg-btn-container text-center">
          <button
            *ngIf="(disableNextLoading || !loading) && hasNextButton()"
            type="button"
            class="primary-button app-slide-button app-slide-button--next"
            [class.app-slide-button--next-disabled]="isNextDisabled()"
            (click)="onNext()"
          >
            {{ continueText ? continueText : text.nextText }}
          </button>
          <div *ngIf="!disableNextLoading && loading" class="lds-dual-ring"></div>
          <a *ngIf="showSkipSearch" class="navigation-link app-slide-secondary-action" (click)="onSecondaryAction()">
            Skip Search
          </a>
        </div>
      </div>
    </div>
    <div class="row d-flex d-md-none justify-content-between align-items-top app-slide-button-container">
      <div class="col-12 mb-3 app-slide-next-actions text-center">
        <button
          *ngIf="(disableNextLoading || !loading) && hasNextButton()"
          type="button"
          class="primary-button app-slide-button app-slide-button--next"
          [disabled]="isNextDisabled()"
          (click)="onNext()"
        >
          {{ text.nextText }}
        </button>
        <div *ngIf="!disableNextLoading && loading" class="lds-dual-ring"></div>

        <a *ngIf="showSkipSearch" class="navigation-link app-slide-secondary-action" (click)="onSecondaryAction()">
          Skip Search
        </a>
      </div>
      <div class="col-12 mb-3 text-center" *ngIf="hasBackButton()">
        <div
          [tooltip]="getBackButtonTooltipText()"
          [tooltipEnable]="hasBackTootltip()"
          triggers="mouseenter:mouseleave"
        >
          <button
            *ngIf="hasBackButton()"
            class="border-button app-slide-button app-slide-button--back"
            [disabled]="isBackDisabled()"
            (click)="onPrev()"
          >
            {{ text.backText }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 text-center d-block d-lg-none mt-2" *ngIf="!loading && showSaveAndContinue && isNotPrefilled()">
      <i class="fas fa-save app-slide-save-icon p-0"></i>&nbsp;
      <a class="navigation-link app-slide-secondary-action" (click)="onSecondaryAction()" tabindex="0">
        Save & Continue Later
      </a>
    </div>
    <div class="row d-md-flex justify-content-between align-items-top" *ngIf="hasSubmitError">
      <div class="col-sm-12">
        <div class="col col-sm-12">
          <p class="app-slide-submit-error">{{ text?.submitError }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasFootNote()" class="row app-slide-footer-disclosure">
    <div class="col-sm-12">
      <p [innerHTML]="text.footNote"></p>
    </div>
  </div>
</div>
