import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { formatPhone, randomComponentId, touchAndValidate } from '@xup-payments/xup-frontend-utils/utils';
import { ApplicantInfo, masks, messages } from '@xup-payments/xup-frontend-utils/models';
import { commonErrors } from '@xup-payments/xup-frontend-utils/constants';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@eventhorizon/services/auth.service';
import { InvalidApplicationService } from '@eventhorizon/services/invalid-application.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent extends CarouselSlide implements OnInit {
  public masks = masks;

  public messages = messages.contactInfoSlide;

  public id = randomComponentId();

  public name: MobxFormControl;

  public lastName: MobxFormControl;

  public phoneNumber: MobxFormControl;

  public email: MobxFormControl;

  public legalName: MobxFormControl;

  public existingClientCustomer: MobxFormControl;

  public isKeyController: MobxFormControl;

  applicant: ApplicantInfo;

  public errorMessage: string;

  public bankInfoConfig: Record<string, object>;

  public displayExistingClientCustomer: boolean = true;

  public enableLeadFormCaptcha: boolean = true;

  constructor(
    protected cd: ChangeDetectorRef,
    public store: ApplicationStore,
    private fb: UntypedFormBuilder,
    public contentService: ContentServiceService,
    public bsModalService: BsModalService,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private invalidApplication: InvalidApplicationService,
  ) {
    super(cd);
    this.bankInfoConfig = this.contentService?.bankInfoConfig || null;
    this.displayExistingClientCustomer = !!this.bankInfoConfig?.isKeybank;
    this.activatedRoute?.data.subscribe(
      (
        {
          /* partnerInfo */
        },
      ) => {
        this.enableLeadFormCaptcha = false;
        // if (this.isReCaptchaValidated) {
        //   this.enableLeadFormCaptcha = false;
        // } else if (partnerInfo) {
        //   this.enableLeadFormCaptcha = partnerInfo.enableLeadFormCaptcha;
        // }
      },
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  public get isPrefill() {
    return this.store.prefilled;
  }

  public get isReCaptchaValidated() {
    return true;
    // const { name, lastName, phoneNumber, email, companyName } = this.store.applicantInfo;
    // const token = sessionStorage.getItem('ci_recaptcha');
    // return !!name && !!lastName && !!phoneNumber && !!email && !!companyName && !!token;
  }

  public validate() {
    touchAndValidate(this.form);
    this.cd.detectChanges();
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public isDirty(): boolean {
    return this.form.dirty;
  }

  public isTouched(): boolean {
    return this.form.touched;
  }

  private buildForm() {
    this.name = new MobxFormControl(
      'name',
      () => this.store.applicantInfo.name,
      v => (this.store.applicantInfo.name = v),
      CommonValidator.personalName(),
    );
    this.lastName = new MobxFormControl(
      'lastName',
      () => this.store.applicantInfo.lastName,
      v => (this.store.applicantInfo.lastName = v),
      CommonValidator.personalName(),
    );
    this.phoneNumber = new MobxFormControl(
      'phoneNumber',
      () => formatPhone(this.store.applicantInfo.phoneNumber),
      v => (this.store.applicantInfo.phoneNumber = formatPhone(v)),
      CommonValidator.phone(),
    );
    this.email = new MobxFormControl(
      'email',
      () => this.store.applicantInfo.email,
      v => (this.store.applicantInfo.email = v),
      CommonValidator.email(),
    );
    this.legalName = new MobxFormControl(
      'legalName',
      () => this.store.businessInfo.legalName,
      v => (this.store.businessInfo.legalName = v),
      Validators.required,
    );
    this.existingClientCustomer = new MobxFormControl(
      'existingClientCustomer',
      () => this.store.applicantInfo.existingClientCustomer,
      v => (this.store.applicantInfo.existingClientCustomer = v),
      Validators.required,
      true,
    );
    this.isKeyController = new MobxFormControl(
      'isKeyController',
      () => this.store.applicantInfo.isKeyController,
      v => {
        this.store.applicantInfo.isKeyController = v;
      },
      Validators.required,
      true,
    );

    this.form = this.fb.group({
      name: this.name,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      legalName: this.legalName,
      isKeyController: this.isKeyController,
    });

    if (this.displayExistingClientCustomer) {
      this.form.addControl('existingClientCustomer', this.existingClientCustomer);
    }

    if (this.isPrefill && this.displayExistingClientCustomer) {
      this.form.controls.existingClientCustomer.setValue(true);
    }

    if (this.enableLeadFormCaptcha) {
      this.form.addControl('leadFormCaptcha', new UntypedFormControl(null, Validators.required));
    }

    this.invalidApplication.setForm(this.form);
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    try {
      await lastValueFrom(this.store.saveContactInfo());
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public async onCaptchaValidated(reCaptchaToken: string) {
    if (!reCaptchaToken) {
      return;
    }

    try {
      await lastValueFrom(this.authService.authenticateCaptchaWithGuestToken(reCaptchaToken));
      sessionStorage.setItem('ci_recaptcha', 'true');
    } catch {
      this.form.controls.leadFormCaptcha.reset();
      this.errorMessage = commonErrors.errorEncountered;
    }
  }
}
