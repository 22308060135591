/* eslint-disable @typescript-eslint/dot-notation */
import { CartService } from '@eventhorizon/services/cart.service';
import { action, computed, observable } from 'mobx-angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { configure, makeObservable } from 'mobx';
import {
  AcquiringPricing,
  Cart,
  CartError,
  CartResponse,
  LocationProducts,
  OrderDetailsInterface,
  Product,
  UpdateProductRequest,
} from '@xup-payments/xup-frontend-utils/models';
import {
  cartEventsTypes,
  mandatoryProductsIds,
  phonePaymentIds,
  productTypes,
  purchaseType,
  ROUTES,
} from '@xup-payments/xup-frontend-utils/constants';
import { formatError } from '@xup-payments/xup-frontend-utils/utils';
import { catchError, map, tap } from 'rxjs/operators';
import { CartPopupConstants } from '@eventhorizon/components/cart/cart-popup/cart-popup.constants';

@Injectable({ providedIn: 'root' })
export class CartStore implements OrderDetailsInterface {
  cart: Cart;

  acquiringPricing: AcquiringPricing;

  public isLoading = false;

  private _isLoaded$ = new BehaviorSubject<boolean>(!this.isLoading);

  isLoaded$ = this._isLoaded$.asObservable();

  public isGroupingProducts = false;

  canCheckout = false;

  hasProducts = false;

  private cartLoaded = false;

  public locationProducts: LocationProducts[] = [];

  public transactionProducts: Product[];

  hasSoftwareError: boolean;

  hasPosError: boolean;

  hasAdditionalErrors: boolean;

  hasIncompatibleMccError: boolean;

  incompatibleMccErrorMessage: string;

  posErrorMessage: string;

  hasPhonePayment = false;

  totalPurchaseProducts = 0;

  purchaseProductTotalAmount = 0;

  recurringProductTotalAmount = 0;

  // TODO: get this from the carts transaction details when the API returns it
  transactionRateDisplay = '2.5% + $0.10';

  orderId = '';

  addProductsErrors = '';

  taxAmount = 0;

  totalAmount = 0;

  unknownErrorMessages: any = {};

  public hasSoftwareProducts = new Map();

  constructor(
    private cartService: CartService,
    private routingService: RoutingPathService,
    private locationService: LocationsService,
    private bsModalService: BsModalService,
  ) {
    configure({
      enforceActions: 'never',
    });
    makeObservable(this, {
      cart: observable,
      acquiringPricing: observable,
      isLoading: observable,
      isGroupingProducts: observable,
      destroyCart: action,
      loadCart: action,
      updateProducts: action,
      updateSoftwareProducts: action,
      setCart: action,
      softwareErrorType: computed,
      additionalErrorType: computed,
    });
    this.listenToLocationChange();
  }

  get softwareErrorType(): string {
    if (!this.cart || !this.cart.errors) return '';

    for (const error of this.cart.errors) {
      const errorType = error.errortype.toLowerCase();
      if (errorType.indexOf('software') >= 0) {
        return errorType;
      }
    }

    return '';
  }

  public softwareErrorTypeByLocation(locationId: string): string {
    if (!this.cart || !this.cart.errors) return '';

    for (const error of this.cart.errors) {
      const errorType = error.errortype.toLowerCase();
      if (error.businessLocationId === locationId && errorType.indexOf('software') >= 0) {
        return errorType;
      }
    }

    return '';
  }

  get additionalErrorType(): string {
    for (const error of this.cart.errors) {
      const errorType = error.errortype.toLowerCase();
      if (errorType.indexOf('software') < 0 && errorType.indexOf('acquiring') < 0 && errorType.indexOf('tiers') < 0) {
        return errorType;
      }
    }
    return '';
  }

  public destroyCart() {
    this.cartLoaded = false;
    this.cart = undefined;
  }

  public loadCart(force = false): Observable<boolean | any> {
    if (this.cartLoaded && !force) {
      return of(false);
    }

    return this.cartService.getCartByLocationId(this.locationService.currentLocationId).pipe(
      catchError(error => {
        if (error.status !== 404) {
          return throwError(error);
        }
        return this.cartService.newCart();
      }),
      map((response: CartResponse) => {
        this.setCart(response);
        return true;
      }),
    );
  }

  private emitIsLoaded() {
    this._isLoaded$.next(!this.isLoading);
  }

  public updateProducts(products: UpdateProductRequest[], showCart = false): Observable<CartResponse> {
    this.isLoading = true;
    this.emitIsLoaded();
    return this.cartService.updateProducts(this.cart.id, products).pipe(
      tap((response: CartResponse) => {
        this.setCart(response);
        if (showCart) {
          import('@eventhorizon/components/cart/cart-popup/cart-popup.component').then(module => {
            // Angular library doesn't allow circular imports on build, we have to import this on real time to avoid that
            this.bsModalService.show(module.CartPopupComponent, {
              class: 'cart-modal modal-lg side-cart',
              animated: true,
              ariaLabelledBy: CartPopupConstants.ariaLabelledBy,
            });
          });
        }
      }),
      catchError(error => {
        this.isLoading = false;
        this.emitIsLoaded();
        this.canCheckout = false;
        this.addProductsErrors = formatError(error);
        setTimeout(() => {
          this.addProductsErrors = null;
        }, 30000); // Error will disappear after 30 seconds
        return throwError(() => error);
      }),
    );
  }

  public async updateSoftwareProducts(locationId, softwareToAdd: Product, showCart = true) {
    if (!locationId) {
      console.error('Unable to add product to cart');
      return;
    }

    const products: UpdateProductRequest[] = [
      {
        id: softwareToAdd.productId,
        purchaseType: softwareToAdd.defaultPurchaseType || 'P',
        quantity: 1,
        businessLocationId: locationId,
      },
    ];

    const prevSoftwareProductInCart = this.currentSoftwareSelection();
    if (prevSoftwareProductInCart) {
      products.push({
        id: prevSoftwareProductInCart.productId,
        purchaseType: prevSoftwareProductInCart.purchaseType || 'P',
        quantity: 0,
        businessLocationId: locationId,
      });
    }
    await lastValueFrom(this.updateProducts(products, showCart));
  }

  public currentSoftwareSelection(): Product {
    if (!this.cart || !this.cart.products) return;

    return this.cart.products.find(
      prod =>
        (prod.productType === productTypes.SOFTWARE || prod.productType === productTypes.SOLUTION_FEE) &&
        !mandatoryProductsIds.includes(prod.productId),
    );
  }

  public currentSoftwareSelectionOfferingType(): string {
    const currentSoftwareSelection = this.currentSoftwareSelection();

    return !!currentSoftwareSelection ? currentSoftwareSelection['offeringTypes'][0] : '';
  }

  public currentSoftwareSelectionByLocation(businessLocationId: string): Product {
    if (!this.cart || !this.cart.products) return;

    return this.cart.products.find(
      (prod: Product) =>
        (prod.productType === productTypes.SOFTWARE || prod.productType === productTypes.SOLUTION_FEE) &&
        !mandatoryProductsIds.includes(prod.productId) &&
        prod.businessLocationId === businessLocationId,
    );
  }

  public getProducts(): Product[] {
    return (this.cart && this.cart.products) || [];
  }

  public setCart(cart: CartResponse) {
    this.cart = cart;
    this.setErrors();
    if (cart.orderId) {
      this.orderId = cart.orderId;
    } 
    this.acquiringPricing = cart.acquiringPricing;
    this.groupProducts();
    this.setCanCheckout();
    this.isLoading = false;
    this.emitIsLoaded();
    this.cartLoaded = true;
  }

  public cartCheckout(applicationId: string): Observable<CartResponse> {
    return this.cartService.postCartCheckout(applicationId).pipe(
      tap((resp: CartResponse) => {
        this.orderId = resp.orderId;
      }),
    );
  }

  private groupProducts() {
    this.isGroupingProducts = true;
    this.locationProducts = [];
    this.totalPurchaseProducts = 0;
    this.purchaseProductTotalAmount = 0;
    this.recurringProductTotalAmount = 0;
    this.hasProducts = false;
    this.hasPhonePayment = false;
    this.transactionProducts = [];
    this.orderId = '';

    if (this.cart) {
      this.taxAmount = this.cart.taxAmount;
    } else {
      this.taxAmount = 0;
    }
    if (!this.cart || !this.cart.products) return;
    this.orderId = this.cart.orderId;
    this.totalAmount = this.cart.totalAmount;

    const result = this.groupCartProducts(this.cart);

    this.locationProducts = result.locationProducts;
    this.transactionProducts = result.transactionProducts;
    this.totalPurchaseProducts = result.totalPurchaseProducts;
    this.purchaseProductTotalAmount = result.purchaseProductTotalAmount;
    this.recurringProductTotalAmount = result.recurringProductTotalAmount;
    this.hasProducts = result.hasProducts;
    this.hasPhonePayment = result.hasPhonePayment;
    this.hasSoftwareProducts = result.hasSoftwareProducts;

    this.isGroupingProducts = false;
  }

  private groupWhenLocationHasId(cart: Cart, result: GroupCartProductsResult) {
    const locationsProductsTempObject: { [key: string]: LocationProducts } = {};
    const businessLocationId = cart.locationId;

    cart.products.forEach((p: Product) => {
      if (businessLocationId && typeof locationsProductsTempObject[businessLocationId] === 'undefined') {
        locationsProductsTempObject[businessLocationId] = {
          purchaseProducts: [],
          recurringProducts: [],
        };
      }

      if (phonePaymentIds.includes(p.productId)) {
        result.hasPhonePayment = true;
      }
      if (p.productType === productTypes.SOLUTION_FEE) {
        locationsProductsTempObject[businessLocationId].recurringProducts.push(p);
        result.totalPurchaseProducts += p.quantity;
        result.recurringProductTotalAmount += p.price * p.quantity;
      } else if (p.productType === productTypes.ACQUIRING || p.productType === productTypes.NET_FEE) {
        result.transactionProducts.push(p);
      } else if (p.purchaseType === purchaseType.PURCHASE) {
        locationsProductsTempObject[businessLocationId].purchaseProducts.push(p);
        result.totalPurchaseProducts += p.quantity;
        result.purchaseProductTotalAmount += p.price * p.quantity;
      } else {
        locationsProductsTempObject[businessLocationId].recurringProducts.push(p);
        result.totalPurchaseProducts += p.quantity;
        result.recurringProductTotalAmount += p.price * p.quantity;
      }
      if (p.productType !== productTypes.ACQUIRING) {
        result.hasProducts = true;
      }
      if (p.productType === productTypes.SOFTWARE) {
        result.hasSoftwareProducts.set(businessLocationId, true);
      }
    });
    result.locationProducts = Object.values(locationsProductsTempObject);
  }

  public groupCartProducts(cart: Cart): GroupCartProductsResult {
    const result: GroupCartProductsResult = {
      locationProducts: [],
      transactionProducts: [],
      totalPurchaseProducts: 0,
      purchaseProductTotalAmount: 0,
      recurringProductTotalAmount: 0,
      hasProducts: false,
      hasSoftwareProducts: new Map(),
      hasPhonePayment: false,
    };
    if (!cart.locationId) {
      // order-status cart doesn't include multi locations and doesn't have an id
      cart.locationId = 'single-location-cart';
    }
    this.groupWhenLocationHasId(cart, result);
    return result;
  }

  private setErrors() {
    this.hasSoftwareError = false;
    this.hasPosError = false;
    this.hasIncompatibleMccError = false;
    this.hasAdditionalErrors = false;
    this.unknownErrorMessages = {};

    if (!this.cart || !this.cart.errors || !this.cart.errors.length) {
      this.handleErrorPages();
      return;
    }

    for (const error of this.cart.errors) {
      const errorType = error.errortype.toLowerCase();
      if (errorType.indexOf('software') >= 0) {
        this.hasSoftwareError = true;
      } else if (errorType.indexOf('terminal') >= 0) {
        this.hasPosError = true;
        this.posErrorMessage = 'Please select a gateway to continue your application.';
      } else if (errorType.indexOf('clover-incompatible-mcc') >= 0) {
        this.hasIncompatibleMccError = true;
        this.incompatibleMccErrorMessage = error.errormessage || '';
      } else if (errorType && !['acquiring', 'tiers'].find(e => errorType.indexOf(e) >= 0)) {
        this.hasAdditionalErrors = true;
      } else {
        error.show = true;
      }
    }
    this.getUnknownErrorMessages();
    this.handleErrorPages();
  }

  public getSoftwareProductsPerLocation(locationId: string): string {
    if (this.cart && this.cart.errors) {
      for (const error of this.cart.errors) {
        const errorType = error.errortype.toLowerCase();
        if (errorType.indexOf('software') >= 0 && error.businessLocationId === locationId) {
          return errorType;
        }
      }
      return '';
    }
    return '';
  }

  private handleErrorPages() {
    if (this.hasSoftwareError) this.routingService.activateRoute(ROUTES.SOFTWARE);

    if (this.hasAdditionalErrors) this.routingService.activateRoute(ROUTES.ADDITIONAL_PRODUCTS);
    else this.routingService.deactivateRoute(ROUTES.ADDITIONAL_PRODUCTS);
  }

  private filterByErrorType(error: CartError) {
    const errorType = error.errortype.toLowerCase();
    return (
      errorType.indexOf('software') < 0 &&
      errorType.indexOf('terminal') < 0 &&
      errorType.indexOf('acquiring') < 0 &&
      errorType.indexOf('tiers') < 0
    );
  }

  private getUnknownErrorMessages() {
    this.unknownErrorMessages.generic = [];
    if (this.cart && this.cart.errors.length > 0) {
      const allUnknownErrors = this.cart.errors.filter(this.filterByErrorType);
      this.unknownErrorMessages.generic = allUnknownErrors
        .filter(error => !error.businessLocationId)
        .map(error => error.errormessage);
      const unknownErrorsLocationsSet = new Set(allUnknownErrors.map(error => error.businessLocationId));
      unknownErrorsLocationsSet.forEach(value => {
        this.unknownErrorMessages[value] = allUnknownErrors
          .filter(error => error.businessLocationId === value)
          .map(error => error.errormessage);
      });
    }
  }

  private setCanCheckout() {
    this.canCheckout = this.cart && this.cart.errors && !this.cart.errors.length && this.cart.products.length > 0;
  }

  public getCartQty(productId: string): number {
    return this.cart.products
      .filter(p => p.productId === productId)
      .reduce((sum, current) => sum + current.quantity, 0);
  }

  public async getApplicationAllCarts(): Promise<Cart[]> {
    try {
      const allLocationsId: string[] = this.locationService.getLocations().map(location => location.id);
      const cartResponses: CartResponse[] = await this.cartService.getAllCarts(allLocationsId);
      return cartResponses.map(response => <Cart>response);
    } catch (error) {
      console.log(error);
    }
  }

  async listenToLocationChange() {
    if (this.locationService.locationChanged) {
      this.locationService.locationChanged.subscribe(async () => {
        this.destroyCart();
        this.isLoading = true;
        this.emitIsLoaded();
        if (this.locationService.currentLocationId) {
          await lastValueFrom(this.loadCart(true));
        }
        this.isLoading = false;
        this.emitIsLoaded();
      });
    }
  }

  public isCurrentProductDisabledToBeAdded(currentProductId: string): boolean {
    const blockedProductEvent = this.cart.events
      ? this.cart.events.find(event => event.affectedProductId === currentProductId)
      : undefined;
    return blockedProductEvent && blockedProductEvent.eventType === cartEventsTypes.DISABLED_PRODUCT;
  }
}

export interface GroupCartProductsResult {
  locationProducts: LocationProducts[];
  transactionProducts: Product[];
  totalPurchaseProducts: number;
  purchaseProductTotalAmount: number;
  recurringProductTotalAmount: number;
  hasProducts: boolean;
  hasSoftwareProducts: Map<string, boolean>;
  hasPhonePayment: boolean;
}
