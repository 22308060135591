import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AcquiringTypes,
  Category,
  MCCSearchResult,
  MerchantCategoryCodes,
  Product,
  ProductFull,
  ProductTypeGroup,
} from '@xup-payments/xup-frontend-utils/models';
import { productTypes } from '@xup-payments/xup-frontend-utils/constants';

@Injectable({ providedIn: 'root' })
export class ProductService {
  protected BASE_URL;

  '/api/products';

  constructor(private http: HttpClient,  @Inject('env') private environment) {
    this.BASE_URL = environment.onboardingApi
      ? `${environment.onboardingApi}/products`
      : `${environment.api.url}/products`;
  }

  public getProducts(
    applicationId: string,
    name?: string,
    productTypeIds?: string[],
    categoryIds?: string[],
  ): Observable<Product[]> {
    let url = `${this.BASE_URL}/?`;

    if (applicationId) {
      url += `&applicationId=${applicationId}`;
    }
    if (name) {
      url += `&productName=${name}`;
    }
    if (productTypeIds && productTypeIds.length) {
      url += `&productTypeGroupIds=${productTypeIds}`;
    }
    if (categoryIds && categoryIds.length) {
      url += `&categories=${categoryIds}`;
    }

    return this.http.get<Product[]>(url);
  }

  public getProductsByOfferingType(offeringType: string): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.BASE_URL}/?offeringType=${offeringType}`);
  }

  public getSoftwareProducts(
    offeringType: string,
    applicationId: string,
    businessLocationId?: string,
  ): Observable<Product[]> {
    const params = new HttpParams()
      .set('offeringType', offeringType)
      .set('applicationId', String(applicationId))
      .set('businessLocationId', businessLocationId);

    return this.http.get<Product[]>(`${this.BASE_URL}/software`, { params });
  }

  public getProductTypeGroups(): Observable<ProductTypeGroup[]> {
    return this.http.get<ProductTypeGroup[]>(`${this.BASE_URL}/producttypegroups`);
  }

  public getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.BASE_URL}/categories`);
  }

  public getProductDetail(productId: string, applicationId: string) {
    let url = `${this.BASE_URL}/${productId}/full`;
    if (applicationId) {
      url += `?applicationId=${applicationId}`;
    }
    return this.http.get<ProductFull>(url);
  }

  public getIndustries(categoryName: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.BASE_URL}/categories/${categoryName}/industries`);
  }

  public getMCC(searchTerm: string): Observable<MCCSearchResult[]> {
    return this.http.get<MCCSearchResult[]>(`${this.BASE_URL}/mcc?search=${searchTerm}`);
  }

  public getMerchantCategoryCodes(
    categoryName: string,
    industryDescription: string,
  ): Observable<MerchantCategoryCodes[]> {
    return this.http.get<MerchantCategoryCodes[]>(
      `${this.BASE_URL}/categories/${categoryName}/industry/${industryDescription}/merchantcategorycodes`,
    );
  }

  public getRecomendedProducts(
    businessSize: string,
    categoryId: number,
    applicationId: string,
  ): Observable<ProductFull[]> {
    const params = new HttpParams()
      .set('businessSize', businessSize)
      .set('categoryId', String(categoryId))
      .set('applicationId', String(applicationId));

    return this.http.get<ProductFull[]>(`${this.BASE_URL}/recommendedproducts`, { params });
  }

  isSoftwareProduct(product: Product) {
    return product?.productType === productTypes.SOLUTION_FEE || product?.productType === productTypes.SOFTWARE;
  }

  /**
   * When a partnerId or payfacId is provided, this gets all of the available acquiring types for the entity.
   * When neither a partnerId or a payfacId is provided, it returns the default acquiring types available for
   * the user to choose from.
   *
   * This should not be used to get selected acquiring type for a specific application. To get that, look in the cart.
   *
   * @param partnerId
   * @param payfacId
   */
  getAcquiringTypes(partnerId?: string, payfacId?: string): Observable<AcquiringTypes[]> {
    let params = new HttpParams();
    if (partnerId) {
      params = params.set('objectId', partnerId).set('objectType', 'partner');
    } else if (payfacId) {
      params = params.set('objectId', payfacId).set('objectType', 'payfac');
    } else {
      params = undefined;
    }
    return this.http.get<AcquiringTypes[]>(`${this.BASE_URL}/acquiring-types`, { params });
  }

  /**
   * When a partnerId or payfacId is provided, this gets the rates and fees that are configured for those
   * entities. When neither a partnerId or a payfacId is provided, it returns the default rates and fees.
   *
   * This should not be used to get the rates and fees for a specific application. To get that, look in the cart.
   *
   * @param partnerId
   * @param payfacId
   * @param sortBy
   */
  getRatesFees(partnerId: string, payfacId: string, sortBy: string, productGroupId?: string) {
    let params = new HttpParams();

    if (partnerId) {
      params = params.set('objectId', partnerId).set('objectType', 'partner');
    } else if (payfacId) {
      params = params.set('objectId', payfacId).set('objectType', 'payfac');
    }

    if (sortBy) params = params.set('sortBy', sortBy);

    if (productGroupId) params = params.set('productGroupId', productGroupId);

    return this.http.get<AcquiringTypes[]>(`${this.BASE_URL}/rates-fees`, { params });
  }
}
