<div class="modal-header justify-content-end p-0">
  <button type="button" class="btn pull-right" aria-label="Close" (click)="close(false)">
    <i class="far fa-xmark-large"></i>
  </button>
</div>
<div class="modal-body pt-0">
  <div class="text-center">
    <div class="row justify-content-center">
      <h3 id="modal-title" class="fw-bold text-dark">{{ title }}</h3>
    </div>
    <div class="fw-bold m-2 price-color">${{ price }}/mo</div>
    <small *ngIf="priceDescription" class="price-description">
      <p>&#43; {{ priceDescription }}/mo for each additional device</p>
    </small>
    <p class="m-4">{{ description }}</p>
    <div class="row justify-content-center">
      <button class="btn primary-button software-action w-50 p-0" (click)="close(true)">Add to Cart</button>
    </div>
  </div>
</div>
