import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Directive, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { filter, tap } from 'rxjs/operators';
import { AppStatus } from '@eventhorizon/constants/appStatus.constants';
import { AppStatusStr } from '@eventhorizon/models/application-status.model';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { CartResponse } from '@eventhorizon/models/cart.model';
import { PaginatedResult } from '@eventhorizon/models/paginated-result';
import { Product } from '@eventhorizon/models/product.model';
import { Statement } from '@eventhorizon/models/statement';
import { lastValueFrom } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  public currentLocation: BusinessLocationModel = null;

  // To save the current location index of the business locations array.
  public currentLocationIndex = 0;

  // To save the current location ID, when routing.
  public currentLocationId = '';

  // To save the business locations IDs according to their location number.
  public locationIdMapping = new Map();

  readonly BASE_URL;

  private currentSlug = '';

  public previousLocationId = '';

  @Output() locationChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private httpClient: HttpClient,
    public store: ApplicationStore,
    private route: ActivatedRoute,
    private router: Router,
    @Inject('env') private environment,
  ) {
    this.BASE_URL = `${environment.api.url}/merchants`;
  }

  public initLocationsService(): void {
    this.setCurrentLocationValues();
    this.listenToRouting();
  }

  public getLocations(): BusinessLocationModel[] {
    return this.store.businessLocations;
  }

  public async getProducts(locationId: string): Promise<Product[]> {
    const resp = await lastValueFrom(this.httpClient.get<CartResponse>('/assets/mock-cart.json'));
    return resp.products.filter(
      product =>
        product.businessLocationId === locationId &&
        product.productType !== 'ACQUIRING' &&
        product.productType !== 'NET_FEE',
    );
  }

  public async getStatements(merchantId: string, params): Promise<PaginatedResult<Statement>> {
    return lastValueFrom(
      this.httpClient.get<PaginatedResult<Statement>>(`/api/merchantstatements/${merchantId}/statements`, {
        params,
      }),
    );
  }

  public async downloadStatement(statementId: string): Promise<Blob> {
    return lastValueFrom(
      this.httpClient
        .get<Blob>(`/api/merchantstatements/${statementId}/pdf`, {
        responseType: 'blob' as 'json',
      })
        .pipe(
          tap((file: Blob) => {
            const link = document.createElement('a');
            link.setAttribute('style', 'display: none');
            link.href = URL.createObjectURL(file);
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
          }),
        ),
    );
  }

  /**
   * To track the current location ID.
   */
  private listenToRouting(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const slug = event.url.includes('onboarding/location/') ? event.url.split('/').pop() : null;
      this.setCurrentLocationValues(slug);
    });
  }

  /**
   * Set current location id,
   * current location and current location index values.
   *
   * @param slug
   */
  private setCurrentLocationValues(slug?: string): void {
    const primaryLocationIndex = this.getPrimaryLocationIndex();

    let locationIndex: number;

    // slug parameter should take precedence
    if (slug) {
      this.currentSlug = slug;
    } else if (!slug) {
      const urlSegments = this.router.url.split('/');
      const guidOrIndex = urlSegments.pop();
      const segment = urlSegments.pop();
      const multiLocationIndex = guidOrIndex.substr(guidOrIndex.length - 1);

      if (segment.toLocaleLowerCase().includes('location')) {
        // If guidOrIndex is a number, then its the index of the location.
        // Otherwise, it is a guid. The merchant side uses index, while admin uses guid.
        // This is just a workaround and we should see if we can make both account types use guid.
        if (!isNaN(<any>guidOrIndex)) {
          locationIndex = <number>(<unknown>guidOrIndex);
        } else {
          locationIndex = this.getLocationIndex(guidOrIndex);
        }
      } else if (primaryLocationIndex > -1) {
        locationIndex = primaryLocationIndex;
      }

      if (guidOrIndex.includes('location-info')) {
        locationIndex = Number(multiLocationIndex);
      }
      this.currentSlug = locationIndex?.toString() || '';
    } else {
      this.currentSlug = primaryLocationIndex.toString();
    }

    // if the slug is a new location
    const parsedSlug = parseInt(this.currentSlug, 10);
    if (parsedSlug >= this.store.businessLocations.length + 1) {
      this.currentLocationIndex = parsedSlug;
      this.currentLocationId = null;
    } else {
      const primaryLocationId =
        this.store.businessLocations && this.store.businessLocations.length > 0
          ? this.store.businessLocations[0].id
          : null;
      const id = this.store.businessLocations[this.currentSlug]
        ? this.store.businessLocations[this.currentSlug]?.id
        : this.locationIdMapping.get(parseInt(this.currentSlug, 10));

      // Update new locationId.
      this.previousLocationId = `${this.currentLocationId}`;
      this.currentLocationId = id || primaryLocationId;
      this.currentLocation = this.store.businessLocations.find(b => b.id === this.currentLocationId);
      if (this.currentLocation) this.currentLocationIndex = this.store.businessLocations.indexOf(this.currentLocation);
    }

    // If the app is switching locations, load another cart by emiting an event.
    if (this.previousLocationId !== this.currentLocationId) {
      this.locationChanged.next(this.currentLocationId);
    }
  }

  public setCurrentLocationId(newLocationId: string) {
    this.currentLocationId = newLocationId;
    this.locationChanged.next(this.currentLocationId);
  }

  public isMultiLocation(): boolean {
    return this.store.businessLocations.length > 1;
  }

  // Check if we are creating a new location.
  public isNewLocation(): boolean {
    return parseInt(this.currentSlug, 10) === this.store.businessLocations.length + 1;
  }

  public getPrimaryLocationIndex(): number {
    return this.store.businessLocations.findIndex(location => location.isPrimary);
  }

  /**
   * This function is meant to save the mapping between the
   * location number and their ID, so when we route to
   * /location/products/2
   * we can get the location ID of the second location and add correctly
   * the products.
   *
   * @param numberOfLocs
   * @param id
   */
  public mapLocationPerId(numberOfLocs: number, id?): void {
    this.locationIdMapping.set(numberOfLocs, id || this.currentLocationId);
  }

  /**
   * Check if the location in the store.
   *
   * @param locationIndex
   * @returns bool
   */
  public locationExist(locationIndex: number): boolean {
    return !!(this.store.businessLocations.length > locationIndex && this.store.businessLocations[locationIndex]);
  }

  /**
   * Load a new location by manual means.
   *
   * @param slug
   * @param forceLoading if true, location always be reloaded
   */
  public loadLocationManually(slug: string, forceLoading: boolean = false): void {
    if (forceLoading || this.currentLocationId !== this.store.businessLocations[parseInt(slug, 10)]?.id) {
      this.setCurrentLocationValues(slug);
    }
  }

  public reloadLocation(slug?: string): void {
    this.setCurrentLocationValues(slug);
  }

  public isPrimaryLocationApproved() {
    return this.store.businessLocations.find(
      location => location.isPrimary && location.status && location.status.id === AppStatus.APPROVED,
    );
  }

  public isPrimaryLocationPending() {
    return this.store.businessLocations.find(
      location => location.isPrimary && location.status && location.status.id === AppStatus.PENDING,
    );
  }

  public getLocationIndex(id: string): number {
    return this.store.businessLocations.findIndex(loc => loc.id === id);
  }

  public getPrimaryLocationMerchantId(): string {
    const primaryLocation = this.store.businessLocations.find((location: BusinessLocationModel) => location.isPrimary);
    if (!primaryLocation) return;
    return primaryLocation.merchantId;
  }

  public getPrimaryLocation(): BusinessLocationModel {
    return this.store.businessLocations.find(location => location.isPrimary);
  }

  public getSubLocations(): BusinessLocationModel[] {
    return this.store.businessLocations.filter(location => !location.isPrimary);
  }

  public get isSecondaryLocation(): boolean {
    const onboardingLocationRegExp = /\/onboarding\/location\/.+/g;
    const onboardingBusinessInfoRegExp = /\/onboarding\/business-info/g;
    const currentRoute = this.router.routerState.snapshot.url;
    const parentMerchantStatus: AppStatusStr = this.store.businessLocations.find(
      (location: BusinessLocationModel) => location.isPrimary,
    ).status.status;

    const evaluation =
      parentMerchantStatus === 'Complete' &&
      (onboardingLocationRegExp.test(currentRoute) || onboardingBusinessInfoRegExp.test(currentRoute));
    return evaluation;
  }
}
