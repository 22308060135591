<div *ngIf="isLoading" class="spinner">
  <div class="lds-dual-ring"></div>
</div>
<div *ngIf="errorMessage" class="col-12">
  <div class="form-error-message">{{ errorMessage }}</div>
</div>
<div *ngIf="network.isOffline$ | async" class="col-12">
  <div class="form-error-message">You are currently offline, please check your device's network status.</div>
</div>

<div *ngIf="!isLoading && !errorMessage">
  <div class="row">
    <div class="col-xl-4">
      <div class="mobile-title text-center d-block d-xl-none">
        <h1>
          <span class="product-name">{{ productDetail.name }}</span>
        </h1>
        <h1>
          <span class="product-name">{{ productDetail.price | currency }}</span>
        </h1>
        <span *ngIf="getLowestRecurringPrice(productDetail); let pricing" class="product-lease">
          or from <span class="product-lease-cost">${{ pricing }}/mo</span>
        </span>
      </div>
      <app-tg-product-detail-images
        id="product-image"
        [alt]="productDetail.productName"
        [productImagesURLs]="productDetail.imageUrls"
        [showThumbnails]="false"
      ></app-tg-product-detail-images>
    </div>
    <div class="col-xl-8 ps-0">
      <div>
        <div class="d-none d-xl-block">
          <div class="col-12 row px-0">
            <div class="col-6">
              <h1>
                <span class="product-name large-title">{{ productDetail.name }}</span>
              </h1>
            </div>
            <div class="col-6 text-end">
              <h1>
                <span class="fw-bold product-price">
                  {{ productDetail?.price | currency }}
                </span>
                <span *ngIf="getLowestRecurringPrice(productDetail); let pricing" class="product-lease">
                  or from
                  <span class="product-lease-cost">${{ pricing }}/mo</span>
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div class="col-12 row">
          <p class="px-0 product-description-text">{{ productDetail.description }}</p>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="fw-bold product-pay-options">How would you like to pay?</span>
            <span class="ms-2">
              <app-tg-tooltip [tooltipText]="tooltipText"></app-tg-tooltip>
            </span>
          </div>
          <div class="col-12 row p-0 pt-4">
            <div class="d-flex col-12 mb-3" *ngFor="let paymentTypeOption of paymentTypesOptions">
              <app-radio-card
                radioName="paymentType"
                [formControlRef]="paymentTypesControl"
                (valueChanged)="onPaymentTypeChange()"
                [title]="paymentTypeOption.label"
                [value]="paymentTypeOption.value"
                [subtitle]="subtitles[paymentTypeOption.label]"
                [price]="paymentTypeOption.price"
              ></app-radio-card>
            </div>
          </div>
          <ng-container *ngIf="paymentTermsOptions[selectedPaymentType]">
            <div class="col-12">
              <span class="fw-bold product-pay-options">Select Term:</span>
            </div>
            <div class="col-12 row p-0 pt-4">
              <div
                class="d-flex col-12 mb-3"
                *ngFor="let paymentTermOption of paymentTermsOptions[selectedPaymentType]"
              >
                <app-radio-card
                  radioName="paymentTerm"
                  [formControlRef]="paymentTermControl"
                  (valueChanged)="onPaymentTermChange()"
                  [title]="paymentTermOption.label"
                  [value]="paymentTermOption.value"
                  [price]="paymentTermOption.price"
                ></app-radio-card>
              </div>
            </div>
          </ng-container>
          <div class="row align-items-center p-0">
            <div class="col-12 offset-lg-5 col-lg-3 mb-lg-0 mb-2">
              <app-tg-input-spinner
                [useInputTimeout]="false"
                (changeQuantity)="onQuantityChange($event)"
                min="0"
              ></app-tg-input-spinner>
            </div>
            <div class="col-12 col-lg-4">
              <div
                [tooltip]="getTooltipText()"
                [tooltipEnable]="(network.isOffline$ | async) || selectedPurchaseType === '' || productDisabled"
                triggers="mouseenter:mouseleave"
              >
                <button
                  type="button"
                  class="primary-button"
                  [disabled]="
                    (network.isOffline$ | async) ||
                    selectedPurchaseType === '' ||
                    productDisabled ||
                    productQuantity <= 0
                  "
                  (click)="addToCart()"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="hasFeatures">
        <div class="col-12 section-title">Features:</div>
        <div class="col-12">
          <ul class="detail-list">
            <li class="detail-list-features" *ngFor="let feature of productDetail.features">
              <i class="far fa-circle-plus"></i>{{ feature }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row" *ngIf="hasIncludedProducts">
        <div class="col-12 section-title">Package Includes:</div>
        <div class="col-12 px-0">
          <div class="row">
            <div class="col-12 col-sm-6 col-lg-4" *ngFor="let includedProduct of productDetail.includedProducts">
              <div class="included-product" (click)="goToIncludedProduct(includedProduct.productId)">
                <img
                  [alt]="includedProduct.productName"
                  class="included-product-image"
                  [src]="
                    includedProduct.imageUrls && includedProduct.imageUrls.length
                      ? includedProduct.imageUrls[0]
                      : defaultImage
                  "
                />
                <span class="included-product-name">{{ includedProduct.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="hasSpecs">
        <div class="col-12 section-title">Hardware Specifications:</div>
        <div class="col-12">
          <table class="table table-striped spec-table">
            <tbody>
              <tr *ngFor="let specification of productDetail.specifications | keyvalue">
                <th scope="row">{{ specification.key }}</th>
                <td>
                  <span *ngFor="let value of specification.value">{{ value }}<br /></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="hasFaq">
        <div class="col-12 section-title">Commonly Asked Questions:</div>
        <div class="col-12">
          <ul class="detail-list">
            <li class="detail-list-faqs" *ngFor="let question of productDetail.faq">
              <i class="far fa-circle-info"></i>
              <div>
                <div class="question-header">{{ question.header }}</div>
                <div>{{ question.shortAnswer }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" *ngIf="hasSimilarProducts">
        <div class="col-12 section-title">Frequently Bought Together:</div>
        <div class="col-12 carousel-area d-none d-sm-block">
          <div class="d-none d-lg-block col-lg-12">
            <carousel
              id="similar-products-carousel"
              [itemsPerSlide]="itemsPerSlide"
              [singleSlideOffset]="true"
              [interval]="false"
              [noWrap]="true"
              [showIndicators]="false"
            >
              <slide *ngFor="let product of productDetail.recommendedProducts">
                <app-tg-product [product]="product"></app-tg-product>
              </slide>
            </carousel>
          </div>
          <div class="d-block d-lg-none col-12">
            <carousel
              id="similar-products-carousel"
              [itemsPerSlide]="1"
              [singleSlideOffset]="true"
              [interval]="false"
              [noWrap]="true"
              [showIndicators]="false"
            >
              <slide *ngFor="let product of productDetail.recommendedProducts">
                <app-tg-product [product]="product"></app-tg-product>
              </slide>
            </carousel>
          </div>
        </div>
        <div class="col-12 similar-products-card d-block d-sm-none">
          <div class="row">
            <div class="col-12 col-xl-4 py-2" *ngFor="let product of productDetail.recommendedProducts">
              <app-tg-product [product]="product"></app-tg-product>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
