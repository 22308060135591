export enum AppStatus {
  ALL = 0,
  IN_PROGRESS = 1,
  ABANDONED = 2,
  PENDING = 3,
  APPROVED = 4,
  DECLINED = 5,
  OFF_RAMPED = 6,
  CANCELED = 7,
}
