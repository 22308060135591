<div class="contact-info-container">
  <form [formGroup]="form" class="mt-3" #formElem>
    <div class="row">
      <div class="col-sm-12 col-md-6 mb-3 pb-0 form-group">
        <input
          [id]="id"
          formControlName="name"
          class="form-control qa-contact-info-first-name"
          type="text"
          autocomplete="given-name"
          [maskito]="masks.personalName"
          #focus
        />
        <label class="form-label" [for]="id"> Contact First Name </label>
        <app-field-error [control]="name"></app-field-error>
      </div>

      <div class="col-sm-12 col-md-6 mb-3 pb-0 form-group">
        <input
          [id]="id + 1"
          formControlName="lastName"
          class="form-control qa-contact-info-last-name"
          type="text"
          autocomplete="family-name"
          [maskito]="masks.personalName"
        />
        <label class="form-label" [for]="id + 1"> Contact Last Name </label>
        <app-field-error [control]="lastName"></app-field-error>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 mb-3 pb-0 form-group">
        <input
          [id]="id + 3"
          formControlName="phoneNumber"
          class="form-control qa-contact-info-phone-number"
          type="text"
          autocomplete="tel"
          [maskito]="masks.phone"
        />
        <label class="form-label" [for]="id + 3"> Phone Number </label>
        <app-field-error [control]="phoneNumber"></app-field-error>
      </div>
      <div class="col-sm-12 col-md-6 mb-3 pb-0 form-group">
        <input
          [id]="id + 4"
          formControlName="email"
          class="form-control qa-contact-info-email"
          type="text"
          maxlength="70"
          autocomplete="email"
        />
        <label class="form-label" [for]="id + 4"> Email Address </label>
        <app-field-error [control]="email"></app-field-error>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-3 pb-0 form-group">
        <span class="tooltip-span">
          <app-tg-tooltip
            [tooltipText]="
              'The legal business name is the formal and officially registered name of your business that appears on the formation document of your business entity. If you are doing business as a sole proprietorship, then the legal business name will be the first and last name of the sole proprietor.'
            "
          ></app-tg-tooltip
        ></span>
        <input
          [id]="id + 2"
          formControlName="legalName"
          class="form-control qa-contact-info-company-name"
          type="text"
          autocomplete="on"
          [maskito]="masks.companyName"
        />
        <label class="form-label" [for]="id + 2">Legal Business Name </label>

        <app-field-error [control]="legalName"></app-field-error>
      </div>
    </div>
    <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 form-group">
        <label class="checkbox-label">Are you an owner of this business?</label>
        <p class="d-flex align-items-center">
          <input
            type="radio"
            class="ms-0 qa-contact-info-existing-client-customer"
            [id]="id + 5"
            name="isKeyController"
            [value]="true"
            formControlName="isKeyController"
          />
          <label [for]="id + 5" class="me-4 radio-label">Yes</label>
          <input
            type="radio"
            class="ms-0 qa-contact-info-existing-client-customer"
            [id]="id + 6"
            name="isKeyController"
            [value]="false"
            formControlName="isKeyController"
          />
          <label [for]="id + 6" class="radio-label">No</label>
        </p>
        <app-field-error [control]="form?.controls?.isKeyController"></app-field-error>
      </div>
      <div
        id="existing-customer-wrapper"
        class="col-sm-12 col-lg-6 form-group"
        *ngIf="!isPrefill && displayExistingClientCustomer"
      >
        <label class="checkbox-label">Are you an existing KeyBank customer?</label>
        <p class="d-flex align-items-center">
          <input
            type="radio"
            class="ms-0 qa-contact-info-existing-client-customer"
            [id]="id + 7"
            name="existingClientCustomer"
            [value]="true"
            formControlName="existingClientCustomer"
          />
          <label [for]="id + 7" class="me-4 radio-label">Yes</label>
          <input
            type="radio"
            class="ms-0 qa-contact-info-existing-client-customer"
            [id]="id + 8"
            name="existingClientCustomer"
            [value]="false"
            formControlName="existingClientCustomer"
          />
          <label [for]="id + 8" class="radio-label">No</label>
        </p>
        <p
          class="form-error-message"
          role="alert"
          *ngIf="form?.controls?.existingClientCustomer.touched && form?.controls?.existingClientCustomer?.invalid"
        >
          Required
        </p>
      </div>
    </div>

    <div class="row pt-2" *ngIf="bankInfoConfig?.contactInfoDisclosures?.text">
      <app-callout-notification [notification]="bankInfoConfig.contactInfoDisclosures.text"></app-callout-notification>
    </div>

    <div class="re-captcha-container" *ngIf="enableLeadFormCaptcha">
      <div class="re-captcha-component">
        <re-captcha
          formControlName="leadFormCaptcha"
          size="normal"
          (resolved)="$event && onCaptchaValidated($event)"
        ></re-captcha>
        <p
          class="form-error-message re-captcha-error d-block d-md-none"
          role="alert"
          *ngIf="form?.controls?.leadFormCaptcha.touched && form?.controls?.leadFormCaptcha?.invalid"
        >
          Required
        </p>
      </div>
      <p
        class="form-error-message re-captcha-error d-none d-md-block"
        role="alert"
        *ngIf="form?.controls?.leadFormCaptcha.touched && form?.controls?.leadFormCaptcha?.invalid"
      >
        Required
      </p>
    </div>
  </form>
</div>
