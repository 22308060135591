import { makeObservable, observable } from 'mobx';

export interface QuestionChoice {
  answerNumber: number;
  answerText: string;
}

export interface QuizQuestion {
  questionNumber: number;
  questionText: string;
  questionChoices: QuestionChoice[];
}

export interface GetQuizResponse {
  responseId: number;
  quizInitialResponseType: string;
  transactionKey: string;
  quizId: number;
  quizQuestions: QuizQuestion[];
  error?: any;
}

export class ApplicationQuiz {
  TransactionKey: string;

  QuizId: number;

  QuizAnswers: ApplicationQuizAnswer[] = [];

  constructor() {
    makeObservable(this, {
      TransactionKey: observable,
      QuizId: observable,
      QuizAnswers: observable,
    });
  }
}

export class ApplicationQuizAnswer {
  QuestionNumber: number;

  AnswerNumber: number;

  constructor() {
    makeObservable(this, {
      QuestionNumber: observable,
      AnswerNumber: observable,
    });
  }
}
