import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { currencyToNumber } from '@xup-payments/xup-frontend-utils/utils';

export class HighTicketValueValidator {
  static highTicketValue(mcc: string): ValidatorFn {
    return (control: UntypedFormControl): any => {
      const mccSpecialCases = {
        5814: {
          conditionToFail: parseInt(control.value, 10) > 25,
          error: 'high-ticket-less-than-25',
        },
      };

      if (mccSpecialCases[mcc] && mccSpecialCases[mcc].conditionToFail) {
        return {
          [mccSpecialCases[mcc].error]: true,
        };
      }
      return null;
    };
  }

  static higherThanZero(invalidError: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value || control.value === 0) {
        const value = currencyToNumber(control.value);

        if (value < 1) {
          const returnVal = {};
          returnVal[invalidError] = true;
          return returnVal;
        }
      }

      return null;
    };
  }
}
