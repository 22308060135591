<div class="row" *ngIf="!isSoleProprietor()">
  <p *ngIf="!isTaxExempt()" class="owners-info-text p-0">
    Information for anyone who owns 25% or more of the business must be included. Select
    <strong>add owner</strong> to provide additional representatives.
  </p>
  <p *ngIf="isTaxExempt()" class="owners-info-text p-0">
    A Controlling Party is a single individual with significant responsibility to control, manage or direct the business
    including an executive officer or senior manager (e.g., a Chief Executive Officer, Chief Financial Officer, Chief
    Operating Officer, Managing Member, General Partner, President, Vice President, or Treasurer) or any other
    individual who regularly performs similar functions.
  </p>
</div>
<div class="row">
  <div class="col-sm-12 mx-auto p-0">
    <form [formGroup]="form">
      <input id="primary-owner-selected" type="hidden" formControlName="primarySelected" />
      <div *ngIf="store.owners">
        <div class="tabs-heading col-sm-12">
          <button
            *ngFor="let owner of store.owners; let i = index"
            [ngClass]="{
              'tab-button': true,
              'tab-button-selected': selectedOwner === i
            }"
            (click)="setOwner(i)"
          >
            {{ ownerTitle(i) }}
          </button>
          <button
            *ngIf="!isSoleProprietor() && !isTaxExempt()"
            class="tab-button"
            (click)="addOwner()"
            aria-label="Add More Owners"
            [disabled]="disableAddOwners"
          >
            + Add Owner
          </button>
        </div>
        <div class="tabs-content">
          <app-tg-owner
            *ngFor="let owner of store.owners; let i = index"
            (isPrimaryChanged)="primaryChanged($event)"
            [index]="i"
            [applicant]="owner"
            [hidden]="selectedOwner !== i"
            [allowLessThan50Percentage]="allowLessThan50Percentage"
          ></app-tg-owner>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end">
      <button *ngIf="showRemoveOwners" class="btn btn-sm btn-secondary" (click)="removeOwner()">Remove Owner</button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="col-md-12 form-error-message error-message text-center">
    {{ errorMessage }}
  </div>
</div>
