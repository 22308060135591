import { Component } from '@angular/core';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HelpCenterPopupComponent } from '../help-center-popup/help-center-popup.component';
import { HeaderConfig, NavigationLink } from '@xup-payments/xup-frontend-utils/models';
import { Router } from '@angular/router';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public contentService: ContentServiceService,
    private bsModalService: BsModalService,
    private router: Router,
  ) {}

  public get config(): HeaderConfig {
    return this.contentService.bankInfoConfig.headerConfig;
  }

  public get showPartnerLogo(): boolean {
    return !this.router.url.includes(ROUTES.PARTNER);
  }

  public get partnerLogo(): string {
    let partnerInfo = JSON.parse(sessionStorage.getItem('partner_info'));
    return partnerInfo?.logo ? partnerInfo.logo : '';
  }

  actionMap: { [key: string]: () => void } = {
    openHelpCenter: () => {
      this.bsModalService.show(HelpCenterPopupComponent, {
        class: 'modal-xl',
        ariaLabelledBy: 'help-center',
      });
    },
  };

  public doAction(navLink: NavigationLink): void {
    if (navLink.url) {
      if (navLink.shouldOpenInNewTab) {
        window.open(navLink.url, '_blank');
      } else {
        window.location.href = navLink.url;
      }
    } else {
      const camelCaseAction = this.toCamelCase(navLink.action);
      const actionFn = this.actionMap[camelCaseAction];
      if (typeof actionFn !== 'undefined') {
        actionFn();
      }
    }
  }

  private toCamelCase(str): string {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}
