import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Indicator } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-status-line',
  templateUrl: './status-line.component.html',
  styleUrls: ['./status-line.component.scss'],
})
export class StatusLineComponent {
  @Input()
  public title: string;

  @Input()
  public pass: boolean;

  @Input()
  public indicators: Indicator[];

  @Input()
  public pageToRedirect: string;

  public displayDetails = false;

  constructor(protected router: Router) {}

  public toggleDetails() {
    this.displayDetails = !this.displayDetails;
  }

  public redirectTo() {
    this.router.navigate([`/${this.pageToRedirect}`]);
  }
}
