import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Category } from '@eventhorizon/models/category.model';
import { ProductTypeGroup } from '@eventhorizon/models/product.model';

@Component({
  selector: 'app-tg-product-search-filters',
  templateUrl: './product-search-filters.component.html',
  styleUrls: ['./product-search-filters.component.scss'],
})
export class ProductSearchFiltersComponent implements OnInit {
  @Input()
    categories: Category[];

  @Input()
    productTypes: ProductTypeGroup[];

  @Input()
    bigButtons = false;

  public form: FormGroup<IProductSearchFiltersForm>;

  @Output()
    changeCategoryEvent = new EventEmitter<string[]>();

  @Output()
    changeProductTypeEvent = new EventEmitter<string[]>();

  @Output()
    changeNameEvent = new EventEmitter<string>();

  @Output()
    changeSortByEvent = new EventEmitter<string>();

  private categoryIds = [];

  private productTypeIds = [];

  public sortByFilters = [
    { id: 'htl', name: 'Price: High to Low', value: true },
    { id: 'lth', name: 'Price: Low to High', value: false },
  ];

  public productTypesChips = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group<IProductSearchFiltersForm>({
      name: this.fb.control<string>(''),
    });
  }

  public handleCategoryChange(categoryId: number, checked: boolean) {
    if (checked) {
      this.categoryIds.push(categoryId);
    } else {
      const idx = this.categoryIds.findIndex(id => id === categoryId);
      this.categoryIds.splice(idx, 1);
    }

    this.changeCategoryEvent.emit(this.categoryIds);
  }

  public handleProductTypeChange(typeId: string, checked: boolean) {
    if (checked) {
      this.productTypeIds.push(typeId);
      this.productTypesChips.push(this.productTypes[parseInt(typeId) - 1]);
    } else {
      const idx = this.productTypeIds.findIndex(id => id === typeId);
      this.productTypeIds.splice(idx, 1);
      this.productTypesChips.splice(idx, 1);
    }

    this.changeProductTypeEvent.emit(this.productTypeIds);
  }

  public removeChip(typeId: number) {
    const idx = this.productTypeIds.findIndex(id => id === typeId);
    this.productTypeIds.splice(idx, 1);
    this.productTypesChips.splice(idx, 1);
    this.toggleCheck(typeId);

    this.changeProductTypeEvent.emit(this.productTypeIds);
  }

  public clearAll() {
    this.productTypeIds = [];
    this.productTypesChips = [];
    this.toggleCheck();

    this.changeProductTypeEvent.emit(this.productTypeIds);
  }

  public toggleCheck(typeId?: number) {
    if (!typeId) return false;
    return this.productTypeIds.findIndex(id => id === typeId) > -1;
  }

  public handleNameChange(event) {
    this.changeNameEvent.emit(event);
  }

  public setSortFilter(id: string) {
    this.sortByFilters.forEach(option => {
      if (option.id === id) {
        option.value = true;
      } else {
        option.value = false;
      }
    });
    this.changeSortByEvent.emit(id);
  }

  public handleKeyUp(event: KeyboardEvent, checkBox: HTMLInputElement, id: any, input: string) {
    if (event.code === 'Space') {
      checkBox.checked = !checkBox.checked;
      if (input === 'category') {
        this.handleCategoryChange(id, checkBox.checked);
      } else if (input === 'type') {
        this.handleProductTypeChange(id, checkBox.checked);
      }
    }
  }

  public isSelected(index: number): boolean {
    return this.sortByFilters[index].value;
  }
}

export interface IProductSearchFiltersForm {
  name: FormControl<string>;
}