<div class="terms-text-container">
  <!-- <div class="merchant-app-activity-disclaimer" data-testid="activity-disclaimer">
    <i class="far fa-circle-info"></i>
    <div class="disclaimer-container">
      <p class="disclaimer-description">
        The signer of the agreement is also agreeing to serve as the personal guarantor for the business.
      </p>
    </div>
  </div> -->

  <ngx-extended-pdf-viewer
    *ngIf="!isLoading"
    [src]="fileSrc"
    height="400px"
    [sidebarVisible]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showHighlightEditor]="false"
    [showTextEditor]="false"
    [showRotateButton]="false"
    [showPrintButton]="false"
    [showOpenFileButton]="false"
    [filenameForDownload]="'MPA'"
    [attr.aria-busy]="isLoading"
  ></ngx-extended-pdf-viewer>
</div>

<form [formGroup]="form" #formElem>
  <!-- <div class="row my-4">
    <div class="col-12 pe-0">
      <app-signers-dropdown
        #signerDropdown
        [options]="options"
        [contact]="contact"
        [businessOwners]="businessOwners"
        formArrayName="signerInfo"
        (dataEvent)="handleDataFromSignerForm($event)"
      ></app-signers-dropdown>
    </div>
  </div> -->
  
  <div *ngIf="shouldShow" class="row ic-plus-links-container">
    <div *ngFor="let link of mpaLinks" class="col-12 pt-3">
      <a class="navigation-link ic-plus-links" [href]="link.href" target="_blank">{{ link.label }} <i class="far fa-arrow-right ps-2"></i></a>
    </div>
  </div>

  <div class="row pb-2">
    <div class="col-sm-12 mb-3">
      <div class="input-container">
        <input
          class="form-option-input align-self-baseline d-inline"
          [class.form-option-input-error]="form?.invalid && form?.touched"
          type="checkbox"
          id="accept"
          formControlName="accept"
          value="accept"
          role="checkbox"
          tabindex="0"
          aria-labelledby="accept"
          aria-checked="false"
          required
        />
        <label class="form-option-label ps-2 pe-4 flex-grow-1" for="accept">
          I have read and agree to the above Terms & Conditions and consent to receiving documents electronically.
          <!-- <span class="merchant-app-activity-required">Required - </span> I certify that I am the individual listed
          within the "Signer" field, am authorized to contract on behalf of the this business, have read and agree to
          the above Terms & Conditions, and consent to receiving documents electronically. -->
        </label>
        <div>
          <button (click)="downloadMpa()" class="transparent-button" aria-label="Download MPA">
            <i class="far fa-arrow-down-to-line download-icon"></i>
          </button>
        </div>
      </div>
      <div class="input-container" *ngIf="!ddaInterestDisclosureHidden">
        <input
          class="form-option-input align-self-baseline d-inline"
          id="contact-info-disclosures"
          type="checkbox"
          formControlName="ddaInterestStatus"
          aria-labelledby="contactInfoDisclosures"
          (change)="updateDdaInterestDisclosure()"
        />
        <label
          for="contact-info-disclosures"
          class="form-option-label ps-2 pe-4 flex-grow-1"
          [innerHTML]="ddaInterestDisclosure"
        ></label>
      </div>
    </div>
    <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
      <p *ngFor="let error of errorMessage">
        {{ error }}
      </p>
    </div>
  </div>
</form>
