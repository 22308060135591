<div class="tg-ocd-container">
  <div class="tg-ocd-details">
    <div class="row mb-4">
      <div class="tg-ocd-number col-sm-5">{{ strings.orderNumber + orderDetails.orderId }}</div>
      <div [ngClass]="{ 'col-sm-7': true, 'tg-ocd-msg': true, 'tg-ocd-msg-error': !!cancelledOrder }">{{ title }}</div>
    </div>
    <div *ngIf="!cancelledOrder" class="tg-ocd-details-expand row">
      <button class="col btn btn-link right-align" (click)="expandDetails()">
        {{ strings.viewDetails }}&nbsp;&nbsp;<i
          [ngClass]="{
            'tg-ocd-details-expand-icon': true,
            fas: true,
            'fa-caret-right': true,
            'tg-ocd-details-expand-icon-active': showOrder
          }"
        ></i>
      </button>
    </div>
  </div>
  <div [hidden]="!showOrder" class="tg-ocd-order">
    <app-tg-cart [showExtendedDisplay]="true" [readonly]="true"></app-tg-cart>
  </div>
</div>
