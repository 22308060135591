export const tinTypes = [
  {
    value: '0',
    code: 1,
    description: 'I have a Tax ID, and file under my Tax ID',
  },
  {
    value: '1',
    code: 0,
    description: 'I have a Tax ID, but file under my SSN',
  },
  {
    value: '2',
    code: 0,
    // eslint-disable-next-line @typescript-eslint/quotes
    description: 'I don’t have a Tax ID. I file under my SSN',
  },
];
