import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ModalInterface } from '@eventhorizon/interfaces/modal-interface';
import { Cart, UpdateProductRequest } from '@eventhorizon/models/cart.model';

@Component({
  selector: 'app-software-details-modal',
  templateUrl: './software-details-modal.component.html',
  styleUrls: ['./software-details-modal.component.scss'],
})
export class SoftwareDetailsModalComponent implements ModalInterface {
  public productId: string;

  public title: string;

  public price: number;

  public description: string;

  public priceDescription: string;

  public cartUpdated: boolean;

  public disableButton: boolean;

  public isLoading: boolean = false;

  public cart: Cart;

  constructor(private modalRef: BsModalRef, protected cartStore: CartStore) {}

  public async close(addToCart: boolean) {
    if (addToCart) {
      this.isLoading = true;
      try {
        const locationId = this.cart?.locationId;
        let updateRequests: UpdateProductRequest[] = [];

        updateRequests.push({
          id: this.productId,
          quantity: 1,
          purchaseType: 'P',
          businessLocationId: locationId,
        });

        for (let product of this.cart.products) {
          if (product.productType === 'SOFTWARE') {
            updateRequests.push({
              id: product.productId,
              quantity: 0,
              purchaseType: 'P',
              businessLocationId: locationId,
            });
          }
        }

        this.cart = await lastValueFrom(this.cartStore.updateProducts(updateRequests, false, this.cart.id));
        this.cartUpdated = true;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    }
    this.modalRef.hide();
  }
}
