/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { MCCSearchResult } from '@eventhorizon/models/product.model';
import { randomComponentId } from '@eventhorizon/utils/util';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'lib-location-business-type',
  templateUrl: './location-business-type.component.html',
  styleUrls: ['./location-business-type.component.scss'],
})
export class LocationBusinessTypeComponent implements OnInit {

  @Input()
  public id: string = randomComponentId();

  @Input()
  public location: BusinessLocationModel = new BusinessLocationModel();

  public searchMCC: FormControl<string>;

  public mccOptions: MCCSearchResult[] = [];

  public get selectionMatchesSearch(): boolean {
    return this.searchMCC.value === this.location.mccDescription;
  }

  constructor(protected productService: ProductService, protected appStore: ApplicationStore) {}
  
  ngOnInit(): void {
    let initialValue: string = this.location?.mccDescription;
    if (!initialValue) {
      this.location.mcc = this.appStore.mcc;
      this.location.mccDescription = this.appStore.mccDescription;
      initialValue = this.appStore?.mccDescription;
    }

    this.searchMCC = new FormControl<string>(
      initialValue,
      Validators.required,
    );
    this.searchMCC.valueChanges
      .pipe(switchMap(v => this.search(v.trim())))
      .subscribe(res => {
        this.setMCCOptions(res);
      });
  }

  public isOptionSelected(option: MCCSearchResult): boolean {
    return option?.mcc && option.mcc === this.location.mcc;
  }

  public selectOption(option: MCCSearchResult) {
    if (!option?.mcc || !option?.description) return;
    this.location.mcc = option.mcc;
    this.location.mccDescription = option.description;
    this.searchMCC.setValue(option.description);
  }

  protected search(term: string) {
    return term ? this.productService.getMCC(term) : of(null);
  }

  protected setMCCOptions(res: any) {
    // no value provided in search
    if (!res) {
      this.mccOptions = [];
    } else if (!res.length) {
      // search value provided but yielded no results
      this.mccOptions = [{
        mcc: null,
        description: 'No results found',
        industryDescription: 'Please try another search term',
      }];
    } else {
      this.mccOptions = res;
    }
  }
}


export interface IBusinessTypeForm {
  mcc: FormControl<string>;
  mccDescription: FormControl<string>;
  mccAdditionalDetails: FormControl<string>;
}

