<app-container *mobxAutorun>
  <ng-container *ngIf="!submitLimit">
    <app-application-page
      [index]="3"
      [subIndex]="3"
      [text]="messages"
      [disableNextLoading]="true"
      (prev)="prev()"
      (next)="next()"
    >
      <app-tg-terms-and-conditions #innerSlide (prev)="prev()" (next)="next()"></app-tg-terms-and-conditions>
    </app-application-page>
  </ng-container>
  <ng-container *ngIf="submitLimit">
    <div class="terms-screen-limit text-center row justify-content-md-center">
      <div class="col-12">
        <h1 class="terms-screen-limit__title">Uh oh…</h1>
        <p class="m-2 terms-screen-limit__subtitle">
          We encountered an issue and were unable to submit your application.
        </p>
        <p class="m-2 terms-screen-limit__description">
          Don’t worry, your information is safe. Please contact our merchant team to get help with your application.
          We’ll be back up soon!
        </p>
      </div>
      <div class="col-12 col-md-2 terms-screen-limit__button">
        <button data-testid="help-btn" type="button" class="primary-button mt-2" (click)="openHelpCenter()">
          Get Help
        </button>
      </div>
    </div>
  </ng-container>
</app-container>
