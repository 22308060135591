import { HttpErrorResponse } from '@angular/common/http';
import { commonErrors } from '@eventhorizon/constants/general.constants';


export function formatError(error: any): string {
  try {
    return error.error.errors.join(' ');
  } catch {
    return 'Something went wrong';
  }
}

export function formatErrorAsList(error: any): string[] {
  let errorMessage = [];
  if (error instanceof HttpErrorResponse) {
    if (error.error && error.error.statusCode === 400) {
      errorMessage = error.error.errors;
    } else {
      errorMessage.push(commonErrors.failedToSaveInfo);
    }
  } else {
    errorMessage.push(commonErrors.failedToSaveInfo);
  }
  return errorMessage;
}
