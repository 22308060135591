import { Component } from '@angular/core';
import { ContentBase } from '@app/pages/content-page/content-module/content-components/base-content/content.base';
import { Content } from '@eventhorizon/models/page.model';

@Component({
  selector: 'app-content-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent extends ContentBase {
  displayText: string;

  url: string;

  iconClass: string;

  shouldOpenInNewTab: boolean;

  setContent(content: Content) {
    this.displayText = content.displayText;
    this.url = content.url;
    this.iconClass = content.iconClass;
    this.shouldOpenInNewTab = content.shouldOpenInNewTab;
  }
}

export default LinkComponent;
