import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaxRate } from '@xup-payments/xup-frontend-utils/models';

@Injectable({ providedIn: 'root' })
export class TaxRateService {
  protected BASE_URL;

  constructor(private http: HttpClient, @Inject('env') private environment) {
    this.BASE_URL = environment.onboardingApi
      ? `${environment.onboardingApi}/sales-tax`
      : `${environment.api.url}/sales-tax`;
  }

  public getSalesTaxRate(zipCode: string): Observable<TaxRate> {
    const params: HttpParams = new HttpParams().set('zipCode', zipCode);
    return this.http.get<TaxRate>(this.BASE_URL, { params });
  }
}
