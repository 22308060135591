import { Component, EventEmitter, Input, Output } from '@angular/core';
import { clone } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent {
  @Input() elements: { id: string; value: string }[];

  @Output() valueChanged = new EventEmitter();

  removeChip(typeId: string) {
    const idx = this.elements.findIndex(element => element.id === typeId);
    const selectedElement = clone(this.elements[idx]);
    this.elements.splice(idx, 1);
    this.valueChanged.emit(selectedElement.id);
  }
}
