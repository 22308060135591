<div class="step-tracker-steps">
  <div class="step-tracker-line"></div>
  <ng-container *ngFor="let step of config?.steps; let i = index; first as isFirst; last as isLast">
    <div class="step-tracker-step" [class.first]="isFirst" [class.last]="isLast">
      <div class="step-tracker-step-circle" [class.filled]="i <= config?.activeIndex">
        <i *ngIf="i < config?.activeIndex" class="fa-solid fa-check"></i>
        <span *ngIf="i >= config?.activeIndex">{{i + 1}}</span>
      </div>
      <span [class.previous]="i < config?.activeIndex" [class.current]="i === config?.activeIndex">{{step}}</span>
    </div>
  </ng-container>
</div>
