/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/keyword-spacing */
/* eslint-disable @typescript-eslint/brace-style */
// import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { debounceTime, delay, distinctUntilChanged, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { PaymentTypesController } from '@eventhorizon/controllers/payment-types.controller';
import { DeliveryOptionsController } from '@eventhorizon/controllers/delivery-options.controller';
import { BankInfoController } from '@eventhorizon/controllers/bank-info.controller';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ProductService } from '@eventhorizon/services/product.service';
import { IndustryController } from '@eventhorizon/controllers/industry.controller';
import { AddressComponent, IAddressForm } from '@eventhorizon/components/address/address.component';
import { BankService } from '@eventhorizon/services/bank.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseBankInfoComponent } from '../bank-screen/base-bank-info/base-bank-info.component';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { masks } from '@eventhorizon/data/masks.data';
import { isAddressEmpty, isAddressEqual, copyAddress, clearAddress, Address } from '@eventhorizon/models/address.model';
import { BankInfoModel } from '@eventhorizon/models/bank-info.model';
import { BusinessInfoModel } from '@eventhorizon/models/business-info.model';
import { IBusinessLocationConfig, BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { Category } from '@eventhorizon/models/category.model';
import { TransactionDistribution } from '@eventhorizon/models/transaction-distribution.model';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { formatPhone, randomComponentId } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-onboarding-location-information',
  templateUrl: './onboarding-location-information.component.html',
  styleUrls: ['./onboarding-location-information.component.scss'],
})
export class OnboardingLocationInformationComponent extends CarouselSlide implements OnInit, AfterViewInit, OnDestroy {
  @Input() public config: IBusinessLocationConfig = {
    displayLocationCategory: false,
    displayPrimaryLocationRb: true,
    displayMccConfiguration: true,
    displayMccDescription: true,
    displayPhoneNumber: true,
  };

  public masks = masks;

  @ViewChild('address')
  private address: AddressComponent;

  @ViewChild('bankInfo')
  private bankInfo: BaseBankInfoComponent;

  @ViewChild('bankForm', { static: false }) bankForm: NgForm;

  public sameAsLocation: UntypedFormControl;

  public sameAsLegal: FormControl<boolean>;

  private formChangesSubscription: Subscription;

  public hideTotalChannelsError = false;

  public hideTotalOptionsError = false;

  @Input() public canChangeBankAccount = true;

  @Input()
  public requirePrimary = true;

  public locationBankInfo: BankInfoModel;

  private businessCategory: MobxFormControl<string>;

  private businessPhone: MobxFormControl<string>;

  public locationDBA: MobxFormControl<string>;

  public businessCategories: Category[];

  public isValid = true;

  public id = randomComponentId();

  private subscriptions: Subscription = new Subscription();

  private destroy: Subject<void> = new Subject();

  @Input()
  public set paymentCategories(paymentCategories) {
    if (paymentCategories && paymentCategories.length) {
      this.paymentTypesController.paymentCategories = paymentCategories;
    }
  }

  public readonly SOLE_P_CODE = 'I';

  constructor(
    protected fb: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected pathService: RoutingPathService,
    protected applicationStore: ApplicationStore,
    protected locationsService: LocationsService,
    private paymentTypesController: PaymentTypesController,
    private deliveryOptionsController: DeliveryOptionsController,
    private bankInfoController: BankInfoController,
    private formBuilder: UntypedFormBuilder,
    public changeDetector: ChangeDetectorRef,
    protected productService: ProductService,
    protected industryController: IndustryController,
    protected bankService: BankService,
    protected bsModalService: BsModalService,
  ) {
    super(changeDetector);
  }

  public get locations() {
    return this.applicationStore.businessLocations;
  }

  @Input()
  public location: BusinessLocationModel = new BusinessLocationModel();

  public isNextDisabled(): boolean {
    return !this.form.valid;
  }

  public get selectedLocation(): BusinessLocationModel {
    return this.locationsService.currentLocation;
  }

  public get primaryLocation(): BusinessLocationModel {
    return this.applicationStore.businessLocations.find(location => location.isPrimary);
  }

  public get legalAddress(): Address {
    return this.applicationStore.businessAddresses.legalAddress;
  }

  public get deliveryOptions(): any[] {
    return this.deliveryOptionsController.deliveryOptionCategories;
  }

  public get fieldTextType() {
    return this.bankInfoController.fieldTextType;
  }

  public get paymentCategories(): any[] {
    return this.paymentTypesController.paymentCategories;
  }

  ngOnInit() {
    this.settingUpLocationInfo();
    this.buildForm();

    this.formChangesSubscription = this.form.valueChanges
      .pipe(delay(0), takeUntil(this.destroy), distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.isValid = this.form.valid;
        this.hideTotalChannelsError = this.paymentTypesController.hideTotalChannelsError();
        this.hideTotalOptionsError = this.deliveryOptionsController.hideTotalOptionsError();
        if (this.selectedLocation.deliveryOptions) {
          this.deliveryOptions.map(cat => {
            if (cat.control.value && cat.control.value !== 0) {
              this.onDeliveryPercentageChange(cat.control.value, cat.controlName);
            }
          });
        }

        if (this.selectedLocation.transactionDistribution) {
          this.paymentCategories.map(cat => {
            if (cat.control.value && cat.control.value !== 0) {
              this.onPercentageChange(cat.control.value, cat.controlName);
            }
          });
        }
      });
  }

  ngAfterViewInit() {
    this.form.setControl('address', this.address.form);
    this.form.setControl('bankInfo', this.bankInfo.form);

    if (this.router.url === '/location-info') {
      this.router.navigateByUrl('/locations');
    }

    if (this.address && !isAddressEmpty(this.address.address) && !isAddressEmpty(this.legalAddress)) {
      const sal = isAddressEqual(this.legalAddress, this.address.address);
      this.sameAsLegal.setValue(sal);
      this.address.readonly = sal;
    }

    this.setFormValuesByConfig();
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  public onOpen() {
    super.onOpen();
  }

  public initialFocus() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
  }

  async setFormValuesByConfig() {
    if (this.config.displayLocationCategory) {
      try {
        await lastValueFrom(this.productService.getCategories()).then(data => {
          this.businessCategories = this.industryController
            .transformCategories(data)
            .sort((objectA, objectB) => objectA.rank - objectB.rank);
        });
        this.businessCategory = new MobxFormControl(
          'businessCategory',
          () =>
            this.selectedLocation.businessCategory && this.selectedLocation.businessCategory.description
              ? this.selectedLocation.businessCategory.name
              : null,
          v => {
            const category = {
              id: 1,
              name: v,
              displayName: v,
              description: v,
              rank: 1,
              imageUrl: '',
            };
            this.selectedLocation.businessCategory = category;
            if (this.selectedLocation.businessCategory.description) {
              this.selectedLocation.businessCategory = category;
            }
          },
          Validators.required,
        );
        this.form.setControl('businessCategory', this.businessCategory);
      } catch (error) {
        console.error(error);
      }
    }

    if (this.config.displayPhoneNumber) {
      this.selectedLocation.businessInfo = this.selectedLocation.businessInfo
        ? this.selectedLocation.businessInfo
        : new BusinessInfoModel();

      this.businessPhone = new MobxFormControl<string>(
        'businessPhone',
        () => formatPhone(this.selectedLocation.businessInfo.phone),
        (v: string) => {
          this.selectedLocation.businessInfo.phone = v;
        },
        CommonValidator.phone(true),
      );
      this.form.setControl('businessPhone', this.businessPhone);
    }
  }

  public changeSameAsLegal(value: boolean) {
    if (value) {
      this.address.readonly = true;
      copyAddress(this.legalAddress, this.address.address);
    } else {
      this.address.readonly = false;
      clearAddress(this.address.address);
    }
  }

  public onInputChange(quantity: string, controlName: string, deliveryOptions: boolean) {
    let quantityInt = parseInt(quantity, 10) || 0;

    if (quantityInt < 0) {
      quantityInt = 0;
      this.form.controls[controlName].setValue(quantityInt);
    } else {
      if (deliveryOptions) {
        this.onDeliveryPercentageChange(quantityInt, controlName);
      } else {
        this.onPercentageChange(quantityInt, controlName);
      }
    }
    if (quantity == '') {
      // This block allows user to enter an empty string
      this.form.controls[controlName].setValue(quantity);
    }
  }

  public onDeliveryPercentageChange(value, controlName) {
    this.deliveryOptionsController.onPercentageChange(value, controlName);
  }

  public onPercentageChange(value, controlName) {
    this.paymentTypesController.onPercentageChange(value, controlName);
  }

  private buildForm() {
    this.sameAsLegal = new FormControl(false);

    this.form = this.formBuilder.group({
      sameAsLegal: this.sameAsLegal,

      ...this.paymentTypesController.buildForm(
        false,
        this.selectedLocation,
        this.locationsService.currentLocationIndex,
      ),
      ...this.deliveryOptionsController.buildForm(
        false,
        this.selectedLocation,
        this.locationsService.currentLocationIndex,
      ),

      address: new FormGroup<IAddressForm>({
        address1: new FormControl<string>(null),
        address2: new FormControl<string>(null),
        city: new FormControl<string>(null),
        state: new FormControl<string>(null),
        zip: new FormControl<string>(null),
      }),
    });

    this.locationDBA = new MobxFormControl<string>(
      'locationDBA',
      () => this.selectedLocation.name,
      (v: string) => (this.selectedLocation.name = v),
      [Validators.required],
    );
    this.form.setControl('locationDBA', this.locationDBA);

    return this.form;
  }

  private settingUpLocationInfo() {
    if (!this.selectedLocation.bankInfo) {
      this.locationBankInfo = new BankInfoModel();
    } else {
      this.locationBankInfo = this.selectedLocation.bankInfo;
    }

    if (!this.selectedLocation.transactionDistribution) {
      this.selectedLocation.transactionDistribution = new TransactionDistribution();
    }
    // setting productShippingAddress to increase progress code
    if (this.selectedLocation.productShippingAddress) {
      this.selectedLocation.productShippingAddress.address = this.applicationStore.businessAddresses.legalAddress;
      this.selectedLocation.productShippingAddress.companyName = this.applicationStore.businessInfo.legalName;
      this.selectedLocation.productShippingAddress.firstName = this.applicationStore.applicantInfo.name;
      this.selectedLocation.productShippingAddress.lastName = this.applicationStore.applicantInfo.lastName;
    }
  }

  public async save(): Promise<boolean> {
    if (this.selectedLocation.deliveryOptions) {
      this.selectedLocation.deliveryOptions.timeFrame0 = this.form.controls.timeFrame0.value;
      this.selectedLocation.deliveryOptions.timeFrame1To7 = this.form.controls.timeFrame1To7.value;
      this.selectedLocation.deliveryOptions.timeFrame8To14 = this.form.controls.timeFrame8To14.value;
      this.selectedLocation.deliveryOptions.timeFrame15To30 = this.form.controls.timeFrame15To30.value;
      this.selectedLocation.deliveryOptions.timeFrameOver30 = this.form.controls.timeFrameOver30.value;
    }

    if (this.selectedLocation.transactionDistribution) {
      this.selectedLocation.transactionDistribution.faceToFace = this.form.controls.faceToFace.value;
      this.selectedLocation.transactionDistribution.internet = this.form.controls.internet.value;
      this.selectedLocation.transactionDistribution.phoneOrEmail = this.form.controls.phoneOrEmail.value;
      this.selectedLocation.transactionDistribution.onTheGo = this.form.controls.onTheGo.value;
    }

    try {
      this.paymentTypesController.setEmptyToZero();
      this.deliveryOptionsController.setEmptyToZero();

      if (this.selectedLocation.isPrimary) {
        await lastValueFrom(this.applicationStore.savePrimaryLocationDbaName(this.selectedLocation.name));
      }

      await lastValueFrom(this.applicationStore.updateSubmerchant(this.selectedLocation.id, this.selectedLocation));
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}
