import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { reaction } from 'mobx';
import { removeModalIfPresent } from '@eventhorizon/util/util';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  BankInfoConfigModel,
  Cart,
  messages,
  OrderDetails,
  OrderStatus,
} from '@xup-payments/xup-frontend-utils/models';
import { statuses } from '@xup-payments/xup-frontend-utils/constants';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { ApplicationPageComponent } from '../application-page/application-page.component';

@Component({
  selector: 'app-tg-application-confirmation',
  templateUrl: './application-confirmation.component.html',
  styleUrls: ['./application-confirmation.component.scss'],
})
export class ApplicationConfirmationComponent extends CarouselSlide implements OnInit {
  @Input()
  public isOrderStatus = false;

  public messages = messages.applicationConfirmationSlide;

  private errorSubmitting = false;

  private token: string;

  public isLoading: boolean;

  public errorMessage: string;

  private orderStatusCart = new Cart();

  public trackingUrl = '';

  public bankInfoConfig: BankInfoConfigModel;

  constructor(
    public appStore: ApplicationStore,
    public cartStore: CartStore,
    protected cd: ChangeDetectorRef,
    public appPageComponent: ApplicationPageComponent,
    private route: ActivatedRoute,
    private appService: ApplicationService,
    public contentService: ContentServiceService,
    public bsModalService: BsModalService,
  ) {
    super(cd);
    reaction(
      () => !!this.cartStore.isGroupingProducts,
      (isGrouped: boolean) => {
        if (!isGrouped && this.isOrderStatus) {
          this.getOrderStatus();
        }
      },
    );
  }

  ngOnInit() {
    if (this.isOrderStatus) {
      this.getOrderStatus();
    }
    this.contentService.loadBankConfigs().subscribe(bankInfoConfig => {
      this.bankInfoConfig = bankInfoConfig;
    });
    removeModalIfPresent(this.bsModalService);
  }

  public status(): number {
    if (this.appStore.status && this.appStore.status.internalOrderStatus) {
      return this.appStore.status.internalOrderStatus.id;
    }
    return statuses.InProgress;
  }

  applicationStatusIcon() {
    if (this.errorSubmitting) {
      return 'fa-exclamation-circle appconfirm-icon-error';
    }
    switch (this.status()) {
      case statuses.Complete:
        return 'fa-check-circle appconfirm-icon-approved';
      case statuses.Declined:
        return 'fa-circle-xmark appconfirm-icon-denied';
      case statuses.InProgress:
        return 'fa-arrows-rotate appconfirm-icon-progress';
      default:
        return 'fa-clock appconfirm-icon-inreview';
    }
  }

  applicationStatusMsg() {
    if (this.errorSubmitting) {
      this.appPageComponent.smallHeadlineOverride = this.messages.applicationError;
      this.appPageComponent.showHeadline = false;
      this.appPageComponent.headlineOverride = this.messages.altHeadline;
      return this.messages.errorSubmitting;
    }
    switch (this.status()) {
      case statuses.InProgress:
        this.appPageComponent.smallHeadlineOverride = this.messages.altSmallHeadline;
        return this.messages.applicationInProgress;
      case statuses.Complete:
        this.appPageComponent.headlineOverride = null;
        this.appPageComponent.smallHeadlineOverride = null;
        return this.messages.applicationApproved;
      case statuses.Declined:
        this.appPageComponent.headlineOverride = this.messages.altHeadline;
        this.appPageComponent.smallHeadlineOverride = null;
        return this.messages.applicationDenied;
      default:
        this.appPageComponent.headlineOverride = null;
        this.appPageComponent.smallHeadlineOverride = null;
        return this.messages.applicationInReview;
    }
  }

  orderDetailsTitle() {
    if (this.errorSubmitting) {
      return this.messages.applicationError;
    }
    switch (this.status()) {
      case statuses.InProgress:
        return this.messages.submitting;
      case statuses.Complete:
        return this.messages.complete;
      case statuses.Declined:
        return this.messages.cancelled;
      default:
        return this.messages.pendingApproval;
    }
  }

  orderDetails() {
    return <OrderDetails>{
      transactionProducts: this.cartStore.transactionProducts,
      locationProducts: this.cartStore.locationProducts,
      totalPurchaseProducts: this.cartStore.totalPurchaseProducts,
      purchaseProductTotalAmount: this.cartStore.purchaseProductTotalAmount,
      transactionRateDisplay: this.cartStore.transactionRateDisplay,
      orderId: this.appStore.orderId || this.cartStore.orderId,
      taxAmount: this.cartStore.taxAmount,
      totalAmount: this.cartStore.totalAmount,
      hasPhonePayment: this.cartStore.hasPhonePayment,
    };
  }

  cancelledOrder(): string {
    if (this.status() === statuses.Declined) {
      return this.messages.cancelled;
    }
    return '';
  }

  getOrderStatus() {
    this.isLoading = true;
    this.errorMessage = undefined;
    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.token = params.token;
        this.appService.getOrderStatus(this.token).subscribe(
          response => {
            this.isLoading = false;
            this.fillStores(response);
          },
          () => {
            this.isLoading = false;
            this.errorMessage = 'Invalid token.';
          },
        );
      }
    });
  }

  fillStores(response: OrderStatus) {
    this.appStore.status = { ...this.appStore.status, ...response.status };
    this.appStore.progressCode = response.status.progressCode;
    this.appStore.orderId = response.orderId;
    this.appStore.id = response.applicationId;

    this.orderStatusCart.products = response.products;
    this.orderStatusCart.oneTimeTotal = response.oneTimeTotal;
    this.orderStatusCart.amount = response.amount;
    this.orderStatusCart.taxAmount = response.taxAmount;
    this.orderStatusCart.totalAmount = response.totalAmount;
    this.orderStatusCart.shippingAmount = response.shippingAmount;
    this.orderStatusCart.recurringAmount = response.recurringAmount;
    this.orderStatusCart.recurringtaxAmount = response.recurringTaxAmount;
    this.orderStatusCart.recurringtotalAmount = response.recurringTotalAmount;
    this.orderStatusCart.orderId = response.orderId;
    this.cartStore.setCart(this.orderStatusCart);
    this.cartStore.acquiringPricing = response.acquiringPricing;

    this.trackingUrl = response.trackingUrl;
  }

  downloadMpa() {
    this.appStore.downloadMpa(this.appStore.hasMultipleDocuments);
  }
}
