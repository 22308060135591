import { Injectable } from '@angular/core';
import { action, autorun, computed, configure, makeObservable, observable } from 'mobx';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { BehaviorSubject, EMPTY, lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { ɵDomSanitizerImpl } from '@angular/platform-browser';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { catchError, map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { RiskService } from '@eventhorizon/services/risk.service';
import { cloneDeep, isEqual } from 'lodash';
import { Address } from '@eventhorizon/models/address.model';
import { AlertCountsResponse, AlertStatusEnum, AlertSummary, RiskAlertSummaryRequest } from '@eventhorizon/models/risk.model';
import { ApplicantInfo } from '@eventhorizon/models/applicant-info.model';
import { ApplicationQuiz } from '@eventhorizon/models/quiz.verification.model';
import { ApplicationStatus, DDAInterestStatus } from '@eventhorizon/models/application-status.model';
import { AutofillResponseData, SearchResultsData, SearchResultsResponse } from '@eventhorizon/models/autofill.model';
import { BankInfoModel } from '@eventhorizon/models/bank-info.model';
import { BusinessAddressesModel } from '@eventhorizon/models/business-addresses.model';
import { BusinessInfoModel } from '@eventhorizon/models/business-info.model';
import { BusinessLocationModel, BusinessLocationModelResponse } from '@eventhorizon/models/business-location.model';
import { BusinessOwner } from '@eventhorizon/models/business-owner.model';
import { CampaignInformation } from '@eventhorizon/models/referral.model';
import { Category } from '@eventhorizon/models/category.model';
import { CreatedBy } from '@eventhorizon/models/created-by.model';
import { InternalLead } from '@eventhorizon/models/internal-lead.model';
import { OrganizationType } from '@eventhorizon/models/organization-type.model';
import { Progress } from '@eventhorizon/models/progress.model';
import { rules } from '@eventhorizon/data/business-types.data';
import { tinTypes } from '@eventhorizon/data/tin-types.data';
import { TransactionDistribution } from '@eventhorizon/models/transaction-distribution.model';
import { TransactionInfo } from '@eventhorizon/models/transaction-info.model';
import { ApplicationRequest, ApplicationResponse, ApplicationSaveReturnRequest } from '@eventhorizon/models/application.model';
import { constants } from '@eventhorizon/constants/general.constants';
import moment from 'moment';
import { ProgressBarState, progressBarStates } from '@eventhorizon/data/routes.data';

@Injectable({ providedIn: 'root' })
export class ApplicationStore {
  isLoaded = false;

  private _isLoaded$ = new BehaviorSubject<boolean>(this.isLoaded);

  isLoaded$ = this._isLoaded$.asObservable();

  id: string;

  redirect = false;

  progressCode: number;

  submitAttempts: number;

  category: Category;

  subCategory: Category;

  applicantInfo: ApplicantInfo = new ApplicantInfo();

  internalLead: InternalLead = new InternalLead();

  orderId: string;

  rules = rules;

  progress: Progress[];

  autofill: AutofillResponseData[];

  industry: string;

  mccAdditionalDetails: string;

  businessSize: string;

  businessPurchaseType: number;

  annualRevenue: number;

  organizationTypes: OrganizationType[];

  mcc: string;

  mccDescription: string;

  owners: BusinessOwner[] = [new BusinessOwner()];

  transactionInfo: TransactionInfo = new TransactionInfo();

  businessInfo: BusinessInfoModel = new BusinessInfoModel();

  businessAddresses: BusinessAddressesModel = new BusinessAddressesModel();

  bankInfo: BankInfoModel = new BankInfoModel();

  quiz: ApplicationQuiz = new ApplicationQuiz();

  businessLocations: BusinessLocationModel[] = [];

  searchResults: SearchResultsResponse;

  selectedSearchResult: SearchResultsData;

  status: ApplicationStatus;

  threatMetrixGuid = '';

  hasPoliticalTies: boolean;

  partnerReferenceId: string;

  hasMultipleDocuments = false;

  hasGroupedAuthFees = false;

  shouldGetLLCType = false;

  prefilled = false;

  merchantId: string;

  partnerId: string;

  groupId: string;

  createdBy: CreatedBy;

  createdAt: string;

  updatedAt: string;

  callBackRequested: boolean;

  failRiskCount: number;

  alertSummary: AlertSummary;

  alertSummaryFilters: RiskAlertSummaryRequest;

  customerId?: string;

  sourceType?: string;

  source?: string;

  hideLocations: boolean = true;

  resumeApp = false;

  public readonly SSN_ONLY = tinTypes[2].code;

  private riskSignal = new Subject<string>();

  private states = this.getClientSpecificProgressBarStates();

  private readonly GUEST_MERCHANT: string = 'Guest Merchant';

  /* Defined all saves for future refactor. */
  public readonly saveSections = {
    BANK_INFO_SAVE: 'bank-info',
    BUSINESS_INFORMATION_SAVE: 'business-information',
    BUSINESS_STRUCTURE_SAVE: 'business-structure',
    BUSINESS_LOCATION_SAVE: 'business-location',
    BUSINESS_PURCHASE_TYPE_SAVE: 'business-purchase-type',
    BUSINESS_SIZE_SAVE: 'business-size',
    BUSINESS_TYPE_SAVE: 'business-type',
    CATEGORY_SAVE: 'category',
    CONTACT_INFO_SAVE: 'contact-info',
    OWNERS_SAVE: 'owners',
    PROGRESS_CODE_SAVE: 'progress-code',
    TRANSACTION_INFO_SAVE: 'transaction-info',
    THREAT_METRIX_SAVE: 'threat-metrix',
    GROUP_ID: 'group-id',
    CALLBACK_REQUESTED: 'callback',
  };

  private getClientSpecificProgressBarStates(): ProgressBarState[] {
    const clientSpecificStates = (window as any).progressBarConfig;
    return this.getStates().map(state => {
      const clientState =
        clientSpecificStates &&
        clientSpecificStates.filter(currentClientState => currentClientState.section === state.section);
      if (clientState && clientState.length) {
        state.states = [...state.states, ...clientState.pop().states];
      }
      return state;
    });
  }

  public constructor(
    private applicationService: ApplicationService,
    private routingPathService: RoutingPathService,
    private riskService: RiskService,
  ) {
    configure({
      enforceActions: 'never',
    });

    makeObservable<ApplicationStore, 'setApplication'>(this, {
      isLoaded: observable,
      id: observable,
      redirect: observable,
      progressCode: observable,
      submitAttempts: observable,
      category: observable,
      subCategory: observable,
      applicantInfo: observable,
      internalLead: observable,
      orderId: observable,
      rules: observable,
      progress: observable,
      autofill: observable,
      industry: observable,
      mccAdditionalDetails: observable,
      businessSize: observable,
      businessPurchaseType: observable,
      annualRevenue: observable,
      organizationTypes: observable,
      mcc: observable,
      mccDescription: observable,
      owners: observable,
      transactionInfo: observable,
      businessInfo: observable,
      businessAddresses: observable,
      bankInfo: observable,
      quiz: observable,
      businessLocations: observable,
      searchResults: observable,
      selectedSearchResult: observable,
      status: observable,
      threatMetrixGuid: observable,
      hasPoliticalTies: observable,
      partnerReferenceId: observable,
      hasMultipleDocuments: observable,
      hasGroupedAuthFees: observable,
      shouldGetLLCType: observable,
      prefilled: observable,
      merchantId: observable,
      partnerId: observable,
      groupId: observable,
      createdBy: observable,
      createdAt: observable,
      updatedAt: observable,
      callBackRequested: observable,
      failRiskCount: observable,
      alertSummary: observable,
      customerId: observable,
      sourceType: observable,
      source: observable,
      setRedirectFlag: action,
      loadApplication: action,
      createApplication: action,
      loadApplicationById: action,
      setApplication: action,
      clearApplication: action,
      setBusinessLocations: action,
      setOrganizationType: action,
      businessTitles: computed,
      showOwnership: computed,
      showAmexAnnualSales: computed,
      showAmexMemberId: computed,
      showMccDescription: computed,
      firstBusinessLocationId: computed,
      deleteLocationFromBackEnd: action,
    });

    this.buildInitialStatuses();

    autorun(() => {
      this.owners.forEach(owner => {
        if (!this.businessTitles.has(owner.title)) {
          owner.title = undefined;
        }
      });
    });
    this.riskSignal
      .pipe(
        throttleTime(1000),
        switchMap(merchantId => this.riskService.getAlertCount(merchantId, AlertStatusEnum.Fail)),
        catchError(error => {
          console.warn(`Failed to get Risk Counts, ${error.message}`, error);
          return EMPTY;
        }),
      )
      .subscribe((result?: AlertCountsResponse) => {
        if (!result) return;
        this.failRiskCount = result.totalCount;
      });
  }

  public setRedirectFlag(value) {
    this.redirect = value;
  }

  public setResumeAppFlag(value: boolean): void {
    this.resumeApp = value;
  }

  private emitIsLoaded() {
    this._isLoaded$.next(this.isLoaded);
  }

  /**
     * This attempts to retrieve an application for a specific user, and if it does not find it, it creates a new
     application.
     * This should only be used by miramar (onboarding) and the support tool. 
     * If you need to get an application to be viewed by a payfac-admin or a payfac-viewer, you need to use
     loadApplicationById
     *
     * @returns
     */
  public loadApplication(): Observable<boolean> {
    this.isLoaded = false;
    this.emitIsLoaded();
    return this.applicationService.getApplicationForUser().pipe(
      catchError(error => (error.status !== 404 ? throwError(error) : this.applicationService.createApplication())),
      map((response: ApplicationResponse) => {
        this.setApplication(response);
        if (!this.owners || this.owners.length <= 0 || this.owners[0].firstName === void 0) {
          this.hasPoliticalTies = null;
        }
        return true;
      }),
      catchError(error => {
        console.log('load application error', error);
        return throwError(error);
      }),
      tap(() => this.emitIsLoaded()),
    );
  }

  public createApplication(): Observable<ApplicationResponse> {
    return this.applicationService.createApplication().pipe(
      tap((response: ApplicationResponse) => {
        this.setApplication(response);
        if (!this.owners || this.owners.length <= 0 || this.owners[0].firstName === void 0) {
          this.hasPoliticalTies = null;
        }
        sessionStorage.setItem('app_id', this.id);
      }),
    );
  }

  public loadApplicationById(id: string, loadRiskCount: boolean = false) {
    this.isLoaded = false;
    this.emitIsLoaded();
    return this.applicationService.getApplicationById(id).pipe(
      tap((response: ApplicationResponse) => {
        this.setApplication(response);
        if (!this.owners || this.owners.length <= 0 || this.owners[0].firstName === void 0) {
          this.hasPoliticalTies = null;
        }
        if (loadRiskCount) {
          this.getApplicationFailRiskCount();
        }
      }),
      catchError(error => {
        console.log('load application error', error);
        if (error.status !== 404) {
          return throwError(error);
        }
        return of(null);
      }),
      tap(() => this.emitIsLoaded()),
    );
  }

  public getPrimaryLocation() {
    return this.businessLocations.find((location: BusinessLocationModel) => location.isPrimary);
  }

  public getApplicationFailRiskCount() {
    const primaryLocation = this.getPrimaryLocation();
    if (!primaryLocation) return;
    this.riskSignal.next(primaryLocation.merchantId);
  }

  async getAlertSummary(params?: RiskAlertSummaryRequest) {
    const primaryLocation = this.getPrimaryLocation();
    if (!primaryLocation || !primaryLocation.merchantId) return;

    if (params && !isEqual(params, this.alertSummaryFilters)) {
      this.alertSummaryFilters = params;
    }
    this.alertSummary = await lastValueFrom(
      this.riskService.getAlertSummary(primaryLocation.merchantId, this.alertSummaryFilters),
    );
  }

  public createSubmerchant(location: BusinessLocationModel): Observable<BusinessLocationModelResponse> {
    return this.applicationService.createLocation(this.id, location);
  }

  public updateSubmerchant(locationId: string, lc: BusinessLocationModel): Observable<BusinessLocationModel> {
    let location = cloneDeep(lc);
    // To avoid errors in the API,
    // deleting the transactionDistribution if is empty.
    if (location.transactionDistribution && location.transactionDistribution.isEmpty()) {
      delete location.transactionDistribution;
    } else if (location.transactionDistribution) {
      location.transactionDistribution = this.ensureDefaultTransactionDistribution(location.transactionDistribution);
    }

    if (location.bankInfo && location.bankInfo.isEmpty()) {
      delete location.bankInfo;
    }
    return this.applicationService.patchLocation(this.id, location, locationId).pipe(
      map((resp: ApplicationResponse) => {
        for (let bl of this.businessLocations) {
          if (bl.id !== locationId) {
            return bl;
          }
          return new BusinessLocationModel(resp);
        }
      }),
    );
  }

  private ensureDefaultTransactionDistribution(
    transaction?: Partial<TransactionDistribution>,
  ): TransactionDistribution {
    const t =
      (transaction as TransactionDistribution) ??
      new TransactionDistribution({ faceToFace: 0, internet: 0, onTheGo: 0, phoneOrEmail: 0 });

    if (!t?.faceToFace) t.faceToFace = 0;
    if (!t?.internet) t.internet = 0;
    if (!t?.onTheGo) t.onTheGo = 0;
    if (!t?.phoneOrEmail) t.phoneOrEmail = 0;

    return t;
  }

  public save(sections: string[]): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    sections.forEach(section => {
      this.fillRequestBySection(section, req);
    });
    return this.applicationService.patchApplication(this.id, req).pipe(
      tap((resp: ApplicationResponse) => {
        this.setApplication(resp);
      }),
    );
  }

  private fillRequestBySection(section: string, req: ApplicationRequest) {
    switch (section) {
      case this.saveSections.BANK_INFO_SAVE:
        req.application.bankInfo = this.bankInfo;
        break;
      case this.saveSections.TRANSACTION_INFO_SAVE:
        this.setBusinessLocations(this.transactionInfo.numberOfLocations, this.businessLocations);
        if (this.businessLocations.length > 0) {
          const { transactionDistribution } = this.businessLocations[0];
          this.businessLocations.forEach((businessLocation, index) => {
            if (
              index > 0 &&
              (!businessLocation.transactionDistribution || businessLocation.transactionDistribution.isEmpty())
            ) {
              businessLocation.transactionDistribution = transactionDistribution;
            }
            businessLocation.transactionInfo = this.transactionInfo;
          });
        }
        req.application.businessLocations = this.businessLocations;
        req.application.businessLocations.forEach(location => {
          if (location.address.isEmpty()) location.address = null;
        });
        req.application.businessLocations.forEach(location => {
          if (location.bankInfo && location.bankInfo.isEmpty()) {
            location.bankInfo = null;
          }
        });
        req.application.transactionInfo = this.transactionInfo;
        req.application.businessSize = this.businessSize;
        req.application.businessPurchaseType = this.businessPurchaseType;
        break;
      case this.saveSections.CONTACT_INFO_SAVE:
        req.application.applicantInfo = this.applicantInfo;
        req.application.businessInfo = this.businessInfo ? this.businessInfo : new BusinessInfoModel();
        req.application.businessInfo.legalName = this.businessInfo.legalName;
        if (this.internalLead.firstName || this.internalLead.lastName || this.internalLead.email) {
          req.application.internalLead = this.internalLead;
        }

        req.application.businessInfo.legalName = this.businessInfo.legalName;

        if (this.businessLocations.length > 0) {
          req.application.businessLocations = this.businessLocations;
        }

        break;
      case this.saveSections.BUSINESS_TYPE_SAVE:
        if (this.category) req.application.businessCategory = this.subCategory || this.category;
        req.application.mcc = this.mcc;
        req.application.industry = this.industry;
        req.application.mccAdditionalDetails = this.mccAdditionalDetails;
        req.application.mccDescription = this.mccDescription;
        break;
      case this.saveSections.BUSINESS_SIZE_SAVE:
        req.application.businessCategory = this.subCategory || this.category;
        break;
      case this.saveSections.BUSINESS_INFORMATION_SAVE:
        req.application.businessInfo = this.businessInfo;
        req.application.applicantInfo = req.application.applicantInfo || this.applicantInfo;
        req.application.businessInfo.legalName = this.businessInfo.legalName;
        this.businessAddresses.legalAddress = this.shouldClearAddress(this.businessAddresses.legalAddress);
        req.application.businessAddresses = this.businessAddresses;

        break;
      case this.saveSections.BUSINESS_STRUCTURE_SAVE:
        req.application.businessInfo = this.businessInfo;
        this.businessAddresses.legalAddress = this.shouldClearAddress(this.businessAddresses.legalAddress);
        this.businessAddresses.mailingAddress = this.shouldClearAddress(this.businessAddresses.mailingAddress);
        req.application.businessAddresses = this.businessAddresses;
        break;
      case this.saveSections.BUSINESS_LOCATION_SAVE:
        req.application.transactionInfo = this.transactionInfo;
        req.application.businessLocations = this.businessLocations;

        req.application.businessLocations.forEach(location => {
          if (location.productShippingAddress) {
            location.productShippingAddress.firstName = this.applicantInfo.name;
            location.productShippingAddress.lastName = this.applicantInfo.lastName;
            location.productShippingAddress.companyName = this.applicantInfo.companyName;
          }
          if (location.bankInfo && location.bankInfo.isEmpty()) {
            location.bankInfo = null;
          }
          if (location.transactionDistribution && location.transactionDistribution.isEmpty()) {
            location.transactionDistribution = null;
          }
        });
        break;
      case this.saveSections.OWNERS_SAVE:
        const ownersToSave = this.owners;
        ownersToSave[0].primaryOwner = true;
        ownersToSave.forEach((owner, index) => {
          if (!this.owners[index].isKeyController) {
            this.owners[index].isKeyController = false;
          }
          owner.percentageOwnership = Number(this.owners[index].percentageOwnership);
        });
        // If “I don’t have a Tax ID. I file under my SSN” is selected,
        // the primary owner’s SSN is stored as the TIN/EIN instead.
        if (this.businessInfo && this.businessInfo.taxId === '' && this.businessInfo.tinType === this.SSN_ONLY) {
          req.application.businessInfo = this.businessInfo;
          req.application.businessInfo.taxId = ownersToSave[0].socialSecurityNumber;
        }
        req.application.businessOwners = ownersToSave;
        req.application.hasPoliticalTies = this.hasPoliticalTies;
        break;
      case this.saveSections.GROUP_ID:
        req.application.groupId = this.groupId;
        break;
      case this.saveSections.CALLBACK_REQUESTED:
        req.application.callBackRequested = this.callBackRequested;
        break;
    }
  }

  // used by assisted onboarding when creating a new application
  public saveNewApplication(): Observable<ApplicationResponse> {
    const sectionsToSave = [
      this.saveSections.CONTACT_INFO_SAVE,
      this.saveSections.BUSINESS_TYPE_SAVE,
      this.saveSections.BUSINESS_SIZE_SAVE,
      this.saveSections.TRANSACTION_INFO_SAVE,
      this.saveSections.GROUP_ID,
    ];
    return this.save(sectionsToSave);
  }

  public saveBusinessType(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.BUSINESS_TYPE_SAVE]);
  }

  public saveCategory(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.BUSINESS_TYPE_SAVE]);
  }

  public saveContactInfo(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.CONTACT_INFO_SAVE]);
  }

  public saveBusinessSize(): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    req.application.businessSize = this.businessSize;
    return this.applicationService.patchApplication(this.id, req);
  }

  public saveGroupId(groupId: string): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    req.application.groupId = groupId;
    return this.applicationService.patchApplication(this.id, req);
  }

  public saveTransactionInfo(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.TRANSACTION_INFO_SAVE]);
  }

  public saveBusinessPurchaseType(purchaseType: number): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    req.application.businessPurchaseType = purchaseType;
    return this.applicationService.patchApplication(this.id, req);
  }

  public savePrimaryLocationDbaName(dbaName: string): Observable<ApplicationResponse> {
    this.businessInfo.dbaName = dbaName;
    this.applicantInfo.companyName = dbaName;

    // setting transactionDistribution to null when empty
    this.setBusinessLocations(this.transactionInfo.numberOfLocations, this.businessLocations);
    this.businessLocations.forEach(businessLocation => {
      if (!businessLocation.transactionDistribution || businessLocation.transactionDistribution.isEmpty()) {
        businessLocation.transactionDistribution = null;
      }
    });

    return this.save([this.saveSections.BUSINESS_INFORMATION_SAVE, this.saveSections.CONTACT_INFO_SAVE]);
  }

  public saveOwnersInformation(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.OWNERS_SAVE]);
  }

  public saveBusinessInformation(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.BUSINESS_INFORMATION_SAVE, this.saveSections.TRANSACTION_INFO_SAVE]);
  }

  // This method will only update single location. Don't use it with multi-location.
  public saveBusinessLocations(): Observable<BusinessLocationModel> {
    return this.updateSubmerchant(this.id, this.businessLocations[0]);
  }

  public saveBankInfo(): Observable<ApplicationResponse> {
    return this.save([this.saveSections.BANK_INFO_SAVE]);
  }

  public submitQuiz(quizRequest): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    return this.applicationService
      .submitQuiz(this.id, quizRequest)
      .pipe(mergeMap(() => this.applicationService.patchApplication(this.id, req)));
  }

  public saveThreatMetrix(): Observable<ApplicationResponse> {
    const req = new ApplicationRequest();
    req.application.threatMetrixGuid = this.threatMetrixGuid;
    return this.applicationService.patchApplication(this.id, req);
  }

  public submitFinalApplication(): Observable<ApplicationResponse> {
    return this.applicationService.submitApplication(this.id, new ApplicationRequest().fromStore(this)).pipe(
      tap((resp: ApplicationResponse) => {
        this.setApplication(resp);
      }),
    );
  }

  public getIsResumeApp(): Observable<boolean> {
    return this.applicationService.getIsResumeApp(this.id);
  }

  /**
   * Send returning email to merchant's location
   *
   * @param businessLocation
   * @param type string
   * @returns Observable<ApplicationResponse>
   */

  // TODO: Not used in miramar
  public sendReturningEmail(businessLocation: BusinessLocationModel, type: string): Observable<ApplicationResponse> {
    const request: ApplicationSaveReturnRequest = {
      email: this.applicantInfo.email,
      phoneNumber: this.applicantInfo.phoneNumber,
      businessLocationId: businessLocation.id,
    };
    return this.applicationService.sendReturningEmail(this.id, request, type);
  }

  public downloadMpa(downloadSignedWhenMultiple: boolean = false) {
    this.applicationService.getMpa(this.id, false, downloadSignedWhenMultiple).subscribe((data: Blob) => {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(file);
      const sanitizer = new ɵDomSanitizerImpl(document);
      sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'MPA.pdf';
      link.click();
    });
  }

  private setApplication(response: ApplicationResponse) {
    this.id = response.id;
    this.merchantId = response.merchantId;
    this.category = response.businessCategory || this.category;
    this.submitAttempts = response.submitAttempts;
    this.progressCode = response.status.progressCode;
    this.hasMultipleDocuments = response.hasMultipleDocuments;
    this.hasGroupedAuthFees = response.hasGroupedAuthFees;

    if (response.businessAddresses) {
      this.businessAddresses = new BusinessAddressesModel(response.businessAddresses);
    }

    this.industry = response.industry;
    if (response.mcc) {
      this.mcc = response.mcc;
    } else {
      this.mcc = '';
    }
    this.mccDescription = response.mccDescription;

    this.partnerReferenceId = response.partnerReferenceId;
    this.hasPoliticalTies = response.hasPoliticalTies;
    this.businessSize = response.businessSize || 'Small';
    this.businessPurchaseType = response.businessPurchaseType || 0;
    this.annualRevenue = response.annualRevenue;
    if (response.transactionInfo) {
      this.transactionInfo = response.transactionInfo;
    }
    this.setBusinessLocations(
      this.transactionInfo.numberOfLocations
        ? this.transactionInfo.numberOfLocations
        : response.businessLocations.length,
      response.businessLocations,
    );
    this.hideLocations = !response.businessLocations.find(
      location => location?.transactionDistribution?.faceToFace > 0,
    );
    if (response.businessInfo) {
      this.businessInfo = response.businessInfo;
      if (this.businessInfo.startDate) this.businessInfo.incDate = this.getOwnerDob(this.businessInfo.startDate);
    }
    if (response.applicantInfo) {
      this.applicantInfo = response.applicantInfo;
    } else {
      this.applicantInfo = new ApplicantInfo();
    }
    this.internalLead = response.internalLead || new InternalLead();
    if (response.businessOwners && response.businessOwners.length > 0) {
      this.owners = response.businessOwners;
      for (const owner of this.owners) {
        owner.dob = owner.dateOfBirth ? this.getOwnerDob(owner.dateOfBirth) : '';
      }
    }
    if (response.bankInfo) {
      this.bankInfo.accountNumber = response.bankInfo.accountNumber;
      this.bankInfo.routingNumber = response.bankInfo.routingNumber;
      this.bankInfo.bankName = response.bankInfo.bankName;
    } else {
      this.bankInfo = new BankInfoModel();
    }
    if (response.orderId) {
      this.orderId = response.orderId;
    }

    if (response.partnerId) {
      this.partnerId = response.partnerId;
    }

    if (response.createdBy) {
      this.createdBy = response.createdBy;
    }

    if (response.createdAt) {
      this.createdAt = response.createdAt;
    }

    if (response.updatedAt) {
      this.updatedAt = response.updatedAt;
    }
    this.source = response.source;
    this.sourceType = response.sourceType;
    this.customerId = response.customerId;
    this.callBackRequested = response.callBackRequested || undefined;

    this.groupId = response.groupId;
    this.mccAdditionalDetails = response.mccAdditionalDetails;
    this.prefilled = response.prefilled;
    this.shouldGetLLCType = response.shouldGetLLCType;
    this.status = response.status;
    this.isLoaded = true;
  }

  public clearApplication() {
    this.id = null;
    this.merchantId = null;
    this.category = null;
    this.submitAttempts = 0;
    this.progressCode = 0;
    this.hasMultipleDocuments = false;
    this.hasGroupedAuthFees = false;
    this.businessAddresses = new BusinessAddressesModel();
    this.industry = null;
    this.mcc = '';
    this.partnerReferenceId = '';
    this.mccDescription = '';
    this.hasPoliticalTies = false;
    this.businessSize = 'Small';
    this.businessPurchaseType = 0;
    this.annualRevenue = 0;
    this.transactionInfo = new TransactionInfo();
    this.businessLocations = [];
    this.businessInfo = new BusinessInfoModel();
    this.applicantInfo = new ApplicantInfo();
    this.internalLead = new InternalLead();
    this.owners = [new BusinessOwner()];
    this.bankInfo = new BankInfoModel();
    this.orderId = '';
    this.partnerId = null;
    this.createdBy = null;
    this.createdAt = null;
    this.mccAdditionalDetails = null;
    this.prefilled = false;
    this.shouldGetLLCType = false;
    this.isLoaded = false;
    this.status = null;
  }

  setBusinessLocations(numberOfLocations: number, _businessLocations: BusinessLocationModel[]): void {
    let n = numberOfLocations;
    if (isNaN(n)) n = 0;
    if (n > 0 && _businessLocations && _businessLocations.length) {
      this.businessLocations = _businessLocations.map(item => new BusinessLocationModel(item));
    }
  }

  public setOrganizationType(code: string) {
    let organization = this.businessInfo.organizationType;
    if (this.organizationTypes) {
      organization = this.organizationTypes.find(org => org.code === code);
    }

    this.businessInfo.organizationType = organization
      ? { code: organization.code, description: organization.description }
      : null;
  }

  private buildInitialStatuses() {
    this.progress = [];
    this.states.forEach(state => {
      this.progress.push(new Progress(state));
    });
  }

  get businessTitles(): Set<string> {
    return new Set<string>(
      this.rules.titles[this.businessInfo.organizationType ? this.businessInfo.organizationType.description : ''],
    );
  }

  get showOwnership(): boolean {
    const index = this.businessInfo.organizationType ? this.businessInfo.organizationType.description : '';
    return this.rules.percentageOwner[index];
  }

  get showAmexAnnualSales(): boolean {
    return this.transactionInfo.creditCardVolume >= constants.annualVolumeCheck;
  }

  get showAmexMemberId(): boolean {
    return this.showAmexAnnualSales && this.transactionInfo.amexAnnualVolume >= constants.annualVolumeCheck;
  }

  get showMccDescription(): boolean {
    if (this.mcc) {
      return this.mcc.slice(-2) === '99';
    }
    return false;
  }

  get ownershipTotal(): number {
    return this.owners.reduce((sum, x) => sum + +(x.percentageOwnership || 0), 0);
  }

  get firstBusinessLocationId(): string {
    return this.businessLocations && this.businessLocations.length && this.businessLocations[0].id;
  }

  private isOwnerPossiblyActive(i: number): boolean {
    if (i <= 0) {
      return true;
    }
    return this.owners[i - 1].moreOwners;
  }

  public isOwnerActive(i: number): boolean {
    // Need to account for the scenario where a user selects YES more owners then fills in ownership
    //  > 75.  This would negate
    return this.isOwnerPossiblyActive(i) && this.ownershipTotal - +(this.owners[i].percentageOwnership || 0) <= 75;
  }

  private getOwnerDob(dob: Date): string {
    if (moment(dob).isValid()) {
      // returns date from api into string formated mm/dd/yyyy
      return moment.utc(dob).format('MM/DD/YYYY');
    } else {
      //It return whatever obfuscation is being returned by the BE
      return dob.toString();
    }
  }

  private shouldClearAddress(address: Address) {
    return address && address.isEmpty() ? null : address;
  }

  private getStates(): ProgressBarState[] {
    return (window as any).progressBar ? (window as any).progressBar : progressBarStates;
  }

  public async deleteLocation(slug?: number) {
    const location =
      slug !== void 0 && slug !== null ? this.businessLocations.splice(slug, 1)[0] : this.businessLocations.pop();
    await this.deleteLocationFromBackEnd(location.id);
  }

  public async deleteLocationFromBackEnd(locationId: string) {
    await lastValueFrom(this.applicationService.deleteLocation(this.id, locationId));
    this.transactionInfo.numberOfLocations = this.businessLocations.length;
    await lastValueFrom(this.save([this.saveSections.TRANSACTION_INFO_SAVE]));
  }

  public updateCampaignInformation(campaignInformation: CampaignInformation): void {
    if (this.createdBy.firstName !== this.GUEST_MERCHANT) return;

    this.applicationService.updateCampaignInformation(this.id, campaignInformation).subscribe(
      () => {},
      error => `Error setting campaign information: ${error.message}`,
    );
  }

  public async updateDdaInterestDisclosure(appId: string, state: DDAInterestStatus): Promise<void> {
    return lastValueFrom(this.applicationService.patchDdaInterestDisclosure(appId, state));
  }
}
