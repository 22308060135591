import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { reaction } from 'mobx';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ANSWER_TYPES, commonErrors } from '@eventhorizon/constants/general.constants';
import { AvailableAnswer, Question } from '@eventhorizon/models/risk.verification.model';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-risk-verification',
  templateUrl: './risk-verification.component.html',
  styleUrls: ['./risk-verification.component.scss'],
})
export class RiskVerificationComponent extends FormCarouselSlide<FormGroup<IRiskVerificationForm>> implements OnInit {
  public errorMessage: string;

  public quizQuestions = [];

  public questionsControls = new FormArray<FormGroup<IQuestionForm>>([]);

  public quizLoaded: boolean;

  public quizCompleted = false;

  public ANSWER_TYPES = ANSWER_TYPES;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: FormBuilder,
    private applicationService: ApplicationService,
  ) {
    super(bsModalService, cd);
    reaction(
      () => !!this.store.isLoaded && !!this.store,
      () => {
        this.onOpen();
      },
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  private sortQuestions(questions: Question[]): Question[] {
    // Sort questionDetails if present
    questions.forEach(question => {
      if (question.valueDetails) question.valueDetails.sort((d1, d2) => (d1.sortOrder < d2.sortOrder ? -1 : 1));
    });

    // Sort and return sorted questions
    return questions.sort((q1, q2) => (q1.sequenceNumber < q2.sequenceNumber ? -1 : 1));
  }

  private setQuestions(questions: Question[]) {
    this.quizQuestions = questions;
    this.quizQuestions.forEach(question => {
      this.addQuestion(question);
    });
    this.quizLoaded = true;
  }

  public onOpen() {
    super.onOpen();
    if (!this.store.id) return;
    this.applicationService.getRiskQuiz(this.store.id).subscribe({
      next: response => {
        this.quizCompleted = response.complete;
        this.setQuestions(this.sortQuestions(response.questions));
      },
      error: () => {
        this.errorMessage = 'Failed to retrieve questions.';
        this.quizLoaded = true;
      },
    },
    );
  }

  addQuestion(question: Question) {
    let questionFormGroup: IQuestionForm;
    if (question.answerType === ANSWER_TYPES.FREE_FORM) {
      questionFormGroup = {
        answerValue: new FormControl<string>(question.selectedAnswerValue, Validators.required),
        questionId: new FormControl<string>(question.id, Validators.required),
      };
    } else if (
      question.answerType === ANSWER_TYPES.SINGLE ||
      question.answerType === ANSWER_TYPES.SINGLE_DROPDOWN ||
      question.answerType === ANSWER_TYPES.MULTIPLE ||
      question.answerType === ANSWER_TYPES.MULTIPLE_DROPDOWN
    ) {
      questionFormGroup = {
        answerIds: new FormControl<string[]>(question.selectedAnswerIds || [], Validators.required),
        questionId: new FormControl<string>(question.id, Validators.required),
      };
    }
    if (questionFormGroup) this.questionsControls.push(this.fb.group(questionFormGroup));
  }

  private addOrRemoveAnswer(currentAnswers: string[], answerId: string): string[] {
    const newAnswersArray = currentAnswers.filter(ans => ans !== answerId);
    if (newAnswersArray.length === currentAnswers.length) {
      newAnswersArray.push(answerId);
    }
    return newAnswersArray;
  }

  setSingleAnswer(questionForm: FormGroup<IQuestionForm>, answerId: string) {
    questionForm.controls.answerIds.setValue([answerId]);
  }

  setMultipleAnswer(questionForm: FormGroup<IQuestionForm>, answerId: string) {
    questionForm.controls.answerIds.setValue(this.addOrRemoveAnswer(questionForm.controls.answerIds.value, answerId));
  }

  public async save(): Promise<boolean> {
    try {
      if (this.quizCompleted) return true;
      const request = this.form.controls.questions.value.map(questionData =>
        questionData.answerIds
          ? questionData
          : {
            questionId: questionData.questionId,
            value: questionData.answerValue,
          },
      );
      const response = await lastValueFrom(this.applicationService.submitRiskQuiz(this.store.id, request));

      if (response.complete) {
        this.quizCompleted = true;
        return this.quizCompleted;
      }
      this.buildForm();
      this.setQuestions(this.sortQuestions(response.questions));
      return false;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  private buildForm() {
    this.form = this.fb.group({
      questions: this.questionsControls,
    });
    this.quizLoaded = false;
  }

  isOptionSelected(formG: FormGroup<IQuestionForm>, id: string) {
    return formG.value.answerIds && formG.value.answerIds.filter(e => e === id).length;
  }

  getSelectedAnswerValue(formG: FormGroup<IQuestionForm>, availableAnswers: AvailableAnswer[]) {
    return availableAnswers
      .filter(availableAnswer => formG.value.answerIds.indexOf(availableAnswer.id) >= 0)
      .map(ans => ({
        id: ans.id,
        value: ans.value,
      }));
  }
}

export interface IRiskVerificationForm {
  questions: FormArray<FormGroup<IQuestionForm>>;
}

export interface IQuestionForm {
  answerValue?: FormControl<string>;
  answerIds?: FormControl<string[]>;
  questionId: FormControl<string>;
}