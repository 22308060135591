import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AddressComponent, IAddressForm } from '@eventhorizon/components/address/address.component';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ShippingLocations } from '@eventhorizon/models/shipping-locations.model';
import { masks } from '@eventhorizon/data/masks.data';
import { messages } from '@eventhorizon/data/messages.data';
import { randomComponentId } from '@eventhorizon/utils/util';
import { touchAndValidate } from '@eventhorizon/utils/forms';
import { defaultImage } from '@eventhorizon/constants/general.constants';

@Component({
  selector: 'app-shipping-location',
  styleUrls: ['./shipping-location.component.scss'],
  templateUrl: './shipping-location.component.html',
})
export class ShippingLocationComponent implements OnInit, AfterViewInit {
  @ViewChild('focus') public focus: ElementRef;

  @Input()
    shippingLocation: ShippingLocations = new ShippingLocations();

  @Input()
  public isAssisted = false;

  @Input()
  public shippingLocations: ShippingLocations[] = [];

  @Output()
    isPrimaryChanged = new EventEmitter<boolean>();

  @ViewChild(AddressComponent)
  private personalAddress: AddressComponent;

  public errorMessage: string;

  public form: FormGroup<IShippingLocationForm>;

  public masks = masks;

  public messages = messages.ownerInformationSlide;

  public id = randomComponentId();

  public firstName: MobxFormControl<string>;

  public lastName: MobxFormControl<string>;

  public companyName: MobxFormControl<string>;

  public defaultImage = defaultImage;

  constructor(
    public store: ApplicationStore,
    public cartStore: CartStore,
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    if (this.personalAddress) this.form.setControl('personalAddress', this.personalAddress?.buildForm());
  }

  @Input()
  set readOnly(value: boolean) {
    this.personalAddress.readonly = value;
  }

  get readonly(): boolean {
    return this.personalAddress.readonly;
  }

  public onOpen() {
    this.cd.detectChanges();
  }

  public validate() {
    touchAndValidate(this.form);
    this.cd.detectChanges();
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public isDirty(): boolean {
    return this.form.dirty;
  }

  public isTouched(): boolean {
    return this.form.touched;
  }

  public allDirty(): boolean {
    for (const control in this.form.controls) {
      const actualControl = this.form.get(control);

      if (!actualControl.dirty) {
        return false;
      }
    }
    return true;
  }

  private buildForm(): FormGroup<IShippingLocationForm> {
    if (this.form) {
      return this.form;
    }

    this.firstName = new MobxFormControl<string>(
      'firstName',
      () => this.shippingLocation.firstName,
      (v: string) => {
        this.shippingLocation.firstName = v;
      },
      Validators.required,
    );
    this.lastName = new MobxFormControl<string>(
      'lastName',
      () => this.shippingLocation.lastName,
      (v: string) => {
        this.shippingLocation.lastName = v;
      },
      Validators.required,
    );
    this.companyName = new MobxFormControl<string>(
      'companyName',
      () => this.shippingLocation.companyName,
      (v: string) => {
        this.shippingLocation.companyName = v;
      },
      Validators.required,
    );
    this.form = this.fb.group({
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      personalAddress: this.personalAddress?.buildForm() || null,
    });
    return this.form;
  }
}

export interface IShippingLocationForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  companyName: FormControl<string>;
  personalAddress: FormGroup<IAddressForm>; //It's defined when AddressComponent renders
}
