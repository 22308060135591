import { Injectable } from '@angular/core';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveStatusService {
  public savingStatus = {
    saving: false,
    justSaved: false,
  };

  private action$ = timer(1000);

  public initSave() {
    this.savingStatus.saving = true;
  }

  public isSaveInProgress() {
    return this.savingStatus.saving || this.savingStatus.justSaved;
  }

  public noSavingInProgress() {
    return !this.savingStatus.saving && !this.savingStatus.justSaved;
  }

  public isSaving() {
    return this.savingStatus.saving;
  }

  public isJustSaved() {
    return this.savingStatus.justSaved;
  }

  public cancelSave() {
    this.savingStatus.saving = false;
  }

  public finalizeSavingStatus() {
    this.savingStatus.saving = false;
    this.savingStatus.justSaved = true;
    this.action$.subscribe(() => (this.savingStatus.justSaved = false));
  }
}
