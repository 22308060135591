import { Component, Input, OnInit } from '@angular/core';
import { defaultImage } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-tg-product-detail-images',
  templateUrl: './product-detail-images.component.html',
  styleUrls: ['./product-detail-images.component.scss'],
})
export class ProductDetailImagesComponent implements OnInit {
  @Input() alt = '';

  @Input() productImagesURLs: string[] = [];

  @Input() showThumbnails = true;

  private imagesURL: string[];

  private thumbnailsURL: string[];

  private selected = 0;

  ngOnInit() {
    this.pairImageUrls(this.productImagesURLs);
  }

  get largeImageUrls(): string[] {
    return this.imagesURL;
  }

  get thumbImageUrls(): string[] {
    return this.thumbnailsURL;
  }

  get selectedIndex(): number {
    return this.selected;
  }

  set selectedIndex(index: number) {
    this.selected = index;
  }

  private pairImageUrls(imageUrls: string[] = []) {
    this.imagesURL = [];
    this.thumbnailsURL = [];
    let erroring = false;
    imageUrls.forEach(url => {
      if (url.indexOf('large') === url.lastIndexOf('large')) {
        if (url.indexOf('large') > 0) {
          this.imagesURL.push(url);
          this.thumbnailsURL.push(url.replace('large', 'thumb'));
        }
      } else {
        erroring = true;
      }
    });

    if (erroring || !this.imagesURL.length) {
      this.imagesURL = imageUrls;
      this.thumbnailsURL = imageUrls;
    }

    if (this.imagesURL.length === 0) {
      this.imagesURL.push(defaultImage);
    }
  }

  public imageShown(index: number) {
    this.selected = index;
  }

  public isSelected(index: number): boolean {
    return this.selected === index;
  }
}
