import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationGuard implements CanActivate {
  constructor(
    private appStore: ApplicationStore,
    private routingPathService: RoutingPathService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!this.appStore.progressCode) {
      await lastValueFrom(this.appStore.loadApplication());
    }

    const currentProgress = this.appStore.progressCode;
    const targetRouteProgress = this.routingPathService.getRouteProgress(route.routeConfig.path);

    if (this.appStore.id && currentProgress >= targetRouteProgress) {
      return true;
    }

    if (this.appStore.hideLocations) {
      return true;
    }

    const progressRoute = this.routingPathService.getRouteFromProgress(this.appStore.progressCode);

    if (route.routeConfig.path != progressRoute) {
      this.router.navigate([progressRoute]);
    }

    return false;
  }
}
