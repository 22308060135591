export const routes = {
  GET_STARTED: 'get-started',
  CONTACT_INFO: 'contact-info',
  INDUSTRY_CATEGORY: 'industry-category',
  INDUSTRY_SUBCATEGORY: 'industry-subcategory',
  INDUSTRY_MCC: 'industry-mcc',
  BUSINESS_STRUCTURE: 'business-structure',
  BUSINESS_INFO: 'business-info',
  LOCATION_INFO: 'location-info',
  LOCATION_PRODUCTS: 'location-products',
  LOCATION_ADDONS: 'location-addons',
  LOCATIONS: 'locations',
  REVIEW_CART: 'review-cart',
  OWNERS: 'owners',
  SHIPPING: 'shipping',
  FINAL_REVIEW: 'final-review',
  AGREEMENT: 'agreement',
  APPLICATION_CONFIRMATION: 'application-confirmation',
};

export const progressBarStates = [
  {
    section: 'Your Needs',
    page: '/' + routes.CONTACT_INFO,
    states: [
      {
        state: 'Contact Information',
        page: '/' + routes.CONTACT_INFO,
        active: true,
      },
      {
        state: 'Industry',
        page: '/' + routes.INDUSTRY_CATEGORY,
        active: true,
        subPages: [
          '/' + routes.INDUSTRY_SUBCATEGORY,
          '/' + routes.INDUSTRY_MCC,
        ],
      },
    ],
  },
  {
    section: 'Your Application',
    page: '/' + routes.BUSINESS_STRUCTURE,
    states: [
      {
        state: 'Business',
        page: '/' + routes.BUSINESS_STRUCTURE,
        active: true,
        subPages: [
          '/' + routes.BUSINESS_INFO,
        ],
      },
      {
        state: 'Locations',
        page: '/' + routes.LOCATIONS,
        active: true,
        subPages: [
          '/' + routes.LOCATION_INFO,
          '/' + routes.LOCATION_PRODUCTS,
          '/' + routes.LOCATION_ADDONS,
        ],
      },
      {
        state: 'Review Cart',
        page: '/' + routes.REVIEW_CART,
        active: true,
      },     
      {
        state: 'Owners',
        page: '/' + routes.OWNERS,
        active: true,
      },
    ],
  },
  {
    section: 'Review & Submit',
    page: '/' + routes.SHIPPING,
    states: [
      {
        state: 'Shipping',
        page: '/' + routes.SHIPPING,
        active: true,
      },
      {
        state: 'Final Review',
        page: '/' + routes.FINAL_REVIEW,
        active: true,
      },
      {
        state: 'Agreement',
        page: '/' + routes.AGREEMENT,
        active: true,
      },
    ],
  },
];

export interface ProgressBarState {
  section: string;
  page: string;
  lastPage: string;
  states: { state: string; page: string }[];
}