import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ContentBase } from '../base-content/content.base';
import { UUID } from 'angular2-uuid';
import { Content, Slide } from '@eventhorizon/models/page.model';

@Component({
  selector: 'app-tg-slide-carousel',
  templateUrl: './slide-carousel.component.html',
  styleUrls: ['./slide-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlideCarouselComponent extends ContentBase implements OnInit {
  @Input() slides: Slide[];

  @Input() showIndicators: boolean = true;

  @Input() itemsPerSlide: number = 1;

  @Input() shouldAutoplay: boolean = false;

  @Input() linkClickable: boolean = true;

  @Input() showDescription: boolean = true;

  @Input() showLinkArrow: boolean = true;

  @Output() clickAction: EventEmitter<any> = new EventEmitter();

  public uniqueId = UUID.UUID();

  public loadedConfigs = false;

  public prevId = '';

  public nextId = '';

  public dotsId = '';

  private slideConfig: any = {
    dots: this.showIndicators,
    slidesToShow: this.itemsPerSlide,
    slidesToScroll: 1,
    infinite: this.shouldAutoplay,
    autoplay: this.shouldAutoplay,
    customPaging: function () {
      return '<i class="fas fa-circle"></i>';
    },
    responsive: [],
    accessibility: true,
  };

  public loadedScript = false;

  ngOnInit(): void {
    this.loadScript('//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.min.js');
    this.setControlsClasses();
    this.setItemsPerSlide();
  }

  private loadScript(script: string): void {
    // Load the script

    let scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = script;
    scriptElement.onload = () => {
      this.loadedScript = true;
    };

    scriptElement.onerror = () => {
      console.error("Couldn't load script " + script);
    };

    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  }

  /**
   * Setting classes for the controls and dots.
   */
  private setControlsClasses(): void {
    this.prevId = 'prev-' + this.uniqueId;
    this.nextId = 'next-' + this.uniqueId;
    this.dotsId = 'carousel-dots-' + this.uniqueId;
    this.slideConfig.prevArrow = `#${this.prevId}`;
    this.slideConfig.nextArrow = `#${this.nextId}`;
    this.slideConfig.appendDots = `#${this.dotsId}`;
  }

  /**
   * Used to set the items per slide display
   * per media breakpoint if the carousel has
   * multislides.
   */
  private setItemsPerSlide(): void {
    this.slideConfig.slidesToShow = this.itemsPerSlide;

    // If multislide.
    if (this.itemsPerSlide > 1) {
      // Do not show dots if items per slide
      // same as slides length.
      if (this.itemsPerSlide === this.slides.length) {
        this.slideConfig.dots = false;
      } else {
        this.slideConfig.dots = true;
      }

      // If multislides, set slides to show per
      // breakpoint.
      this.slideConfig.responsive = [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ];
    }
    // To init the carousel with the correct configurations.
    this.loadedConfigs = true;
  }

  public setContent(content: Content) {
    this.slides = content.slides;
    this.showIndicators = true;
    this.itemsPerSlide = content.itemsPerSlide;
    this.shouldAutoplay = content.shouldAutoplay;
  }
}

export default SlideCarouselComponent;
