import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormRecord, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction } from 'mobx';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { randomComponentId } from '@eventhorizon/utils/util';
import {
  BusinessLocationComponent,
  IBusinessLocationForm,
} from '@eventhorizon/components/business-location/business-location.component';
import { IAddressForm } from '../address/address.component';

@Component({
  selector: 'app-tg-business-addresses',
  templateUrl: './base-business-addresses.component.html',
  styleUrls: ['./base-business-addresses.address.component.scss'],
})
export class BaseBusinessAddressesComponent implements OnInit, AfterViewInit {
  public id = randomComponentId();

  public form: FormRecord<FormControl<string> | FormGroup<IBusinessLocationForm>>;

  public selectedAddressTab = 1;

  public isPrimarySelected = true;

  protected primarySelected: MobxFormControl<string>;

  public errorMessage: string[];

  protected isSlideOpen = false;

  private MAX_LOCATIONS = 5;

  @Input() isAssisted = false;

  @Input() public displayLocationDBA: boolean = true;

  @ViewChildren('locationAddress')
  private locationAddresses: QueryList<BusinessLocationComponent>;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected routingService: RoutingPathService,
  ) {}

  ngOnInit() {
    this.setActiveTab(1);
    this.buildForm();

    reaction(
      () =>
        !!this.store.isLoaded &&
        !!this.store.transactionInfo &&
        !!this.store.businessAddresses &&
        this.store.transactionInfo.numberOfLocations,
      (numberOfLocations: number) => {
        this.syncLocations(numberOfLocations);
      },
    );
  }

  ngAfterViewInit() {
    if (this.store.transactionInfo) this.syncLocations(this.store.transactionInfo.numberOfLocations);
    this.setActiveTab(1);
    this.wireLocationAddresses();
    this.locationAddresses.changes.subscribe(() => {
      this.wireLocationAddresses();
      this.cd.detectChanges();
    });
    this.cd.detectChanges();
  }

  private wireLocationAddresses() {
    this.isPrimarySelected = this.locationAddresses.length <= 1;
    this.locationAddresses.map((la, i) => {
      this.form.setControl(`locationAddress${i}`, la.form);
      const bl = this.store.businessLocations[i];
      if (bl) this.isPrimarySelected = this.isPrimarySelected || bl.isPrimary;
    });
  }

  private syncLocations(numberOfLocations: number) {
    const s = this.store;
    if (!s || !this.store.isLoaded) return;
    if (!s.businessLocations) s.businessLocations = [];
    while (s.businessLocations.length < numberOfLocations) s.businessLocations.push(new BusinessLocationModel());
    if (numberOfLocations < s.businessLocations.length) {
      s.businessLocations = s.businessLocations.slice(0, numberOfLocations);
    }
  }

  private buildForm() {
    this.primarySelected = new MobxFormControl<string>(
      'primarySelected',
      () => (this.isPrimarySelected ? 'true' : 'false'),
      (v: string) => {
        this.isPrimarySelected = !!v;
      },
      this.isAssisted ? undefined : Validators.required,
    );

    const f: any = {
      primarySelected: this.primarySelected,
    };
    let numCount = this.MAX_LOCATIONS;
    if (this.store.businessLocations && this.store.businessLocations.length) {
      numCount = this.store.businessLocations.length;
    }
    for (let i = 0; i < numCount; i += 1) {
      f[`locationAddress${i}`] = [
        new FormGroup<IBusinessLocationForm>({
          address: new FormGroup<IAddressForm>({
            address1: new FormControl<string>(''),
            address2: new FormControl<string>(''),
            city: new FormControl<string>(''),
            state: new FormControl<string>(''),
            zip: new FormControl<string>(''),
          }),
          isPrimary: new FormControl<boolean>(false),
          sameAsLegal: new FormControl<boolean>(false),
        }),
        [],
      ];
    }
    this.form = this.fb.record(f);
  }

  public setActiveTab(i: number) {
    this.selectedAddressTab = i;
  }

  public primaryChanged() {
    this.isPrimarySelected = false;
    this.store.businessLocations.map(l => {
      this.isPrimarySelected = this.isPrimarySelected || l.isPrimary;
    });
    this.primarySelected.setValue(this.isPrimarySelected || 'false');
  }
}
