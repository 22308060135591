import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ShippingLocationComponent } from '@eventhorizon/components/shipping-location/shipping-location.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IProductShippingLocationForm,
  ProductShippingLocationsController,
} from '@eventhorizon/controllers/product-shipping-locations.controller';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Address, ShippingLocations } from '@xup-payments/xup-frontend-utils/models';
import { formatErrorAsList } from '@xup-payments/xup-frontend-utils/utils';

@Component({
  selector: 'app-tg-product-shipping-locations-base',
  templateUrl: './product-shipping-locations-base.component.html',
  styleUrls: ['./product-shipping-locations-base.component.scss'],
})
export class ProductShippingLocationsBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(ShippingLocationComponent)
  protected shippingLocations: QueryList<ShippingLocationComponent>;

  @ViewChild(TabsetComponent)
  @Output()
    refreshForm = new EventEmitter();

  protected tabSet: TabsetComponent;

  public isLoading = true;

  public errorMessage: string[] = [];

  public form: FormGroup<IProductShippingLocationForm>;

  public ownerIndexes: number[] = [];

  public addresses: Map<string, Address> = new Map();

  public selectedAddress$: Observable<string>;

  constructor(
    public store: ApplicationStore,
    protected router: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    public locationsController: ProductShippingLocationsController,
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.selectedAddress$ = this.locationsController.selectedAddress$;
    if (!this.store.businessLocations[0].productShippingAddress) {
      this.store.businessLocations[0].productShippingAddress = new ShippingLocations();
    }
    this.ownerIndexes = this.locationsController.loadInfo(this.addresses);
    if (this.ownerIndexes.length > 1) {
      this.filterOwnersAddresses();
    }

    this.form = this.locationsController.buildForm();
    const emitter = this.refreshForm;

    this.form.valueChanges.subscribe(() => {
      emitter.emit(this.form);
    });
  }

  async ngOnDestroy() {
    this.ownerIndexes = [];
    this.addresses.clear();
    this.locationsController.resetController();
  }

  ngAfterViewInit() {
    this.shippingLocations.changes.subscribe(() => {
      this.locationsController.buildPropertyForm(this.shippingLocations, this.form);
      this.cd.detectChanges();
    });
    this.locationsController.buildPropertyForm(this.shippingLocations, this.form);
    this.cd.detectChanges();
  }

  public async save(): Promise<boolean> {
    let valid = true;

    this.shippingLocations.forEach((shippingLocation, index) => {
      if (index === 0 || shippingLocation.isTouched()) {
        shippingLocation.validate();
      }

      if (!shippingLocation.isValid() && valid) {
        valid = false;
      }
    });

    if (valid) {
      try {
        this.isLoading = true;
        await this.locationsController.saveStoreChanges();
        this.cd.detectChanges();
      } catch (error) {
        // Not using format error as checkout errors need to be printed a list with items and errorFormat function
        // returns the errors space separated
        this.errorMessage = formatErrorAsList(error);
        this.isLoading = false;
        this.cd.detectChanges();
        return false;
      }
    }
    return valid;
  }

  private filterOwnersAddresses() {
    const ownersAddressesArray = [];
    this.addresses.forEach((value: Address, key: string) => {
      if (key.includes('Owner')) {
        const currAddress = this.locationsController.getAddressFormatted(value).toLocaleLowerCase();
        if (!ownersAddressesArray.includes(currAddress)) {
          ownersAddressesArray.push(currAddress);
        } else {
          this.addresses.delete(key);
        }
      }
    });
  }

  public selectAddress(name: string, index?: number) {
    this.locationsController.selectAddress(name, this.shippingLocations, this.addresses, index);
    this.form.updateValueAndValidity();
  }
}
