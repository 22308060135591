import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInterface } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-status-popup',
  templateUrl: './general-action-popup.component.html',
  styleUrls: ['./general-action-popup.component.scss'],
})
export class GeneralActionPopUpComponent implements ModalInterface, OnDestroy {
  title: string;

  subtitle?: string;

  action?: string;

  content?: TemplateRef<any>;

  showClose?: boolean;

  public takeAction = false;

  constructor(private modalRef: BsModalRef) {}

  public ngOnDestroy() {
    this.close();
  }

  public close(takeAction?: boolean): void {
    this.takeAction = takeAction;
    this.modalRef.hide();
  }
}
