import { Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationSlideText, messages } from '@xup-payments/xup-frontend-utils/models';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';

@Component({
  selector: 'app-contact-info-screen',
  templateUrl: './contact-info-screen.component.html',
  styleUrls: ['./contact-info-screen.component.scss'],
})
export class ContactInfoScreenComponent extends BasePageDriverComponent {
  public messages: ApplicationSlideText = messages.contactInfoSlide;

  public showCart = false;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    private contentService: ContentServiceService,
  ) {
    super(rs, router, route);

    this.configureText();
  }

  private configureText() {
    const config = this.contentService?.bankInfoConfig?.contactInfoConfig || null;

    if (config) {
      this.messages.smallHeadline = config.smallHeadline || this.messages.smallHeadline;
      this.messages.subText = config.subText || this.messages.subText;

      this.messages.smallHeadlineAlign = config.smallHeadlineAlign;
      this.messages.subTextAlign = config.subTextAlign;

      this.showCart = config.displayCart;
    }
  }
}
