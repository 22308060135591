import { Component } from '@angular/core';
import { ContentBase } from '@app/pages/content-page/content-module/content-components/base-content/content.base';
import { Content } from '@eventhorizon/models/page.model';

@Component({
  selector: 'app-icon-text-block',
  templateUrl: './icon-text-block.component.html',
  styleUrls: ['./icon-text-block.component.scss'],
})
export class IconTextBlockComponent extends ContentBase {
  public iconClass: string;

  public heading: string;

  public text: string;

  setContent(content: Content) {
    this.iconClass = content.iconClass;
    this.text = content.text;
    this.heading = content.heading;
  }
}

export default IconTextBlockComponent;
