import { Injectable } from '@angular/core';
import { DateFilter } from '@xup-payments/xup-frontend-utils/models';
import moment from 'moment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateFilterService {
  private dateFilterKey = '$xup$dateFilterID';

  private dateFilterRange = '$xup$dateFilterRange';

  public readonly DATEFILTER_RANGE = -1;

  public readonly DATEFILTER_TODAY = 0;

  public readonly DATEFILTER_WEEK = 1;

  public readonly DATEFILTER_MONTH = 2;

  public readonly DATEFILTER_YEAR = 3;

  public filters: DateFilter[] = [
    {
      id: 0,
      title: 'Today',
      startDate: () => moment().startOf('day').utc().format(),
      endDate: () => moment().utc().format(),
    },
    {
      id: 1,
      title: 'This Week',
      startDate: () => moment().startOf('week').utc().format(),
      endDate: () => moment().utc().format(),
    },
    {
      id: 2,
      title: 'This Month',
      startDate: () => moment().startOf('month').utc().format(),
      endDate: () => moment().utc().format(),
    },
    {
      id: 3,
      title: 'Year to Date',
      startDate: () => moment().startOf('year').utc().format(),
      endDate: () => moment().utc().format(),
    },
  ];

  private currentFilterSource = new Subject<DateFilter>();

  public currentFilterStream = this.currentFilterSource.asObservable();

  private fromRange: Date[] | string[] = null;

  public constructor() {
    // Check if filter id is already set before defaulting to 'This Month'
    let filterId = this.currentFilter();
    if (!filterId) {
      sessionStorage.setItem(this.dateFilterKey, this.DATEFILTER_MONTH.toString());
    }
  }

  currentFilterId(newId?: number, dateRange?: Date[]): number {
    // id passsed - set it
    if (typeof newId === 'number') {
      if (newId === this.DATEFILTER_RANGE) {
        sessionStorage.setItem(this.dateFilterKey, newId.toString());
        if (dateRange) {
          this.fromRange = dateRange;
          sessionStorage.setItem(this.dateFilterRange, JSON.stringify([dateRange[0].toJSON(), dateRange[1].toJSON()]));
        }
      } else {
        if (this.fromRange) {
          this.fromRange = null;
        }
        sessionStorage.removeItem(this.dateFilterRange);
        sessionStorage.setItem(this.dateFilterKey, newId.toString());
      }
      return newId;
    }

    // no id passed - get it from storage
    const id = sessionStorage.getItem(this.dateFilterKey);
    return id ? parseInt(id, 10) : this.DATEFILTER_RANGE;
  }

  currentFilter(): DateFilter {
    const id = this.currentFilterId();
    if (id === this.DATEFILTER_RANGE) {
      if (!this.fromRange) {
        const fromStorage = sessionStorage.getItem(this.dateFilterRange);
        this.fromRange = fromStorage ? JSON.parse(fromStorage) : null;
      }
      return this.fromRange
        ? {
          id: this.DATEFILTER_RANGE,
          title: 'Range',
          startDate: () => moment(this.fromRange[0]).startOf('day').utc().format(),
          endDate: () => moment(this.fromRange[1]).endOf('day').utc().format(),
        }
        : null;
    }
    if (id > this.DATEFILTER_RANGE) {
      return this.filters.find(f => f.id === id);
    }
    return null;
  }

  public getResetFilter(): DateFilter {
    return this.filters.find(({ id }) => id === this.DATEFILTER_MONTH);
  }

  public getRange() {
    if (!this.fromRange) {
      const fromStorage = sessionStorage.getItem(this.dateFilterRange);
      try {
        this.fromRange = fromStorage ? JSON.parse(fromStorage) : null;
      } catch {
        this.fromRange = null;
      }
    }

    return this.fromRange;
  }

  public resetbroadcastDate(): void {
    this.currentFilterSource.next(this.getResetFilter());
  }

  public broadcastDateChange(): void {
    this.currentFilterSource.next(this.currentFilter());
  }
}
