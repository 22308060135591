import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CartController {
  totalCost(cart, estimatedTaxRate) {
    if (cart.taxAmount !== 0) {
      return cart.totalAmount;
    }

    return cart.totalAmount + estimatedTaxRate;
  }
}
