import { Component } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { messages } from '@eventhorizon/data/messages.data';

@Component({
  selector: 'app-verification-screen',
  templateUrl: './risk-verification-screen.component.html',
})
export class RiskVerificationScreenComponent extends BasePageDriverComponent {
  messages = messages;

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router);
  }
}
