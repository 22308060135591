import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'general-action-modal',
  templateUrl: './general-action-modal.component.html',
  styleUrls: ['./general-action-modal.component.scss'],
})
export class GeneralActionModalComponent implements OnDestroy {
  title?: string;

  content?: TemplateRef<any>;

  actionButtons? = [];

  actionTaken: string;

  templateContext?;

  constructor(private modalRef: BsModalRef) {}

  ngOnDestroy() {
    this.close();
  }

  close(action?): void {
    this.actionTaken = action;
    this.modalRef.hide();
  }
}
