import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatHandlerService {
  private readonly chatboxSelector = 'web-connect-wrapper';

  private readonly configKey = '$kb$_partnerApplicationSettings';

  private chatElement: HTMLElement;

  private elementCounter = 0;

  public setDisplayMode(displayMode: string | 'block' | 'none' = 'block') {
    let settings: any = this.getSettings();
    settings.chatDisplayMode = displayMode;
    sessionStorage.setItem(this.configKey, JSON.stringify(settings));
    this.setChatboxDisplayMode();
  }

  private getDisplayMode() {
    const settings = this.getSettings();
    return settings.chatDisplayMode || 'block';
  }

  private getSettings() {
    return JSON.parse(sessionStorage.getItem(this.configKey)) || {};
  }

  public get displayChat() {
    return this.getDisplayMode() === 'block';
  }

  private getChatboxElement() {
    // Try 10 times before failing gracefully
    if (this.elementCounter < 10) {
      setTimeout(() => {
        this.elementCounter++;
        this.chatElement = document.getElementById(this.chatboxSelector);
        this.setChatboxDisplayMode();
      }, 100);
    } else {
      console.log('Chatbox is not defined');
      return;
    }
  }

  public setChatboxDisplayMode() {
    try {
      if (!(this.chatElement instanceof HTMLElement)) {
        this.getChatboxElement();
        return;
      }
      this.chatElement.style.display = this.getDisplayMode();
    } catch (error) {
      console.error('Chatbox is not defined', error);
    }
  }
}
