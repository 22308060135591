<app-container *mobxAutorun>
  <app-application-page
    [index]="2"
    [subIndex]="0"
    [text]="messages.businessInfoSlide"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-business-info #innerSlide [displayLocationDBA]="false" (prev)="prev()" (next)="next()"></app-tg-business-info>
  </app-application-page>
</app-container>
