import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs/operators';
import { Product } from '@xup-payments/xup-frontend-utils/models';
import { goToTop } from '@xup-payments/xup-frontend-utils/utils';
import { SoftwareDetailsModalComponent } from '../software-details-modal/software-details-modal.component';

@Component({
  selector: 'app-software-option',
  templateUrl: './software-option.component.html',
  styleUrls: ['./software-option.component.scss'],
})
export class SoftwareOptionComponent implements OnInit {
  @Input() product: Product;

  public isRecommended = false;

  constructor(
    public cartStore: CartStore,
    protected appStore: ApplicationStore,
    protected bsModalService: BsModalService,
  ) {}

  get isProductInCart() {
    return !!this.cartStore.cart.products.find(prod => prod.productId === this.product?.productId);
  }

  ngOnInit() {
    goToTop();
  }

  public async addToCart() {
    await this.cartStore.updateSoftwareProducts(this.appStore.firstBusinessLocationId, this.product);
  }

  showDetailsModal(product) {
    const initialState = {
      title: product.name || product.productName,
      price: product.price,
      description: product.productLongDescription,
      priceDescription: product.pricePerAdditionalDevice,
    };
    const modalRef: BsModalRef = this.bsModalService.show(SoftwareDetailsModalComponent, {
      initialState,
      ariaLabelledBy: 'modal-title',
    });
    modalRef.onHide
      .pipe(
        map(() => {
          const addToCart: boolean = modalRef.content?.addToCart;
          if (addToCart) {
            this.addToCart();
          }
        }),
        take(1),
      )
      .subscribe();
  }
}
