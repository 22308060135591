import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { Progress, ProgressState } from '@eventhorizon/models/progress.model';
import { IReactionDisposer, reaction } from 'mobx';



@Component({
  selector: 'app-vertical-progress-wizard',
  templateUrl: './vertical-progress-wizard.component.html',
  styleUrls: ['./vertical-progress-wizard.component.scss'],
})

export class VerticalProgressWizardComponent implements OnInit, OnDestroy {
  public progressConfig: Progress[];

  public currentPageProgressCode: number;

  public currentStepSelected: string;

  private storeProgressCodeReactionDisposer: IReactionDisposer | null = null;

  constructor(
    public router: Router,
    protected store: ApplicationStore,
    protected routingService: RoutingPathService,
  ) {
    this.progressConfig = store.progress ? store.progress : [];
    this.currentPageProgressCode = this.routingService.getRouteProgress(this.router.url);
  }

  ngOnInit(): void {
    // whenever the store's progress code changes, rebuild progress bar
    this.storeProgressCodeReactionDisposer = reaction(
      () => this.store.progressCode,
      () => this.buildProgressBar(),
    );
    this.buildProgressBar();
  }

  public buildProgressBar() {
    this.initializeSteps();
    this.markStepInProgress();
    this.markCompletedSteps();
    this.markCurrentSelectedStep();
  }

  public initializeSteps() {
    for (let section of this.progressConfig) {
      for (let step of section.states) {
        step.progress = this.routingService.getRouteProgress(step.page);
        step.inProgress = false;
        step.hide = !step.active;
        // remove completed status in an event the application's progress code regresses
        // for example, if you pass the cart step but remove all items from your cart, you will go back to the products step
        if (this.currentProgressCode < step.progress) step.completed = false;
      }
    }
  }

  public markStepInProgress() {
    let steps: ProgressState[] = this.allStepsShownInProgressBar;
    let stepInProgress: ProgressState = null;
    // when the same progress code spans multiple steps, we need additional logic to determine which step is in progress/completed
    // keep a list to deal with these edge cases (e.g. 40 is mapped to 'Cart' and 'Business' steps)
    let codesWithMultipleSteps: Set<number> = new Set();
    for (let i = 0; i < steps.length; i++) {
      let progressCode = steps[i].progress;
      for (let j = i + 1; j < steps.length; j++) {
        if (steps[j].progress === progressCode) {
          codesWithMultipleSteps.add(progressCode);
          break;
        }
      }
    }
    // majority case, a step is in progress if its progress code is equal to the application's progress code
    // some cases, a step has multiple states (e.g. 'Industry' step has 'industry','industry-subcategory','business-type' states)
    // we can check if that step is in progress if the next step has a higher progress code than the application's progress code
    if (!codesWithMultipleSteps.has(this.currentProgressCode))
      for (let i = 0; i < steps.length; i++) {
        let currentStepProgress = steps[i].progress;
        let nextStepProgress = i + 1 < steps.length ? steps[i + 1].progress : 100;
        if (this.currentProgressCode >= currentStepProgress && this.currentProgressCode < nextStepProgress) {
          stepInProgress = steps[i];
          break;
        }
      }
    // edge case when dealing with codes belonging to multiple steps, use the current page to identify which step is in progress
    else if (this.currentPageProgressCode === this.currentProgressCode) {
      stepInProgress = steps.find(step => step.page === this.router.url.split('?')[0]);
    } else {
      stepInProgress = steps.find(step => step.progress === this.currentProgressCode && !step.completed);
    }
    if (stepInProgress) {
      stepInProgress.completed = false;
      stepInProgress.inProgress = true;
    }
  }

  public markCompletedSteps() {
    let steps: ProgressState[] = this.allStepsShownInProgressBar;
    for (let step of steps) {
      if (step.inProgress || step.progress > this.currentProgressCode) break;
      else step.completed = true;
    }
  }

  public markCurrentSelectedStep() {
    let steps = this.allStepsShownInProgressBar;
    for (let step of steps) {
      const currentPage = this.router.url.split('?')[0];
      if (currentPage === step.page || step.subPages?.some(subpage => currentPage === subpage)) {
        this.currentStepSelected = step.state;
      }
    }
    if (!this.currentStepSelected) {
      for (let i = 0; i < steps.length; i++) {
        let currentStepProgress = steps[i].progress;
        let nextStepProgress = i + 1 < steps.length ? steps[i + 1].progress : 100;
        if (this.currentProgressCode >= currentStepProgress && this.currentProgressCode < nextStepProgress) {
          this.currentStepSelected = steps[i].state;
          break;
        }
      }
    }
    let sectionToExpand = this.progressConfig.find(section =>
      section.states.some(step => step.state === this.currentStepSelected),
    );
    for (let section of this.progressConfig) {
      section.expanded = section === sectionToExpand;
    }
  }

  public isSectionInProgress(section: Progress) {
    return section.states.some(step => step.inProgress);
  }

  public isSectionCompleted(section: Progress): boolean {
    for (let step of section.states.filter(s => !s.hide)) {
      if (!step.completed) return false;
    }
    return true;
  }

  public expand(index: number) {
    this.progressConfig.forEach((progressStep: Progress, i) => {
      if (i === index) progressStep.expand(!progressStep.expanded);
      else progressStep.expand(false);
    });
  }

  public stepClick(step: ProgressState) {
    if (step.completed || step.inProgress) {
      this.router.navigateByUrl(step.page);
    }
  }

  public get allStepsShownInProgressBar(): any[] {
    // organize steps into one flat array to easily navigate across sections
    let flattenArray: ProgressState[] = [];
    for (let section of this.progressConfig) {
      for (let step of section.states) {
        if (!step.hide) flattenArray.push(step);
      }
    }
    return flattenArray;
  }

  public get currentProgressCode(): number {
    return this.store.progressCode;
  }

  ngOnDestroy(): void {
    if (this.storeProgressCodeReactionDisposer) this.storeProgressCodeReactionDisposer();
  }
}
