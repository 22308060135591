<div class="panel text-center unsaved-changes">
  <div class="modal-header unsaved-changes-modal-header">
    <button type="button" class="btn pull-right" aria-label="Close" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
  <div class="modal-body unsaved-changes-modal-body">
    <h5 id="modal-title" class="modal-title unsaved-changes-modal-title">You have unsaved changes.</h5>
    <div class="info-text unsaved-changes-info">
      Are you sure? This action will not save any changes made in this form.
    </div>
  </div>

  <hr />

  <div class="row unsaved-changes-modal-footer">
    <div class="col-12 col-md-6">
      <button type="button" class="border-button unsaved-changes-button" (click)="close()">Cancel</button>
    </div>
    <div class="col-12 col-md-6">
      <button type="button" class="primary-button m-0 unsaved-changes-button" (click)="proceed()">Proceed</button>
    </div>
  </div>
</div>
