import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';

@Component({
  selector: 'app-tg-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent extends FormCarouselSlide {
  public errorMessage: string;

  @ViewChild('cart')
  protected cart;

  constructor(
    public cartStore: CartStore,
    private appStore: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    private router: Router,
  ) {
    super(bsModalService, cd);
  }

  onOpen() {
    this.cartStore.loadCart(true).subscribe();
    super.onOpen();
  }

  public async preOnNext(): Promise<boolean> {
    if (this.cartStore.hasPosError) {
      this.router.navigate([`/${ROUTES.PRODUCTS}`]);
    } else if (this.cartStore.hasSoftwareError) {
      this.router.navigate([`/${ROUTES.SOFTWARE}`]);
    } else if (this.cartStore.hasAdditionalErrors) {
      this.router.navigate([`/${ROUTES.ADDITIONAL_PRODUCTS}`]);
    }
    return true;
  }

  isNextDisabled(): boolean {
    const locationId =
      this.appStore.businessLocations &&
      this.appStore.businessLocations.length > 0 &&
      this.appStore.businessLocations[0].id;
    return (
      (this.cartStore.unknownErrorMessages.generic && this.cartStore.unknownErrorMessages.generic.length > 0) ||
      (locationId &&
        this.cartStore.unknownErrorMessages[locationId] &&
        this.cartStore.unknownErrorMessages[locationId].length > 0)
    );
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}
