import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BankService, ValidateRequest, ValidateResponse } from '@eventhorizon/services/bank.service';
import { UntypedFormControl } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { catchError, debounceTime, filter, mergeMap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { SaveStatusService } from '@eventhorizon/services/save-status.service';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { BankInfoModel } from '@eventhorizon/models/bank-info.model';
import { BankingValidator } from '@eventhorizon/validation/banking.validator';
import { ControllerModel } from '@eventhorizon/models/controller.model';

@Injectable({
  providedIn: 'root',
})
export class BankInfoController extends ControllerModel<BankingControls> implements OnDestroy {
  public routing: MobxFormControl;

  public bankName: MobxFormControl;

  public accountNumber: MobxFormControl;

  public accountNumberConfirmation: UntypedFormControl;

  public usePrimary: MobxFormControl;

  public fieldTextType: boolean;

  private routingChangeSubscription: Subscription;

  private destroy: Subject<void> = new Subject();

  constructor(
    public store: ApplicationStore,
    protected bankService: BankService,
    private saveStatusService: SaveStatusService,
  ) {
    super();
  }

  public buildForm(
    isAssisted: boolean = false,
    locationBankInfo?: BankInfoModel,
    isPrimary?: boolean,
    locationIndex = 0,
  ): BankingControls {
    const source =
      isAssisted && locationBankInfo ? locationBankInfo : this.store.businessLocations[locationIndex].bankInfo;

    this.routing = new MobxFormControl(
      'routingNumber',
      () => source.routingNumber,
      v => {
        if (((v && v.length < 9) || source.routingNumber !== v) && source.bankName) source.bankName = '';
        source.routingNumber = v;
      },
      BankingValidator.routing(!isAssisted),
    );
    this.bankName = new MobxFormControl(
      'bankName',
      () => source.bankName,
      v => {
        source.bankName = v;
      },
      BankingValidator.bankName(!isAssisted),
    );
    this.accountNumber = new MobxFormControl(
      'accountNumber',
      () => source.accountNumber,
      v => (source.accountNumber = v),
      BankingValidator.accountNumber(!isAssisted),
    );
    this.usePrimary = new MobxFormControl(
      'usePrimary',
      () => source.usePrimary,
      v => {
        const primaryLocation = this.store.businessLocations.find(location => location.isPrimary);
        source.usePrimary =
          !primaryLocation || !primaryLocation.bankInfo || isPrimary
            ? false
            : this.setSameAccountAsPrimaryLocation(v, primaryLocation, source);
      },
      null,
      true,
    );

    this.routingChangeSubscription = this.routing.valueChanges
      .pipe(
        debounceTime(500),
        filter(text => text.length === 9),
        mergeMap(routingNumber => {
          this.saveStatusService.initSave();
          const request: ValidateRequest = { routingNumber };
          if (!isAssisted || !this.bankName.value) {
            return this.bankService.validate(request).pipe(
              catchError(() => {
                if (!isAssisted || routingNumber !== '') this.routing.setErrors({ 'routing-invalid': true });
                this.saveStatusService.cancelSave();
                return of(null);
              }),
            );
          }
          if (isAssisted && this.bankName.value) {
            return of({ bankName: this.bankName.value });
          }
        }),
      )
      .subscribe(
        (response: ValidateResponse) => {
          const bankNameResponse = response ? response.bankName : null;
          this.bankName.setValue(bankNameResponse);
          source.bankName = bankNameResponse;
          this.saveStatusService.cancelSave();
        },
        () => {
          this.saveStatusService.cancelSave();
        },
        () => {
          this.saveStatusService.cancelSave();
        },
      );
    if (isAssisted && this.routing.value && this.routing.value !== '') {
      this.routing.updateValueAndValidity({ emitEvent: true });
    }

    const controls: BankingControls = {
      routing: this.routing,
      bankName: this.bankName,
      accountNumber: this.accountNumber,
    };

    controls.usePrimary = this.usePrimary;
    if (!isPrimary && this.usePrimary.value) {
      this.disableSection();
    }
    return controls;
  }

  public showAccountNumber() {
    this.fieldTextType = !this.fieldTextType;
  }

  public setSameAccountAsPrimaryLocation(
    triggerChange: boolean,
    primaryLocation: BusinessLocationModel,
    existingBankInfo: BankInfoModel,
  ): boolean {
    if (triggerChange) {
      this.routing.setValue(primaryLocation.bankInfo.routingNumber, { emitEvent: true });
      this.accountNumber.setValue(primaryLocation.bankInfo.accountNumber);
      this.bankName.setValue(primaryLocation.bankInfo.bankName);

      this.routing.disable();
      this.accountNumber.disable();
    } else if (!this.isSameAsPrimary(primaryLocation.bankInfo, existingBankInfo)) {
      this.routing.setValue(existingBankInfo.routingNumber);
      this.accountNumber.setValue(existingBankInfo.accountNumber);
      this.bankName.setValue(existingBankInfo.bankName);

      this.routing.enable();
      this.accountNumber.enable();
    } else {
      this.routing.setValue('');
      this.accountNumber.setValue('');
      this.bankName.setValue('');

      this.routing.enable();
      this.accountNumber.enable();
    }

    return triggerChange;
  }

  public isSameAsPrimary(primaryLocation: BankInfoModel, existing: BankInfoModel): boolean {
    return (
      primaryLocation.routingNumber === existing.routingNumber &&
      primaryLocation.accountNumber === existing.accountNumber
    );
  }

  public isBankInfoEmpty(): boolean {
    return !this.accountNumber.value && !this.bankName.value && !this.routing.value;
  }

  public unsubscribeFromObservables() {
    if (this.routingChangeSubscription) this.routingChangeSubscription.unsubscribe();
  }

  public disableSection() {
    this.routing.disable();
    this.accountNumber.disable();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

export interface BankingControls {
  routing: MobxFormControl;
  bankName: MobxFormControl;
  accountNumber: MobxFormControl;
  usePrimary?: MobxFormControl;
}
