import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormErrorService } from '@eventhorizon/services/form-errors.service';
import { constants } from '@xup-payments/xup-frontend-utils/constants';
import { valueExists } from '@xup-payments/xup-frontend-utils/utils';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfoErrorService {
  constructor(private formErrorService: FormErrorService) {}

  handlePaymentInformationErrors(
    change: {
      annualCardVolume: number;
      averageTicketValue: number;
      highTicketValue: number;
      totalAnnualSales: number;
      amexAnnualVolume?: number;
    },
    form: UntypedFormGroup,
  ) {
    // Set error if the gross annual sales is lower than threhold.
    if (
      valueExists(change.totalAnnualSales) &&
      change.totalAnnualSales < constants.ANNUAL_CC_VOL_MIN &&
      !form.get('totalAnnualSales').hasError('annual-sales-gt-cc')
    ) {
      this.formErrorService.addErrorToControl('annual-ccvol-low', form, 'totalAnnualSales');
      // Clear the error if gross annual sales is higher than threhold..
    } else {
      this.formErrorService.removeErrorFromControl('annual-ccvol-low', form, 'totalAnnualSales');
    }
    // Set error if the anual credit card volume is lower than threhold.
    if (valueExists(change.annualCardVolume) && change.annualCardVolume < constants.ANNUAL_CC_VOL_MIN) {
      this.formErrorService.addErrorToControl('annual-ccvol-low', form, 'annualCardVolume');
      // Clear the error if anual credit card volume is higher than threhold..
    } else {
      this.formErrorService.removeErrorFromControl('annual-ccvol-low', form, 'annualCardVolume');
    }
    // Set error if the average ticket size is greater than the annual credit card volume.
    if (change.averageTicketValue > change.annualCardVolume) {
      this.formErrorService.addErrorToControl('average-size-greater-annual-credit', form, 'averageTicketValue');
      // Clear the error if the average ticket size is smaller than the annual credit card volume.
    } else {
      this.formErrorService.removeErrorFromControl('average-size-greater-annual-credit', form, 'averageTicketValue');
    }
    // Set error if the average ticket size is greater than the gross annual sales.
    if (change.averageTicketValue > change.totalAnnualSales) {
      this.formErrorService.addErrorToControl('average-size-greater-gross-anual', form, 'averageTicketValue');
      // Clear the error if the average ticket size is smaller than the total annual sales.
    } else {
      this.formErrorService.removeErrorFromControl('average-size-greater-gross-anual', form, 'averageTicketValue');
    }

    // Set error if the amex card volume is greater than the anual credit card volume.
    if (change.amexAnnualVolume && change.annualCardVolume && change.amexAnnualVolume > change.annualCardVolume) {
      this.formErrorService.addErrorToControl('amex-lt-cc', form, 'amexAnnualVolume');
      // Clear the error if the amex card volume is smaller than the anual credit card volume.
    } else {
      this.formErrorService.removeErrorFromControl('amex-lt-cc', form, 'amexAnnualVolume');
    }
  }
}
