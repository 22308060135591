import { LocationProducts } from '@eventhorizon/interfaces/order-details-interface';
import { BusinessLocationModel } from './business-location.model';
import { Cart } from './cart.model';

export class LocationListRow {
  public location: BusinessLocationModel;
  
  public cart: Cart;
  
  public isExpanded: boolean = false;
  
  public locationProducts: LocationProducts[];
    
  constructor(location: BusinessLocationModel, cart: Cart) {
    this.location = location;
    this.cart = cart;
  }
  
  public get isPrimary(): boolean {
    return this.location.isPrimary;
  }
  
  public get isComplete(): boolean {
    return this.location.progressCode === 100;
  }
  
  public get name(): string {
    return this.location.name;
  }
  
  public get address(): string {
    return this.location.address?.address1 || 'No Address';
  }
  
  public get mcc(): string {
    return this.location.mccDescription || 'No MCC';
  }
  
  public get id(): string {
    return this.location.id;
  }
}