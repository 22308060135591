import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-inactive-partner-popup',
  templateUrl: './inactive-partner-popup.component.html',
  styleUrls: ['./inactive-partner-popup.component.scss'],
})
export class InactivePartnerPopupComponent implements OnDestroy {
  inactivePartnerMessage: string;

  constructor(private modalRef: BsModalRef, private router: Router) {}

  public handleContinue() {
    this.router.navigate([ROUTES.CONTACT_INFO]);
    this.close();
  }

  public ngOnDestroy() {
    this.close();
  }

  public close(): void {
    this.modalRef.hide();
  }
}
