import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { messages } from '@eventhorizon/data/messages.data';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { routes } from '@eventhorizon/data/routes.data';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ApplicationPageComponent } from '../application-page/application-page.component';
import { IStepTrackerConfig } from '../step-tracker/step-tracker.component';
import { Cart } from '@eventhorizon/models/cart.model';

@Component({
  selector: 'app-location-addons-screen',
  templateUrl: './location-addons-screen.component.html',
  styleUrls: ['./location-addons-screen.component.scss'],
})
export class LocationAddonsScreenComponent extends BasePageDriverComponent {
  @ViewChild('page') public page: ApplicationPageComponent;

  public text = messages.locationAddonsSlide;

  public locationIndex: number = -1;

  public cart: Cart;

  public stepTrackerConfig: IStepTrackerConfig = {
    steps: [ 'Information', 'Products', 'Add-Ons' ],
    activeIndex: 2,
  };

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    protected cartStore: CartStore,
  ) {
    super(rs, router, route);

    // if navigated here without location query, redirect to locations
    this.route.queryParams.subscribe(params => {
      const locationIndex = params.location;
      if (isNaN(locationIndex) || locationIndex < 0 || locationIndex >= store.businessLocations.length) {
        router.navigate([routes.LOCATIONS]);
      } else {
        this.locationIndex = locationIndex;
      }
    });
  }

  onCartsUpdated(updatedCarts: Cart[]) {
    let locationId = this.store.businessLocations[this.locationIndex].id;
    this.cart = updatedCarts.find(x => x.locationId === locationId);
  }

  openCart() {
    this.page.openCart(300);
  }
}
