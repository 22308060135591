<div class="location-addons-container">
  <div class="location-addons-section" *ngIf="showSoftwareSection">
    <h1>Location Software</h1>
    <p>Due to the hardware selected for this location, software selection is required. The software will be applied to all hardware assigned to this location.</p>
    <a href="/assets/images/KMS-software-solutions-payments.png" target="_blank" class="software-link">
      <span>View Software Comparisons</span>
      <i class="fa fa-arrow-right"></i>
    </a>
    <div class="location-addons-cards">
      <div *ngFor="let software of softwares" class="radio-card" [class.selected]="isProductSelected(software)" (click)="showDetailsModal(software)">
        <button class="input-circle">
          <div class="input-circle-fill"></div>
        </button>
        <div class="image-container">
          <img [src]="software.imageUrls?.length ? software.imageUrls[0] : ''" alt="" />
        </div>
        <div class="radio-card-text">
          <h1>{{software.productName}}</h1>
          <h2>{{parseSoftwarePricingDescription(software)}}</h2>
        </div>
        <button class="view-details-button">View Details</button>
      </div>
    </div>
  </div>
  <div class="location-addons-section" *ngIf="showPinpadSection">
    <h1>Pin Pad</h1>
    <p>Due to the hardware selected, a pin pad selection is required.</p>
    <div class="location-addons-cards">
      <div *ngFor="let pinpad of pinpads" class="radio-card" [class.selected]="isProductSelected(pinpad)" (click)="showPinpadDetailsModal(pinpad)">
        <button class="input-circle">
          <div class="input-circle-fill"></div>
        </button>
        <div class="image-container">
          <img class="p-1" [src]="pinpad.imageUrls?.length ? pinpad.imageUrls[0] : ''" alt="" />
        </div>
        <div class="radio-card-text">
          <h1>{{pinpad.name}}</h1>
          <h2 *ngIf="parsePinpadPricingDescription(pinpad)">{{parsePinpadPricingDescription(pinpad)}}</h2>
        </div>
        <button class="view-details-button" (click)="showPinpadDetailsModal(pinpad)">View Details</button>
      </div>
    </div>
  </div>
</div>