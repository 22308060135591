import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { routes } from '@eventhorizon/data/routes.data';
import { Address } from '@eventhorizon/models/address.model';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';
import { DeliveryOptions } from '@eventhorizon/models/delivery-options.model';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tg-locations-summary',
  templateUrl: './locations-summary.component.html',
  styleUrls: ['./locations-summary.component.scss'],
})
export class LocationsSummaryComponent extends CarouselSlide {
  @Input()
  public isInitialState: boolean = false;

  constructor(
    public cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected pathService: RoutingPathService,
    protected applicationStore: ApplicationStore,
    protected bsModalService: BsModalService,
  ) {
    super(cd);
  }

  public get locations() {
    return this.applicationStore.businessLocations;
  }

  public isNextDisabled(): boolean {
    return this.applicationStore?.businessLocations?.some(loc => loc.progressCode !== 100);
  }

  public get isAddDisabled() {
    return this.isInitialState || this.applicationStore.businessLocations.length >= 10;
  }

  public navigateToLocationInfoRoute(locationIndex: number) {
    this.pathService.activateRoute(routes.LOCATION_INFO);
    this.pathService.activateRoute(routes.LOCATION_PRODUCTS);
    this.router.navigate([routes.LOCATION_INFO], {
      queryParams: {
        location: locationIndex,
      },
    });
  }

  public handleStartClick(): void {
    this.navigateToLocationInfoRoute(0);
  }

  public handleEdit(index: number) {
    this.navigateToLocationInfoRoute(index);
  }

  public handleAdd() {
    lastValueFrom(
      this.applicationStore.createSubmerchant({
        name: '',
        isPrimary: false,
        address: new Address(),
        productShippingAddress: null,
        transactionDistribution: null,
        deliveryOptions: new DeliveryOptions(),
        progressCode: 0,
      }),
    )
      .then(resp => {
        if (resp) {
          lastValueFrom(this.applicationStore.loadApplication()).then(() => {
            let newLocationIndex = this.applicationStore.businessLocations.findIndex(
              loc => loc.id === resp.businessLocation.id,
            );
            if (newLocationIndex > -1) {
              this.navigateToLocationInfoRoute(newLocationIndex);
            }
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  }

  public async handleDelete(index: number) {
    try {
      let splicedLocation: BusinessLocationModel[];
      splicedLocation = this.applicationStore.businessLocations.splice(index, 1);
      await this.applicationStore.deleteLocationFromBackEnd(splicedLocation[0].id);
    } catch (e) {
      console.error(e);
    }
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}
