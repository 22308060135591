import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { TimeoutErrorService } from '@eventhorizon/services/timeout-error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: ApplicationStore,
    private readonly timeOutService: TimeoutErrorService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.BadGateway || error.status === HttpStatusCode.ServiceUnavailable) {
              this.router.navigate([ROUTES.ERROR]);
            } else if (
              error.status === HttpStatusCode.GatewayTimeout ||
              error.status === HttpStatusCode.RequestTimeout
            ) {
              this.timeOutService.show();
            } else if (error.status < 401 || error.status > 403 || this.router.url.includes('/resume?token')) {
              return;
            } else {
              this.store.clearApplication();
              this.router.navigate([ROUTES.INTRO_PAGE]);
            }
          }
        },
      ),
    );
  }
}
