export interface ApplicationSlideText {
  smallHeadline: string;
  smallHeadlineAlign?: 'left' | 'center' | 'right';
  headline: string;
  headlineAlign?: 'left' | 'center' | 'right';
  tooltipTitleText?: string;
  tooltipTitleTextAlign?: 'left' | 'center' | 'right';
  tooltipBackButton?: string;
  tooltipBackButtonAlign?: 'left' | 'center' | 'right';
  backText?: string;
  backTextAlign?: 'left' | 'center' | 'right';
  nextText: string;
  nextTextAlign?: 'left' | 'center' | 'right';
  subText: string;
  subTextAlign?: 'left' | 'center' | 'right';
  footNote?: string;
  footNoteAlign?: 'left' | 'center' | 'right';
  submitError?: string;
  submitErrorAlign?: 'left' | 'center' | 'right';
}
export interface PhysicalLocationSlideText extends ApplicationSlideText {
  noteText: string;
}
export interface LocationsSlideText extends ApplicationSlideText {
  smallHeadlineInitialState: string;
}
export interface LocationInfoSlideText extends ApplicationSlideText {
  smallHeadlinePrimaryLocation: string;
}
export interface BusinessCriteriaSlideText extends ApplicationSlideText {
  brickAndMortarStoreQuestion: string;
  brickAndMortarStoreYes: string;
  brickAndMortarStoreNo: string;
  paymentTypesQuestion: string;
  paymentTypes: string[];
  numLocationsQuestion: string;
  creditCardSalesQuestion: string;
  creditCardSalesYes: string;
  creditCardSalesNo: string;
  wifiQuestion: string;
  wifiQuestionWifi: string;
  wifiQuestion3GLTE: string;
  wifiQuestionBoth: string;
  extrasQuestion: string;
  extrasDiscounts: string;
  extrasSalesService: string;
  extrasBoth: string;
}

export interface MerchantCongratsSlideText extends ApplicationSlideText {
  equipmentOnItsWay: string;
  orderNumber: string;
  checkInbox: string;
}

export interface TermsAndConditionsSlideText extends ApplicationSlideText {
  iHaveRead: string;
  submitError: string;
}

export interface OwnershipSlideText extends ApplicationSlideText {
  additionalOwnersLabel: string;
  additionalOwnersYes: string;
  additionalOwnersNo: string;
  ownershipLabel: string;
}

export interface OrderConfirmSlideText extends ApplicationSlideText {
  orderNumber: string;
  viewDetails: string;
  continue: string;
  point1: string;
  point2: string;
  point3: string;
  merchantProducts: string;
  oneTimeCost: string;
  recurringCost: string;
  transactions: string;
  subTotal: string;
  shipping: string;
  freeShipping: string;
  tax: string;
  total: string;
  trackOrder: string;
}

export interface PaymentTypeCategory {
  heading: string;
  subtext: string;
  icon: string;
  value: number;
}

export type PaymentTypesSlideText = ApplicationSlideText;

export interface ApplicationConfirmationSlideText extends ApplicationSlideText {
  altHeadline: string;
  altSmallHeadline: string;
  welcomeBack: string;
  orderStatus: string;
  aplication: string;
  order: string;
  applicationStatus: string;
  applicationInProgress: string;
  applicationError: string;
  applicationInReview: string;
  applicationApproved: string;
  applicationDenied: string;
  helpLine: string;
  orderSummary: string;
  downloadMpa: string;
  submitting: string;
  complete: string;
  errorSubmitting: string;
  pendingApproval: string;
  estimated: string;
  cancelled: string;
  clickForDetails: string;
  footNote: string;
}

export const bankerMessages = {
  industrySelectionSlide: <ApplicationSlideText>{
    headline: 'Tell us more!',
    smallHeadline: 'We are excited to find a solution that is the perfect fit for your client',
    nextText: 'Continue',
  },

  businessTypeSlide: <ApplicationSlideText>{
    smallHeadline: 'What type of business do you run?',
    subText: 'Search for the industry or services that your business offers.',
    backText: 'Back',
    nextText: 'Continue',
  },

  paymentTypesSlide: <ApplicationSlideText>{
    headline: 'Payments',
    smallHeadline: 'Where do you plan on taking the majority of your payments?',
    subHeadline: 'Don&#39;t worry if you have plans for more than one channel. You can add to this later.',
    backText: 'Back',
    nextText: 'Continue',
    subText: '',
  },

  businessSubtypeSlide: <ApplicationSlideText>{
    backText: 'Back',
    nextText: 'Continue',
  },

  businessCriteriaSlide: <BusinessCriteriaSlideText>{
    headline: "Tell us more about your client's business",
    smallHeadline: ' ',
    backText: 'Back',
    nextText: 'Continue',
    brickAndMortarStoreQuestion: 'Do you have a brick and mortar store?',
    brickAndMortarStoreYes: 'Yes',
    brickAndMortarStoreNo: 'No',
    paymentTypesQuestion: 'What types of payments will you be taking?',
    paymentTypes: [
      'Swipe',
      'Chip',
      'Gift Cards',
      'Cash',
      'Checks',
      'Pre-Auths',
      'PIN Debits',
      'Mobile Wallet',
      'Signature Credit',
      'Signature Debit',
      'NFC',
    ],
    numLocationsQuestion: 'How many locations do you have?',
    creditCardSalesQuestion: 'What types of payments will you be taking?',
    creditCardSalesYes: 'Yes',
    creditCardSalesNo: 'No',
    wifiQuestion: 'Do you require Wifi or 3G/LTE?',
    wifiQuestionWifi: 'Wifi',
    wifiQuestion3GLTE: '3G/LTE',
    wifiQuestionBoth: 'Both',
    extrasQuestion: 'Will you require the below?',
    extrasDiscounts: 'Ability to Apply Discounts',
    extrasSalesService: 'Ability to Apply Sales Tax & Service Charges',
    extrasBoth: 'Both',
  },

  packageSelectionSlide: <ApplicationSlideText>{
    headline: ' ',
    smallHeadline: "Good News! We have a solution that's perfect for your client!",
    backText: 'Back',
    nextText: 'Add Package',
  },

  businessInfoSlide: <ApplicationSlideText>{
    headline: 'Business Information',
    smallHeadline: "Let's get some details about your client's business!",
    backText: 'Back',
    nextText: 'Continue',
  },

  businessAddressesSlide: <ApplicationSlideText>{
    headline: 'Business Info',
    smallHeadline: 'Where is your business located?',
    backText: 'Back',
    nextText: 'Continue',
  },

  ownerInformationSlide: <OwnershipSlideText>{
    headline: 'Owner Information',
    smallHeadline: "Let's get some details about your client's business!",
    backText: 'Back',
    nextText: 'Continue',

    additionalOwnersLabel: 'Are there any other owners with 25% or more equity?',
    additionalOwnersYes: 'Yes',
    additionalOwnersNo: 'No',
    ownershipLabel: 'Ownership',
  },

  identityVerificationSlide: <ApplicationSlideText>{
    headline: 'Identity Verification',
    smallHeadline: "Verifying your client's details",
    backText: 'Back',
    nextText: 'Next',
  },
};

const appConfirmation = <ApplicationConfirmationSlideText>{
  headline: 'Woohoo!',
  altHeadline: 'Thank You',
  smallHeadline: 'Your merchant application is under review',
  altSmallHeadline: 'Your merchant application is being submitted',
  subText: '',
  backText: '',
  nextText: '',
  aplication: 'Application',
  order: 'Order',
  applicationStatus: 'Application Status',
  applicationInProgress: 'Your application is being submitted. Please wait for a status update in a moment',
  applicationError: 'There was an error submitting your application. Please refresh the page to retry',
  applicationInReview: 'Your application is being reviewed. We will notify you when the review is complete.',
  applicationApproved: 'Your application has been approved! Please check your email for details',
  applicationDenied: 'Your application has been denied. Please contact KeyBank for details.',
  orderSummary: 'Order Summary',
  downloadMpa: 'Download your signed MPA agreement (PDF)',
  pendingApproval: 'Pending Application Approval',
  submitting: 'Submitting Application',
  complete: '',
  errorSubmitting: 'Error Submitting Application',
  estimated: 'Estimated Delivery:',
  cancelled: 'Canceled due to Application Denial',
  clickForDetails: 'Click to View Details',
  footNote:
    '<sup>1</sup>Transaction rates do not include equipment costs, chargeback fees, software or security fees, or other third-party charges. Custom pricing available; restrictions apply.',
};

const appConfirmationStatus = { ...appConfirmation };
appConfirmationStatus.headline = 'Welcome Back!';
appConfirmationStatus.smallHeadline = 'Track the status of your application and order';

export const messages = {
  contactInfoSlide: {
    headline: 'Apply Now',
    smallHeadline: 'What you need to finish applying',
    subText: 'To checkout and complete your merchant enrollment, you’ll need:',
    nextText: 'Continue',
  },

  businessSizeSlide: {
    headline: 'Business Size',
    smallHeadline: 'What type of business do you run?',
    nextText: 'Continue',
    backText: 'Back',
  },

  shippingLocationSlide: {
    headline: 'Shipping',
    smallHeadline: 'Where would you like us to ship your order?',
    nextText: 'Continue',
    backText: 'Back',
  },

  industrySelectionSlide: {
    headline: 'Industry',
    smallHeadline: 'What type of business do you run?',
    subText: 'Select the category that best describes your business.',
    nextText: 'Continue',
    backText: 'Back',
  },

  industrySubcategorySelectionSlide: {
    headline: 'Industry Subcategory',
    smallHeadline: '',
    subText: 'Select the category that best describes your business.',
    nextText: 'Continue',
    backText: 'Back',
  },

  packageSelectionSlide: {
    headline: 'The Perfect Solution',
    smallHeadline: 'Recommended packages just for you!',
    backText: 'Back',
    nextText: 'Continue',
  },

  packageDetailsSlide: {
    headline: 'Check out the package below',
    smallHeadline: 'Good News! We have found solutions that will work for you.',
    backText: 'Back',
    nextText: 'Buy Now',
  },

  softwareSlide: {
    headline: "A product you've chosen requires software",
    smallHeadline: 'Add software to complete your solution',
    subText:
      'The products in your cart require a software plan. We’ve handpicked the best options to optimize your business. View the comparison to learn more.',
    backText: 'Back',
    nextText: 'Continue',
  },

  additionalProductsSlide: {
    headline: 'Add a device to complete your solution',
    smallHeadline: 'Add a device to complete your solution',
    subText:
      'At least one of the following products is required to complete your solution. Click to view more details and add to your cart.',
    backText: 'Back',
    nextText: 'Continue',
  },

  pinpadErrorSlide: {
    headline: 'Add a PinPad device to complete your solution',
    smallHeadline: 'Add a PinPad device to complete your solution',
    subText:
      'Select the PinPad device that best meets your business needs. View the solution details to add a product to your cart.',
    backText: 'Back',
    nextText: 'Continue',
  },

  checkoutSlide: {
    headline: 'Shopping Cart',
    smallHeadline: 'Review your cart before continuing your application.',
    subText:
      'Please review all selected products, relevant rates and fees, and total charges below. Your order will be processed after your application is submitted and approved.',
    backText: 'Back',
    nextText: 'Continue',
    footNote:
      '<sup>1</sup>Transaction rates do not include equipment costs, chargeback fees, software or security fees, or other third-party charges. Custom pricing available; restrictions apply.',
  },

  orderConfirmation: <OrderConfirmSlideText>{
    headline: 'Order Submitted',
    smallHeadline: 'Thank you for confirming your order!',
    backText: 'Back',
    nextText: 'Continue Application',

    orderNumber: 'Order #',
    viewDetails: 'View Details',
    continue: 'Continue your application to get your business up and running',
    point1: 'Please continue with your Merchant Application by clicking "Continue Application".',
    point2: 'Your order will be processed after your application is approved.',
    point3: 'Your will be charged for your purchase as part of your second statement.',
    merchantProducts: 'Merchant Products',
    oneTimeCost: 'One Time Cost',
    recurringCost: 'Recurring Cost',
    transactions: 'Transactions',
    subTotal: 'Subtotal',
    shipping: 'Shipping',
    freeShipping: 'free shipping',
    tax: 'Est. Tax',
    total: 'Total',
    trackOrder: 'Track the order',
  },

  cartSlide: {
    headline: 'Customize Your Package',
    smallHeadline: 'Make sure to add or remove accessories as needed.',
    backText: 'Select Another Package',
    nextText: 'Continue',
  },

  pricingSelectionSlide: {
    headline: 'Pricing and Plans',
    smallHeadline: 'Choose the right plan for your and your business.',
    backText: 'Back',
    nextText: 'Continue',
  },

  welcomeSlide: <ApplicationSlideText>{
    headline: 'Welcome',
    smallHeadline: '',
    nextText: 'Continue',
  },

  businessInfoSlide: <ApplicationSlideText>{
    headline: 'Business Info',
    smallHeadline: "Now, Let's talk about the business.",
    // will be added back via V2
    // subText:
    //   'Provide legal information about your business. You may also provide website if you take payments through online platforms.',
    backText: 'Back',
    nextText: 'Continue',
  },

  businessTypeSlide: <ApplicationSlideText>{
    headline: 'Industry',
    smallHeadline: 'What type of business do you run?',
    subText: 'Search for the industry or services that your business offers.',
    backText: 'Back',
    nextText: 'Continue',
  },

  businessTaxesSlide: <ApplicationSlideText>{
    headline: 'Business Taxes',
    smallHeadline: 'Now let’s get you signed up for payment processing',
    subText: 'How is your business structured?',
    backText: 'Back',
    nextText: 'Continue',
  },

  paymentInfoSlide: <ApplicationSlideText>{
    headline: 'Payments',
    smallHeadline: 'What is your transaction volume?',
    subText: 'Tell us more about your sales and expected credit card volume.',
    backText: 'Back',
    nextText: 'Continue',
  },

  locationsSlide: <LocationsSlideText>{
    headline: 'Locations',
    smallHeadlineInitialState: "Now let's add all your business locations.",
    smallHeadline: "Now, let's add any additional business locations.",
    subText:
      'If your business has multiple locations, include all locations below. If your business has more than 10 total locations, please contact us using the "Help" option above',
    backText: 'Back',
    nextText: 'Continue',
  },

  locationInfoSlide: <LocationInfoSlideText>{
    headline: 'Locations',
    smallHeadlinePrimaryLocation: 'Tell us about your primary business location.',
    smallHeadline: 'Tell us about your business location.',
    backText: 'Back',
    nextText: 'Continue',
  },

  locationProductsSlide: <ApplicationSlideText>{
    headline: 'Locations',
    smallHeadline: 'Select products for this business location',
    subText:
      'Each location must have at least one product. Depending on selections, software or additional products may be required.',
    backText: 'Back',
    nextText: 'Continue',
  },

  locationAddonsSlide: <ApplicationSlideText>{
    headline: 'Locations',
    smallHeadline: 'Additional products are required for this business location.',
    backText: 'Back',
    nextText: 'Continue',
  },

  paymentTypesSlide: <PaymentTypesSlideText>{
    headline: 'Payments',
    smallHeadline: 'Where do you plan on taking payments?',
    subHeadline: 'Don&#39;t worry if you have plans for more than one channel. You can add to this later.',
    backText: 'Back',
    nextText: 'Continue',
    subText:
      'Give us an idea of how you think you’ll accept card payments from the options below. You can select multiple, but your total must equal 100%.',
  },

  businessAddressesSlide: <ApplicationSlideText>{
    headline: 'Business Info',
    smallHeadline: 'Where is your business located?',
    backText: 'Back',
    nextText: 'Continue',
  },

  ownerInformationSlide: <OwnershipSlideText>{
    headline: 'Owner Info',
    smallHeadline: 'Tell us more about the ownership of your business',
    backText: 'Back',
    nextText: 'Continue',

    additionalOwnersLabel: 'Are there any other owners with 25% or more equity?',
    additionalOwnersYes: 'Yes',
    additionalOwnersNo: 'No',
    ownershipLabel: 'Ownership',
  },

  ownerInformationTaxExemptSlide: <OwnershipSlideText>{
    headline: 'Controlling Party Information',
    smallHeadline: 'Controlling Party Information',
    backText: 'Back',
    nextText: 'Continue',

    additionalOwnersLabel: 'Are there any other owners with 25% or more equity?',
    additionalOwnersYes: 'Yes',
    additionalOwnersNo: 'No',
    ownershipLabel: 'Ownership',
  },

  bankInformationSlide: <ApplicationSlideText>{
    headline: 'Banking',
    smallHeadline: 'Where would you like us to deposit your funds?',
    subText:
      'Please verify your bank account via one of the following methods to link it to your new merchant account.',
    backText: 'Back',
    nextText: 'Continue',
  },

  verificationSlide: <ApplicationSlideText>{
    headline: 'Verification',
    smallHeadline: 'Verification',
    backText: 'Back',
    nextText: 'Continue',
  },

  riskVerificationSlide: <ApplicationSlideText>{
    headline: 'Verification',
    smallHeadline: 'Verification',
    backText: 'Back',
    nextText: 'Continue',
  },

  identityVerificationSlide: <ApplicationSlideText>{
    headline: 'Accuracy Results',
    smallHeadline: 'Everything looks good!',
    subText:
      'Here’s a summary of your application for review. Click to revisit or update any of your business information.',
    backText: 'Back',
    nextText: 'Next',
  },

  applicationSummarySlide: <ApplicationSlideText>{
    headline: 'Summary',
    smallHeadline: 'Please review all the info very carefully',
    backText: 'Back',
    nextText: 'Continue',
  },

  termsAndConditionsSlide: <TermsAndConditionsSlideText>{
    headline: 'Terms & Conditions',
    smallHeadline: 'Review and sign your merchant agreement.',
    // subText:
    //   'The signer must be one of the business owners who is also an authorized signer for the business; if you are not the signer, please select "I am not the signer" in the dropdown below.',
    iHaveRead: 'I have read and agreed to the above terms and conditions',
    tooltipBackButton: 'Your application can not be updated once you agree to the Terms and Conditions.',
    backText: 'Back',
    nextText: 'Submit',
    submitError: 'SORRY, YOUR APPLICATION HAS ALREADY BEEN SUBMITTED. CHECK YOUR INBOX FOR FURTHER DETAIL.',
  },

  paymentSlide: <ApplicationSlideText>{
    headline: 'Checkout Below',
    smallHeadline: 'How would you like to pay?',
    backText: 'Back',
    nextText: 'Continue',
  },

  congratsSlide: <MerchantCongratsSlideText>{
    headline: "You're all set!",
    smallHeadline: '',
    equipmentOnItsWay: 'Great news - your equipment is on its way!',
    orderNumber: 'Order Number: ',
    checkInbox: 'Check your inbox for your application and order details.',
  },

  applicationConfirmationSlide: appConfirmation,
  applicationStatusSlide: appConfirmationStatus,
};
