<form *ngIf="columnedBankInfo" [formGroup]="form" #formElem>
  <div class="row">
    <div class="col-12 p-0">
      <div class="mb-3 position-relative bank-selection-radio">
        <label [for]="id + 4" class="bank-selection-radio-label">
          <input [id]="id + 4" type="radio" [value]="true" formControlName="bankInfoMethod" />&nbsp;{{
            instantLabel
          }}&nbsp;
          <app-tg-tooltip [tooltip]="this.instantVerificationTooltip"></app-tg-tooltip>
        </label>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isYodleeSelected">
    <div class="col-12 p-0 bank-yodlee">
      <div id="container-fastlink"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0">
      <div class="mb-3 position-relative bank-selection-radio">
        <label [for]="id + 5" class="bank-selection-radio-label">
          <input [id]="id + 5" type="radio" [value]="false" formControlName="bankInfoMethod" />&nbsp;{{ routingLabel }}
        </label>
      </div>
    </div>
  </div>

  <div class="row bank-account-row" [hidden]="isYodleeSelected">
    <div class="col-lg-6 mx-auto">
      <div class="row">
        <div class="col-sm-12 mb-3 position-relative">
          <input
            [id]="id"
            formControlName="routing"
            class="form-control"
            type="text"
            autocomplete="on"
            [textMask]="masks.routingAlt"
            [class.validation-failed]="validationFailed"
            (focus)="clearMaskedControl(routing)"
          />
          <label class="form-label" [for]="id"> Routing Number </label>
          <app-field-error [control]="routing"></app-field-error>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-3 position-relative">
          <div class="right-inner-addon">
            <span>
              <i
                class="far"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="showAccountNumber()"
              ></i>
            </span>
            <input
              [id]="id + 1"
              formControlName="accountNumber"
              class="form-control"
              type="password"
              autocomplete="new-password"
              [textMask]="masks.accountAlt"
              [type]="fieldTextType ? 'text' : 'password'"
              (focus)="clearMaskedControl(accountNumber)"
            />
            <label class="form-label" [for]="id + 1"> Account Number </label>
            <app-field-error [control]="accountNumber"></app-field-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-3 position-relative">
          <input
            [id]="id + 2"
            formControlName="bankName"
            class="form-control bank-name-disabled"
            type="text"
            tabindex="-1"
            [readonly]="true"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 2"> Bank Name</label>
          <app-field-error [control]="bankName"></app-field-error>
        </div>
      </div>
    </div>
    <div class="col-lg-6 bank-info-image text-center mx-auto">
      <img aria-hidden="true" src="./assets/images/Check-Graphic.svg" alt="Check graphic" class="img-fluid" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="bank-info-message">
        <i class="fal fa-lightbulb bank-info-message-icon" alt="Information Icon"></i>
        <p class="bank-info-message-text">
          All transactions such as sales, equipment purchases, and chargebacks will be credited or debited, as
          applicable, to the account provided.
        </p>
      </div>
    </div>
  </div>
</form>

<form *ngIf="!columnedBankInfo" [formGroup]="form" #formElem>
  <div class="row">
    <div class="mb-3 position-relative bank-selection-radio">
      <label
        [for]="id + 4"
        [ngClass]="{ 'bank-info-active': isYodleeSelected }"
        class="bank-selection-radio-label bank-info-small"
      >
        <input [id]="id + 4" type="radio" [value]="true" formControlName="bankInfoMethod" />&nbsp;{{
          instantLabel
        }}&nbsp;
        <app-tg-tooltip [tooltip]="this.instantVerificationTooltip"></app-tg-tooltip>
      </label>
    </div>
  </div>
  <div class="row" *ngIf="isYodleeSelected">
    <div class="col-12 bank-yodlee">
      <div id="container-fastlink"></div>
    </div>
  </div>
  <div class="row">
    <div class="mb-3 position-relative bank-selection-radio">
      <label
        [for]="id + 5"
        [ngClass]="{ 'bank-info-active': !isYodleeSelected }"
        class="bank-selection-radio-label bank-info-small"
      >
        <input [id]="id + 5" type="radio" [value]="false" formControlName="bankInfoMethod" />&nbsp;{{ routingLabel }}
      </label>
    </div>
  </div>

  <div class="row" [hidden]="isYodleeSelected">
    <div class="mx-auto row col-lg-12">
      <div class="col-4 p-0">
        <div class="col-sm-12 mb-3 position-relative position-relative">
          <i
            class="far fa-circle-exclamation me-1 giact-validation-failed-icon position-absolute"
            *ngIf="validationFailed"
          ></i>
          <input
            [id]="id"
            formControlName="routing"
            class="form-control"
            type="text"
            autocomplete="on"
            [textMask]="masks.routingAlt"
            #focus
            [class.giact-validation-failed]="validationFailed"
          />
          <label class="form-label" [for]="id"> Routing Number </label>
          <app-field-error [control]="routing"></app-field-error>
        </div>
      </div>

      <div class="col-4 p-0">
        <div class="col-sm-12 mb-3 position-relative">
          <div class="right-inner-addon">
            <span>
              <i class="far fa-circle-exclamation me-1 giact-validation-failed-icon" *ngIf="validationFailed"></i>
              <i
                class="far"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="showAccountNumber()"
              ></i>
            </span>
            <input
              [id]="id + 1"
              formControlName="accountNumber"
              class="form-control"
              type="password"
              autocomplete="new-password"
              [textMask]="masks.accountAlt"
              [type]="fieldTextType ? 'text' : 'password'"
              [class.giact-validation-failed]="validationFailed"
            />
            <label class="form-label" [for]="id + 1"> Account Number </label>
            <app-field-error [control]="accountNumber"></app-field-error>
          </div>
        </div>
      </div>

      <div class="col-4 p-0">
        <div class="col-sm-12 mb-3 position-relative">
          <input
            [id]="id + 2"
            formControlName="bankName"
            class="form-control"
            type="text"
            tabindex="-1"
            [readonly]="true"
            disabled="true"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 2"> Bank Name </label>
          <app-field-error [control]="bankName"></app-field-error>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-sm-12 form-error-message error-message">
    {{ errorMessage }}
  </div>
</div>
