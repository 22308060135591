import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from '@eventhorizon/models/address.model';

@Injectable({ providedIn: 'root' })
export class AddressService {
  baseUrl;

  constructor(private http: HttpClient,  @Inject('env') private environment) {
    this.baseUrl = environment.onboardingApi ? environment.onboardingApi : environment.api.url;
  }

  public validate(request: Address): Observable<Address> {
    return this.http.post<Address>(`${this.baseUrl}/address/validate`, request);
  }
}
