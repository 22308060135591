import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { DateFilterService } from '@eventhorizon/services/date-filter.service';
import { brandDatePicker, DateFilter } from '@xup-payments/xup-frontend-utils/models';
import moment from 'moment';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Output() selected = new EventEmitter<number>();

  public form: FormGroup<IDateFilterForm>;

  public today = moment().utc().toDate();

  private customDateRange: FormControl<Date[]>;

  public brandDatePicker = { ...brandDatePicker, showPreviousMonth: true };

  constructor(public filterService: DateFilterService, private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
    const range = this.filterService.getRange();
    if (this.filterService.currentFilterId() < this.filterService.DATEFILTER_TODAY && range) {
      const rangeDates = [moment(range[0]).toDate(), moment(range[1]).toDate()];
      this.form.controls.dateRange.setValue(rangeDates);
    }
  }

  private buildForm() {
    this.customDateRange = this.fb.control<Date[]>(null);
    this.form = this.fb.group({
      dateRange: this.customDateRange,
    });
  }

  getClass(id: number) {
    const filter = this.filterService.filters.find(f => f.id === id);
    return `date-filter${filter.id === this.getSelectedFilter() ? ' date-filter-selected' : ''}`;
  }

  getSelectedFilter(): number {
    return this.filterService.currentFilterId();
  }

  public changeFilter(id: number, broadcast: boolean = true) {
    if (this.form.controls.dateRange.value) {
      this.form.controls.dateRange.reset();
    }
    if (id !== this.filterService.currentFilterId()) {
      this.filterService.currentFilterId(id);
      this.selected.emit(id);
      if (broadcast) {
        this.filterService.broadcastDateChange();
      }
    }
  }

  public filterCustomRange(dateRange: Date[]) {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const currentFilterDate: DateFilter = this.filterService.currentFilter();
      const startDate = moment(dateRange[0]).format('YYYY-MM-DD');
      const endDate = moment(dateRange[1]).format('YYYY-MM-DD');
      // Update and broadcast selected date if dateRange is different than current filter date range
      if (startDate !== currentFilterDate.startDate() || endDate !== currentFilterDate.endDate()) {
        dateRange[0] = moment(startDate).toDate();
        dateRange[1] = moment(endDate).toDate();
        this.filterService.currentFilterId(-1, dateRange);
        this.selected.emit(-1);
        this.filterService.broadcastDateChange();
      }
    }
  }
}

export interface IDateFilterForm {
  dateRange: FormControl<Date[]>;
}
