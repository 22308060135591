import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-callout-notification',
  templateUrl: './callout-notification.component.html',
  styleUrls: ['./callout-notification.component.scss'],
})
export class CalloutNotificationComponent {
  @Input()
  public notification: string;
}
