import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStore } from '@eventhorizon/stores/auth.store';
import { Observable } from 'rxjs';
import {
  ApplicationQuizAnswer,
  ApplicationRequest,
  ApplicationResponse,
  ApplicationSaveReturnRequest,
  CallbackRequest,
  CampaignInformation,
  GetQuizResponse,
  LocationApplicationUpdateRequest,
  MpaDocuments,
  OrderStatus,
  QuestionResponse,
  SignableDocumentRequest,
  UserToken,
  VerificationResponse,
} from '@xup-payments/xup-frontend-utils/models';
import { IActivityLogSummary } from '@eventhorizon/models/activity-log';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  readonly BASE_URL_VERIFICATION;

  readonly urls = {
    payfacs: '/api/payfacs',
  };

  public constructor(private http: HttpClient, private authStore: AuthStore, @Inject('env') private environment) {
    this.BASE_URL_VERIFICATION = environment.onboardingApi
      ? `${this.environment.onboardingApi}/quiz`
      : `${this.environment.api.url}/quiz`;
  }

  public newCart(): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(`${this.getUrl()}/new`);
  }

  public getCart(): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(`${this.getUrl()}/get/`);
  }

  public setCart(req: ApplicationRequest): Observable<ApplicationResponse> {
    return this.http.post<ApplicationResponse>(`${this.getUrl()}/set`, req);
  }

  public getApplicationForUser(): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(`${this.getUrl()}/current`);
  }

  public getApplicationById(applicationId: string): Observable<ApplicationResponse> {
    return this.http.get<ApplicationResponse>(`${this.getUrl()}/${applicationId}`);
  }

  public createApplication(): Observable<ApplicationResponse> {
    return this.http.post<ApplicationResponse>(`${this.getUrl()}/create`, {});
  }

  public patchApplication(applicationId: string, req: ApplicationRequest): Observable<ApplicationResponse> {
    return this.http.patch<ApplicationResponse>(`${this.getUrl()}/${applicationId}/update`, req);
  }

  public createLocation(applicationId: string, req: any): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/${applicationId}/locations`, req);
  }

  public patchLocation(applicationId: string, req: any, locationId: string): Observable<any> {
    return this.http.patch<any>(`${this.getUrl()}/${applicationId}/locations/${locationId}`, req);
  }

  public deleteLocation(applicationId: string, locationId: string): Observable<any> {
    return this.http.delete<any>(`${this.getUrl()}/${applicationId}/locations/${locationId}`);
  }

  public sendReturningEmail(applicationId: string, req: ApplicationSaveReturnRequest, type?: string) {
    let url = `${this.getUrl()}/${applicationId}/save`;
    if (type) {
      url += `?type=${type}`;
    }

    return this.http.post<ApplicationResponse>(url, req);
  }

  public getMpa(
    applicationId: string,
    hasMultipleDocuments = false,
    downloadSignedWhenMultiple = false,
  ): Observable<Blob | MpaDocuments> {
    if (hasMultipleDocuments) {
      return this.http.get<MpaDocuments>(`${this.getUrl()}/${applicationId}/mpa`, {});
    }
    if (downloadSignedWhenMultiple) {
      return this.getSignedMpa(applicationId);
    }
    return this.http.get<Blob>(`${this.getUrl()}/${applicationId}/mpa`, { responseType: 'blob' as 'json' });
  }

  public getSignedMpa(applicationId: string): Observable<Blob | MpaDocuments> {
    return this.http.get<Blob>(`${this.getUrl()}/${applicationId}/signedmpa`, { responseType: 'blob' as 'json' });
  }

  public saveTerms(applicationId: string): any {
    return this.http.post(`${this.getUrl()}/${applicationId}/mpa/agree`, applicationId, {
      responseType: 'blob' as 'json',
    });
  }

  public saveSignedDocuments(applicationId: string, signedDocuments: SignableDocumentRequest[]): any {
    return this.http.post(`${this.getUrl()}/${applicationId}/uploaddocuments`, signedDocuments);
  }

  public saveCallback(applicationId: string, req: CallbackRequest): Observable<any> {
    return this.http.post(`${this.getUrl()}/${applicationId}/callback`, req);
  }

  public submitApplication(applicationId: string, req: ApplicationRequest): Observable<ApplicationResponse> {
    return this.http.post<ApplicationResponse>(`${this.getUrl()}/${applicationId}/submit`, req);
  }

  /**
   * Update location status of a specific application
   *
   * @param payfacId string
   * @param request LocationApplicationUpdateRequest
   */
  public updateLocationApplicationStatus(
    payfacId: string,
    request: LocationApplicationUpdateRequest,
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.urls.payfacs}/${payfacId}/application-statuses/${request.applicationId}/locations/${request.businessLocationId}`,
      request,
    );
  }

  public getOrderStatus(token: string): Observable<OrderStatus> {
    return this.http.post<OrderStatus>(`api/order-status?token=${token}`, {});
  }

  public getQuiz(applicationId: string): Observable<GetQuizResponse> {
    return this.http.get<GetQuizResponse>(`${this.BASE_URL_VERIFICATION}/getquiz?applicationId=${applicationId}`);
  }

  public submitQuiz(applicationId: string, answers: ApplicationQuizAnswer[]): Observable<GetQuizResponse> {
    return this.http.post<GetQuizResponse>(
      `${this.BASE_URL_VERIFICATION}/submitquiz?applicationId=${applicationId}`,
      answers,
    );
  }

  public getRiskQuiz(applicationId: string): Observable<QuestionResponse> {
    return this.http.get<QuestionResponse>(`${this.getUrl()}/${applicationId}/questions/risk`);
  }

  public submitRiskQuiz(applicationId: string, answers): Observable<QuestionResponse> {
    return this.http.post<QuestionResponse>(`${this.getUrl()}/${applicationId}/questions/risk`, answers);
  }

  public getApplicationActivity(applicationId: string): Observable<IActivityLogSummary> {
    return this.http.get<IActivityLogSummary>(`api/activitylogs/short`, {
      params: {
        applicationId: applicationId,
      },
    });
  }

  public getUrl(): string {
    const uri = this.authStore.getPayfacId() ? 'merchants' : 'applications';
    return this.environment.onboardingApi
      ? `${this.environment.onboardingApi}/${uri}`
      : `${this.environment.api.url}/${uri}`;
  }

  public updateCampaignInformation(applicationId: string, campaignInformation: CampaignInformation): Observable<any> {
    return this.http.patch(`${this.getUrl()}/${applicationId}/campaign-information`, campaignInformation);
  }

  public renewAuthToken(newToken: UserToken): void {
    sessionStorage.setItem('auth_token', JSON.stringify(newToken));
  }

  public getAuthToken(): UserToken {
    const rawToken = sessionStorage.getItem('auth_token') || sessionStorage.getItem('$kb$_currentUser');
    if (!rawToken) return null;

    try {
      return JSON.parse(rawToken);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public getIsResumeApp(applicationId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.getUrl()}/${applicationId}/reminders`);
  }

  public updatePartnerAdmin(applicationId: string, userId: string): Observable<ApplicationResponse> {
    return this.http.patch<ApplicationResponse>(`${this.getUrl()}/${applicationId}/salesRep`, {
      salesRepUserId: userId,
    });
  }

  public patchDdaInterestDisclosure(applicationId: string, status: number): Observable<void> {
    return this.http.patch<any>(`${this.getUrl()}/${applicationId}/dda-interest`, { optionId: status });
  }
}
