<div class="row">
  <div class="col-md-12 mx-auto p-0">
    <form [formGroup]="form" #formElem>
      <div class="row">
        <div class="col-sm-12 mb-3 position-relative p-0">
          <div class="row">
            <fieldset class="number-of-locations-container col-sm-12 p-0">
              <legend class="number-of-locations-label"></legend>
              <div *ngFor="let n of numOfLocations; let index = index" class="number-of-locations-selector col">
                <input type="radio" [id]="index" formControlName="numberOfLocations" [value]="n.value" tabindex="-1" />
                <label
                  class="number-of-locations"
                  [ngClass]="{ 'is-selected': numberOfLocations.value === n.value }"
                  [for]="index"
                  #focus
                  tabindex="0"
                  (keyup)="keyboardSelectCard(n.value, $event.keyCode); $event.stopPropagation()"
                >
                  <span class="number-of-locations-value">{{ n.display }}</span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
          {{ errorMessage }}
        </div>
      </div>
    </form>
  </div>
</div>
