import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export class AverageTicketValueValidator {
  static averageTicketValue(mcc: string): ValidatorFn {
    return (control: UntypedFormControl): any => {
      const mccSpecialCases = {
        5814: {
          conditionToFail: parseInt(control.value, 10) > 15,
          error: 'average-ticket-less-than-15',
        },
      };

      if (mccSpecialCases[mcc] && mccSpecialCases[mcc].conditionToFail) {
        return {
          [mccSpecialCases[mcc].error]: true,
        };
      }
      return null;
    };
  }

  static averageTicketSmallerThanHighTicketValue(name: string): ValidatorFn {
    // eslint-disable-next-line complexity
    return (control: UntypedFormControl): any => {
      let highValue;
      let averageValue;
      let highControl;
      let averageControl;
      if (control.parent) {
        highControl = (control.parent.controls as any).highTicketValue;
        averageControl = (control.parent.controls as any).averageTicketValue;
        if (highControl.value) {
          highValue =
            typeof highControl.value === 'number'
              ? highControl.value
              : parseFloat(highControl.value.split(',').join(''));
        }

        if (averageControl.value) {
          averageValue =
            typeof averageControl.value === 'number'
              ? averageControl.value
              : parseFloat(averageControl.value.split(',').join(''));
        }
      }
      if (highValue && highValue < averageValue) {
        return {
          'higher-value-smaller-error': true,
        };
      }

      if (highControl && name === 'average' && highControl.getError('higher-value-bigger-error')) {
        highControl.setErrors({ ...highControl.errors, ...{ 'higher-value-bigger-error': false } });
        highControl.updateValueAndValidity();
      }
      if (averageControl && name === 'high' && averageControl.getError('higher-value-smaller-error')) {
        averageControl.setErrors({ ...averageControl.errors, ...{ 'higher-value-smaller-error': false } });
        averageControl.updateValueAndValidity();
      }
      return null;
    };
  }

  static highTicketValueBiggerThenaverageTicket(): ValidatorFn {
    // eslint-disable-next-line complexity
    return (control: UntypedFormControl): any => {
      let highValue;
      let averageValue;
      let highControl;
      let averageControl;
      if (control.parent) {
        highControl = (control.parent.controls as any).highTicketValue;
        averageControl = (control.parent.controls as any).averageTicketValue;
        if (highControl.value) {
          highValue =
            typeof highControl.value === 'number'
              ? highControl.value
              : parseFloat(highControl.value.split(',').join(''));
        }

        if (averageControl.value) {
          averageValue =
            typeof averageControl.value === 'number'
              ? averageControl.value
              : parseFloat(averageControl.value.split(',').join(''));
        }
      }

      if (highValue && highValue < averageValue) {
        return {
          'higher-value-bigger-error': true,
        };
      }

      if (highControl && highControl.getError('higher-value-bigger-error')) {
        highControl.setErrors({ ...highControl.errors, ...{ 'higher-value-bigger-error': false } });
        highControl.updateValueAndValidity();
        return null;
      }
      if (averageControl && averageControl.getError('higher-value-smaller-error')) {
        averageControl.setErrors({ ...averageControl.errors, ...{ 'higher-value-smaller-error': false } });
        averageControl.updateValueAndValidity();
        return null;
      }
    };
  }
}
