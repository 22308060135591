import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Partner,
  PartnerPrefillReferralApplication,
  PartnerReferral,
  Referral,
} from '@eventhorizon/models/partners.model';
import { IPartnerSummaryDetails } from '@eventhorizon/models/partner-summary';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {
  readonly BASE_URL = '/api/partners';

  constructor(private http: HttpClient) {}

  public saveReferral(partnerId: string, applicationId: string): Observable<Referral> {
    return this.http.post<Referral>(`${this.BASE_URL}/${partnerId}/referral`, { applicationId });
  }

  public getPartners(): Observable<PartnerReferral[]> {
    return this.http.get<PartnerReferral[]>(`${this.BASE_URL}`);
  }

  public getPartner(partnerId: string): Observable<IPartnerSummaryDetails> {
    return this.http.get<IPartnerSummaryDetails>(`${this.BASE_URL}/${partnerId}`);
  }

  public getPartnerBySlug(partnerSlug: string): Observable<Partner[]> {
    return this.http.get<Partner[]>(`${this.BASE_URL}?slug=${partnerSlug}`);
  }

  public prefillPartnerApplication(
    applicationId: string,
    partnerId: string,
    prefillTemplateSlug: string,
  ): Observable<PartnerPrefillReferralApplication> {
    return this.http.post<PartnerPrefillReferralApplication>(`${this.BASE_URL}/${partnerId}/referral`, {
      applicationId,
      prefillTemplateSlug,
    });
  }
}
