export const ROUTES = {
  INTRO_PAGE: 'get-started',
  BUSINESS_TAXES: 'business-taxes',
  BUSINESS_INFO: 'business-info',
  PAYMENT_INFO: 'payment-info',
  LOCATIONS: 'locations',
  LANDING: 'landing-page',
  OWNERS: 'owners',
  BANK: 'bank',
  INDUSTRY: 'industry',
  INDUSTRY_SUBCATEGORY: 'industry-subcategory',
  BUSINESS_TYPE: 'business-type',
  PAYMENT_TYPES: 'payment-types',
  CONTACT_INFO: 'contact-info',
  PACKAGE_SELECTION: 'package-selection',
  SOFTWARE: 'software',
  ADDITIONAL_PRODUCTS: 'additional-products',
  CHECKOUT: 'checkout',
  SHIPPING: 'shipping',
  ORDER_CONFIRMATION: 'order-confirmation',
  BUSINESS_ADDRESSES: 'business-addresses',
  ID_VERIFICATION: 'summary',
  VERIFICATION: 'verification',
  RISK_VERIFICATION: 'risk-verification',
  TERMS: 'terms',
  APPLICATION_CONFIRMATION: 'application-confirmation',
  RESUME: 'resume',
  PRODUCTS: 'products',
  ORDER_STATUS: 'order-status',
  OFFRAMP: 'offramp',
  CALLBACK: 'callback',
  LANDING_PAGE: 'landingpage',
  PARTNER: 'partner',
  NOT_FOUND: '404',
  ERROR: '50x',
  PREFILL: 'prefill',
  PARTNER_PREFILL: ':partnerSlug/:templateId',
  OB: {
    BUSINESS_INFO: 'onboarding/business-info',
    OWNERS: 'onboarding/owners',
    FINANCIAL_INFO: 'onboarding/financial-info',
    PRODUCTS: 'onboarding/products',
    SOFTWARE: 'onboarding/software',
  },
};
