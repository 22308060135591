export const PURCHASE_TYPES_CODES = {
  PURCHASED: 'P',
  OWNED: 'O',
  RENT: 'R',
  EXISTING: 'E',
  INSTALLMENT_3_PAYMENTS: 'IP3',
  INSTALLMENT_6_PAYMENTS: 'IP6',
  INSTALLMENT_9_PAYMENTS: 'IP9',
  INSTALLMENT_12_PAYMENTS: 'IP12',
  LEASE_12_MONTHS: 'LT12',
  LEASE_24_MONTHS: 'LT24',
  LEASE_27_MONTHS: 'LT27',
  LEASE_36_MONTHS: 'LT36',
  LEASE_48_MONTHS: 'LT48',
  LEASE_60_MONTHS: 'LT60',
};

export const PURCHASE_TYPES_FORM_LABEL_CODES = {
  purchase: 'P',
  owned: 'O',
  rent: 'R',
  existing: 'E',
  installment3: 'IP3',
  installment6: 'IP6',
  installment9: 'IP9',
  installment12: 'IP12',
  leasing12: 'LT12',
  leasing24: 'LT24',
  leasing27: 'LT27',
  leasing36: 'LT36',
  leasing48: 'LT48',
  leasing60: 'LT60',
};

export const CODES_TO_FORM_LABEL_PURCHASE_TYPE = {
  P: 'purchase',
  O: 'owned',
  R: 'rent',
  E: 'existing',
  IP3: 'installment3',
  IP6: 'installment6',
  IP9: 'installment9',
  IP12: 'installment12',
  LT12: 'leasing12',
  LT24: 'leasing24',
  LT27: 'leasing27',
  LT36: 'leasing36',
  LT48: 'leasing48',
  LT60: 'leasing60',
};

export const PURCHASE_TYPE_LABELS = {
  'Owned': 'Owned',
  'Purchased': 'Purchased',
  'Rent': 'Rent',
  'Existing': 'Existing',
  '3 Installment': '3 Mon. Installment',
  '6 Installment': '6 Mon. Installment',
  '9 Installment': '9 Mon. Installment',
  '12 Installment': '12 Mon. Installment',
  '12 Lease': '12 Mon. Lease',
  '24 Lease': '12 Mon. Lease',
  '27 Lease': '12 Mon. Lease',
  '36 Lease': '12 Mon. Lease',
  '48 Lease': '12 Mon. Lease',
  '60 Lease': '12 Mon. Lease',
};

export const CATEGORY_TYPE_TO_CODE = {
  installments: ['IP3', 'IP6', 'IP9', 'IP12'],
  lease: ['LT12', 'LT24', 'LT27', 'LT36', 'LT48', 'LT60'],
};

export const DEFAULT_PRICING_MODEL_ACTIVE = {
  P: true,
  O: true,
};

export const LEASING_TYPES_CODES = {
  LT12: true,
  LT24: true,
  LT27: true,
  LT36: true,
  LT48: true,
  LT60: true,
};

export const INSTALLMENT_TYPES_CODES = {
  IP3: true,
  IP6: true,
  IP9: true,
  IP12: true,
};

export const PARTNER_STATUS_ID = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const INCLUDED_GROUP_EDITION_ITEMS = {
  'Qualified Credit': true,
  'Qualified Signature Debit': true,
  'AVS Fees': true,
  'Access Fee': true,
  'Address Verification Fees': true,
  'Voice Authorization Fees': true,
};

export const PAYMENT_CATEGORIES = {
  PURCHASE: 'purchase',
  OWNED: 'owned',
  INSTALLMENT: 'installment',
  LEASING: 'leasing',
};

export const PRODUCT_TYPES = {
  ACQUIRING: 'ACQUIRING',
  NET_FEE: 'NET_FEE',
};

export const PRODUCT_PURCHASE_TYPE = {
  PAY_FULL: 'P',
  MONTHLY: 'M',
};
