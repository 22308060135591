import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { OrganizationService } from '@eventhorizon/services/organization.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { reaction } from 'mobx';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddressComponent } from '@eventhorizon/components/address/address.component';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { Address, brandDatePicker, masks, organizationTypes } from '@xup-payments/xup-frontend-utils/models';
import { formatErrorAsList, formatPhone, randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { onDateFieldKeyChangePatchValue } from '@eventhorizon/util/util';
import {
  CommonValidator,
  BusinessNameValidator,
  conditionalRequiredValidator,
} from '@xup-payments/xup-frontend-utils/validation';
import { lastValueFrom } from 'rxjs';
import { BaseBusinessAddressesComponent } from '@eventhorizon/components/base-business-addresses/base-business-addresses.component';

@Component({
  selector: 'app-tg-business-info',
  templateUrl: './base-business-info.component.html',
  styleUrls: ['./base-business-info.component.scss'],
})
export class BaseBusinessInfoComponent extends FormCarouselSlide implements AfterViewInit, OnInit {
  public id = randomComponentId();

  public today = new Date();

  public masks = masks;

  public brandDatePicker = brandDatePicker;

  public onDateFieldKeyChangePatchValue = onDateFieldKeyChangePatchValue;

  public errorMessage: string[] = [];

  public isLoading = false;

  public showLocationsAddresses = false;

  public legalName: MobxFormControl;

  public dbaName: MobxFormControl;

  public taxName: MobxFormControl;

  public businessPhone: MobxFormControl;

  public businessEmail: MobxFormControl;

  public website: MobxFormControl;

  public startDate: MobxFormControl;

  public numBOs: MobxFormControl;

  public tin: MobxFormControl;

  public minDateOfIncorporation: Date;

  @Input() public displayLocationDBA: boolean = true;

  @ViewChild('legalAddress')
  public legalAddress: AddressComponent;

  @ViewChild('locationAddresses')
  public locationAddresses: BaseBusinessAddressesComponent;

  public selectedAddressTab = 0;

  public autoPopulatedFields = new Set([
    'legalName',
    'businessPhone',
    'businessEmail',
    'website',
    'startDate',
    'dbaName',
  ]);

  @ViewChild('focus') public focus: ElementRef = undefined;

  public bankInfoConfig: Record<string, object | string>;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected organizationService: OrganizationService,
    protected routingService: RoutingPathService,
    protected locationsService: LocationsService,
    public contentService: ContentServiceService,
  ) {
    super(bsModalService, cd);
    this.showLocationsAddresses = routingService.isRouteActive('locations');

    reaction(
      () => !!this.store.isLoaded,
      () => {
        this.setBusinessLocations();
      },
    );
  }

  ngOnInit() {
    this.bankInfoConfig = this.contentService.bankInfoConfig || null;
    this.buildForm();
    this.hideBasedOnTinType();
    this.setBusinessLocations();
  }

  ngAfterViewInit() {
    this.form.setControl('legalAddress', this.legalAddress.form);
    if (this.locationAddresses) {
      this.form.setControl('locationAddresses', this.locationAddresses?.form);
    }
    this.cd.detectChanges();
  }

  private isSoleProprietor(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.soleProprietor.code
    ) {
      return false;
    }

    return true;
  }

  public get displayPhysicalLocForm(): boolean {
    return !this.isSoleProprietor() && this.showLocationsAddresses;
  }

  public onOpen() {
    super.onOpen();
  }

  public initialFocus() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
  }

  public buildForm() {
    const s = this.store;
    const hasOnlineTransactions = this.store.businessLocations.find(
      ({ transactionDistribution }) => transactionDistribution !== null && transactionDistribution.internet > 0,
    );

    this.legalName = new MobxFormControl(
      'legalName',
      () => s.businessInfo.legalName,
      v => {
        s.businessInfo.legalName = v;
        s.businessInfo.taxFilingName = v;
      },
      BusinessNameValidator.legalName(),
    );
    this.dbaName = new MobxFormControl(
      'dbaName',
      () => s.businessLocations[0].name,
      v => {
        s.businessLocations[0].name = v;
      },
      BusinessNameValidator.dbaName(),
    );
    this.businessPhone = new MobxFormControl(
      'businessPhone',
      () => formatPhone(s.businessInfo.phone),
      v => (s.businessInfo.phone = v),
      CommonValidator.phone(),
    );
    this.businessEmail = new MobxFormControl(
      'businessEmail',
      () => s.businessInfo.email,
      v => (s.businessInfo.email = v),
      CommonValidator.email(),
    );
    this.website = new MobxFormControl(
      'website',
      () => s.businessInfo.website,
      v => (s.businessInfo.website = v),
      Validators.compose([conditionalRequiredValidator(() => !!hasOnlineTransactions), CommonValidator.website]),
    );

    this.minDateOfIncorporation = new Date();
    this.minDateOfIncorporation.setFullYear(this.minDateOfIncorporation.getFullYear() - 200);
    this.startDate = new MobxFormControl(
      'startDate',
      () => s.businessInfo.incDate,
      v => {
        s.businessInfo.incDate = v;
        const timestamp = Date.parse(v);
        if (!isNaN(timestamp)) s.businessInfo.startDate = new Date(timestamp);
      },
      CommonValidator.dateInPastWithin200Years(),
    );

    this.tin = new MobxFormControl(
      'tin',
      () => s.businessInfo.taxId,
      v => {
        s.businessInfo.taxId = v;
        s.businessInfo.taxIdConfirmation = v;
      },
      CommonValidator.tinAlt(),
    );

    this.form = this.fb.group({
      legalName: this.legalName,
      dbaName: this.dbaName,
      businessPhone: this.businessPhone,
      businessEmail: this.businessEmail,
      website: this.website,
      startDate: this.startDate,
      tin: this.tin,
      legalAddress: ['', []],
      locationAddresses: ['', []],
    });
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;

    // The onboarding page doesn't ask for the tax name, but it is required
    if (this.store.businessInfo.legalName) {
      this.store.businessInfo.taxFilingName = this.store.businessInfo.legalName;
    } else if (this.store.businessInfo.dbaName) {
      this.store.businessInfo.taxFilingName = this.store.businessInfo.dbaName;
    }

    try {
      await lastValueFrom(
        this.store.updateSubmerchant(
          this.locationsService.currentLocationId,
          this.store.businessLocations[this.locationsService.currentLocationIndex],
        ),
      );
      await lastValueFrom(this.store.saveBusinessInformation());
      return true;
    } catch (error) {
      this.errorMessage = formatErrorAsList(error);
      return false;
    }
  }

  private setLegalToBusinessAddress() {
    if (
      (this.isSoleProprietor() || !this.routingService.isRouteActive('locations')) &&
      this.store.businessLocations.length > 0
    ) {
      this.store.businessLocations[0].address = this.store.businessAddresses.legalAddress;
    }
  }

  private fillBusinessLocations() {
    if (this.store.businessLocations.length > 1) {
      this.store.businessLocations.slice(1).forEach(location => {
        location.address = this.store.businessLocations[0].address;
      });
    }
  }

  public async preOnNext(): Promise<boolean> {
    this.setLegalToBusinessAddress();
    this.fillBusinessLocations();
    return this.save();
  }

  hideBasedOnTinType(): boolean {
    if (this.store.businessInfo.tinType === 0 && !this.store.businessInfo.taxId) {
      this.form.controls.tin.disable();
      this.form.controls.legalName.disable();
      return true;
    }
  }

  public updateFormValidity() {
    Object.keys(this.form.controls).forEach(key => {
      if (this.autoPopulatedFields.has(key)) {
        this.form.controls[key].markAsTouched();
        this.form.controls[key].markAsDirty();
      }
    });
  }

  public setBusinessLocations() {
    if (
      this.store.businessLocations &&
      this.store.businessLocations.length > 0 &&
      !this.isSoleProprietor() &&
      this.showLocationsAddresses
    ) {
      this.store.businessLocations.forEach(businessLocation => {
        if (businessLocation.address === null) businessLocation.address = new Address();
      });
    }
  }
}
