<div class="row">
  <div class="col-md-11 mx-auto business-taxes-container">
    <form [formGroup]="form">
      <input type="hidden" class="form-control" formControlName="businessType" />
      <input type="hidden" class="form-control" formControlName="tinType" />
      <div class="row justify-content-center" *ngIf="!selectedType()">
        <div class="col-sm-12">
          <div
            [class]="typeClass(this.SOLE_P_CODE, 'business-taxes-type')"
            [class.business-taxes-error]="form?.invalid && form?.touched"
            tabindex="0"
            (click)="select(this.SOLE_P_CODE)"
            (keyup.enter)="select(this.SOLE_P_CODE)"
            #focus
          >
            {{ soleProprietorType().description }}
          </div>
        </div>
        <div class="col-sm-12">
          <div class="business-taxes-separate"></div>
        </div>
        <div class="col-sm-12 business-taxes-group-container">
          <div
            *ngFor="let t of businessTypes(); let index = index"
            tabindex="0"
            (keyup.enter)="select(t.code)"
            [class]="typeClass(t.code, 'business-taxes-type')"
            [class.business-taxes-error]="
              form?.controls?.businessType?.invalid && form?.controls?.businessType?.touched
            "
            (click)="select(t.code)"
          >
            {{ t.description }}
          </div>
        </div>
      </div>
      <div class="row justify-content-center" *ngIf="selectedType()">
        <div class="col-sm-12">
          <div
            tabindex="0"
            [class]="typeClass(selectedType().code, 'business-taxes-type col-sm-12')"
            (click)="unselect()"
            (keyup.enter)="unselect()"
          >
            {{ selectedType().description }}
          </div>
        </div>
        <div class="col-sm-12">
          <div *ngIf="bTController.getTinTypes(selectedType().code).length > 0" class="business-taxes-label">
            How do you file your business taxes?
          </div>
        </div>
        <div class="col-sm-12 business-taxes-group-container">
          <div
            *ngFor="let tt of bTController.getTinTypes(selectedType().code)"
            tabindex="0"
            [class]="tinTypeClass(tt.value, selectedType().code, 'business-taxes-type')"
            [class.business-taxes-error]="form?.controls?.tinType?.invalid && form?.controls?.tinType?.touched"
            (click)="selectTinType(tt.value)"
            (keyup.enter)="selectTinType(tt.value)"
          >
            {{ tt.description }}
          </div>
        </div>
        <div *ngIf="form?.controls?.tin?.touched && form?.controls?.tinType?.invalid" class="col-12">
          <p class="form-error-message" role="alert">Please select how your business taxes are filed.</p>
        </div>
        <div
          *ngIf="!!bTController.tinTypeValue && bTController.tinTypeValue !== SSN_ONLY"
          class="col-sm-12 mb-3 position-relative business-taxes-tin"
        >
          <input
            [id]="id + 3"
            formControlName="tin"
            class="form-control"
            type="text"
            [maskito]="masks.taxIdAlt"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 3"> Tax Identification Number (TIN) </label>

          <app-field-error [control]="form.controls.tin"></app-field-error>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
    {{ errorMessage }}
  </div>
</div>
