<ng-container *ngIf="form">
  <form [formGroup]="form" #formElem>
    <div class="row">
      <div class="col-12 p-0">
        <div class="row mb-3">
          <div class="col-12 p-0">
            <div class="tg-delivery-options-heading tg-delivery-options-bold pt-1">Business Sales Information</div>
            <div class="tg-paymenttype-info">This information is across all locations.</div>
          </div>
        </div>
        <section class="row">
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="annualSales"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="totalAnnualSales"
                  [maskito]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="annualSales">Gross Annual Sales ($)</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="totalAnnualSales"></app-field-error>
            </div>
          </div>
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="annualCardVolume"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="annualCardVolume"
                  [maskito]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="annualCardVolume">Annual Credit Card Volume</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="annualCardVolume"></app-field-error>
            </div>
          </div>
          <div class="col-md-4 tg-paymenttype-category">
            <div class="tg-paymenttype-percent">
              <div class="left-inner-addon position-relative">
                <span>$</span>
                <input
                  id="averageTicket"
                  class="form-control"
                  type="text"
                  inputmode="numeric"
                  autocomplete="on"
                  formControlName="averageTicketValue"
                  [maskito]="masks.usCurrencyNoPrefix"
                />
                <label class="form-label" for="averageTicket">Average Ticket Size</label>
              </div>
            </div>
            <div class="mb-3">
              <app-field-error [control]="averageTicketValue"></app-field-error>
            </div>
          </div>
        </section>
      </div>

      <div class="col-md-4 ps-0" [hidden]="!store.showAmexAnnualSales">
        <div class="tg-paymenttype-category">
          <div class="tg-paymenttype-percent">
            <div class="left-inner-addon position-relative">
              <span>$</span>
              <input
                id="amexAnnualVolume"
                class="form-control"
                type="text"
                inputmode="numeric"
                autocomplete="on"
                formControlName="amexAnnualVolume"
                [maskito]="masks.usCurrencyNoPrefix"
              />
              <label class="form-label" for="amexAnnualVolume">AMEX Annual Card Sales ($)</label>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <app-field-error [control]="amexAnnualVolume"></app-field-error>
        </div>
      </div>

      <div class="col-md-4 ps-0" [hidden]="!store.showAmexMemberId && !pIController.referralRequiresAmexMemberId">
        <div class="tg-paymenttype-category">
          <div class="tg-paymenttype-percent">
            <div class="left-inner-addon position-relative">
              <input
                id="amexMemberId"
                class="form-control ps-2"
                type="text"
                autocomplete="on"
                formControlName="amexMemberId"
                [maskito]="masks.amexMemberId"
              />
              <label class="form-label" for="amexMemberId">AMEX Membership Number</label>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <app-field-error [control]="amexMemberId"></app-field-error>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
        {{ errorMessage }}
      </div>
    </div>
  </form>
</ng-container>
