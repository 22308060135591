import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { UserToken } from '@eventhorizon/models/auth-token.model';

@Injectable({ providedIn: 'root' })
export class ApplicationInterceptor implements HttpInterceptor {
  private targetRoutes: string[];

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
  ) {
    this.targetRoutes = ['/api/merchants/', '/api/applications/'];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const targetRouteFound = this.targetRoutes.filter(r => req.urlWithParams.includes(r)).length > 0;
    if (targetRouteFound) {
      return next.handle(req).pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            const renewal = evt.headers.get('renewed-token');
            if (renewal) {
              const authToken: UserToken = this.applicationService.getAuthToken();
              if (!authToken) return;

              const validity = evt.headers.get('renewed-token-expiresat');
              if (validity) {
                authToken.expiresAt = validity;
              }
              authToken.token = renewal;
              this.applicationService.renewAuthToken(authToken);
            }
          }
        }),
        catchError(error => {
          if (error.status && error.status > 500) {
            return throwError(error);
            //TO-DO Add logic for the cases when we must display the Site Under Maintenance
            this.router.navigateByUrl('/50x');
          } else {
            return throwError(error);
          }
        }),
      );
    }
    return next.handle(req);

  }
}
