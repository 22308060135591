import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormCarouselSlide } from '../form-carousel-slide';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { SendApplicationModalComponent } from '../send-application-modal/send-application-modal.component';

@Component({
  selector: 'app-signers-dropdown',
  templateUrl: './signers-dropdown.component.html',
  styleUrls: ['./signers-dropdown.component.scss'],
})
export class SignersDropdownComponent extends FormCarouselSlide implements OnInit {
  @Input()
  public options: any;

  @Input()
  public contact: any;

  @Input()
  public businessOwners: any;

  @Input()
  public hiddenTooltip = false;

  @Input()
  public removeNoSigner = false;

  @Input()
  public isAssisted = false;

  @Output() dataEvent = new EventEmitter<void>();

  public selectedValue;

  public disabled = false;

  public value: string;

  public firstName: MobxFormControl;

  public lastName: MobxFormControl;

  public email: MobxFormControl;

  public isSelected: boolean;

  constructor(
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: UntypedFormBuilder,
    protected appStore: ApplicationStore,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
    });

    if (this.selectedValue === undefined) {
      this.selectedValue = `${'Select One'}`;
    }
  }

  sendDataToTermsForm(data) {
    if (data === 'no-signer') {
      this.selectedValue = 'I am not the signer';
      this.form.reset();
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.form.parent.controls['accept'].reset();
      const initialState = {
        contact: this.contact,
        businessOwners: this.businessOwners,
        applicationId: this.appStore.id,
      };
      this.bsModalService.show(SendApplicationModalComponent, {
        initialState,
        backdrop: 'static',
        ariaLabelledBy: 'modal-title modal-subtitle',
        class: 'medium-modal',
      });
    } else {
      this.form.setValue(data);
      this.selectedValue = `${data.firstName}` + ' ' + `${data.lastName}` + ' - ' + `${data.email}`;
      this.dataEvent.emit(data);
    }
  }

  formatOptionDescription(option: any) {
    return option.firstName + ' ' + option.lastName + ' - ' + option.email;
  }

  noSignerOption() {
    return `${'I am not the signer'}`;
  }

  showDesignatedLabel() {
    if (!this.isAssisted) {
      return 'Who is the signer of this application?';
    } else {
      return 'Recipient';
    }
  }
}
