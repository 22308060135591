import { Component, Input, OnInit } from '@angular/core';
import { BosInformationService } from '@eventhorizon/services/bos-information.service';
import { BOS_INFORMATION_TITLES } from '@eventhorizon/constants/general.constants';
import { BosInformationModel } from '@eventhorizon/models/bos-information.model';

@Component({
  selector: 'app-bos-information',
  templateUrl: './bos-information.component.html',
})
export class BosInformationComponent implements OnInit {
  constructor(private bosInformationService: BosInformationService) {}

  @Input() applicationId: string;

  bosInformation: BosInformationModel;

  BOS_INFORMATION_TITLES = BOS_INFORMATION_TITLES;

  errorMessage: string;

  public isLoading: boolean = true;

  ngOnInit() {
    this.bosInformationService.getBOSinformation(this.applicationId).subscribe(
      (res: BosInformationModel) => {
        this.bosInformation = res;
        this.isLoading = false;
      },
      () => {
        this.errorMessage =
          'BOS/MCA results are unavailable. These checks will be automatically run once the full application is complete, signed, and submitted by the merchant.';
        this.isLoading = false;
      },
    );
  }

  get getBOSinformationIterableKeys() {
    return Object.keys(this.bosInformation);
  }
}
