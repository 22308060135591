import { Injectable } from '@angular/core';
import { OrganizationType } from '@eventhorizon/models/organization-type.model';
import { lastValueFrom } from 'rxjs';
import { OrganizationService } from './organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationCacheService {
  constructor(private organizationService: OrganizationService) {}

  private organizationTypes: OrganizationType[];

  public async getOrganizationTypes(): Promise<OrganizationType[]> {
    if (this.organizationTypes) {
      return this.organizationTypes;
    }
    await this.loadOrganizationTypes();
    return this.organizationTypes;

  }

  private async loadOrganizationTypes() {
    this.organizationTypes = await lastValueFrom(this.organizationService.getOrganizationTypes());
  }
}
