<div class="review-cart-container">
  <lib-locations-list #locationsList
    [locations]="getLocations()"
    [locationCarts]="locationCarts"
    [showLocationIcon]="true"
    [showPrimaryIndicator]="true"
    [showMcc]="true"
    [showCartHeader]="true"
    [showCartPaymentColumn]="true"
  ></lib-locations-list>
  <ng-container *ngIf="carts.length">
    <strong class="mt-2 mb-1">Your Transactions</strong>
    <div class="review-cart-transactions">
      <app-transactions [cart]="primaryCart"></app-transactions>
    </div>
    <app-cart-summary
      [readonly]="false"
      [showExtendedDisplay]="true"
      [carts]="carts"
      [readonly]="!allowTaxEstimation"
    ></app-cart-summary>
  </ng-container>
</div>
