<app-container *mobxAutorun>
  <app-application-page
    [index]="2"
    [subIndex]="1"
    [text]="ownerMessages"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-personal-info #innerSlide (prev)="prev()" (next)="next()"></app-tg-personal-info>
  </app-application-page>
</app-container>
