import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { reaction } from 'mobx';
import { ProductService } from '@eventhorizon/services/product.service';
import { AcquiringTypes } from '@eventhorizon/models/product.model';
import { TransactionInfo } from '@eventhorizon/models/transaction-info.model';
import { currencyToNumber } from '@eventhorizon/utils/util';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { AverageTicketValueValidator } from '@eventhorizon/validation/average-ticket-value.validator';
import { lastValueFrom } from 'rxjs';
import { ControllerModel } from '@eventhorizon/models/controller.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfoController extends ControllerModel<PaymentInfoControls> {
  public totalAnnualSales: MobxFormControl;

  public annualCardVolume: MobxFormControl;

  public averageTicketValue: MobxFormControl;

  public highTicketValue: MobxFormControl;

  public amexMemberId: MobxFormControl;

  public amexAnnualVolume: MobxFormControl;

  public errorMessage: string;

  public mcc: string;

  public referralRequiresAmexMemberId: boolean;

  public acquiringTypes: AcquiringTypes[];

  public hasAmexDirect: boolean;

  constructor(
    public store: ApplicationStore,
    private cartStore: CartStore,
    private productService: ProductService) {
    super();
  }

  public buildForm(isAssisted: boolean = false): PaymentInfoControls {
    const s = this.store;
    this.mcc = this.store.mcc;
    const isRequired = isAssisted ? undefined : Validators.required;
    const isRequiredString = isAssisted ? undefined : 'required';
    this.annualCardVolume = new MobxFormControl(
      'annualCardVolume',
      () => this.store.transactionInfo.creditCardVolume,
      v => {
        if (v) {
          this.store.transactionInfo.creditCardVolume = currencyToNumber(v);
        }
      },
      isRequired,
    );
    const isRequiredArray = isAssisted
      ? [CommonValidator.ltWithZero(this.annualCardVolume, 'amex-lt-cc')]
      : [Validators.required, CommonValidator.ltWithZero(this.annualCardVolume, 'amex-lt-cc')];
    this.totalAnnualSales = new MobxFormControl(
      'totalAnnualSales',
      () => s.transactionInfo.totalAnnualSales,
      v => {
        if (v) {
          this.store.transactionInfo.totalAnnualSales = currencyToNumber(v);
        }
      },
      Validators.compose([
        CommonValidator.number(!isAssisted),
        CommonValidator.gt(this.annualCardVolume, 'annual-sales-gt-cc', isRequiredString),
      ]),
    );
    this.averageTicketValue = new MobxFormControl(
      'averageTicketValue',
      () => s.transactionInfo.averageTicketValue,
      v => {
        if (v) {
          this.store.transactionInfo.averageTicketValue = currencyToNumber(v);
        }
      },
      Validators.compose(this.getAverageTicketValidators(isAssisted)),
    );
    this.highTicketValue = new MobxFormControl(
      'highTicketValue',
      () => s.transactionInfo.highTicketValue,
      v => {
        if (v) {
          this.store.transactionInfo.highTicketValue = currencyToNumber(v);
        }
      },
    );
    this.amexAnnualVolume = new MobxFormControl(
      'amexAnnualVolume',
      () => s.transactionInfo.amexAnnualVolume,
      v => {
        if (v) {
          this.store.transactionInfo.amexAnnualVolume = currencyToNumber(v);
        }
      },
      Validators.compose(isRequiredArray),
    );
    this.amexMemberId = new MobxFormControl(
      'amexMemberId',
      () => this.store.transactionInfo.amexmemberId,
      v => {
        if (v) {
          this.store.transactionInfo.amexmemberId = currencyToNumber(v);
        }
      },
      this.getAmexMemberValidators(this.referralRequiresAmexMemberId || this.hasAmexDirect, false, this.amexMemberId),
    );

    this.amexAnnualVolume.setEnabled(this.store.showAmexAnnualSales);
    this.amexMemberId.setEnabled(
      this.store.showAmexMemberId || this.referralRequiresAmexMemberId || this.hasAmexDirect,
    );

    reaction(
      () => !!this.store.isLoaded && this.store.showAmexAnnualSales,
      (show: boolean) => {
        this.amexAnnualVolume.setEnabled(show);
      },
    );
    reaction(
      () => !!this.store.isLoaded && this.store.showAmexMemberId,
      (show: boolean) => {
        this.amexMemberId.setEnabled(show || this.referralRequiresAmexMemberId);
      },
    );

    reaction(
      () => !!this.store.isLoaded && !!this.store.transactionInfo && this.store.transactionInfo.creditCardVolume,
      () => {
        if (this.store.transactionInfo.creditCardVolume) this.totalAnnualSales.validate();
      },
    );

    return {
      totalAnnualSales: this.totalAnnualSales,
      averageTicketValue: this.averageTicketValue,
      highTicketValue: this.highTicketValue,
      amexAnnualVolume: this.amexAnnualVolume,
      amexMemberId: this.amexMemberId,
      annualCardVolume: this.annualCardVolume,
    };
  }

  public buildFormForLocation(isAssisted: boolean = false, transactionInfo?: TransactionInfo): PaymentInfoControls {
    const s = isAssisted && transactionInfo ? transactionInfo : this.store.businessLocations[0].transactionInfo;
    const isRequired = isAssisted ? [] : [Validators.required];
    this.annualCardVolume = new MobxFormControl(
      'annualCardVolume',
      () => this.store.transactionInfo.creditCardVolume,
      v => {
        this.store.transactionInfo.creditCardVolume = currencyToNumber(v);
      },
      isRequired,
    );
    this.totalAnnualSales = new MobxFormControl(
      'totalAnnualSales',
      () => s.totalAnnualSales,
      v => {
        s.totalAnnualSales = currencyToNumber(v);
      },
    );
    this.averageTicketValue = new MobxFormControl(
      'averageTicketValue',
      () => s.averageTicketValue,
      v => (s.averageTicketValue = currencyToNumber(v)),
    );
    this.highTicketValue = new MobxFormControl(
      'highTicketValue',
      () => s.highTicketValue,
      v => (s.highTicketValue = currencyToNumber(v)),
    );
    this.amexAnnualVolume = new MobxFormControl(
      'amexAnnualVolume',
      () => s.amexAnnualVolume,
      v => (s.amexAnnualVolume = currencyToNumber(v)),
    );
    this.amexMemberId = new MobxFormControl(
      'amexMemberId',
      () => s.amexmemberId,
      v => (s.amexmemberId = currencyToNumber(v)),
    );
    return {
      totalAnnualSales: this.totalAnnualSales,
      averageTicketValue: this.averageTicketValue,
      highTicketValue: this.highTicketValue,
      amexAnnualVolume: this.amexAnnualVolume,
      amexMemberId: this.amexMemberId,
      annualCardVolume: this.annualCardVolume,
    };
  }

  public getAverageTicketValidators(isAssisted: boolean = false) {
    return [CommonValidator.number(!isAssisted), AverageTicketValueValidator.averageTicketValue(this.mcc)];
  }

  public getAmexMemberValidators(amexDirect: boolean, isAssisted: boolean, amexMemberId) {
    if (amexDirect) {
      return [
        CommonValidator.number(!isAssisted),
        CommonValidator.amexMemberId(),
        Validators.required,
        CommonValidator.lt(amexMemberId, 'amex-direct-required', 'amex-direct-required'),
      ];
    }
    if (isAssisted && !amexDirect) {
      return [CommonValidator.number(!isAssisted), CommonValidator.amexMemberId()];
    }
    return [CommonValidator.number(!isAssisted), CommonValidator.amexMemberId(), Validators.required];
  }

  public getReferralRequiresAmexMemberId() {
    if (this.cartStore.cart && this.cartStore.cart.products) {
      this.referralRequiresAmexMemberId = !!this.cartStore.cart.products.filter(
        product => product.productId === '10435',
      ).length;

      if (this.store.partnerId && !this.referralRequiresAmexMemberId) {
        this.getPartnerAcquiringTypes(this.store.partnerId);
      }
    }
  }

  public async getAcquiringTypes(...params): Promise<AcquiringTypes[]> {
    this.acquiringTypes = await lastValueFrom(this.productService.getAcquiringTypes(...params).pipe());
    this.removeVenmoPaymentType(this.acquiringTypes);
    return this.acquiringTypes;
  }

  public async getPartnerAcquiringTypes(param?: string) {
    await this.getAcquiringTypes(param);
    return this.checkForPartnerAmexDirect(this.acquiringTypes);
  }

  public checkForPartnerAmexDirect(acquiringTypes) {
    acquiringTypes.forEach(acqType => {
      acqType.paymentTypes.forEach(paymentType => {
        if (paymentType.enabled && paymentType.productId === '10435') {
          this.hasAmexDirect = true;
          this.amexMemberId.setEnabled(this.hasAmexDirect);
          this.amexMemberId.setValidators(this.getAmexMemberValidators(this.hasAmexDirect, true, this.amexMemberId));
          this.amexMemberId.validate();
        }
      });
    });
  }

  private removeVenmoPaymentType(acquiringTypes: AcquiringTypes[]): void {
    const venmoProductId = '777-1000108704'; // Take out of this function if you need to reuse
    acquiringTypes.forEach(acquiringType => {
      const index = acquiringType.paymentTypes.findIndex(pT => pT.productId === venmoProductId);
      if (index > -1) {
        acquiringType.paymentTypes = [...acquiringType.paymentTypes.filter((pT, i) => i !== index)];
      }
    });
  }
}

export interface PaymentInfoControls {
  totalAnnualSales: MobxFormControl;
  averageTicketValue: MobxFormControl;
  highTicketValue: MobxFormControl;
  amexAnnualVolume: MobxFormControl;
  amexMemberId: MobxFormControl;
  annualCardVolume: MobxFormControl;
}
