import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constants, ROUTES } from '@xup-payments/xup-frontend-utils/constants';

export interface Route {
  route: string;
  active: boolean;
  progressCode: number;
}

@Injectable({
  providedIn: 'root',
})
export class RoutingPathService {
  public routePath = [
    { route: '', active: true, progressCode: constants.START_PROGRESS },
    { route: ROUTES.CONTACT_INFO, active: true, progressCode: constants.CONTACT_INFO_PROGRESS },
    { route: ROUTES.INDUSTRY, active: true, progressCode: constants.INDUSTRY_PROGRESS },
    { route: ROUTES.INDUSTRY_SUBCATEGORY, active: true, progressCode: constants.INDUSTRY_SUBCATEGORY_PROGRESS },
    { route: ROUTES.BUSINESS_TYPE, active: true, progressCode: constants.BUSINESS_TYPE_PROGRESS },
    { route: ROUTES.PAYMENT_TYPES, active: true, progressCode: constants.PAYMENT_TYPES_PROGRESS },
    { route: ROUTES.PAYMENT_INFO, active: true, progressCode: constants.PAYMENT_INFO_PROGRESS },
    { route: ROUTES.LOCATIONS, active: false, progressCode: constants.LOCATIONS_PROGRESS },
    { route: ROUTES.PRODUCTS, active: true, progressCode: constants.PACKAGE_SELECTION_PROGRESS },
    { route: `${ROUTES.PRODUCTS}:id`, active: false, progressCode: constants.PACKAGE_SELECTION_PROGRESS },
    { route: ROUTES.SOFTWARE, active: false, progressCode: constants.SOFTWARE_SELECTION_PROGRESS },
    { route: ROUTES.ADDITIONAL_PRODUCTS, active: false, progressCode: constants.ADDITIONAL_PACKAGE_SELECTION_PROGRESS },
    { route: ROUTES.CHECKOUT, active: true, progressCode: constants.CHECKOUT_PROGRESS },
    { route: ROUTES.BUSINESS_TAXES, active: true, progressCode: constants.BUSINESS_TAXES_PROGRESS },
    { route: ROUTES.BUSINESS_INFO, active: true, progressCode: constants.BUSINESS_INFO_PROGRESS },
    { route: ROUTES.OWNERS, active: true, progressCode: constants.BUSINESS_OWNERS_PROGRESS },
    { route: ROUTES.BANK, active: true, progressCode: constants.BANK_PROGRESS },
    { route: ROUTES.SHIPPING, active: true, progressCode: constants.SHIPPING },
    { route: ROUTES.VERIFICATION, active: false, progressCode: constants.VERIFY_QUIZ_PROGRESS },
    { route: ROUTES.RISK_VERIFICATION, active: false, progressCode: constants.VERIFY_QUIZ_PROGRESS },
    { route: ROUTES.ID_VERIFICATION, active: true, progressCode: constants.VERIFY_PROGRESS },
    { route: ROUTES.TERMS, active: true, progressCode: constants.TERMS_PROGRESS },
    { route: ROUTES.APPLICATION_CONFIRMATION, active: true, progressCode: constants.CONFIRMATION_PROGRESS },
    { route: ROUTES.ORDER_STATUS, active: true, progressCode: constants.CONFIRMATION_PROGRESS },
    { route: ROUTES.OFFRAMP, active: true, progressCode: constants.PAYMENT_TYPES_PROGRESS },
    { route: ROUTES.CALLBACK, active: true, progressCode: constants.PAYMENT_TYPES_PROGRESS },
  ];

  protected currentIndex = 0;

  constructor() {
    const routePath = JSON.parse(sessionStorage.getItem('routes'));
    if (routePath) {
      this.setRoutes(routePath);
    }
  }

  public urlToRoute(url: string): string {
    let route = url.replace('/', '');
    if (url.includes('?')) {
      route = route.split('?')[0];
    }
    return route;
  }

  public getSnapshot(route: ActivatedRoute) {
    return route.snapshot;
  }

  public getNextActiveRoute(router: Router, urlPath?: string): string {
    const path = urlPath || this.urlToRoute(router.url);
    this.currentIndex = this.routePath.findIndex(({ route }) => route === path);
    for (let i = this.currentIndex; i <= this.routePath.length - 1; i++) {
      if (this.routePath[i + 1]?.active) {
        return this.routePath[i + 1].route;
      }
    }
    return '404';
  }

  public getPrevActiveRoute(router: Router): string {
    const path = this.urlToRoute(router.url);
    this.currentIndex = this.routePath.findIndex(({ route }) => route === path);
    for (let i = this.currentIndex; i >= 0; i--) {
      if (this.routePath[i - 1]?.active) {
        return this.routePath[i - 1].route;
      }
    }
    return '404';
  }

  public isActive(router: Router): boolean {
    const path = this.urlToRoute(router.url);
    try {
      return this.routePath.find(({ route }) => route === path).active;
    } catch (e) {
      console.error('Route not found.');
      return false;
    }
  }

  public isRouteActive(path: String): boolean {
    try {
      return this.routePath.find(({ route }) => route === path).active;
    } catch (e) {
      console.error('Route not found.');
      return false;
    }
  }

  public deactivateRoute(routeToDeactivate: string) {
    try {
      this.routePath.find(({ route }) => route === routeToDeactivate).active = false;
      sessionStorage.setItem('routes', JSON.stringify(this.routePath));
    } catch (e) {
      console.error('Route not found.');
    }
  }

  public activateRoute(routeToActivate: string) {
    try {
      this.routePath.find(({ route }) => route === routeToActivate).active = true;
      sessionStorage.setItem('routes', JSON.stringify(this.routePath));
    } catch (e) {
      console.error('Route not found.');
    }
  }

  public setRoutes(routePath: Route[]) {
    this.routePath = routePath;
  }

  public getRouteProgress(route: string): number {
    const path = this.urlToRoute(route);
    try {
      return this.routePath.find(rp => rp.route === path).progressCode;
    } catch (e) {
      console.error('Route not found.');
      return 0;
    }
  }

  public getNextProgressCode(currentProgressCode: number): number {
    try {
      const index = this.routePath.findIndex(({ progressCode }) => progressCode === currentProgressCode);
      for (let i = index + 1; i < this.routePath.length; i++) {
        // Return next active progress code.
        if (this.routePath[i].active) {
          return this.routePath[i].progressCode;
        }
      }
      return this.routePath[index].progressCode;
    } catch (e) {
      console.error('Route not found.');
      return 0;
    }
  }

  public getRouteFromProgress(progressCode: number): string {
    const getRouteFromProgress = this.routePath.find(route => route.progressCode >= progressCode && route.active);
    return getRouteFromProgress ? getRouteFromProgress.route : '';
  }
}
