import { Injectable } from '@angular/core';
import { UserService } from '@eventhorizon/services/user.service';
import { action, computed, configure, makeObservable, observable, reaction } from 'mobx';
import { AuthService } from '@eventhorizon/services/auth.service';
import { UserAccount } from '@eventhorizon/models/user-account';
import { UserToken } from '@eventhorizon/models/auth-token.model';
import { Permission, Role } from '@eventhorizon/models/role';
import { combineLatest, tap } from 'rxjs';
import { PartnerStore } from '@eventhorizon/stores/partner.store';

export const permissionsNames = {
  MERCHANT: {
    CAN_GET_APPLICATION: 'CanGetApplication',
    CAN_UPDATE_APPLICATION: 'CanUpdateApplication',
    CAN_GET_LAST_VERIFICATION: 'CanGetLastVerification',
    CAN_GET_MPA: 'CanGetMpa',
    CAN_DO_EXPORT: 'CanDoExport',
    CAN_EXPORT_APPLICATION_STATUS_SUMMARY: 'CanExportApplicationStatusSummary',
    CAN_EXPORT_ALL_APPLICATION_INFORMATION: 'CanExportAllApplicationInformation',
    CAN_GET_CART: 'CanGetCart',
    CAN_UPDATE_CART: 'CanUpdateCart',
    CAN_ADD_PRODUCTS_TO_CART: 'CanAddProductsToCart',
    CAN_SET_SHIPPING_LOCATIONS: 'CanSetShippingLocations',
    CAN_VALIDATE_CART: 'CanValidateCart',
    CAN_UPDATE_APPLICATION_STATUS: 'CanUpdateApplicationStatus',
    CAN_CREATE_NOTES: 'CanCreateNotes',
    CAN_UPDATE_NOTES: 'CanUpdateNotes',
    CAN_DELETE_NOTES: 'CanDeleteNotes',
    CAN_GET_PARTNER_ADMIN_USERS: 'CanGetPartnerAdminUsers',
    CAN_VIEW_DDA_INTEREST: 'CanViewDDAInterest',
  },
  DASHBOARD: {
    CAN_GET_STATUSES: 'CanGetStatuses',
    CAN_GET_APPLICATIONS_BY_DATE: 'CanGetApplicationsByDate',
    CAN_GET_APPLICATIONS_VOLUME: 'CanGetApplicationsVolume',
    CAN_GET_ACTION_NEEDED: 'CanGetActionNeeded',
  },
  PARTNERS: {
    CAN_CREATE_PARTNERS: 'CanCreatePartners',
    CAN_UPDATE_PARTNERS: 'CanUpdatePartners',
    CAN_GET_PARTNERS: 'CanGetPartners',
    CAN_GET_PARTNERS_SUMMARY: 'CanGetPartnersSummary',
    CAN_IMPORT_PARTNERS: 'CanImportPartners',
    CAN_ADD_PAYMENT_TYPES: 'CanAddPaymentTypes',
    CAN_OVERRIDE_PRODUCT_RATE_FEE: 'CanOverrideProductRateFee',
    CAN_VIEW_PRODUCT_RATE_FEE: 'CanViewProductRateFee',
    CAN_UPDATE_PARTNER_INFO: 'CanUpdatePartnersContactInfo',
    CAN_ACCESS_PREFILL_TEMPLATES: 'CanAccessPrefillTemplates',
  },
  USERS: {
    CAN_GET_USERS: 'CanGetUsers',
    CAN_DELETE_USERS: 'CanDeleteUsers',
    CAN_CREATE_USERS: 'CanCreateUsers',
    CAN_UPDATE_USERS: 'CanUpdateUsers',
    CAN_GET_ROLES: 'CanGetRoles',
    CAN_GET_USER_ACTIONS: 'CanGetUserActions',
  },
  CAN_ADD_PARTNER_PRODUCT_RECOMMENDATIONS: 'CanAddPartnerProductRecommendations',
  CAN_CREATE_PARTNER_PRICING: 'CanCreatePartnerPricing',
  CAN_UPDATE_PARTNER_PRICING: 'CanUpdatePartnerPricing',
  CAN_DELETE_PARTNER_PRICING: 'CanDeletePartnerPricing',
  CAN_GET_AL_PRODUCTS: 'CanGetAllProducts',
  CAN_TOGGLE_PRODUCT_STATUS: 'CanToggleProductStatus',
  CAN_TOGGLE_PRODUCT_PRICING_STATUS: 'CanToggleProductPricingStatus',
  SONY: {
    CAN_VIEW_SONY_REPORT: 'CanViewSonyReport',
    CAN_SELECT_SONY_REPORT: 'CanSelectSonyData',
    CAN_DOWNLOAD_SONY_REPORT: 'CanDownloadSonyData',
    CAN_EDIT_SONY_REPORT: 'CanEditSonyData',
    CAN_DELETE_SONY_REPORT: 'CanDeleteSonyData',
  },
  PARTNER_ADMIN: {
    CAN_VIEW_PARTNER_ADMIN_PROFILE: 'CanViewPartnerAdminProfile',
    CAN_VIEW_PARTNER_HELP: 'CanViewPartnerHelpSupport',
  },
  SUPPORT: {
    CAN_VIEW_HELP: 'CanViewHelpSupport',
  },
};

@Injectable({ providedIn: 'root' })
export class AuthStore {
  userId = '';

  // TODO: Depracate, use partnerIds instead
  // partner-admins used to only be able to be associated with one
  // Now they can be associated with many
  partnerId = '';

  partnerIds: string[] = [];

  userAccount: UserAccount = null;

  permissions: Map<string, Permission> = new Map();

  allPermissions: Permission[] = [];

  allRoles: Role[] = [];

  public permissionsLoaded: boolean = false;

  readonly userStorage = '$kb$_currentUser';

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private partnerStore: PartnerStore,
  ) {
    configure({
      enforceActions: 'never',
    });
    makeObservable(this, {
      userId: observable,
      userAccount: observable,
      permissions: observable,
      allPermissions: observable,
      allRoles: observable,
      setUserID: action,
      userRole: computed,
      cleanAuth: action,
      permissionsLoaded: observable,
    });

    reaction(
      () => this.userId,
      userId => {
        if (userId && userId !== '') {
          this.permissionsLoaded = false;

          combineLatest([this.userService.getUser(userId), this.userService.getRoles('SupportTool')]).subscribe(
            ([user, roles]) => {
              this.userAccount = user;
              this.allRoles = roles;
              // TODO: Depracate, use partnerIds instead
              // partner-admins used to only be able to be associated with one
              // Now they can be associated with many
              this.partnerId =
                this.userGroups.find(group => group.entityType.toLocaleLowerCase() === 'partner')?.entityId || '';
              this.partnerIds = this.userGroups.filter(group => group.entityType.toLocaleLowerCase() === 'partner').map(p => p.entityId);
              this.userService
                .getPermissions()
                .pipe(
                  tap(permissions => {
                    this.allPermissions = permissions;
                    this.permissions = this.getAllPermissionsFromRole(permissions);
                  }),
                )
                .subscribe(() => {
                  this.permissionsLoaded = true;
                });
            },
          );
        }
      },
    );
  }

  public setUserID(id: string) {
    this.userId = id;
  }

  public get userRole() {
    const token: UserToken = this.authService.getAuthToken();
    const userInfo = token ? JSON.parse(atob(this.authService.getAuthToken().token.split('.')[1])) : undefined;
    return userInfo?.Role ? userInfo.Role : '';
  }

  public get userGroups() {
    return this.userAccount.groups || [];
  }

  public cleanAuth() {
    this.userId = '';
    this.userAccount = null;
    this.permissions.clear();
    this.allPermissions = [];
    this.allRoles = [];
  }

  private getAllPermissionsFromRole(permissions: Permission[]): Map<string, Permission> {
    const filteredPermissions = permissions.filter(permission => permission.roles.includes(this.userRole));
    const mappedPermissions = new Map();
    filteredPermissions.forEach(value => {
      mappedPermissions.set(value.name, value);
    });

    return mappedPermissions;
  }

  public getPayfacId(): string | undefined {
    const token: UserToken = this.authService.getAuthToken();
    const userInfo = token ? JSON.parse(atob(this.authService.getAuthToken().token.split('.')[1])) : undefined;
    return userInfo?.PayfacId ? userInfo.PayfacId : undefined;
  }
}
