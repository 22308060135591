import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProductService } from '@eventhorizon/services/product.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { sortByNumber } from '@eventhorizon/util/util';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { reaction } from 'mobx';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SoftwarePopupComponent } from './software-popup/software-popup.component';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { Product } from '@xup-payments/xup-frontend-utils/models';
import { constants } from '@xup-payments/xup-frontend-utils/constants';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.scss'],
})
export class SoftwareComponent extends FormCarouselSlide<FormGroup<ISoftwareForm>> implements OnInit {
  protected allProducts: Product[];

  products: Product[];

  public isLoading = true;

  public itemsPerSlide = 3;

  public learnMoreClickHandler = this.onLearnMoreClick.bind(this);

  constructor(
    public cartStore: CartStore,
    public applicationStore: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected productService: ProductService,
    protected bsModalService: BsModalService,
    protected route: ActivatedRoute,
    protected contentService: ContentServiceService,
    protected fb: FormBuilder,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.offeringType) {
        this.loadSoftware(params.offeringType);
      } else {
        this.loadSoftware(this.cartStore.softwareErrorType);
      }
    });
    this.buildForm();

    reaction(
      () => this.cartStore.cart && this.cartStore.cart.errors,
      () => {
        if (this.cartStore.hasSoftwareError) {
          this.form.controls.software.setValue(null);
        } else {
          this.form.controls.software.setValue(this.cartStore.currentSoftwareSelection());
        }
      },
    );
  }

  public buildForm() {
    this.form = this.fb.group({
      software: [this.cartStore ? this.cartStore.currentSoftwareSelection() : null, Validators.required],
    });
  }

  private loadSoftware(offeringType: string) {
    this.isLoading = true;

    const locationId = this.applicationStore.firstBusinessLocationId;

    if (!locationId) {
      console.error('Unable to load software');
      this.isLoading = false;
      return;
    }

    this.productService.getSoftwareProducts(offeringType, this.applicationStore.id, locationId).subscribe(result => {
      this.allProducts = result;
      this.processDescriptions(this.allProducts);
      this.isLoading = false;
    });
  }

  protected processDescriptions(allProducts) {
    this.products = sortByNumber(allProducts, 'price', constants.ASC);
    for (const product of this.products) {
      const resumeDescription = this.getFirstTwoSentences(product.productLongDescription);
      product.productLongDescription = resumeDescription;
    }
  }

  private getFirstTwoSentences(description: string): string {
    if (!description) return '';

    const sentences = description.split('.');
    if (sentences.length >= 2) {
      return sentences[1] && sentences[1].trim() ? `${sentences[0]}.${sentences[1]}.` : description;
    }
    return description;
  }

  public onLearnMoreClick(productId) {
    const product = this.products.find(p => p.productId === productId);
    if (!product) return;

    this.bsModalService.show(SoftwarePopupComponent);
  }

  public isNextDisabled(): boolean {
    return this.cartStore && this.cartStore.cart && this.cartStore.cart.errors && this.cartStore.hasSoftwareError;
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}

export interface ISoftwareForm {
  software: FormControl<Product>;
}