import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

interface IDropdownOptions {
  name: string;
  value: string;
}

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
})
export class DropdownFieldComponent {
  @ViewChild('mainButton') mainButton: ElementRef;

  @ViewChild(BsDropdownDirective, { static: false }) dropdown: BsDropdownDirective;

  @Input() field: FormControl;

  @Input() label: string;

  @Input() id: string;

  @Input()
  set options(value: IDropdownOptions[] | Set<string>) {
    this._options = Array.from(value as any).map((v: any) => (v.name ? v : { name: v, value: v }));
  }

  private _options: IDropdownOptions[];

  private searchTermValue = '';

  private searchWaitTime = 1000;

  private lastSearchEventTime: number;

  get options(): IDropdownOptions[] {
    return this._options;
  }

  get formControlValue(): string {
    if (!this.field?.value) return null;
    return this.options.find(st => st.value === this.field.value)?.name;
  }

  @HostListener('keyup', ['$event'])
  keyEvent({ key }: KeyboardEvent) {
    if (key.length > 1) return;

    this.accumulateSearchTerms(key);
    this.searchTerm();
  }

  private accumulateSearchTerms(term: string) {
    const restartSearchTerm: boolean = 
      this.lastSearchEventTime ? 
        ( Date.now() - this.lastSearchEventTime ) > this.searchWaitTime 
        : true;
    
    if (restartSearchTerm) {
      this.searchTermValue = '';
    }

    this.lastSearchEventTime = Date.now();
    this.searchTermValue += term;
  }

  private searchTerm() {
    const lowerCaseTerm = this.searchTermValue.toLocaleLowerCase();
    const match = this._options.find(opt => opt.name.toLocaleLowerCase().startsWith(lowerCaseTerm))?.value;

    if (typeof match !== 'undefined') {
      this.selectOption(match);
    }
  }

  selectOption(value: string) {
    this.field.setValue(value);
    this.mainButton?.nativeElement?.focus();
  }

  toggleDropdown():void {
    this.dropdown.toggle(true);
  }
}
