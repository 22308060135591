<div id="mainContent" tabindex="0" class="main-container container has-header ps-6 pe-6">
  <div class="row container-inner callback-inner">
    <div class="col-md-12">
      <div class="callback-heading-container">
        <h3 class="callback-subheading text-center">Thanks for Requesting a Call Back</h3>
      </div>
    </div>
    <div class="callback-content">
      <div class="info-text callback-inner text-center">
        One of our Merchant Services team members will contact you within 24 business hours to assist with your
        application.
        <br />
        <br />
      </div>
      <div class="text-center">In the meantime, if you have any questions please call us at</div>
      <div class="info-text callback-inner text-center callback-heading">
        {{ contentService?.bankInfoConfig?.phoneNumber }}
      </div>
      <div *ngIf="contentService?.bankInfoConfig?.weekdayPhoneHours" class="info-text callback-inner text-center">
        Our team is available {{ contentService?.bankInfoConfig?.weekdayPhoneHours }} for calls
        <span *ngIf="contentService?.bankInfoConfig?.chatHours">
          &nbsp;and {{ contentService?.bankInfoConfig?.chatHours }} via chat.
        </span>
      </div>
      <div class="row text-center buttons">
        <div class="col-sm-12">
          <button
            type="button"
            class="primary-button app-slide-button callback-button btn-primary"
            (click)="learnMore()"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
