import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { CurrencyPipe } from '@angular/common';
import { SecurityPipe } from '@eventhorizon/pipes/security.pipe';
import { catchError } from 'rxjs/operators';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { Application, BusinessLocationModel } from '@xup-payments/xup-frontend-utils/models';
import { formatDatefromUTCtoLocal, formatPhone, getStringAddress } from '@xup-payments/xup-frontend-utils/utils';
import moment from 'moment';

@Component({
  selector: 'app-your-app',
  templateUrl: './your-app.component.html',
  styleUrls: ['./your-app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SecurityPipe],
})
export class YourAppComponent implements OnInit {
  constructor(
    private applicationService: ApplicationService,
    private securityPipe: SecurityPipe,
    private currencyPipe: CurrencyPipe,
  ) {}

  @Input() applicationId: string;

  @Output() private itemClickAction: EventEmitter<any> = new EventEmitter<any>();

  public ColumnMode = ColumnMode;

  public isLoading = false;

  public rows = [];

  public errorMessage: string;

  ngOnInit() {
    this.refresh();
  }

  itemClick(row) {
    let event = {
      type: 'click',
      row: row
    }
    this.itemClickAction.emit(event);
  }

  refresh() {
    this.isLoading = true;

    forkJoin([
      this.applicationService.getApplicationById(this.applicationId).pipe(
        catchError(() => {
          this.isLoading = false;
          this.errorMessage = 'Unable to load application information.';
          return of(undefined);
        }),
      ),
    ]).subscribe(response => {
      this.rows = this.parseApplicationForTableDisplay(response[0]);
      this.isLoading = false;
    });
  }

  private parseYourNeedsForTableDisplay(application) {
    const transactionInfoData =
      (application &&
        application.transactionInfo && [
        {
          title: 'Gross Annual Sales',
          value: this.currencyPipe.transform(application.transactionInfo.totalAnnualSales),
          redirectOnboardingLink: ROUTES.PAYMENT_INFO,
        },
        {
          title: 'Average Ticket Size',
          value: this.currencyPipe.transform(application.transactionInfo.averageTicketValue),
          redirectOnboardingLink: ROUTES.PAYMENT_INFO,
        },
        {
          title: 'Annual Credit Card Revenue',
          value: this.currencyPipe.transform(application.transactionInfo.creditCardVolume),
          redirectOnboardingLink: ROUTES.PAYMENT_INFO,
        },
      ]) ||
      [];

    if (application.transactionInfo && application.transactionInfo.amexAnnualVolume) {
      transactionInfoData.push({
        title: 'AMEX Annual Sales',
        value: this.currencyPipe.transform(application.transactionInfo.amexAnnualVolume),
        redirectOnboardingLink: ROUTES.PAYMENT_INFO,
      });
    }
    if (application.transactionInfo && application.transactionInfo.amexmemberId) {
      transactionInfoData.push({
        title: 'AMEX Member Id',
        value: application.transactionInfo.amexmemberId,
        redirectOnboardingLink: ROUTES.PAYMENT_INFO,
      });
    }

    return (
      application && [
        ...transactionInfoData,
        {
          title: 'Industry',
          value: application.businessCategory && application.businessCategory.name,
          redirectOnboardingLink: ROUTES.INDUSTRY,
        },
        {
          title: 'Number of Locations',
          value: application?.businessLocations?.length,
          redirectOnboardingLink: ROUTES.LOCATIONS,
        },
      ]
    );
  }

  private parseApplicationForTableDisplay(application: Application) {
    if (!application) return [];

    return [
      ...this.parseBusinessInfoForTableDisplay(application),
      ...this.parseBusinessOwnersForTableDisplay(application),
      ...this.parseLocationsForTableDisplay(application),
      ...this.parseYourNeedsForTableDisplay(application),
    ];
  }

  private parseBusinessOwnersForTableDisplay(application: Application) {
    if (!application.businessOwners) return [];
    const orderedOwners = application.businessOwners;
    orderedOwners.forEach(owner => {
      owner.ownerAddress = getStringAddress(owner.address);
    });
    const ownersObjs = [];
    orderedOwners.forEach((owner, index) => {
      ownersObjs.push([
        {
          title: `Owner ${index + 1} Name`,
          value: `${owner.firstName} ${owner.lastName}`,
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} Title`,
          value: owner.title,
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} SSN`,
          value: this.securityPipe.transform(owner.socialSecurityNumber),
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} D.O.B.`,
          value: moment(owner.dateOfBirth).isValid()
            ? moment.utc(owner.dateOfBirth).format('MM/DD/YYYY')
            : this.securityPipe.transform(owner.dateOfBirth?.toString()),
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} Phone Number`,
          value: formatPhone(owner.phone),
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} Email`,
          value: owner.email,
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} % Ownership`,
          value: `${owner.percentageOwnership}%`,
          redirectOnboardingLink: ROUTES.OWNERS,
        },
        {
          title: `Owner ${index + 1} Address`,
          value: `${owner.ownerAddress}`,
          redirectOnboardingLink: ROUTES.OWNERS,
        },
      ]);
    });

    const res = [];
    ownersObjs.forEach(ownerObj => {
      res.push(...ownerObj);
    });
    return res;
  }

  private parseBusinessInfoForTableDisplay(application: Application) {
    if (!application.businessInfo) return [];
    const TIN_TYPES = {
      0: 'SSN',
      1: 'TIN',
    };
    return [
      {
        title: 'Contact Name',
        value: `${application.applicantInfo.name} ${application.applicantInfo.lastName}`,
        redirectOnboardingLink: ROUTES.CONTACT_INFO,
      },
      {
        title: 'Business Structure',
        value: application.businessInfo.organizationType && application.businessInfo.organizationType.description,
        redirectOnboardingLink: ROUTES.BUSINESS_TAXES,
      },
      {
        title: 'Taxes Filled With',
        value: TIN_TYPES[application.businessInfo.tinType],
        redirectOnboardingLink: ROUTES.BUSINESS_TAXES,
      },
      {
        title: 'Tax Filing Name',
        value: application.businessInfo.taxFilingName,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'TIN',
        value: this.securityPipe.transform(application.businessInfo.taxId),
        redirectOnboardingLink: ROUTES.BUSINESS_TAXES,
      },
      {
        title: 'Legal Name',
        value: application.businessInfo.legalName,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'DBA Name',
        value: application.businessInfo.dbaName,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'Date of Incorporation',
        value: formatDatefromUTCtoLocal(application.businessInfo.startDate?.toString(), false),
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'Business Phone Number',
        value: formatPhone(application.businessInfo.phone),
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'Business Email',
        value: application.businessInfo.email,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'Business Website',
        value: application.businessInfo.website,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
    ];
  }

  private parseLocationsForTableDisplay(application: Application) {
    const legalAddress = application.businessAddresses && getStringAddress(application.businessAddresses.legalAddress);
    const currentShippingAddress = application.businessLocations[0].productShippingAddress;
    const mailingAddress = currentShippingAddress && getStringAddress(currentShippingAddress.address);
    const categories = {
      faceToFace: 'In Store',
      internet: 'Online',
      onTheGo: 'On the Go',
      phoneOrEmail: 'Phone or Mail',
    };

    let locationAddresses = [];

    const parseTransactionDistribution: Function = (
      businessLocation: BusinessLocationModel,
      allDistributions: string[],
    ) => {
      let allDistributionsText = '';
      if (businessLocation.transactionDistribution) {
        Object.keys(businessLocation.transactionDistribution).forEach(key => {
          if (categories[key] && businessLocation.transactionDistribution[key]) {
            allDistributions.push(`${categories[key]} (${businessLocation.transactionDistribution[key]}%)`);
          }
        });
      }
      allDistributionsText += `${allDistributions.join(', ')}\n`;
      return allDistributionsText;
    };

    application.businessLocations.forEach((businessLocation: BusinessLocationModel, index: number) => {
      const locationName = businessLocation.name || `Location ${index + 1}`;

      const allDistributions = [];

      let bankName = '';
      let accountNumber = '';
      let routingNumber = '';
      if (businessLocation.bankInfo) {
        bankName = businessLocation.bankInfo.bankName;
        accountNumber = businessLocation.bankInfo.accountNumber;
        routingNumber = businessLocation.bankInfo.routingNumber;
      }

      let mccCode = businessLocation.mcc || '';
      let mccName = businessLocation.mccDescription || '';

      locationAddresses = [
        ...locationAddresses,
        {
          title: `${locationName} Address`,
          value: getStringAddress(businessLocation.address),
          redirectOnboardingLink: ROUTES.BUSINESS_INFO,
        },
        {
          title: `${locationName} Bank Name`,
          value: bankName,
          redirectOnboardingLink: ROUTES.BANK,
        },
        {
          title: `${locationName} Bank Account Number`,
          value: this.securityPipe.transform(accountNumber),
          redirectOnboardingLink: ROUTES.BANK,
        },
        {
          title: `${locationName} Bank Routing Number`,
          value: this.securityPipe.transform(routingNumber),
          redirectOnboardingLink: ROUTES.BANK,
        },
        {
          title: `${locationName} Payment Channel`,
          value: parseTransactionDistribution(businessLocation, allDistributions),
          redirectOnboardingLink: ROUTES.PAYMENT_TYPES,
        },
        {
          title: `${locationName} MCC Name`,
          value: mccName,
          redirectOnboardingLink: ROUTES.BUSINESS_TYPE,
        },
        {
          title: `${locationName} MCC Code`,
          value: mccCode,
          redirectOnboardingLink: ROUTES.BUSINESS_TYPE,
        },
      ];
    });

    return [
      {
        title: 'Legal Business Address',
        value: legalAddress,
        redirectOnboardingLink: ROUTES.BUSINESS_INFO,
      },
      {
        title: 'Shipping Address',
        value: mailingAddress,
        redirectOnboardingLink: ROUTES.SHIPPING,
      },
      ...locationAddresses,
    ];
  }
}
