import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInterface } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-status-popup',
  templateUrl: './status-popup.component.html',
  styleUrls: ['./status-popup.component.scss'],
})
export class StatusPopupComponent implements ModalInterface, OnDestroy {
  title: string;

  subtitle: string;

  constructor(private modalRef: BsModalRef) {}

  public ngOnDestroy() {
    this.close();
  }

  public close(): void {
    this.modalRef.hide();
  }
}
