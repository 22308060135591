import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { messages } from '@eventhorizon/data/messages.data';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { IStepTrackerConfig } from '../step-tracker/step-tracker.component';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UnsavedChangesPopupComponent } from '@eventhorizon/components/unsaved-changes-popup/unsaved-changes-popup.component';

@Component({
  selector: 'app-location-info-screen',
  templateUrl: './location-info-screen.component.html',
  styleUrls: ['./location-info-screen.component.scss'],
})
export class LocationInfoScreenComponent extends BasePageDriverComponent {
  public text = messages.locationInfoSlide;

  public stepTrackerConfig: IStepTrackerConfig = {
    steps: ['Information', 'Products'],
    activeIndex: 0,
  };

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    private locationService: LocationsService,
    protected bsModalService: BsModalService,
  ) {
    super(rs, router, route);
    // needed so it always refreshes with the right current location
    this.locationService.reloadLocation();
    if (this.locationService.currentLocation) {
      if (this.locationService.currentLocation.isPrimary) {
        this.text.smallHeadline = 'Tell us about your primary business location.';
      } else {
        this.text.smallHeadline = 'Tell us about your business location.';
      }
    }
  }

  prev() {
    const modalRef: BsModalRef = this.bsModalService.show(UnsavedChangesPopupComponent, {
      ariaLabelledBy: 'unsaved-changes',
      class: 'modal-md',
    });
    modalRef.onHide.subscribe();
  }
}
