import { Component, Input, ViewChild } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { LocationsListComponent } from '../locations-list/locations-list.component';
import { Cart } from '@eventhorizon/models/cart.model';
import { BusinessLocationModel } from '@eventhorizon/models/business-location.model';

@Component({
  selector: 'app-tg-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {

  @Input()
  public locations: BusinessLocationModel[] = null;

  @Input()
  public locationCarts: Cart[] = null;

  @Input()
  public allowTaxEstimation: boolean = true;

  @ViewChild('locationsList') 
  public locationsList: LocationsListComponent;

  constructor(protected appStore: ApplicationStore) { }
  
  public getLocations() {
    return this.locations || this.appStore.businessLocations;
  }

  public get carts(): Cart[] {
    return this.locationsList?.carts || [];
  }

  public get primaryCart(): Cart {
    const primaryLocation = this.getLocations().find(x => x.isPrimary);
    if (!primaryLocation?.id) return null;
    return this.carts?.find(x => x.locationId === primaryLocation.id);
  }
}
