export const fiServIds = {
  swipedNonSwiped: ['80382', '80402'],
  interchangePlus: ['31848', '2'],
};

export const elavonIds = {
  swipedNonSwiped: {
    name: 'CLEAR_AND_SIMPLE',
    classes: ['A', 'B', 'C', 'D'],
    types: ['SWIPE', 'KEYED'],
  },
};
