import { OnInit, Component, Input, ViewChild } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ProductController } from '@eventhorizon/controllers/product.controller';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { RatesFeesComponent } from './rates-fees-component/rates-fees.component';
import { AcquiringPricing, Details, Product } from '@xup-payments/xup-frontend-utils/models';
import { transactionTooltipTexts } from '@xup-payments/xup-frontend-utils/constants';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  @Input() headers = ['', 'Rate', 'Fee'];

  public acquiringPricing: AcquiringPricing;

  public ratesAndFees: Details[] = [];

  public acquiringProducts: Product[] = [];

  public showRates = false;

  public title: string;

  public tooltipText: string;

  public isSwiped = false;

  @ViewChild('ratesNFees')
  private ratesAndFeesExample: RatesFeesComponent;

  @Input() keepRatesOpen = false;

  public errorMessage = '';

  public ratesAndFeesGroup: {
    [key: number]: {
      groupDisplayName: string;
      rf: Details;
    };
  } = {};

  constructor(
    public appStore: ApplicationStore,
    protected cartStore: CartStore,
    private productController: ProductController,
  ) {}

  ngOnInit(): void {
    if (!this.cartStore.acquiringPricing) {
      return;
    }
    this.acquiringPricing = this.cartStore.acquiringPricing;
    this.processTransactionProducts();
    this.ratesAndFees = this.productController.filterAndSortPricing(this.acquiringPricing, true, true);
    this.setRatesAndFeesGroups();
    this.getAcquiringProductsFromCart();
  }

  public showMonth(detail: Details): boolean {
    return detail.productType === 'MO_FEE' && detail.occurrence.type === 'Recurring';
  }

  private setRatesAndFeesGroups() {
    this.ratesAndFees.forEach((rf, index) => {
      this.ratesAndFeesGroup[index] = {
        rf,
        groupDisplayName: this.showGroupDisplayName(rf),
      };
    });
  }

  public showGroupDisplayName(detail: Details): string {
    return detail.productName || detail.description || detail.groupDisplayName;
  }

  public toggleShowRates() {
    this.showRates = !this.showRates;
  }

  public processTransactionProducts() {
    if (this.cartStore.transactionProducts.length === 1) {
      this.getTransactionProductType(this.cartStore.transactionProducts[0]);
      this.title = this.cartStore.transactionProducts[0].productName;
      this.tooltipText = this.isSwiped ? transactionTooltipTexts.SWIPED : transactionTooltipTexts.INTERCHANGE;
    }
    if (!this.isSwiped) {
      this.title = 'Interchange +';
      this.tooltipText = transactionTooltipTexts.INTERCHANGE;
    }
  }

  public getTransactionProductType(transactionProduct: Product) {
    this.isSwiped = transactionProduct.productId === '10041' || transactionProduct.productId === 'CLEAR_AND_SIMPLE';
  }

  public getAcquiringProductsFromCart() {
    this.acquiringProducts = this.cartStore.cart.products.filter(product => product.productType === 'ACQUIRING');
  }
}
