import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { defaultImage } from '@eventhorizon/constants/general.constants';
import { Product } from '@eventhorizon/models/product.model';

@Component({
  selector: 'app-tg-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent {
  @Input()
    product: Product;

  @Input()
    clickAction: 'Navigate' | 'Detail' = 'Navigate';

  @Output()
    productClicked = new EventEmitter();

  public defaultImage = defaultImage;

  constructor(private router: Router) {}

  isOwned() {
    return this.product.pricingModel.find(pm => pm.purchaseType === 'O');
  }

  async selectProduct() {
    if (!this.product) return;
    // if (this.clickAction === 'Navigate') {
    //   return this.router.navigateByUrl(`/products/${this.product.productId}`);
    // }
    return this.productClicked.emit(this.product);
  }

  public pricingModel() {
    if (this.product.pricingModel.length === 0) return;

    for (const pricingModel of this.product.pricingModel) {
      if (Object.values(pricingModel).includes('IP3') || Object.values(pricingModel).includes('R')) {
        return pricingModel;
      }
    }
  }
}
