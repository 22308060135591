import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProductService } from '@eventhorizon/services/product.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { reaction } from 'mobx';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Product } from '@xup-payments/xup-frontend-utils/models';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-additional-products',
  templateUrl: './additional-products.component.html',
  styleUrls: ['./additional-products.component.scss'],
})
export class AdditionalProductsComponent extends FormCarouselSlide implements OnInit {
  public products: Product[];

  public isLoading = true;

  @Output() pinpadDetected = new EventEmitter<boolean>();

  constructor(
    public cartStore: CartStore,
    public cd: ChangeDetectorRef,
    private productService: ProductService,
    protected bsModalService: BsModalService,
    protected route: ActivatedRoute,
    private fb: UntypedFormBuilder,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit() {
    if (this.isAdditionalErrorPinpad()) this.pinpadDetected.emit(true);
    else this.pinpadDetected.emit(false);
    this.loadProducts(this.cartStore.additionalErrorType);
    this.form = this.fb.group({
      additionalProduct: ['', Validators.required],
    });

    reaction(
      () => this.cartStore.additionalErrorType,
      pinpadError => {
        if (!pinpadError) {
          const pinpadProductInCart = this.getPinpadProductInCart();
          if (pinpadProductInCart) this.form.controls.additionalProduct.setValue(pinpadProductInCart);
        }
      },
    );
  }

  private getPinpadProductInCart() {
    return this.cartStore.cart.products.find(product => product.productType === 'PINPAD');
  }

  private loadProducts(offeringType: string) {
    this.isLoading = true;
    this.productService.getProductsByOfferingType(offeringType).subscribe(result => {
      this.products = result;
      this.isLoading = false;
    });
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public isNextDisabled(): boolean {
    return this.cartStore && this.cartStore.cart && this.cartStore.cart.errors && this.cartStore.cart.errors.length > 0;
  }

  public isAdditionalErrorPinpad(): boolean {
    return this.cartStore.additionalErrorType === 'pinpad';
  }
}
