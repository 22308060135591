import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-yodlee-popup',
  templateUrl: './yodlee-popup.component.html',
  styleUrls: ['./yodlee-popup.component.scss'],
})
export class YodleePopupComponent implements OnDestroy {
  constructor(protected modalRef: BsModalRef) {}

  public ngOnDestroy() {
    this.close();
  }

  public close(): void {
    this.modalRef.hide();
  }
}
