import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

/**
 * Shortcut to making a field required if a condition is met
 *
 * @param conditional
 */
export function conditionalRequiredValidator(conditional: Function): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (conditional()) {
      return Validators.required(control);
    }
    return null;
  };
}
