import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class OwnershipValidator {
  static ownership(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        const ownership = +control.value;

        if (!ownership || ownership < 1 || ownership > 100) {
          return {
            'ownership-invalid': true,
          };
        }
      } else if (isRequired) {
        return {
          'ownership-required': true,
        };
      }

      return null;
    };
  }

  static ownership100(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return {
        'ownership-100': true,
      };
    }
    return null;
  }

  static ownership50(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return {
        'ownership-50': true,
      };
    }
    return null;
  }

  static ownershipOver100(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) {
      return {
        'total-ownership-invalid': true,
      };
    }
    return null;
  }
}
