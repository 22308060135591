import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-tracker',
  templateUrl: './step-tracker.component.html',
  styleUrls: ['./step-tracker.component.scss'],
})
export class StepTrackerComponent {
  @Input()
  public config: IStepTrackerConfig = { steps: [], activeIndex: -1 };
}

export interface IStepTrackerConfig {
  // room for further customization if we want to reuse outside of locations step tracker
  steps: string[],
  activeIndex: number,
}