import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@eventhorizon/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { RiskService } from '@eventhorizon/services/risk.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { statuses } from '@eventhorizon/constants/general.constants';
import { ROUTES } from '@eventhorizon/constants/routes.constants';
import { WINDOW_TOKEN } from '@eventhorizon/injection-keys/injection-keys';
import { lastValueFrom } from 'rxjs';
import { PartnerStore } from '@eventhorizon/stores/partner.store';

@Component({
  selector: 'app-resume-screen',
  templateUrl: './resume-screen.component.html',
  styleUrls: ['./resume-screen.component.scss'],
})
export class ResumeScreenComponent implements OnInit {
  public errorMessage: string;

  private token: string;

  public tokenExpired = false;

  public displayBackUserMessage = false;

  public code: number;

  public status: string;

  public internalStatusId: number;

  twoFAShow: boolean = false;

  requestSent: boolean = false;

  private initialURL: string;

  logo = '/assets/keybank/header-footer/KB-Logo.png';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public appStore: ApplicationStore,
    public cartStore: CartStore,
    public appService: ApplicationService,
    private locationsService: LocationsService,
    public routingPathService: RoutingPathService,
    private riskService: RiskService,
    private partnerStore: PartnerStore,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {
    this.initialURL = this.window.location.href;
  }

  get accessToken(): string {
    return this.token;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.token === 'expired') {
        this.tokenExpired = true;
        this.twoFAShow = false;
        history.pushState(null, null, this.initialURL);
        window.onpopstate = () => {
          history.pushState(null, null, this.initialURL);
        };
      } else if (params.token) {
        this.token = params.token;
      }
    });
  }

  public async resume(): Promise<boolean> {
    try {
      await lastValueFrom(this.appStore.loadApplication()); //Observable
      this.locationsService.initLocationsService(); //Non async function
      await lastValueFrom(this.cartStore.loadCart()); //Observable

      const result = await lastValueFrom(this.appService.getApplicationForUser());
      this.status = result.status.internalOrderStatus.status;
      this.code = result.status.progressCode;
      this.internalStatusId = result.status.internalOrderStatus.id;

      if (result.partnerId) {
        await this.setPartnerInfo(result.partnerId);
      }

      if (this.internalStatusId === statuses.InProgress || this.internalStatusId === statuses.OffRamped) {
        const path = this.routingPathService.getRouteFromProgress(this.code);
        this.router.navigate([path]);
      } else {
        this.router.navigate(['application-confirmation']);
      }

      return true;
    } catch {
      this.errorMessage = 'Invalid token. An email token can only be used once.';
      return false;
    }
  }

  public async setPartnerInfo(partnerID: string) {
    try {
      const partnerData: any = await this.partnerStore.getPartnerById(partnerID);
      const storageInfo = {
        slug: partnerData?.slug ? partnerData.slug : null,
        logo: partnerData?.imageBase64 ? `data:image/png;base64,${partnerData.imageBase64}` : null,
      };
      sessionStorage.setItem('partner_info', JSON.stringify(storageInfo));
    } catch (e) {
      console.error(e);
    }
  }

  twoFactorInit() {
    this.twoFAShow = true;
  }

  public requestAppLink() {
    this.requestSent = true;
  }

  public startApplication() {
    const partnerInfoString = sessionStorage.getItem('partner_info');
    this.appStore.clearApplication();
    sessionStorage.clear();
    this.appStore = new ApplicationStore(this.appService, this.routingPathService, this.riskService);
    this.authService.ensureGuestToken().subscribe(() => {
      if (partnerInfoString !== null) {
        const partnerInfo = JSON.parse(partnerInfoString);
        this.router.navigate([`${ROUTES.PARTNER}/${partnerInfo.slug}`]);
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
