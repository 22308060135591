import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { messages } from '@eventhorizon/data/messages.data';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { routes } from '@eventhorizon/data/routes.data';
import { ApplicationPageComponent } from '../application-page/application-page.component';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { IStepTrackerConfig } from '../step-tracker/step-tracker.component';
import { Cart } from '@eventhorizon/models/cart.model';
import { goToTop } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-location-products-screen',
  templateUrl: './location-products-screen.component.html',
  styleUrls: ['./location-products-screen.component.scss'],
})
export class LocationProductsScreenComponent extends BasePageDriverComponent implements OnInit {
  @ViewChild('page') public page: ApplicationPageComponent;

  public text = messages.locationProductsSlide;

  public locationIndex: number = -1;

  public cart: Cart;

  public stepTrackerConfig: IStepTrackerConfig = {
    steps: [ 'Information', 'Products' ],
    activeIndex: 1,
  };

  constructor(
    public store: ApplicationStore,
    protected cartStore: CartStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);

    // if navigated here without location query, redirect to locations
    this.route.queryParams.subscribe(params => {
      const locationIndex = params.location;
      if (isNaN(locationIndex) || locationIndex < 0 || locationIndex >= store.businessLocations.length) {
        router.navigate([routes.LOCATIONS]);
      } else {
        this.locationIndex = locationIndex;
        
      }
    });
  }
  
  ngOnInit() {
    goToTop();
  }

  openCart() {
    this.page.openCart(300);
  }

  onCartsUpdated(updatedCarts: Cart[]) {
    let locationId = this.store.businessLocations[this.locationIndex].id;
    this.cart = updatedCarts.find(x => x.locationId === locationId);
  }

  public next(): void {
    // check if addons page is needed for this cart
    if (this.cart?.errors.find(e => this.cartStore.isPinpadOrSoftwareError(e)) || this.cartStore.hasSoftware(this.cart))
      this.routingPathService.activateRoute(routes.LOCATION_ADDONS);
    super.next();
  }
}
