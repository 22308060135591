import { ApplicationPageComponent } from './../application-page/application-page.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';

@Component({
  selector: 'app-tg-base-page-driver',
  template: '',
})
export class BasePageDriverComponent implements AfterViewInit {
  @ViewChild(ApplicationPageComponent)
  private slide: ApplicationPageComponent;

  private currentRouteIndex: number;

  constructor(
    protected routingPathService: RoutingPathService,
    protected router: Router,
    protected activatedRoute?: ActivatedRoute,
  ) {}

  ngAfterViewInit() {
    this.sendOpen();
  }

  public prev(numSlides?: any) {
    if (this.currentRouteIndex <= 0) {
      return;
    }

    if (!numSlides || numSlides <= 1) {
      // THIS COULD PROBABLY TAKE IN numSlides
      this.router.navigate([this.routingPathService.getPrevActiveRoute(this.router)]);
    }
  }

  public next() {
    const continueUrl =
      this.activatedRoute &&
      this.activatedRoute.snapshot &&
      this.activatedRoute.snapshot.queryParams &&
      this.activatedRoute.snapshot.queryParams.continue;
    this.router.navigate([continueUrl || this.routingPathService.getNextActiveRoute(this.router)]);
  }

  private sendOpen() {
    this.slide.onOpen();
    window.scroll(0, 0);
  }
}
