import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { HttpErrorResponse } from '@angular/common/http';
import { TaxRateService } from '@eventhorizon/services/tax-rate.service';
import { UntypedFormControl } from '@angular/forms';
import { masks } from '@eventhorizon/data/masks.data';
import { Cart } from '@eventhorizon/models/cart.model';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { ApplicationStore } from '@eventhorizon/stores/application.store';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnInit {
  @Input() showExtendedDisplay: boolean;

  @Input() showComplexSubtotals: boolean;

  @Input() readonly: boolean;

  @Input() carts?: Cart[];

  @Input() isInsideAnotherContainer: boolean = false;

  @Output() setEstimatedTaxRate = new EventEmitter();

  public showZipCodeInput = false;

  public isLoadingZipCode = false;

  public zipCodeError = '';

  public zipCodeEstimation = '';

  public zipCodeControl: UntypedFormControl;

  public masks = masks;

  public estimatedTaxRate = 0;

  public estimatedTaxTooltipText = 'Monthly software and security plans are not included in taxable subtotal';

  public multilocationTax = 0;

  constructor(
    public cartStore: CartStore, 
    public appStore: ApplicationStore, 
    protected taxRateService: TaxRateService,
  ) {}

  ngOnInit() {
    if (!this.carts) {
      this.cartStore.loadCart().subscribe();
    }
    this.buildForm();
  }

  public estimateTax(): void {
    this.zipCodeError = '';
    this.showZipCodeInput = false;
    this.isLoadingZipCode = true;
    this.zipCodeEstimation = this.zipCodeControl.value;
    this.taxRateService.getSalesTaxRate(this.zipCodeEstimation).subscribe(
      result => {
        this.estimatedTaxRate = this.carts.reduce((sum, cart) => sum += cart.totalAmount, 0) * result.taxRate;
        this.setEstimatedTaxRate.emit(this.estimatedTaxRate);
        this.isLoadingZipCode = false;
      },
      (errorResult: HttpErrorResponse) => {
        this.estimatedTaxRate = null;
        this.setEstimatedTaxRate.emit(this.estimatedTaxRate);
        if (errorResult.status === 404) {
          this.zipCodeError = errorResult.error.errors ? errorResult.error.errors[0] : errorResult.error;
        }
        this.isLoadingZipCode = false;
      },
    );
  }

  public toggleTaxEstimateZipCode() {
    this.showZipCodeInput = !this.showZipCodeInput;
  }

  get salesTaxRate() {
    let taxAmount = this.carts ? this.carts.reduce((sum, cart) => sum += cart.taxAmount, 0) : this.cartStore.cart.taxAmount;
    return this.estimatedTaxRate ? this.estimatedTaxRate : taxAmount;
  }

  protected buildForm() {
    if (this.appStore.businessLocations.length && this.appStore.businessLocations[0].productShippingAddress?.address) {
      this.zipCodeEstimation = this.appStore.businessLocations[0].productShippingAddress?.address.zip;
    }
    this.zipCodeControl = new UntypedFormControl('', CommonValidator.zip());
  }

  public get recurringTotalAmount(): number {
    return !this.carts ? this.cartStore.cart.recurringtotalAmount : this.carts.reduce((sum, cart) => sum += cart.recurringtotalAmount, 0);
  }

  public get oneTimeTotal(): number {
    return !this.carts ? this.cartStore.cart.oneTimeTotal : this.carts.reduce((sum, cart) => sum += cart.oneTimeTotal, 0);
  }

  public get shippingAmount(): number {
    return !this.carts ? this.cartStore.cart.shippingAmount : this.carts.reduce((sum, cart) => sum += cart.shippingAmount, 0);
  }

  public get totalCost(): number {
    return this.recurringTotalAmount + this.oneTimeTotal + this.shippingAmount + this.salesTaxRate;
  }
}
