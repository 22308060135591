import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Content, StruckturedTextBlock } from '@eventhorizon/models/page.model';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-structured-text-block',
  templateUrl: './structured-text-block.component.html',
  styleUrls: ['./structured-text-block.component.scss'],
})
export class StructuredTextBlockComponent extends ContentBase {
  @Input() textBlock: StruckturedTextBlock;

  constructor(protected sanitizer: DomSanitizer) {
    super();
  }

  setContent(content: Content) {
    this.textBlock = {
      heading: content.heading,
      headingColor: content.headingColor,
      subheading: content.subheading,
      alignment: content.alignment,
      text: content.text,
    };
  }
}

export default StructuredTextBlockComponent;
