import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BankerPopupInterface } from '@eventhorizon/data/banker-popups.data';

@Component({
  selector: 'app-banker-popup',
  templateUrl: './banker-popup.component.html',
  styleUrls: ['./banker-popup.component.scss'],
})
export class BankerPopupComponent implements BankerPopupInterface {
  body: string;

  body2: string;

  clientMsg: string;

  clientMsg2: string;

  constructor(private modalRef: BsModalRef) {}

  public close(): void {
    this.modalRef.hide();
  }
}
