import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import type { CartPopupComponent } from '@eventhorizon/components/cart/cart-popup/cart-popup.component';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { NetworkMonitorService } from '@eventhorizon/services/network-monitor.service';
import { LocationProducts } from '@eventhorizon/interfaces/order-details-interface';
import { CartError, UpdateProductRequest } from '@eventhorizon/models/cart.model';

@Component({
  selector: 'app-tg-cart-products',
  templateUrl: './cart-products.component.html',
  styleUrls: ['./cart-products.component.scss'],
})
export class CartProductsComponent {

  @Input()
  public showExtendedDisplay = false;

  @Input()
  public showPaymentTermAsColumn = false;

  @Input()
  public modalDialogInstance: CartPopupComponent = null;

  @Input()
  public readonly: boolean = true;

  @Input()
  public locationProducts: LocationProducts[] = [];

  @Input()
  public hasProducts: boolean = true;

  @Input()
  public showHeader: boolean = true;

  @Input()
  public locationId: string;

  @Input()
  public cartErrors: CartError[] = [];

  @Output()
  public update = new EventEmitter<UpdateProductRequest>();

  constructor(
    public cartStore: CartStore,
    public appStore: ApplicationStore,
    public network: NetworkMonitorService,
  ) {}

  onUpdate(event: UpdateProductRequest) {
    this.update.emit(event);
  }

  public hasP;

  public get incompatibleMccErrorMessage(): string {
    let incompatibleMccError = this.cartErrors?.find(e => e.errortype.toLocaleLowerCase().indexOf('clover-incompatible-mcc') >= 0);
    return incompatibleMccError ? incompatibleMccError.errormessage : '';
  }

  public get posErrorMessage(): string {
    let posError = this.cartErrors?.find(e => e.errortype.toLocaleLowerCase().indexOf('terminal') >= 0);
    return posError ? 'Please select a gateway to continue your application.' : '';
  }
}
