import { makeObservable } from 'mobx';
import { observable } from 'mobx-angular';

export class Progress {
  name: string;

  page: string;

  lastPage: string;

  status: string;

  expanded = false;

  initProgress?: number;

  completeProgress?: number;

  public states: ProgressState[];

  public isCompleted = false;

  public isSubmitted = false;

  constructor(progressSection: any, status: string = 'incomplete', isSubmitted: boolean = false) {
    makeObservable(this, {
      name: observable,
      page: observable,
      lastPage: observable,
      status: observable,
      expanded: observable,
    });
    this.name = progressSection.section;
    this.page = progressSection.page;
    this.lastPage = progressSection.lastPage;
    this.status = status;
    this.states = progressSection.states;
    this.isSubmitted = isSubmitted;
  }

  public markComplete() {
    this.status = 'complete';
  }

  public markInProgress() {
    this.status = 'in-progress';
  }

  public toggleExpanded() {
    this.expand(!this.expanded);
  }

  public expand(state: boolean) {
    this.expanded = state;
  }
}

export interface ProgressState {
  state: string;
  page: string;
  active: boolean;
  code: number;
  sectionCode: number;
  completed: boolean;
  buttons: boolean;
  hide?: boolean;
  inProgress?: boolean;
  isSubmitted?: boolean;
  progress?: number;
}
