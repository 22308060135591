import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '@eventhorizon/services/loading.service';
import { AuthService } from '../services/auth.service';
import { ROUTES } from '@eventhorizon/constants/routes.constants';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private ignoredRoutes = new Set(['/login', '/']);

  constructor(private authService: AuthService, private router: Router, private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    const userToken = this.authService.getAuthToken();
    if (this.authService.isAuthTokenLocallyValid(userToken)) {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken.token}`,
        },
      });
    } else if (userToken) {
      console.log('session expired');
      this.authService.removeAuthToken();
      if (!(window as any).loginConfig) {
        this.router.navigateByUrl('/resume?token=expired');
      }
    }

    if (this.ignoredRoutes.has(this.router.url)) {
      return next.handle(request);
    }
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      catchError(error => {
        if (error && error.status !== 401) {
          /* todo api/products?offeringType=CloverDiningRegisterSoftware sending 400 errors */
          return throwError(error);
        }
        const partnerInfoString = sessionStorage.getItem('partner_info');
        if (partnerInfoString !== null) {
          const partnerInfo = JSON.parse(partnerInfoString);
          this.router.navigate([`${ROUTES.PARTNER}/${partnerInfo.slug}`]);
        } else {
          this.router.navigate(['/']);
        }
        return throwError(error);
      }),
      finalize(() => this.loadingService.setLoading(false)),
    );
  }
}
