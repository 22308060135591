import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction, IReactionDisposer } from 'mobx';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Category, Product, ProductFull, ProductTypeGroup } from '@xup-payments/xup-frontend-utils/models';
import { goToTop } from '@xup-payments/xup-frontend-utils/utils';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';

@Component({
  selector: 'app-tg-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
})
export class ProductSearchComponent extends CarouselSlide implements OnDestroy, OnInit {
  private allProducts: Product[];

  products: Product[];

  categories: Category[];

  productTypes: ProductTypeGroup[];

  @Input() canGoBack = true;

  @Input() productClickAction: 'Navigate' | 'Detail' = 'Navigate';

  @Output() productClicked = new EventEmitter();

  public showRecommended = false;

  public packages: ProductFull[] = [];

  // filters
  private categoryIds = [];

  private productTypeIds = [];

  private name;

  private sortById = 'htl';

  private currentPage = 1;

  readonly pageSize = 50;

  protected start = 1;

  protected end = this.pageSize;

  public inProgess = false;

  public filteredProducts: Product[];

  private readonly storeDisposer: IReactionDisposer;

  private readonly cartDisposer: IReactionDisposer;

  constructor(
    protected cd: ChangeDetectorRef,
    protected productService: ProductService,
    protected store: ApplicationStore,
    protected cart: CartStore,
    protected router: Router,
    protected routingPathService: RoutingPathService,
    protected bsModalService: BsModalService,
  ) {
    super(cd);
    this.storeDisposer = reaction(
      () => !!this.store.isLoaded,
      () => {
        this.loadAll();
      },
    );
    this.cartDisposer = reaction(
      () => !!this.cart.isLoading,
      (isLoading: boolean) => {
        this.inProgess = isLoading;
      },
    );
  }

  ngOnInit() {
    this.loadAll();
  }

  ngOnDestroy() {
    this.storeDisposer();
    this.cartDisposer();
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.start = (this.currentPage - 1) * this.pageSize + 1;
    this.end = (this.currentPage - 1) * this.pageSize + this.pageSize;
    this.updateProductPage();
    goToTop();
  }

  productItemClicked(product: Product) {
    this.productClicked.emit(product);
  }

  protected loadAll() {
    if (!this.store.isLoaded) {
      return;
    }

    this.loadProducts();
    this.loadCategories();
    this.loadProductTypes();
    this.loadRecomendedProducts();
  }

  private loadProducts() {
    this.currentPage = 1;
    this.productService
      .getProducts(this.store.id, this.name, this.productTypeIds, this.categoryIds)
      .subscribe(result => {
        this.allProducts = result;

        const type = ['APP_FEE', 'ACQUIRING', 'MO_FEE', 'NET_FEE', 'SETUP_FEE', 'SOLUTION_FEE'];

        this.filteredProducts = this.allProducts.filter(
          product => product.pricingModel && product.pricingModel.length > 0 && !type.includes(product.type),
        );
        this.sortBy();

        this.updateProductPage();
      });
  }

  private updateProductPage() {
    this.products = this.filteredProducts.slice(
      (this.currentPage - 1) * this.pageSize,
      (this.currentPage - 1) * this.pageSize + this.pageSize,
    );
  }

  private loadProductTypes() {
    this.productService.getProductTypeGroups().subscribe(result => {
      this.productTypes = result;
    });
  }

  private loadCategories() {
    this.productService.getCategories().subscribe(result => {
      this.categories = result.sort((a, b) => a.rank - b.rank);
    });
  }

  private sortBy() {
    if (this.sortById === 'lth') {
      this.filteredProducts.sort((a, b) => a.price - b.price);
    } else if (this.sortById === 'htl') {
      this.filteredProducts.sort((a, b) => b.price - a.price);
    }
  }

  private loadRecomendedProducts() {
    const category = this.store.subCategory || this.store.category;
    const categoryId = category ? category.id : 0;

    this.productService.getRecomendedProducts(this.store.businessSize, categoryId, this.store.id).subscribe(
      result => {
        this.packages = result;
        if (this.packages.length <= 0) {
          this.showRecommended = false;
          return;
        }
        this.showRecommended = true;
      },
      () => {
        this.showRecommended = false;
      },
    );
  }

  public handleCategoryChange(event) {
    this.categoryIds = event;
    this.loadProducts();
  }

  public handleProductTypeChange(event) {
    this.productTypeIds = event;
    this.loadProducts();
  }

  public handleNameChange(event) {
    this.name = event.target.value;
    this.loadProducts();
  }

  public handleSortByChange(event) {
    if (this.sortById !== event) {
      this.sortById = event;
      this.loadProducts();
    }
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public getPrevPage(): string {
    return this.routingPathService.getPrevActiveRoute(this.router);
  }
}
