<div class="cart-icon-container position-relative">
  <div *ngIf="cartStore.addProductsErrors" class="form-error-message error-message">
    {{ cartStore.addProductsErrors }}
  </div>
  <div *ngIf="cartStore.isLoading && !isCartModalOpen" class="spinner-container">
    <div class="lds-dual-ring col-sm-12"></div>
  </div>
  <div>
    <button
      id="reviewCartBtn"
      aria-label="Review My Shopping Cart"
      class="btn btn-cart"
      (click)="onClick()"
      (keyup.enter)="onClick()"
      tabindex="0"
    >
      <i role="button" aria-label="Shopping Cart" class="fal fa-cart-shopping cart-icon"></i>
      <i *ngIf="cartStore.hasProducts" class="fas fa-circle cart-icon-dot"></i>
    </button>
  </div>
</div>
