import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { BusinessNameValidator, CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { formatPhone, touchAll } from '@xup-payments/xup-frontend-utils/utils';
import {
  ANNUAL_CREDITDEBIT_PROCESSING_VOLUME_CONTACT_INFO,
  CALL_LANGUAGE_OPTIONS,
  CALL_TIME_OPTIONS,
  US_TIMEZONES_OPTIONS,
} from '@xup-payments/xup-frontend-utils/constants';
import { masks } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-referred-merchant-contact-form',
  templateUrl: './referred-merchant-contact-form.component.html',
  styleUrls: ['./referred-merchant-contact-form.component.scss'],
})
export class ReferredMerchantContactFormComponent implements OnInit, AfterViewInit {
  @Input() public errorMessage: string = '';

  @Input() public disableCaptcha: boolean = true;

  @Input() set partnerType(value: string) {
    if (value.toLocaleLowerCase() === 'branch') {
      this.representativeText = 'Referring Branch Representative';
    }
  }

  @Output() resetForm: EventEmitter<void> = new EventEmitter();

  @Output() submitForm: EventEmitter<void> = new EventEmitter();

  @ViewChild('recaptchaContainer') recaptchaContainer: ElementRef;

  public page: number = 1;

  public form: FormGroup<IReferredMerchantContactForm>;

  public masks = masks;

  public name: MobxFormControl<string>;

  public lastName: MobxFormControl<string>;

  public phoneNumber: MobxFormControl<string>;

  public email: MobxFormControl<string>;

  public legalName: MobxFormControl<string>;

  public repName: MobxFormControl<string>;

  public repLastName: MobxFormControl<string>;

  public repEmail: MobxFormControl<string>;

  public existingClientCustomer: MobxFormControl<boolean>;

  public annualCreditDebitProcessingVolume: MobxFormControl<number>;

  public timezone: MobxFormControl<number>;

  public preferredLanguage: MobxFormControl<number>;

  public preferredTimeToCall: MobxFormControl<string>;

  public notes: MobxFormControl<string>;

  public recaptchaContainerWidth: number;

  public annualCDProcessingValues = ANNUAL_CREDITDEBIT_PROCESSING_VOLUME_CONTACT_INFO;

  public timeZoneOptions = US_TIMEZONES_OPTIONS;

  public languageOptions = CALL_LANGUAGE_OPTIONS;

  public timeToCallOptions = [];

  public onSecondPart: boolean = false;

  public representativeText: string = 'Sales Representative';

  private localTimezone: string;

  private currentTimestamp: number;

  private timeSelected: string = '';

  constructor(protected store: ApplicationStore, protected fb: FormBuilder) {
    this.currentTimestamp = moment().valueOf();
    this.localTimezone = momentTimezone.tz.guess();
  }

  ngOnInit() {
    this.buildForm();
  }

  ngAfterViewInit() {
    setTimeout(() => this.onResize(), 0);
  }

  private buildPreferredTimeOptions() {
    this.timeToCallOptions = CALL_TIME_OPTIONS.map((value, index) => ({
      value: index,
      label: value,
      name: `preferredTimeCall${value}`,
    }));
  }

  private buildForm() {
    this.name = new MobxFormControl<string>(
      'name',
      () => this.store.applicantInfo.name,
      (v: string) => (this.store.applicantInfo.name = v),
      CommonValidator.personalName(),
    );
    this.lastName = new MobxFormControl<string>(
      'lastName',
      () => this.store.applicantInfo.lastName,
      (v: string) => (this.store.applicantInfo.lastName = v),
      CommonValidator.personalName(),
    );
    this.phoneNumber = new MobxFormControl<string>(
      'phoneNumber',
      () => formatPhone(this.store.applicantInfo.phoneNumber),
      (v: string) => (this.store.applicantInfo.phoneNumber = formatPhone(v)),
      CommonValidator.phone(),
    );
    this.email = new MobxFormControl<string>(
      'email',
      () => this.store.applicantInfo.email,
      (v: string) => (this.store.applicantInfo.email = v),
      CommonValidator.email(),
    );
    this.legalName = new MobxFormControl<string>(
      'legalName',
      () => this.store.businessInfo.legalName,
      (v: string) => (this.store.businessInfo.legalName = v),
      BusinessNameValidator.legalName(true),
    );
    this.repName = new MobxFormControl<string>(
      'repName',
      () => this.store.internalLead.firstName,
      (v: string) => (this.store.internalLead.firstName = v),
      CommonValidator.personalName(false),
    );
    this.repLastName = new MobxFormControl<string>(
      'repLastName',
      () => this.store.internalLead.lastName,
      (v: string) => (this.store.internalLead.lastName = v),
      CommonValidator.personalName(false),
    );
    this.repEmail = new MobxFormControl<string>(
      'repEmail',
      () => this.store.internalLead.email,
      (v: string) => (this.store.internalLead.email = v),
      CommonValidator.email(false),
    );
    this.existingClientCustomer = new MobxFormControl<boolean>(
      'existingClientCustomer',
      () => this.store.applicantInfo.existingClientCustomer,
      (v: boolean) => (this.store.applicantInfo.existingClientCustomer = v),
    );
    this.annualCreditDebitProcessingVolume = new MobxFormControl<number>(
      'annualCreditDebitProcessingVolume',
      () => this.store.applicantInfo.annualCreditDebitProcessingVolume,
      (v: number) => (this.store.applicantInfo.annualCreditDebitProcessingVolume = v),
      Validators.required,
      true,
    );
    this.timezone = new MobxFormControl<number>(
      'timezone',
      () => this.store.applicantInfo.timezone,
      (v: number) => (this.store.applicantInfo.timezone = v),
      Validators.required,
    );
    this.preferredLanguage = new MobxFormControl<number>(
      'preferredLanguage',
      () => this.store.applicantInfo.preferredLanguage,
      (v: number) => (this.store.applicantInfo.preferredLanguage = v),
      Validators.required,
    );
    // pre-select english as a language in the form
    this.preferredLanguage.setValue(0, { emitEvent: true });
    this.preferredTimeToCall = new MobxFormControl<string>(
      'preferredTimeToCall',
      () => this.timeSelected,
      (v: string) => (this.timeSelected = v),
      Validators.required,
    );
    this.notes = new MobxFormControl(
      'notes',
      () => this.store.applicantInfo.notes,
      (v: string) => (this.store.applicantInfo.notes = v),
    );

    this.buildPreferredTimeOptions();

    const defaultTimeZoneData =
      this.timeZoneOptions.find(zone =>
        zone.abbreviations.includes(momentTimezone.tz.zone(this.localTimezone).abbr(this.currentTimestamp)),
      )?.value ?? this.timeZoneOptions[0].value;

    this.timezone.setValue(defaultTimeZoneData);

    this.form = this.fb.group({
      name: this.name,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
      legalName: this.legalName,
      repName: this.repName,
      repLastName: this.repLastName,
      repEmail: this.repEmail,
      existingClientCustomer: this.existingClientCustomer,
      annualCreditDebitProcessingVolume: this.annualCreditDebitProcessingVolume,
      timezone: this.timezone,
      preferredLanguage: this.preferredLanguage,
      preferredTimeToCall: this.preferredTimeToCall,
      notes: this.notes,
    });

    if (!this.disableCaptcha) {
      this.form.addControl('recaptcha', new FormControl(null, Validators.required));
    }

    this.existingClientCustomer.setValue(false);
    this.timeToCallOptions.forEach(option => {
      const control = new MobxFormControl<boolean>(
        option.name,
        () => this.store.applicantInfo.preferredTimesToCall.includes(option.value),
        (v: boolean) => {
          const index = this.store.applicantInfo.preferredTimesToCall.findIndex(time => time === option.value);
          if (v && index === -1) {
            this.store.applicantInfo.preferredTimesToCall.push(option.value);
          }
          if (!v && index > -1) {
            this.store.applicantInfo.preferredTimesToCall.splice(index, 1);
          }
          this.preferredTimeToCall.setValue(this.store.applicantInfo.preferredTimesToCall.toString());
          this.preferredTimeToCall.markAsDirty();
          this.preferredTimeToCall.markAsTouched();
          this.preferredTimeToCall.updateValueAndValidity({ onlySelf: true });
        },
        [],
        true,
      );
      this.form.addControl(option.name, control);
    });
    touchAll(this.form);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.disableCaptcha) {
      this.recaptchaContainerWidth = this.recaptchaContainer.nativeElement.offsetWidth;
    }
  }

  public toggleParts() {
    this.onSecondPart = !this.onSecondPart;
    if (this.onSecondPart) {
      setTimeout(() => this.onResize(), 0);
    }
  }

  public disableNext() {
    return (
      this.name.invalid ||
      this.lastName.invalid ||
      this.legalName.invalid ||
      this.annualCreditDebitProcessingVolume.invalid ||
      this.repEmail.invalid
    );
  }

  public resetContactForm() {
    this.resetForm.emit();
  }

  public submitContactForm() {
    this.submitForm.emit();
  }
}

export interface IReferredMerchantContactForm {
  name: FormControl<string>;
  lastName: FormControl<string>;
  phoneNumber: FormControl<string>;
  email: FormControl<string>;
  legalName: FormControl<string>;
  repName: FormControl<string>;
  repLastName: FormControl<string>;
  repEmail: FormControl<string>;
  existingClientCustomer: FormControl<boolean>;
  annualCreditDebitProcessingVolume: FormControl<number>;
  timezone: FormControl<number>;
  preferredLanguage: FormControl<number>;
  preferredTimeToCall: FormControl<string>;
  notes: FormControl<string>;
  recaptcha?: FormControl;
}
