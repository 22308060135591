import { Component, Input, OnInit } from '@angular/core';
import { ROUTES, USER_ROLES } from '@xup-payments/xup-frontend-utils/constants';
import { HttpParams } from '@angular/common/http';
import { AuthStore } from '../../stores/auth.store';

@Component({
  selector: 'app-merchant-summary',
  templateUrl: './merchant-summary.component.html',
  styleUrls: ['./merchant-summary.component.scss'],
})
export class MerchantSummaryComponent implements OnInit {
  @Input() applicationId: string;

  @Input() config: {
    hideBusinessVerification: boolean;
  };

  @Input() private enableItemClick = true;

  @Input() public accordionConfig = {
    showYourNeeds: false,
    showYourSolution: false,
    showYourApp: false,
    showBussinessVerification: false,
  };

  public hideVerification = false;

  public HEADINGS = {
    YOUR_PRODUCTS: 'Your Products',
    APPLICATION: 'Application',
    BUSS_VERIFICATION: 'Business Verification',
  };

  get isPartnerAdmin(): boolean {
    return this.authStore.userRole === USER_ROLES.PARTNER_ADMIN;
  }

  constructor(private authStore: AuthStore) {}

  ngOnInit() {
    if (this.isPartnerAdmin || this.config.hideBusinessVerification) {
      this.hideVerification = true;
    }
  }

  public itemClick(event) {
    if (event.type === 'click' && event.row.redirectOnboardingLink && this.enableItemClick) {
      const queryParams = { continue: `/${ROUTES.ID_VERIFICATION}`, continueText: 'Return to Summary' };
      const queryParamsString = new HttpParams({ fromObject: queryParams }).toString();
      window.location.href = `/${event.row.redirectOnboardingLink}?${queryParamsString}`;
    }
  }
}
