<div class="processing" [hidden]="!inProgess"></div>
<div class="row product-search-container">
  <div class="col-12 p-0">
    <div *ngIf="showRecommended" class="row">
      <div class="col-12 p-0">
        <div class="row section-container">
          <div class="col-12 p-0">
            <h3 class="section-title">Recommended Products</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4 p-1" *ngFor="let package of packages">
            <app-tg-product
              [product]="package"
              [clickAction]="productClickAction"
              (productClicked)="productItemClicked($event)"
            ></app-tg-product>
          </div>
        </div>
      </div>
    </div>
    <div class="row section-container">
      <div class="col-12 p-0">
        <h3 class="section-title">All Products</h3>
        <div class="section-subtitle">
          Still not sure what you need? Explore our full catalogue of payment solutions.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <app-tg-product-search-filters
          [productTypes]="productTypes"
          [categories]="categories"
          (changeProductTypeEvent)="handleProductTypeChange($event)"
          (changeCategoryEvent)="handleCategoryChange($event)"
          (changeNameEvent)="handleNameChange($event)"
          (changeSortByEvent)="handleSortByChange($event)"
        >
        </app-tg-product-search-filters>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let product of products" class="product-search-product-list col-6 col-md-4 p-1">
        <app-tg-product
          [product]="product"
          [clickAction]="productClickAction"
          (productClicked)="productItemClicked($event)"
        ></app-tg-product>
      </div>
    </div>
    <div class="row justify-content-center justify-content-md-between align-items-center" *ngIf="filteredProducts">
      <div class="col-auto">
        <span class="pagination-description"
          >Showing {{ start }} - {{ filteredProducts.length < end ? filteredProducts.length : end }} of
          {{ filteredProducts.length }}</span
        >
      </div>
      <div class="col-auto">
        <pagination
          [itemsPerPage]="pageSize"
          [totalItems]="filteredProducts.length"
          [customPreviousTemplate]="previous"
          [customNextTemplate]="next"
          (pageChanged)="pageChanged($event)"
        >
        </pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #previous>
  <p aria-label="Previous" role="link"></p>
</ng-template>

<ng-template #next>
  <p aria-label="Next" role="link"></p>
</ng-template>
