import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ROUTES } from '@eventhorizon/constants/routes.constants';
import { ModalInterface } from '@eventhorizon/interfaces/modal-interface';

@Component({
  selector: 'app-unsaved-changes-popup',
  templateUrl: './unsaved-changes-popup.component.html',
  styleUrls: ['./unsaved-changes-popup.component.scss'],
})
export class UnsavedChangesPopupComponent implements ModalInterface {
  constructor(private modalRef: BsModalRef, protected router: Router) {}

  public close(): void {
    this.modalRef.hide();
  }

  public proceed(): void {
    this.modalRef.hide();
    this.router.navigateByUrl(ROUTES.LOCATIONS);
  }
}
