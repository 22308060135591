import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { CartResponse, UpdateProductRequest } from '@eventhorizon/models/cart.model';
import { ShippingLocations } from '@eventhorizon/models/shipping-locations.model';

@Injectable({ providedIn: 'root' })
export class CartService {
  protected BASE_URL;

  constructor(private http: HttpClient, @Inject('env') private environment) {
    this.BASE_URL = environment.onboardingApi ? `${environment.onboardingApi}/cart` : `${environment.api.url}/cart`;
  }

  public newCart(applicationId?: string): Observable<CartResponse> {
    let url = this.BASE_URL;
    if (applicationId) url += `?applicationId=${applicationId}`;
    return this.http.post<CartResponse>(url, {});
  }

  public getCart(id: string): Observable<CartResponse> {
    return this.http.get<CartResponse>(`${this.BASE_URL}/${id}`);
  }

  public getCartByApplicationId(applicationId: string): Observable<CartResponse> {
    const params = new HttpParams().set('applicationId', applicationId);
    return this.http.get<CartResponse>(`${this.BASE_URL}`, { params });
  }

  public async getAllCarts(allLocationsId: string[]): Promise<CartResponse[]> {
    const carts: CartResponse[] = await Promise.all(
      allLocationsId.map(async locationId => {
        const locationCart: CartResponse = await lastValueFrom(this.getCartByLocationId(locationId));
        return locationCart;
      }),
    );

    return carts;
  }

  public postCartCheckout(cartId: string): Observable<CartResponse> {
    return this.http.post<CartResponse>(`${this.BASE_URL}/${cartId}/checkout`, null);
  }

  public getCartByLocationId(locationId: string): Observable<CartResponse> {
    return this.http.get<CartResponse>(`${this.BASE_URL}/locations/${locationId}`);
  }

  public updateProducts(id: string, products: UpdateProductRequest[]): Observable<CartResponse> {
    return this.http.post<CartResponse>(`${this.BASE_URL}/${id}/products`, products);
  }

  public setShippingLocations(id: string, shippingLocations: ShippingLocations[]): Observable<CartResponse> {
    return this.http.post<CartResponse>(`${this.BASE_URL}/${id}/shippingLocations`, shippingLocations);
  }

  public async setShippingAmount(cartId: string, cart) {
    return lastValueFrom(this.http.patch(`${this.BASE_URL}/${cartId}`, cart));
  }

  public async checkRatesAndFeesChange(cartId: string) {
    return lastValueFrom(
      this.http.get<{ cartFeesMatchFiservFees: boolean }>(`${this.BASE_URL}/${cartId}/check-for-ratesfees-changes`),
    );
  }
}
