<div class="terms-text-container">
    <ngx-extended-pdf-viewer
      *ngIf="!isLoading"
      [src]="fileSrc"
      height="400px"
      [showBookmarkButton]="false"
      [sidebarVisible]="false"
      [showRotateButton]="false"
      [showOpenFileButton]="false"
      [filenameForDownload]="'MPA'"
      [attr.aria-busy]="isLoading"
      [enablePrint]="false"
    ></ngx-extended-pdf-viewer>
  </div>

  <div *ngIf="shouldShow" class="row ic-plus-links-container">
    <div *ngFor="let link of mpaLinks" class="col-12">
      <a class="navigation-link ic-plus-links" [href]="link.href" target="_blank">{{ link.label }} &gt;</a>
    </div>
  </div>

  <form [formGroup]="form" #formElem>
    <div class="row pb-2">
      <div class="col-sm-12 mb-3">
        <div class="input-container">
          <input
            class="form-option-input align-self-baseline d-inline"
            [class.form-option-input-error]="form?.invalid && form?.touched"
            type="checkbox"
            id="accept"
            formControlName="accept"
            value="accept"
            role="checkbox"
            tabindex="0"
            aria-labelledby="accept"
            aria-checked="false"
            required
          />
          <label class="form-option-label ps-2 pe-4 flex-grow-1" for="accept">
            I have read and agree to the above Terms & Conditions and consent to receiving documents electronically.
          </label>
          <div>
            <button (click)="downloadMpa()" class="transparent-button" aria-label="Download MPA">
              <i class="far fa-arrow-down-to-line download-icon"></i>
            </button>
          </div>
        </div>
        <div class="input-container" *ngIf="!ddaInterestDisclosureHidden">
          <input
            class="form-option-input align-self-baseline d-inline"
            id="contact-info-disclosures"
            type="checkbox"
            formControlName="ddaInterestStatus"
            aria-labelledby="contactInfoDisclosures"
            (change)="updateDdaInterestDisclosure()"
          />
          <label
            for="contact-info-disclosures"
            class="form-option-label ps-2 pe-4 flex-grow-1"
            [innerHTML]="ddaInterestDisclosure"
          ></label>
        </div>
      </div>
      <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
        <p *ngFor="let error of errorMessage">
          {{ error }}
        </p>
      </div>
    </div>
  </form>