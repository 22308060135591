export enum DateTimeNameFormat {
  dateTime = 'DateTime',
  date = 'Date',
  time = 'Time',
}

export enum DateTimeFormat {
  dateTime = 'MM-DD-YYYY hh:mm A',
  date = 'MM-DD-YYYY',
  time = 'hh:mm A',
}
