import { Component, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductDetailComponent } from '../product-detail.component';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { lastValueFrom } from 'rxjs';
import { cartEventsTypes } from '@eventhorizon/constants/general.constants';
import { Cart, UpdateProductRequest } from '@eventhorizon/models/cart.model';


@Component({
  selector: 'app-tg-product-detail-popup',
  templateUrl: './product-detail-popup.component.html',
  styleUrls: ['./product-detail-popup.component.scss'],
})
export class ProductDetailPopupComponent {

  @ViewChild('productDetail') public productDetail: ProductDetailComponent;

  @Input()
  public cart: Cart;

  @Input()
  public productId: string;

  public includedProductIdToShow: string = '';

  public showCart: boolean = false;

  constructor(
    private modalRef: BsModalRef,
    protected cartStore: CartStore, 
  ) { }

  public get productIsDisabled(): boolean {
    const currentProductId = this.productId;
    const blockedProductEvent = this.cart?.events
      ? this.cart.events.find(event => event.affectedProductId === currentProductId)
      : undefined;
    return blockedProductEvent && blockedProductEvent.eventType === cartEventsTypes.DISABLED_PRODUCT;
  }

  close() {
    this.modalRef.hide();
  }

  setIncludedProductIdToShow(val: string) {
    this.includedProductIdToShow = val;
  }

  updateCart(request: UpdateProductRequest) {
    this.productDetail.isLoading = true;
    const productInCart = this.cart.products.find(
      prod => prod.productId === this.productDetail.productId && prod.purchaseType === request.purchaseType,
    );
    request.quantity += productInCart ? productInCart.quantity : 0;
    request.businessLocationId = this.cart.locationId;

    lastValueFrom(this.cartStore.updateProducts([request], false, this.cart.id))
      .then(res => {
        this.showCart = true;
        this.cart = res;
        this.close();
      })
      .catch(e => {
        this.productDetail.errorMessage = e.error?.errors?.length 
          ? e.error.errors[0]  
          : 'There was an error adding product to cart.';
      });
  }
}
