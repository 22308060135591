import { makeObservable, observable } from 'mobx';

export class ApplicantInfo {
  name = ''; // This should be first name

  lastName = '';

  email = '';

  phoneNumber = '';

  companyName = '';

  existingClientCustomer: boolean;

  isKeyController: boolean;

  annualCreditDebitProcessingVolume: number;

  preferredLanguage: number;

  preferredTimesToCall: number[] = [];

  timezone: number;

  notes: string;

  constructor() {
    makeObservable(this, {
      name: observable,
      lastName: observable,
      email: observable,
      phoneNumber: observable,
      companyName: observable,
      existingClientCustomer: observable,
      isKeyController: observable,
      annualCreditDebitProcessingVolume: observable,
      preferredLanguage: observable,
      preferredTimesToCall: observable,
      timezone: observable,
      notes: observable,
    });
  }
}
