import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AuthToken } from '@eventhorizon/models/auth-token.model';
import { TwoFAApplicantInfoResponse, TwoFARequest } from '@eventhorizon/models/two-factor-auth.mode';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TwoFAService {
  private readonly BASE_URL = '/api/users/token/mfa';

  private resendApplicationUrl = '/api/users/token/regenerate-resume-token';

  constructor(private http: HttpClient) {}

  getApplicantInfo(accessToken: string): Observable<TwoFAApplicantInfoResponse> {
    const request: HttpParams = new HttpParams().set('token', accessToken);

    return this.http.get<TwoFAApplicantInfoResponse>(`${this.BASE_URL}/info`, { params: request });
  }

  requestCode(accessToken: string, type: string): Observable<any> {
    const request: TwoFARequest = {
      accessToken,
      type,
    };
    return this.http.post<any>(`${this.BASE_URL}/request`, request);
  }

  validateCode(accessToken: string, type: string, code: string): Observable<AuthToken> {
    const request: TwoFARequest = {
      accessToken,
      type,
      code,
    };
    return this.http.post<AuthToken>(`${this.BASE_URL}/validate`, request).pipe(
      tap((resp: AuthToken) => {
        sessionStorage.setItem('auth_token', JSON.stringify(resp.userToken));
      }),
    );
  }

  public requestNewLink(token: string): Observable<any> {
    const url = `${this.resendApplicationUrl}?token=${token}`;
    return this.http.post<any | void>(url, null).pipe(
      map(() => true),
      catchError((err: any) => {
        if (err.status !== 200) {
          return throwError(err);
        }
        return of(false);
      }),
    );
  }
}
