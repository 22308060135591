import  moment from 'moment';
import { DateTimeFormat, DateTimeNameFormat } from '@eventhorizon/models/date.config';

export function getLocalDateTime(format: DateTimeNameFormat, dateTime: string, isUTCFormat: boolean, fallbackValue: string) {
  if (!dateTime) {
    return fallbackValue;
  }

  const dateToFormat = isUTCFormat && !dateTime.includes('Z') ? `${dateTime}Z` : dateTime;

  switch (format) {
    case DateTimeNameFormat.dateTime:
      return moment(dateToFormat).local().format(DateTimeFormat.dateTime);
    case DateTimeNameFormat.date:
      return moment(dateToFormat).local().format(DateTimeFormat.date);
    case DateTimeNameFormat.time:
      return moment(dateToFormat).local().format(DateTimeFormat.time);
  }
}

export function valueExists(val) {
  return val !== undefined && val !== null && val !== '';
}

export function areObjectsDifferent(obj1, obj2) {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
}

export function formatDateTimefromUTCtoLocal(
  dateTime: string,
  isUTCFormat: boolean = true,
  fallbackValue: string = null,
) {
  return getLocalDateTime(DateTimeNameFormat.dateTime, dateTime, isUTCFormat, fallbackValue);
}

export function formatDatefromUTCtoLocal(
  date: string,
  isUTCFormat: boolean = true,
  fallbackValue: string = null,
): string {
  return getLocalDateTime(DateTimeNameFormat.date, date, isUTCFormat, fallbackValue);
}

export function formatTimefromUTCtoLocal(
  time: string,
  isUTCFormat: boolean = true,
  fallbackValue: string = null,
): string {
  return getLocalDateTime(DateTimeNameFormat.time, time, isUTCFormat, fallbackValue);
}
