<div id="mainContent" tabindex="0" class="main-container container has-header prefill">
  <div *ngIf="isLoading" class="lds-dual-ring"></div>
  <div *ngIf="!isLoading" class="row container-inner">
    <div class="col-12 expired">
      <div class="expired-title">Your link has expired</div>
      <div class="expired-link">Your link has expired. Please return to the start of your application.</div>
      <div class="expired-start" tabindex="0" (keyup.enter)="startApplication()" (click)="startApplication()">
        <button class="btn primary-button">Start Application</button>
      </div>
    </div>
  </div>
</div>