import { AcquiringPricing } from './acquiring-pricing.model';
import { Product } from './product.model';

export class Cart {
  id: string;

  orderId: string;

  products: Product[];

  acquiringPricing: AcquiringPricing;

  errors: CartError[];

  taxAmount: number;

  totalAmount: number;

  amount: number;

  shippingAmount: number;

  recurringtaxAmount: number;

  recurringtotalAmount: number;

  recurringAmount: number;

  oneTimeTotal: number;

  events: Event[];

  locationId?: string;

  cartFeesMatchFiservFees?: boolean;
}

export class Event {
  affectedByProductId: string;

  affectedProductId: string;

  eventType: string;

  id: string;
}

export class CartResponse extends Cart {}

export class CartRequest extends CartResponse {}

export class CartError {
  errormessage: string;

  errortype: string;

  errorPriority: string;

  _errorOrder: number;

  productType: string;

  show?: boolean;

  businessLocationId?: string;
}

export class UpdateProductRequest {
  id: string;

  quantity: number;

  purchaseType: string;

  businessLocationId?: string;
}

export class SaveProductRequest {
  productId: string;

  quantity: number;

  purchaseType: string;

  paymentType: string;

  paymentTerm: string;
}
