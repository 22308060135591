import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction } from 'mobx';
import { OrganizationCacheService } from '@eventhorizon/services/organization-cache.service';
import { Address } from '@eventhorizon/models/address.model';
import { OrganizationType } from '@eventhorizon/models/organization-type.model';
import { SearchResultsData } from '@eventhorizon/models/autofill.model';
import { tinTypes } from '@eventhorizon/data/tin-types.data';
import { formatPhone } from '@eventhorizon/utils/util';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { ControllerModel } from '@eventhorizon/models/controller.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessTaxesController extends ControllerModel<BusinessTaxesControls> {
  public tin: MobxFormControl;

  public businessType: MobxFormControl;

  public tinType: MobxFormControl;

  taxClassification: MobxFormControl;

  public tinTypes = tinTypes;

  public tinTypeValue = '';

  public readonly TIN_ONLY = tinTypes[0].value;

  public readonly TIN_SSN = tinTypes[1].value;

  public readonly SSN_ONLY = tinTypes[2].value;

  public readonly SOLE_PROPRIETOR = 'I';

  private orgTypeOrder = ['I', 'L', 'P', 'C', 'PRC', 'T', 'G'];

  public tinSearchErrors = '';

  public constructor(
    public store: ApplicationStore,
    private organizationCacheService: OrganizationCacheService,
  ) {
    super();

    reaction(
      () => this.store.selectedSearchResult,
      () => {
        this.fillStoreWithSearchResult(this.store.selectedSearchResult);
      },
    );
  }

  public get organizationTypes(): OrganizationType[] {
    return this.store.organizationTypes;
  }

  updateTinTypeValue() {
    this.tinType.setValue(this.deductTinType());
  }

  public loadData() {
    this.loadOrganizationTypes();
  }

  isOrgTypeAlreadySelectedAtInit() {
    const selectedCode = this.selectedType() ? this.selectedType().code : '';

    if (selectedCode !== '') {
      this.businessType.updateValueAndValidity({ onlySelf: true });
    }
  }

  /**
   * For instances or flows where the tax classification
   * property does not exists, send a NONE value to the backend.
   */
  checkTaxClassValue(): void {
    if (this.businessType.value !== 'L' || !this.store.shouldGetLLCType) {
      this.taxClassification.setValue('NONE');
    }
  }

  private async loadOrganizationTypes() {
    const organizationTypes = await this.organizationCacheService.getOrganizationTypes();
    organizationTypes.sort((a, b) => this.orgTypeOrder.indexOf(a.code) - this.orgTypeOrder.indexOf(b.code));
    this.store.organizationTypes = organizationTypes;
  }

  public buildForm(isAssisted: boolean = false): BusinessTaxesControls {
    const s = this.store;
    const requiredValidator = !isAssisted ? Validators.required : undefined;
    this.businessType = new MobxFormControl(
      'businessType',
      () =>
        s.businessInfo.organizationType && s.businessInfo.organizationType.code
          ? s.businessInfo.organizationType.code
          : '',
      v => {
        s.setOrganizationType(v);
        const businessTinTypes = this.getTinTypes(v);
        if (businessTinTypes.length) {
          if (isAssisted) {
            const exist = businessTinTypes.find(tinType => tinType.value === this.tinTypeValue);
            this.tinTypeValue = exist ? exist.value : '0';
          }
          this.tinType.setValidators(requiredValidator);
        } else {
          this.tinType.clearValidators();
        }
        s.businessInfo.tinType = this.getTinTypeCode(this.tinType?.value);
        this.tinType.updateValueAndValidity({ onlySelf: true });
        this.updateTaxClassValidator(isAssisted);
      },
      requiredValidator,
    );

    this.tinType = new MobxFormControl(
      'tinType',
      () => {
        if (!this.tinTypeValue) {
          return (this.tinTypeValue = this.deductTinType());
        }
        return this.tinTypeValue;
      },
      v => {
        if (v) {
          this.tinTypeValue = v;
          s.businessInfo.tinType = v ? +this.tinTypes[+v].code : undefined;
          if (this.isTinTypeSSNOnly()) {
            this.tin.setValue('');
            this.tin.clearValidators();
          } else {
            this.tin.setValue(this.store.businessInfo.taxId);
            this.tin.setValidators(CommonValidator.tinAlt(!isAssisted));
          }
          this.tin.updateValueAndValidity({ onlySelf: true });
        } else if (isAssisted) {
          s.businessInfo.tinType = undefined;
          this.tinTypeValue = '';
        }
      },
      requiredValidator,
    );

    this.tin = new MobxFormControl(
      'tin',
      () => s.businessInfo.taxId,
      v => {
        s.businessInfo.taxId = v;
        s.businessInfo.taxIdConfirmation = v;
        if (this.tinSearchErrors !== '') this.tinSearchErrors = '';
      },
      CommonValidator.tinAlt(!isAssisted),
    );

    this.taxClassification = new MobxFormControl(
      'taxClassification',
      () => (s.businessInfo.taxClassification ? s.businessInfo.taxClassification : ''),
      v => {
        if (v) {
          s.businessInfo.taxClassification = v;
        } else {
          s.businessInfo.taxClassification = this.store.businessInfo.taxClassification;
        }
      },
      null,
    );

    return {
      businessType: this.businessType,
      tinType: this.tinType,
      tin: this.tin,
      taxClassification: this.taxClassification,
    };
  }

  getTinTypes(code: string): any[] {
    if (code === 'I') return tinTypes;
    if (code === 'P' || code === 'L') return tinTypes.slice(0, tinTypes.length - 1);
    return [];
  }

  public deductTinType(): string {
    if (this.store.businessInfo.tinType === 1) return this.TIN_ONLY;
    if (
      this.store.businessInfo.tinType === 0 &&
      this.store.businessInfo.taxId &&
      !this.store.owners.find(owner => owner.socialSecurityNumber === this.store.businessInfo.taxId)
    ) {
      return this.TIN_SSN;
    }
    if (
      this.store.businessInfo.tinType === 0 &&
      (!this.store.businessInfo.taxId ||
        !!this.store.owners.find(owner => owner.socialSecurityNumber === this.store.businessInfo.taxId)) &&
      this.businessType.value === this.SOLE_PROPRIETOR
    ) {
      return this.SSN_ONLY;
    }
    return '';
  }

  private fillStoreWithSearchResult(searchResult: SearchResultsData) {
    this.store.businessInfo.taxId = searchResult.ein;
    this.store.businessInfo.taxIdConfirmation = searchResult.ein;
    this.store.businessInfo.legalName = searchResult.companyName;
    this.store.businessInfo.taxFilingName = searchResult.companyName;
    this.store.businessInfo.phone = formatPhone(searchResult.telephone);
    this.store.businessInfo.email = searchResult.email;
    this.store.businessInfo.website = searchResult.website;

    const address = {
      address1: searchResult.address || undefined,
      address2: searchResult.address2 || undefined,
      city: searchResult.city || undefined,
      state: searchResult.state || undefined,
      zip: searchResult.zipCode || undefined,
    };
    const legalAddress = new Address(address);
    this.store.businessAddresses.legalAddress = legalAddress;

    const applicant = this.store.owners[0];
    applicant.firstName = searchResult.firstName;
    applicant.lastName = searchResult.lastName;
    applicant.email = searchResult.email;
    applicant.phone = formatPhone(searchResult.telephone);
  }

  public selectedType(): OrganizationType | null {
    if (this.businessType.value && this.store.organizationTypes && this.store.organizationTypes.length) {
      return this.store.organizationTypes.find(t => t.code === this.businessType.value);
    }
    return null;
  }

  public getSelectedTypeTinTypes(): any[] {
    return this.selectedType() ? this.getTinTypes(this.selectedType().code) : [];
  }

  public isTinTypeSSNOnly(): boolean {
    return this.tinType.value === this.SSN_ONLY;
  }

  public isBusinessTypeSoleProprietor(): boolean {
    return this.businessType.value === this.SOLE_PROPRIETOR;
  }

  /**
   * Meant to update the tax classification field validation, in case the type is SSN_ONLY
   *
   * @param isAssisted
   */
  private updateTaxClassValidator(isAssisted: boolean = false) {
    const validator =
      this.businessType.value === 'L' && this.store.shouldGetLLCType && !isAssisted ? Validators.required : null;
    this.taxClassification.setValidators(validator);
    this.taxClassification.updateValueAndValidity();
  }

  /**
   *
   */
  public shouldAskTaxClass(): boolean {
    return this.store.shouldGetLLCType;
  }

  // Business types other than Sole Proprietorship, Partnerships, and LLC only allow for TIN tax filing
  public evaluateSelectedType() {
    const selectedType = this.selectedType();
    if (!['I', 'P', 'L'].includes(selectedType?.code)) {
      this.tinType.setValue(this.TIN_ONLY);
    }
  }

  public getTinTypeCode(tinValue) {
    return this.tinTypes.find(tin => tin.value == tinValue)?.code;
  }
}

export interface BusinessTaxesControls {
  businessType: MobxFormControl;
  tinType: MobxFormControl;
  tin: MobxFormControl;
  taxClassification: MobxFormControl;
}
