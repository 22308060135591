<div class="cart-icon-container position-relative">
  <button
    id="reviewCartBtn"
    aria-label="Review My Shopping Cart"
    class="btn btn-cart"
    (click)="onClick()"
    (keyup.enter)="onClick()"
    tabindex="0"
    *ngIf="!isLoading && !isCartModalOpen; else loading"
  >
    <i role="button" aria-label="Shopping Cart" class="fal fa-cart-shopping cart-icon"></i>
    <div class="cart-icon-count">{{productCount}}</div>
  </button>
</div>

<ng-template #loading>
  <div class="spinner-container">
    <div class="lds-dual-ring"></div>
  </div>
</ng-template>
