import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { UntypedFormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Category } from '@eventhorizon/models/category.model';
import { commonErrors } from '@eventhorizon/constants/general.constants';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { lastValueFrom } from 'rxjs';
import { IndustryController } from '@eventhorizon/controllers/industry.controller';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';

@Component({
  selector: 'app-tg-industry-subcategory-selector',
  templateUrl: './industry-subcategory-selector.component.html',
  styleUrls: ['./industry-subcategory-selector.component.scss'],
})
export class IndustrySubcategorySelectorComponent extends FormCarouselSlide implements OnInit {
  public subCategories: Category[];

  public errorMessage: string;

  public category: MobxFormControl;

  public subCategory: MobxFormControl;

  @Input() public disableNextOnError? = false;

  constructor(
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    private store: ApplicationStore,
    protected fb: UntypedFormBuilder,
    private industryController: IndustryController,
  ) {
    super(bsModalService, cd);
  }

  async ngOnInit() {
    this.buildForm();
    this.subCategories = this.store.category && this.store.category.subCategories;

    if (!!this.subCategories) {
      this.subCategories = await this.industryController.industrySubCategories();
    }
  }

  private buildForm() {
    const s = this.store;

    this.subCategory = new MobxFormControl(
      'subCategory',
      () => (s.subCategory ? s.subCategory.id : -1),
      v => this.selectSubcategory(v),
      CommonValidator.number(),
    );

    this.form = this.fb.group({
      subCategory: this.subCategory,
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    try {
      await lastValueFrom(this.store.saveCategory());
      return true;
    } catch {
      this.errorMessage = commonErrors.errorEncountered;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public isSelectedSubcategory(index: number): boolean {
    if (!this.store.subCategory) {
      return false;
    }
    const subcategory = this.subCategories[index];
    return this.store.subCategory.id === +subcategory.id;
  }

  public selectSubcategory(id: number) {
    const subCategory = this.subCategories && this.subCategories.find(c => c.id === id);
    if (subCategory) {
      this.store.subCategory = {
        id: +subCategory.id,
        name: subCategory.name,
        displayName: subCategory.displayName,
        description: subCategory.description,
        rank: subCategory.rank,
        imageUrl: subCategory.imageUrl,
        isSubcategory: true,
      };
    }
    return true;
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  override isNextDisabled(): boolean {
    return !!this.disableNextOnError && this.errorMessage === commonErrors.errorEncountered;
  }
}
