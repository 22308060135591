<ng-container *ngIf="cartStore.cart && form">
  <div class="cart-container row" [ngClass]="{ 'cart-container-height': !showExtendedDisplay }">
    <form [formGroup]="form" class="cart-form">
      <div class="row" [ngClass]="{ 'one-time-cost-header': !showExtendedDisplay }">
        <div
          class="col-8 cart-section-header p-0"
          [ngClass]="{ 'col-md-6': !showExtendedDisplay, 'col-md-5': showExtendedDisplay }"
        >
          <span [ngClass]="{ 'text-uppercase': !showExtendedDisplay }">Your Solution</span>
        </div>
        <div
          class="col-4 row d-flex align-items-center px-0"
          [ngClass]="{ 'col-md-6': !showExtendedDisplay, 'col-md-7': showExtendedDisplay }"
        >
          <div
            class="d-none d-md-block p-0 text-center"
            [ngClass]="{ 'col-md-5': !showExtendedDisplay, 'col-md-4': showExtendedDisplay }"
          >
            <span class="quantity-header">Quantity</span>
          </div>
          <div
            *ngIf="showExtendedDisplay && showPaymentTermAsColumn"
            class="d-none d-md-block col-md-4 p-0 text-center"
          >
            <span class="quantity-header">Payment Term</span>
          </div>
          <div
            class="col-12 p-0 text-end"
            [class.col-md-7]="!showExtendedDisplay"
            [class.col-md-8]="showExtendedDisplay && !showPaymentTermAsColumn"
            [class.col-md-4]="showExtendedDisplay && showPaymentTermAsColumn"
          >
            <span class="total-header" [ngClass]="{ 'total-header-margin': !showExtendedDisplay }">Total</span>
          </div>
        </div>
      </div>

      <div class="header-separator"></div>

      <div *ngIf="!cartStore.hasProducts" class="col-12 cart-warning form-error-message">
        <div class="add-items-error">Add items to your cart to continue your application</div>
      </div>
      <div *ngIf="cartStore.hasIncompatibleMccError" class="col-12 cart-warning form-error-message">
        <div class="row add-items-error">{{ cartStore.incompatibleMccErrorMessage }}</div>
      </div>
      <div *ngIf="cartStore.hasPosError" class="col-12 cart-warning form-error-message">
        <div class="row add-items-error">{{ cartStore.posErrorMessage }}</div>
      </div>
      <div *ngIf="network.isOffline$ | async" class="col-12 cart-warning form-error-message">
        <div class="add-items-error">You are currently offline, please check your device's network status.</div>
      </div>

      <div *ngFor="let location of getLocationProducts(); let index = index" class="cart-location-container">
        <h2 class="cart-location-title" *ngIf="getLocationProducts().length > 1">
          {{ this.appStore.businessLocations[index]?.name || 'Location ' + (index + 1) }}
        </h2>
        <div
          class="text-start purchased-products"
          *ngFor="let product of location.purchaseProducts"
          [ngClass]="{ 'cart-products-container': !showExtendedDisplay }"
        >
          <app-tg-cart-product
            [modalDialogInstance]="modalDialogInstance"
            [readonly]="(network.isOffline$ | async) || readonly"
            [product]="product"
            [showExtendedDisplay]="showExtendedDisplay"
            [showPaymentTermAsColumn]="showPaymentTermAsColumn"
          ></app-tg-cart-product>
        </div>
        <div
          class="text-start cart-products-container recurring-products"
          *ngFor="let product of location.recurringProducts"
          [ngClass]="{ 'cart-products-container': !showExtendedDisplay }"
        >
          <app-tg-cart-product
            [modalDialogInstance]="modalDialogInstance"
            [readonly]="readonly"
            [product]="product"
            [showExtendedDisplay]="showExtendedDisplay"
            [showPaymentTermAsColumn]="showPaymentTermAsColumn"
          ></app-tg-cart-product>
        </div>
      </div>

      <div class="row" *ngIf="showExtendedDisplay">
        <div class="col-12">
          <div class="cart-unknown-error-container" *ngFor="let message of getUnknownErrors()">
            <i class="fal fa-triangle-exclamation"></i>
            <span class="cart-unknown-error-message">{{ message }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showExtendedDisplay && showTransactions">
        <div class="row mt-4">
          <div class="cart-section-header p-0">
            <span>Your Transactions</span>
          </div>
        </div>
        <div class="header-separator"></div>
        <div>
          <app-transactions></app-transactions>
        </div>
        <div class="header-separator mt-4"></div>
      </ng-container>
    </form>
  </div>
  <app-cart-summary
    (setEstimatedTaxRate)="setEstimatedTaxRate($event)"
    [readonly]="readonly"
    [showExtendedDisplay]="showExtendedDisplay"
    [showComplexSubtotals]="showComplexSubtotals"
    [carts]="getMultilocationCarts()"
  ></app-cart-summary>

  <div class="row totals not-extended" *ngIf="!showExtendedDisplay">
    <div class="totals-text col-8 col-md-9">
      <div>
        <span class="totals-subtotal">Subtotal </span><span class="totals-sub-text">1st payment </span>
        <app-tg-tooltip
          class="totals-sub-text"
          [tooltipText]="'Monthly software and security plans are not included in taxable subtotal'"
        ></app-tg-tooltip>
      </div>
    </div>
    <div class="col-4 totals-amount text-end col-md-3 fw-bold">
      {{ totalCost(cartStore.cart, 0) | currency }}
    </div>
  </div>

  <div *ngIf="showExtendedDisplay" class="row cart-section-total">
    <div class="col-7 col-md-6 due-text">
      <div>{{ dueToMessage || 'Due on 1st payment' }}</div>
    </div>
    <div class="col-5 col-md-6 totals-amount text-end">
      <div>{{ getTotalCost() | currency }}</div>
    </div>
  </div>

  <div *ngIf="showExtendedDisplay && !readonly" class="row cart-disclaimer">
    <div class="col-12">
      <i class="far fa-lightbulb-exclamation"></i
      ><span class="cart-disclaimer-text">{{
        cartDisclaimer || 'You will not need to provide a payment method to complete your application today.'
      }}</span>
    </div>
  </div>

  <div class="row m-4 d-flex justify-content-center align-items-center" *ngIf="!showExtendedDisplay && !readonly">
    <button *ngIf="!cartStore.isLoading" class="primary-button add-products-button m-2" (click)="goToProductsPage()">
      Add Products
    </button>
    <button
      *ngIf="!cartStore.isLoading"
      class="primary-button m-2"
      (click)="goToContinue()"
      [disabled]="!cartStore.hasProducts || cartStore.hasIncompatibleMccError || (network.isOffline$ | async)"
    >
      Continue Application
    </button>
    <div *ngIf="cartStore.isLoading" class="text-center">
      <div class="lds-dual-ring loading"></div>
    </div>
  </div>
</ng-container>
