import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { HttpErrorResponse } from '@angular/common/http';
import { TaxRateService } from '@eventhorizon/services/tax-rate.service';
import { UntypedFormControl } from '@angular/forms';
import { Cart, masks } from '@xup-payments/xup-frontend-utils/models';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnInit {
  @Input() showExtendedDisplay: boolean;

  @Input() showComplexSubtotals: boolean;

  @Input() readonly: boolean;

  @Input() carts?: Cart[];

  @Input() isInsideAnotherContainer: boolean = false;

  @Output() setEstimatedTaxRate = new EventEmitter();

  public showZipCodeInput = false;

  public isLoadingZipCode = false;

  public zipCodeError = '';

  public zipCodeEstimation = '';

  public zipCodeControl: UntypedFormControl;

  public masks = masks;

  public estimatedTaxRate = 0;

  public estimatedTaxTooltipText = 'Monthly software and security plans are not included in taxable subtotal';

  public multilocationOneTime = 0;

  public multilocationRecurring = 0;

  public multilocationShippment = 0;

  public multilocationTax = 0;

  constructor(public cartStore: CartStore, protected taxRateService: TaxRateService) {}

  ngOnInit() {
    if (!this.carts) {
      this.cartStore.loadCart().subscribe();
    } else {
      this.loadAmountsForMultilocation();
    }
    this.buildForm();
  }

  public estimateTax(): void {
    this.zipCodeError = '';
    this.showZipCodeInput = false;
    this.isLoadingZipCode = true;
    this.zipCodeEstimation = this.zipCodeControl.value;
    this.taxRateService.getSalesTaxRate(this.zipCodeEstimation).subscribe(
      result => {
        this.estimatedTaxRate = this.cartStore.cart.amount * result.taxRate;
        this.setEstimatedTaxRate.emit(this.estimatedTaxRate);
        this.isLoadingZipCode = false;
      },
      (errorResult: HttpErrorResponse) => {
        this.estimatedTaxRate = null;
        this.setEstimatedTaxRate.emit(this.estimatedTaxRate);
        if (errorResult.status === 404) {
          this.zipCodeError = errorResult.error.errors ? errorResult.error.errors[0] : errorResult.error;
        }
        this.isLoadingZipCode = false;
      },
    );
  }

  public toggleTaxEstimateZipCode() {
    this.showZipCodeInput = !this.showZipCodeInput;
  }

  get salesTaxRate() {
    let taxAmount = this.carts ? this.multilocationTax : this.cartStore.cart.taxAmount;
    return taxAmount !== 0 ? taxAmount : this.estimatedTaxRate;
  }

  protected buildForm() {
    this.zipCodeControl = new UntypedFormControl('', CommonValidator.zip());
  }

  private loadAmountsForMultilocation() {
    this.carts.forEach(cart => {
      this.multilocationOneTime += cart.oneTimeTotal;
      this.multilocationRecurring += cart.recurringtotalAmount;
      this.multilocationShippment += cart.shippingAmount;
      this.multilocationTax += cart.taxAmount;
    });
  }

  public getRecurringTotalAmount(): number {
    return !this.carts ? this.cartStore.cart.recurringtotalAmount : this.multilocationRecurring;
  }

  public getOneTimeTotal(): number {
    return !this.carts ? this.cartStore.cart.oneTimeTotal : this.multilocationOneTime;
  }

  public getShippingAmount(): number {
    return !this.carts ? this.cartStore.cart.shippingAmount : this.multilocationShippment;
  }
}
