import { FormControl, ValidatorFn } from '@angular/forms';
import { reaction } from 'mobx';
import { valueExists } from '@eventhorizon/utils/general.util';

//The Type = any should be replaced to just Type, when all the MobxFormControl instances had been typed
export class MobxFormControl<Type = any> extends FormControl {
  private name = '';

  constructor(
    name: string,
    get: Function,
    set: Function,
    validator?: ValidatorFn | ValidatorFn[],
    isTrueFalseValues: boolean = false,
  ) {
    super('', validator);

    this.name = name;

    try {
      const initialValue = <Type>get();
      if ((valueExists(initialValue) && isTrueFalseValues) || initialValue) {
        this.setValue(initialValue);
        this.markAsDirty();
        this.markAsTouched();
        this.updateValueAndValidity();
      }
    } catch {}

    reaction(
      () => <Type>get(),
      changed => {
        this.setValue(changed);
        this.markAsDirty();
        // console.debug(`${name}: dirty: ${this.dirty}`);
      },
    );

    this.valueChanges.subscribe((v: Type) => {
      if (!this.disabled) {
        // this.markAsTouched();
        // disabled happens on some fields like mailing addresses that are linked
        //  and causes a cycle
        // Also seems to be what ngModel is doing
        set(v);

        // console.debug(`${name} value changed to: ${get()}`);
      }
    });
  }

  public setEnabled(enabled: boolean) {
    if (enabled) {
      this.enable({ emitEvent: false });
    } else {
      this.disable({ emitEvent: false });
    }
  }

  public validate() {
    this.markAsDirty();
    this.markAsTouched();
    this.updateValueAndValidity();
  }
}
