<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 cart-header">
      <button class="btn cart-header-close" (click)="closeModal()" aria-label="Close Shopping Cart" #focus>
        <i class="fas fa-times"></i>
      </button>
      <h3 [id]="ariaLabelledByValue" class="cart-header-title">CART</h3>
    </div>
  </div>
  <app-tg-cart
    [modalDialogInstance]="this"
    [continuePage]="continuePage"
    [showExtendedDisplay]="false"
    [showComplexSubtotals]="true"
  ></app-tg-cart>
</div>
