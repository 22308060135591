import { UntypedFormGroup } from '@angular/forms';

export interface RiskAlertSummaryRequest {
  pageNumber?: string;
  pageSize?: string;
  skip?: string;
  sortBy?: string;
  query?: string;
}

export interface RiskAlertSummaryCSVRequest extends RiskAlertSummaryRequest {
  timezone: string;
}

export interface Risk {
  id: string;
  createdAt: string;
  updatedAt: string;
  updatedAtDate: string;
  updatedAtTime: string;
  merchantId: string;
  businessLocationId?: any;
  name: string;
  providerType: string;
  alertType: AlertTypeEnum;
  status: AlertStatusEnum;
  overrideReason: string;
}

export enum AlertTypeEnum {
  PrincipalOFAC = 'Ongoing Principal OFAC',
  BusinessOFAC = 'Ongoing Business OFAC',
  MATCHCheck = 'Match Check',
  BusinessVerification = 'Business Verification',
  PrincipalsVerification = 'Principals Verification',
  BankAccountVerification = 'Bank Account Verification',
  BusinessToPrincipalsLinkResults = 'Business to Principals Link Results',
  WebsiteVerification = 'Website Verification',
}

export enum UserActionObjectType {
  BANK_VERIFICATION_RISK_ALERT = 'BANK_VERIFICATION_RISK_ALERT',
  MATCH_RISK_ALERT = 'MATCH_RISK_ALERT',
  PRINCIPAL_OFAC_RISK_ALERT = 'PRINCIPAL_OFAC_RISK_ALERT',
  BUSINESS_OFAC_RISK_ALERT = 'BUSINESS_OFAC_RISK_ALERT',
  BUSINESS_VERIFICATION_RISK_ALERT = 'BUSINESS_VERIFICATION_RISK_ALERT',
  PRINCIPAL_VERIFICATION_RISK_ALERT = 'PRINCIPAL_VERIFICATION_RISK_ALERT',
  BUSINESS_TO_PRINCIPAL_LINK_RISK_ALERT = 'BUSINESS_TO_PRINCIPAL_LINK_RISK_ALERT',
  WEBSITE_VERIFICATION_RISK_ALERT = 'WEBSITE_VERIFICATION_RISK_ALERT',
}

export interface AlertSummary {
  total: number;
  grandTotal: number;
  pageSize: number;
  page: number;
  results: Risk[];
}

export enum AlertStatusEnum {
  Pass = 'Pass',
  Override = 'Override',
  Fail = 'Fail',
}

export enum AlertSubmittedStatusEnum {
  Submitted = 'Submitted',
  NoSubmitted = 'Not Submitted',
}

export interface CountsByType {
  alertType: string;
  count: number;
  businessLocationId: string;
}

export interface AlertCountsResponse {
  totalCount: number;
  countsByType: CountsByType[];
}

export interface AlertType {
  code: number;
  description: string;
}

export interface Principal {
  firstName: string;
  middleInitial?: any;
  lastName: string;
  address: RiskAddress;
  phoneNumber: string;
  altPhoneNumber?: any;
  nationalId?: any;
  driversLicense?: any;
}

export interface InputObject {
  name: string;
  doingBusinessAsName: string;
  address: RiskAddress;
  phoneNumber: string;
  altPhoneNumber?: any;
  nationalTaxId: string;
  countrySubdivisionTaxId?: any;
  serviceProvLegal?: any;
  serviceProvDBA?: any;
  url?: any;
  principal: Principal[];
  searchCriteria?: any;
  addedOnDate?: any;
  terminationReasonCode?: any;
  addedByAcquirerID?: any;
  urlGroup?: any;
  comments?: any;
}

export interface MatchDetail {
  summary: Risk;
  inputObject: InputObject;
}

export interface BankAccountVerificationDetail {
  summary: Risk;
  voidCheckDocumentId: number;
}

export interface RiskPassFailResponse {
  passed: boolean;
}

export interface RiskAddress {
  line1: string;
  line2: string;
  city: string;
  countrySubdivision: string;
  province: string;
  postalCode: string;
  country: string;

  // bridge address fields
  id: string;
  instance: string;
  state: string;
  street1: string;
  text: string;
  type: string;

}

export interface BridgeAddress {
  street1?: any;
  city?: any;
  instance?: number;
  isDeleted?: boolean;
  state?: string;
  postalCode?: string;
  id?: number;
  type?: string;
  text?: any;
  country?: string;
}

export interface InputData {
  entityType: string;
  searchDate: string;
  origin: string;
  fullName: string;
  firstName: string;
  middleName?: any;
  lastName: string;
  additionalInfo: AdditionalInfo;
  addresses: RiskAddress[];
  identifications: Id[];
  phoneNumbers: PhoneNumber[];
  providerId: string;
}

export interface PhoneNumber {
  type: string;
  number: string;
}

export interface AdditionalInfo {
  type: string;
  information: string;
}

export interface Id {
  label?: any;
  number: string;
  issuer?: any;
  entityID: number;
  type: number;
  text?: any;
  typeDescription?: string;
}

export interface Result {
  entityScore?: string;
  name?: string;
  list?: string;
  gender?: string;
  country?: string;
  dob?: string[];
  ids?: Id[];
  addresses?: BridgeAddress[];
}

export interface BridgerInformation {
  total: number;
  grandTotal: number;
  pageSize: number;
  page: number;
  results: Result[];
}

export interface BridgerAlertDetail {
  summary: Risk;
  inputData: InputData;
  bridgerInformation: BridgerInformation;
}

export interface BridgerAlertDetailRequest {
  pageNumber?: string;
  pageSize?: string;
  sortBy?: string;
}

export interface RiskIndicator {
  code: string;
  description: string;
  severity: string;
}

export interface BusinessVerificationAddress {
  streetNumber?: string;
  streetPreDirection?: string;
  streetName?: string;
  streetSuffix?: string;
  streetPostDirection?: string;
  unitDesignation?: string;
  unitNumber?: string;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zip5: string;
  zip4?: string;
  county?: string;
  postalCode?: string;
  stateCityZip?: string;
  latitude?: number;
  longitude?: number;
}

export interface Company {
  companyName: string;
  alternateCompanyName?: string;
  address: BusinessVerificationAddress;
  phone: string;
  fein: string;
}

export interface Name {
  full?: string;
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
  prefix?: string;
}

export interface Dob {
  year: number;
  yearSpecified: boolean;
  month: number;
  monthSpecified: boolean;
  day: number;
  daySpecified: boolean;
}

export interface AuthorizedRep {
  sequence?: string;
  name: Name;
  formerLastName?: string;
  uniqueId?: string;
  address: BusinessVerificationAddress;
  dob: Dob;
  age?: string;
  ssn: string;
  phone: string;
  driverLicenseNumber?: string;
  driverLicenseState?: string;
  email?: string;
}

export interface InputDataBusinessVerification {
  company: Company;
  authorizedRep1: AuthorizedRep;
  authorizedRep2?: AuthorizedRep;
  authorizedRep3?: AuthorizedRep;
  authorizedRep4?: AuthorizedRep;
  authorizedRep5?: AuthorizedRep;
}

export interface VerifiedData {
  companyName?: string;
  streetAddress?: string;
  aptSuite?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  fein: string;
}

export interface BusinessVerificationAlertDetail {
  description: string[];
  score: number;
  riskIndicators: RiskIndicator[];
  summary: Risk;
  inputData: InputDataBusinessVerification;
  verifiedData: VerifiedData;
}

export interface VerifiedPrincipalData {
  name: string;
  streetAddress: string;
  aptSuite?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
  ssn?: string;
}

export interface PrincipalVerificationAlertDetail {
  inputRepresentativeNumber: number;
  description: string[];
  score: number;
  riskIndicators: RiskIndicator[];
  inputData: PrincipalVerificationInputData;
  verifiedData: VerifiedPrincipalData;
  summary: Risk;
}

export interface PrincipalVerificationInputData {
  company: Company;
  authorizedRep1: AuthorizedRep;
  authorizedRep2?: AuthorizedRep;
  authorizedRep3?: AuthorizedRep;
  authorizedRep4?: AuthorizedRep;
  authorizedRep5?: AuthorizedRep;
}

export interface BusinessToPrincipalLinkAlertDetail {
  inputRepresentativeNumber: number;
  business2ExecutiveLinkIndexResult: string;
  description: string[];
  score: number;
  summary: Risk;
  inputData: PrincipalVerificationInputData;
}

export interface RiskOverrideDialogInfo {
  form: UntypedFormGroup;
  title: string;
  text?: string;
  nextButton?: string;
  cancelButton?: string;
  nextButtonClass?: string;
  nextButtonMinWidth?: string;
  cancelButtonClass?: string;
  cancelButtonMinWidth?: string;
  modalClass?: string;
}

export interface WebsiteVerificationAlertDetail {
  latestAlertId: string;
  details: WebsiteVerificationAlertDetail;
  summary: Risk;
}


export interface CustomerAction {
  action: string;
  comment: string;
  createdAt: Date;
}

export interface ContentLabel {
  id: number;
  label: string;
}

export interface LsAction {
  publishedAt: Date;
  actions: string[];
  contentLabels: ContentLabel[];
  reason: string;
}

export interface MerchantDetails {
  acquiringBank: string;
  additionalMerchantUrls: string;
  agent: string;
  associatedEmailAddresses: string;
  businessOwnerCity: string;
  businessOwnerCountry: string;
  businessOwnerName: string;
  businessOwnerPhone: string;
  businessOwnerPostalCode: string;
  businessOwnerState: string;
  businessOwnerStreet: string;
  dbaCity: string;
  dbaCountry: string;
  dbaName: string;
  dbaPhone: string;
  dbaPostalCode: string;
  dbaState: string;
  dbaStreet: string;
  ica: string;
  iso: string;
  merchantCategoryCode: number;
  merchantCity: string;
  merchantCountry: string;
  merchantDescription: string;
  merchantEmail: string;
  merchantId: string;
  merchantName: string;
  merchantPhone: string;
  merchantPostalCode: string;
  merchantRegion: string;
  merchantStreet: string;
  merchantUrl: string;
  status: string;
  subIso: string;
}

export interface WebsiteRegistrar {
  ianaNumber: number;
  name: string;
  riskLevel: number;
}

export interface WebsiteVerificationAlertDetails {
  id: number;
  createdAt: Date;
  customerAction: CustomerAction;
  lsAction: LsAction;
  merchantDetails: MerchantDetails;
  websiteRegistrar: WebsiteRegistrar;
}
