<div class="solution-container" *ngIf="isLoading; else loaded">
  <div class="lds-dual-ring"></div>
</div>

<ng-template #loaded>
  <div class="col-sm-12 ps-3">
    <app-tg-cart
      [showPaymentTermAsColumn]="true"
      [showExtendedDisplay]="true"
      [readonly]="true"
      [continuePage]="routeToSummary"
      [isMultilocation]="isApplicationMultilocation()"
    ></app-tg-cart>
  </div>
</ng-template>
