<div *ngIf="isLoading" class="col-sm-12">
  <div class="lds-dual-ring"></div>
</div>
<div class="appconfirm" *ngIf="!isLoading && !errorMessage">
  <div class="appconfirm-status">{{ messages.applicationStatus }}</div>
  <div class="row">
    <div class="col-sm-2 appconfirm-icon"><i [class]="'far ' + applicationStatusIcon()"></i></div>
    <div class="col-sm-10 appconfirm-status-msg">{{ applicationStatusMsg() }}</div>
  </div>
  <div class="appconfirm-helpline"
       *ngIf="bankInfoConfig?.applicationConfirmationText"
       [innerHtml]="bankInfoConfig?.applicationConfirmationText">
  </div>
  <div class="appconfirm-status">{{ messages.orderSummary }}</div>
  <app-tg-order-confirmation-details
    [title]="orderDetailsTitle()"
    [subTitle]="messages.trackOrder"
    [trackingUrl]="trackingUrl"
    [orderDetails]="orderDetails()"
    [cancelledOrder]="cancelledOrder()"
  ></app-tg-order-confirmation-details>
  <div *ngIf="!isOrderStatus">
    <button
      (click)="downloadMpa()"
      class="transparent-button appconfirm-download"
      [disabled]="isLoading || errorMessage"
    >
      <i class="fas fa-download download-icon"></i>
      <span class="appconfirm-download-message">{{ bankInfoConfig?.downloadMpaLinkText || messages.downloadMpa }}</span>
    </button>
  </div>
</div>
<div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
  {{ errorMessage }}
</div>
