import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { messages } from '@xup-payments/xup-frontend-utils/models';

@Component({
  selector: 'app-payment-types',
  templateUrl: './payment-types-screen.component.html',
  styleUrls: ['./payment-types-screen.component.scss'],
})
export class PaymentTypesScreenComponent extends BasePageDriverComponent {
  messages = messages;

  constructor(
    protected routingPathService: RoutingPathService,
    protected router: Router,
    public store: ApplicationStore,
    protected route: ActivatedRoute,
  ) {
    super(routingPathService, router, route);
  }
}
