import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { randomComponentId } from '@eventhorizon/utils/util';

@Component({
  selector: 'app-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
})
export class RadioCardComponent {
  @Input() title: string;

  @Input() subtitle: string;

  @Input() value: string;

  @Input() radioName: string;

  @Input() price: number;

  @Input() formControlRef: UntypedFormControl;

  @Output() valueChanged = new EventEmitter();

  public id: string = randomComponentId();

  setValue(value) {
    this.formControlRef.setValue(value.currentTarget.checked ? this.value : '');
    this.valueChanged.emit();
  }

  get displayPrice() {
    return this.value !== 'Installment';
  }
}
