import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

export class TransactionDistribution {
  constructor(obj?: any) {
    makeObservable(this, {
      faceToFace: observable,
      phoneOrEmail: observable,
      internet: observable,
      onTheGo: observable,
      totalPercentage: observable,
    });
    Object.assign(this, obj);
  }

  faceToFace = 0;

  phoneOrEmail = 0;

  internet = 0;

  onTheGo = 0;

  totalPercentage = 0;

  public isEmpty(): boolean {
    return this.isTransactionDistributionEmpty();
  }

  private isTransactionDistributionEmpty(): boolean {
    return !this.faceToFace && !this.phoneOrEmail && !this.internet && !this.onTheGo && !this.totalPercentage;
  }
}
