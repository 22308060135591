<div class="panel text-center">
  <div class="modal-header">
    <button type="button" class="btn pull-right" aria-label="Close" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
  <div *ngIf="!successMessage" class="modal-body">
    <h5 id="modal-title" class="modal-title">Save & Continue Later</h5>
    <div class="info-text">
      Enter your email to receive a unique link to resume your order and application where you left off. When returning
      to your saved application, an authentication code will be sent to the email or mobile phone number provided.
    </div>

    <form [formGroup]="form">
      <div class="email-input">
        <div class="row mb-3">
          <div class="col-12">
            <input
              [id]="1"
              formControlName="email"
              class="form-control"
              type="text"
              maxlength="70"
              autocomplete="email"
            />
            <label class="form-label" [for]="1">Email</label>
            <app-field-error [control]="email"></app-field-error>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-1">
            <input
              [id]="2"
              formControlName="phone"
              class="form-control"
              type="text"
              maxlength="70"
              [maskito]="masks.phone"
              autocomplete="tel"
            />
            <label class="form-label" [for]="2">Phone</label>
            <app-field-error [control]="phone"></app-field-error>
          </div>
          <div class="col-12">
            <p class="modal-disclaimer">
              * By providing your cell phone number you consent to receive a one-time verification code via an automatic
              telephone dialing system. Message and data rates may apply.
            </p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 terms-checkbox-container">
            <label class="form-option-label terms-label" for="agree">
              <input id="agree" formControlName="agree" class="form-option-input" type="checkbox" />
              I agree to
              <a
                class="navigation-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.key.com/about/misc/user-agreement.html"
              >
                User Agreement
              </a>
              &
              <a
                class="navigation-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.key.com/about/misc/online-privacy-statement.html"
                >Privacy Policy</a
              >.
            </label>
          </div>
        </div>
        <div class="row">
          <div *ngIf="errorMessage" class="col-12 form-error-message">
            {{ errorMessage }}
          </div>

          <div class="col-12 submit-button-place">
            <button [disabled]="!form.valid" type="button" class="btn primary-button" (click)="submit()">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="successMessage" class="modal-body">
    <div id="modal-title" class="info-text">
      {{ successMessage }}
    </div>
    <div class="info-text"></div>
    <div class="submit-button-place text-center">
      <button type="button" class="btn primary-button" (click)="close()">Return to Application</button>
    </div>
  </div>
</div>
