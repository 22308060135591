import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const invalidSsns = [
  '111223333',
  '123121234',
  '123456789',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
];

const invalidSsnObject = {
  'ssn-invalid': true,
};

export const ssnRegex = /^[0-8][0-9]{8}$/;
export const altSsnRegex = /^\*{5}[0-9]{4}|[0-8][0-9]{8}$/;

export class SSNValidator {
  static ssn(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        // Remove dashes
        const ssn = control.value.replace(/\D+/g, '');

        return SSNValidator.isSsnInvalid(ssn, ssnRegex) ? invalidSsnObject : null;
      }

      return isRequired
        ? {
          'ssn-required': true,
        }
        : null;
    };
  }
  
  static ssnAlt(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        // Remove dashes
        const ssn = control.value.replace(/[^*0-9]+/g, '');
        return SSNValidator.isSsnInvalid(ssn, altSsnRegex) ? invalidSsnObject : null;
      }

      return isRequired
        ? {
          'ssn-required': true,
        }
        : null;
    };
  }

  private static isSsnInvalid(ssn: string, regex: RegExp): boolean {
    return (
      !regex.test(ssn) ||
      ssn.substring(0, 3) === '000' ||
      ssn.substring(0, 3) === '666' ||
      ssn.substring(3, 5) === '00' ||
      ssn.substring(5, 9) === '0000' ||
      invalidSsns.includes(ssn)
    );
  }
}
