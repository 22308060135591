<div class="panel text-center">
  <div *ngIf="isLoading" class="spinner">
    <div class="lds-dual-ring"></div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="!isLoading">
          <div class="row">
            <div class="col-sm-12 col-lg-6 col-xl-5">
              <app-tg-product-detail-images
                [alt]="productDetail.productName"
                [productImagesURLs]="productDetail.imageUrls"
                [showThumbnails]="false"
              ></app-tg-product-detail-images>
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-7 text-start">
              <div class="row">
                <div class="col-sm-12">
                  <h2>
                    <span class="product-name">{{ productDetail.name }}</span>
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>{{ productDetail.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
