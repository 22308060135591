<div class="software" [ngClass]="{ 'is-selected': isProductInCart }">
  <div *ngIf="isRecommended" class="recommended-container">
    <span class="recommended-tag">Recommended</span>
  </div>
  <div class="row justify-content-center">
    <span class="software-name">{{ product?.name || product?.productName }}</span>
  </div>
  <div class="row justify-content-center">
    <span class="software-price">${{ product?.price }}/mo</span>
  </div>
  <div class="row justify-content-center text-center">
    <span class="software-details mt-2" (click)="showDetailsModal(product)">DETAILS &#62;</span>
  </div>
  <div class="row justify-content-center">
    <button
      class="primary-button small-button software-action"
      [ngClass]="{ selected: isProductInCart }"
      (click)="addToCart()"
      [disabled]="isProductInCart || cartStore.isLoading"
    >
      {{ isProductInCart ? 'SELECTED' : 'CHOOSE' }}
    </button>
  </div>
</div>
