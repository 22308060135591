import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { NavigationLink } from '@xup-payments/xup-frontend-utils/models';
import { HelpCenterPopupComponent } from '../help-center-popup/help-center-popup.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(
    private bsModalService: BsModalService,
    private contentService: ContentServiceService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  public get config() {
    return this.contentService.bankInfoConfig?.footerConfig;
  }

  actionMap: { [key: string]: () => void } = {
    startApplication: () => {
      this.router.navigate([ROUTES.CONTACT_INFO]);
    },
    openHelpCenter: () => {
      this.bsModalService.show(HelpCenterPopupComponent, {
        class: 'modal-lg',
        ariaLabelledBy: 'help-center',
      });
    },
    goToProductsPage: () => {
      this.router.navigate(['/products']);
    },
  };

  public doAction(navLink: NavigationLink): void {
    if (navLink.url) {
      if (navLink.shouldOpenInNewTab) {
        window.open(navLink.url, '_blank');
      } else {
        window.location.href = navLink.url;
      }
    } else if (navLink.action) {
      const camelCaseAction = this.toCamelCase(navLink.action);
      const actionFn = this.actionMap[camelCaseAction];
      if (typeof actionFn !== 'undefined') {
        actionFn();
      }
    }
  }

  private toCamelCase(str): string {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
}
