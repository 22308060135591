<div [formGroup]="form">
  <div class="row mb-3">
    <div class="col-12 col-md-6 position-relative ps-0" *ngIf="displayLocationDBA">
      <input
        [id]="id + 'dba'"
        type="text"
        formControlName="locationDBA"
        class="form-control"
        [textMask]="masks.companyName"
      />
      <label [for]="id + 'dba'" class="form-label">Location DBA</label>
      <app-field-error [control]="locationDBA"></app-field-error>
      <app-tg-tooltip
        class="tooltip-icon"
        [tooltipText]="
          'DBA stands for “doing business as.” This is also known as the assumed, trade, or primary name your business operates under at this location. DBA names can be the same for multiple locations.'
        "
      >
      </app-tg-tooltip>
    </div>
    <div class="col-12 col-md-6 position-relative ps-0" *ngIf="config.displayMccConfiguration">
      <lib-location-business-type [id]="id + 'mcc'" [location]="location"></lib-location-business-type>
    </div>
  </div>
  <div class="row mb-3" *ngIf="config.displayPhoneNumber && form.controls.businessPhone">
    <div class="col-12 ps-0 position-relative">
      <input
        [id]="id + 'phone'"
        formControlName="businessPhone"
        class="form-control"
        type="text"
        autocomplete="tel"
        [textMask]="masks.phone"
      />
      <label class="form-label" [for]="id + 'phone'">Business Phone </label>
      <app-field-error [control]="form.controls.businessPhone"></app-field-error>
    </div>
  </div>
  <div class="row mb-3" *ngIf="config.displayLocationCategory && form.controls.businessCategory">
    <div class="col-12 ps-0 position-relative">
      <select [id]="id + 'category'" formControlName="businessCategory" class="form-control form-select">
        <option *ngFor="let location of businessCategories" [value]="location?.name">{{ location?.name }}</option>
      </select>
      <label class="form-label" [for]="id + 'category'">Location Category</label>
      <app-field-error [control]="form.controls.businessCategory"></app-field-error>
    </div>
  </div>
  <app-tg-address #address [isAssisted]="isAssisted" [address]="location.address"></app-tg-address>
  <div class="d-flex mb-4">
    <div class="position-relative">
      <label class="checkbox-label" [for]="id + 'address'">
        <input
          [id]="id + 'address'"
          formControlName="sameAsLegal"
          class="form-option-input"
          type="checkbox"
          (change)="changeSameAsLegal($event.target.checked)"
        />&nbsp;Same as Legal Business Address
      </label>
    </div>
    <label
      class="checkbox-label d-flex align-items-center mx-4"
      [for]="id + 'primary'"
      *ngIf="config.displayPrimaryLocationRb && isAssisted"
    >
      <input
        [id]="id + 'primary'"
        formControlName="isPrimary"
        type="radio"
        [value]="true"
        (change)="changeIsPrimary()"
      />Primary Location
    </label>
  </div>
</div>
