export const categories = [
  {
    id: 1,
    name: 'Retail',
    fa: 'fal fa-cart-shopping',
    description: 'clothing, home goods, technology, and other consumer goods',
  },
  {
    id: 2,
    name: 'Food & Beverage',
    fa: 'fal fa-mug-saucer',
    description: 'coffee shops, grocery stores, fast food, casual dining, and cafés',
  },
  {
    id: 3,
    name: 'Professional Services',
    fa: 'fal fa-calendar',
    description: 'architects, engineers, consultants, lawyers, and financial advisors',
  },
  {
    id: 4,
    name: 'Travel & Hospitality',
    fa: 'fal fa-suitcase',
    description: 'car rentals, airlines, entertainment, lodging and hotels',
  },
  {
    id: 5,
    name: 'Healthcare & Beauty',
    fa: 'fal fa-heart',
    description: 'barbers, dentists, veterinarians, and other healthcare providers',
  },
  {
    id: 6,
    name: 'Other',
    fa: 'fal fa-star',
    description: 'Don’t see your industry? We still have a solution for your unique business.',
  },
];

export const businessCategories = {
  quickService: {
    name: 'Quick Service',
    fa: 'fa fa-jet-fighter',
  },

  fullService: {
    name: 'Full Service',
    fa: 'fa fa-plane-departure',
  },
};
