<div class="panel text-center">
  <h3 id="modal-title" class="modal-title">Link your bank account.</h3>
  <div class="modal-header">
    <button type="button" class="btn pull-right" aria-label="Close" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 p-0 bank-yodlee">
        <div id="container-fastlink"></div>
      </div>
    </div>
  </div>
</div>
