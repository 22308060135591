import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { Router } from '@angular/router';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { formatPhone, randomComponentId } from '@eventhorizon/utils/util';
import { masks } from '@eventhorizon/data/masks.data';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { BusinessNameValidator } from '@eventhorizon/validation/business-name.validator';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-off-ramp',
  templateUrl: './off-ramp.component.html',
  styleUrls: ['./off-ramp.component.scss'],
})
export class OfframpComponent implements OnInit {
  public form: FormGroup<IOffRampForm>;

  public masks = masks;

  public currentUrl = window.location.href;

  public id = randomComponentId();

  public loading = false;

  constructor(
    private appStore: ApplicationStore,
    protected fb: FormBuilder,
    private router: Router,
    public contentService: ContentServiceService,
  ) {}

  ngOnInit() {
    this.buildForm();

    if (this.currentUrl.includes('/offramp')) {
      window.onpopstate = () => {
        history.go(1);
      };
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      name: new MobxFormControl<string>(
        'name',
        () => this.appStore.applicantInfo.name,
        (v: string) => (this.appStore.applicantInfo.name = v),
        CommonValidator.personalName(),
      ),
      lastName: new MobxFormControl<string>(
        'lastName',
        () => this.appStore.applicantInfo.lastName,
        (v: string) => (this.appStore.applicantInfo.lastName = v),
        CommonValidator.personalName(),
      ),
      phoneNumber: new MobxFormControl<string>(
        'phoneNumber',
        () => formatPhone(this.appStore.applicantInfo.phoneNumber),
        (v: string) => (this.appStore.applicantInfo.phoneNumber = formatPhone(v)),
        CommonValidator.phone(),
      ),
      email: new MobxFormControl<string>(
        'email',
        () => this.appStore.applicantInfo.email,
        (v: string) => (this.appStore.applicantInfo.email = v),
        CommonValidator.email(),
      ),
      companyName: new MobxFormControl<string>(
        'companyName',
        () => this.appStore.applicantInfo.companyName,
        (v: string) => (this.appStore.applicantInfo.companyName = v),
        BusinessNameValidator.companyName,
      ),
    });
  }

  public async submit() {
    try {
      this.loading = true;
      this.appStore.callBackRequested = true;
      await lastValueFrom(
        this.appStore.save([
          this.appStore.saveSections.CONTACT_INFO_SAVE,
          this.appStore.saveSections.CALLBACK_REQUESTED,
        ]),
      );
      this.loading = false;
      this.router.navigate(['/callback']);
    } catch (error) {
      this.loading = false;
    }
  }

  public goBack() {
    window.location.href = this.contentService.bankInfoConfig.returnLink;
  }
}

export interface IOffRampForm {
  name: FormControl<string>;
  lastName: FormControl<string>;
  phoneNumber: FormControl<string>;
  email: FormControl<string>;
  companyName: FormControl<string>;
}
