import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { reaction, IReactionDisposer, when } from 'mobx';
import { ProductService } from '@eventhorizon/services/product.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { Validators } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { NetworkMonitorService } from '@eventhorizon/services/network-monitor.service';
import { PricingModel, ProductFull } from '@eventhorizon/models/product.model';
import { UpdateProductRequest } from '@eventhorizon/models/cart.model';
import { defaultImage, purchaseType } from '@eventhorizon/constants/general.constants';
import { goToTop } from '@eventhorizon/utils/util';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';

@Component({
  selector: 'app-tg-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnDestroy, OnInit {
  
  @Input()
  public productId: string;

  @Input()
  public productDisabled = false;

  @Output()
  public additionalProductClicked = new EventEmitter<string>();

  @Output()
  public productUpdate = new EventEmitter<UpdateProductRequest>();

  public defaultImage = defaultImage;

  public productDetail: ProductFull;

  public pricingModel: PricingModel;

  public paymentTypesControl: MobxFormControl;

  public paymentTermControl: MobxFormControl;

  public selectedPaymentType: string;

  public selectedPaymentTerm: string;

  public hasFeatures: boolean;

  public hasIncludedProducts: boolean;

  public hasSpecs: boolean;

  public hasFaq: boolean;

  public hasSimilarProducts: boolean;

  public itemsPerSlide = 3;

  public isLoading: boolean;

  public errorMessage: string;

  public paymentTypesOptions = [];

  public paymentTermsOptions = [];

  public selectedPurchaseType = '';

  public tooltipText =
    // eslint-disable-next-line max-len
    'In lieu of accepting card payments for one-time or recurring costs, you will be auto-charged on either your first or second month’s statement.';

  public subtitles = {
    'Pay in Full': 'Billed one-time on your 1st or 2nd statement',
    'Monthly Installments': 'Interest-free equal monthly payments',
    Owned: 'No additional cost incurred',
    Lease: 'Low monthly payments for a limited time” → NEW ADD, not enabled for KeyBank today',
    'Monthly Rent': 'Interest-free equal monthly payments',
  };

  public productQuantity = 1;


  private readonly applicationDisposer: IReactionDisposer[];

  constructor(
    private productService: ProductService,
    private cartStore: CartStore,
    private applicationStore: ApplicationStore,
    public network: NetworkMonitorService,
  ) {
    when(
      () => this.applicationStore.isLoaded,
      () => this.getProductDetail(),
    );

    const productsReaction = reaction(
      () => this.cartStore?.cart?.products,
      updated => {
        if (updated) this.applicationStore.loadApplication().toPromise();
      },
    );

    this.applicationDisposer = [productsReaction];
  }

  public isCurrentProductDisabledToBeAdded(productId: string): boolean {
    return this.cartStore.isCurrentProductDisabledToBeAdded(productId);
  }

  getTooltipText(): string {
    if (this.productDisabled) {
      // eslint-disable-next-line max-len
      return 'A Clover product is already in your cart. Every Clover device already comes with access to web and phone payments dashboard.';
    }
    if (this.selectedPurchaseType === '') {
      return 'Choose a payment method to add this item to your cart.';
    }
  }

  async ngOnInit() {
    goToTop();
    this.isLoading = true;
    if (this.applicationStore.id) {
      await this.getProductDetail();
    }
  }

  ngOnDestroy() {
    for (const disposer of this.applicationDisposer) disposer();
  }

  onQuantityChange(event) {
    this.productQuantity = event;
  }

  private async getProductDetail() {
    if (!this.applicationStore.isLoaded || !this.productId) {
      return;
    }

    try {
      this.isLoading = true;
      this.errorMessage = undefined;
      const result = await this.productService.getProductDetail(this.productId, this.applicationStore.id).toPromise();
      this.loadProductDetail(result);
    } catch (error) {
      console.error(error);
      this.errorMessage = 'There was an error trying to load the product details at the moment.';
      this.isLoading = false;
    }
  }

  isOwned() {
    return this.productDetail.pricingModel.find(pm => pm.purchaseType === 'O');
  }

  private loadProductDetail(result: ProductFull) {
    this.productDetail = result;
    this.hasFeatures = result.features.length > 0;
    this.hasIncludedProducts = result.includedProducts.length > 0;
    this.hasSpecs = Object.keys(result.specifications).length > 0;
    this.hasFaq = result.faq.length > 0;
    this.hasSimilarProducts = result.recommendedProducts.length > 0;
    this.buildPricingModelsSelection(result);
    this.isLoading = false;
  }

  public getLowestRecurringPrice(product: ProductFull) {
    if (product.pricingModel.length === 0) return;

    let lowest;
    for (const pricingModel of product.pricingModel) {
      if (pricingModel.purchaseType === purchaseType.PURCHASE) continue;

      if (!lowest || pricingModel.price < lowest) {
        lowest = pricingModel.price;
      }
    }

    if (lowest) return lowest;
  }

  private buildPricingModelsSelection(result: ProductFull): void {
    this.paymentTypesControl = new MobxFormControl(
      'paymentTypesControl',
      () => this.selectedPaymentType,
      v => (this.selectedPaymentType = v),
      Validators.required,
    );
    this.paymentTermControl = new MobxFormControl(
      'paymentTermControl',
      () => this.selectedPaymentTerm,
      v => (this.selectedPaymentTerm = v),
      Validators.required,
    );

    this.paymentTypesOptions = [];
    this.paymentTermsOptions = [];
    if (result.pricingModel.length === 0) {
      this.paymentTypesOptions.push({
        label: 'Pay in Full - ',
        value: 'P',
        price: result.price,
        priority: 1,
      });
    } else {
      this.buildPaymentOptions(result.pricingModel);
    }
  }

  private buildPaymentOptions(pricingModels: PricingModel[]): void {
    for (const pricingModel of pricingModels) {
      const alreadyExists = this.paymentTypesOptions.findIndex(opt => opt.value === pricingModel.paymentType);
      if (
        pricingModel.paymentType === 'Purchased' ||
        pricingModel.paymentType === 'Owned' ||
        pricingModel.paymentType === 'Existing'
      ) {
        this.paymentTypesOptions.push({
          label: pricingModel.paymentType === 'Owned' ? 'Owned' : 'Pay in Full',
          value: pricingModel.purchaseType,
          price: pricingModel.price,
          priority: 1,
        });
      } else if (['Installment'].includes(pricingModel.paymentType) && alreadyExists === -1) {
        this.paymentTypesOptions.push({
          label: 'Monthly Installments',
          value: pricingModel.paymentType,
          price: '',
          priority: 2,
        });
      } else if (pricingModel.paymentType === 'Rent') {
        this.paymentTypesOptions.push({
          label: 'Monthly Rent',
          value: pricingModel.purchaseType,
          price: pricingModel.price,
          priority: 3,
        });
      }
      this.paymentTypesOptions.sort((a, b) => a.priority - b.priority);

      if (['Installment'].includes(pricingModel.paymentType)) {
        if (!this.paymentTermsOptions[pricingModel.paymentType]) {
          this.paymentTermsOptions[pricingModel.paymentType] = [];
        }
        this.paymentTermsOptions[pricingModel.paymentType].push({
          label: `${pricingModel.paymentTerm} months `,
          value: pricingModel.purchaseType,
          price: pricingModel.price,
        });
      }
    }
  }

  public onPaymentTypeChange() {
    if (
      this.paymentTypesControl.value === 'P' ||
      this.paymentTypesControl.value === 'O' ||
      this.paymentTypesControl.value === 'R' ||
      this.paymentTypesControl.value === 'E'
    ) {
      this.paymentTermControl?.reset();
      this.selectedPurchaseType = this.paymentTypesControl.value;
    } else {
      this.selectedPurchaseType = '';
    }
  }

  public onPaymentTermChange() {
    this.selectedPurchaseType = this.paymentTermControl.value;
  }

  public goToIncludedProduct(id) {
    this.additionalProductClicked.emit(id);
  }

  public async addToCart() {
    const product: UpdateProductRequest = {
      id: this.productId,
      purchaseType: this.selectedPurchaseType,
      quantity: this.productQuantity,
    };
    this.productUpdate.emit(product);
  }
}
