import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { observable } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { AddressService } from '../../services/address.service';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { Address, masks, states } from '@xup-payments/xup-frontend-utils/models';
import { randomComponentId } from '@xup-payments/xup-frontend-utils/utils';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { InvalidApplicationService } from '../../services/invalid-application.service';

@Component({
  selector: 'app-tg-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @ViewChild('focus') public focus: ElementRef;

  @Input()
  public address: Address = new Address();

  @Input() tooltipText = '';

  @Input() isAssisted = false;

  @Input()
  set readonly(value: boolean) {
    if (value) {
      this.fields.forEach(f => f.disable({ emitEvent: false }));
    } else {
      this.fields.forEach(f => f.enable({ emitEvent: false }));
    }
  }

  get readonly(): boolean {
    const readonly: boolean[] = this.fields.map(f => f.disabled);

    return !readonly.find(f => !f);
  }

  public form: FormGroup<IAddressForm>;

  public states = states;

  public id = randomComponentId();

  public masks = masks;

  public address1: MobxFormControl<string>;

  public address2: MobxFormControl<string>;

  public city: MobxFormControl<string>;

  public state: MobxFormControl<string>;

  public zip: MobxFormControl<string>;

  constructor(
    protected addressService: AddressService,
    protected fb: FormBuilder,
    private invalidApplication: InvalidApplicationService) {
    makeObservable(this, {
      address: observable,
    });
  }

  ngOnInit() {
    this.buildForm();
  }

  public getAddress(): Address {
    if (!this.address) this.address = new Address();
    return this.address;
  }

  public buildForm(): FormGroup<IAddressForm> {
    if (this.form) {
      return this.form;
    }

    this.address1 = new MobxFormControl<string>(
      'address1',
      () => this.getAddress().address1,
      (v: string) => (this.getAddress().address1 = v),
      CommonValidator.address1(!this.isAssisted),
    );
    this.address2 = new MobxFormControl<string>(
      'address2',
      () => this.getAddress().address2,
      (v: string) => (this.getAddress().address2 = v),
      CommonValidator.address2,
    );

    this.city = new MobxFormControl<string>(
      'city',
      () => this.getAddress().city,
      (v: string) => (this.getAddress().city = v),
      CommonValidator.city(!this.isAssisted),
    );
    this.state = new MobxFormControl<string>(
      'state',
      () => (this.getAddress().state ? this.getAddress().state : ''),
      (v: string) => (this.getAddress().state = v),
      this.isAssisted ? undefined : CommonValidator.state,
    );
    this.zip = new MobxFormControl<string>(
      'zip',
      () => this.getAddress().zip,
      (v: string) => (this.getAddress().zip = v),
      CommonValidator.zip(!this.isAssisted),
    );

    this.form = this.fb.group({
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
    });

    this.invalidApplication.setForm(this.form);

    return this.form;
  }

  public validate() {
    // commenting for now because the address service doesn't exist
    // // AK this becomes an observable proxy and shouldTryValidate isn't available
    // // need to figure out how to properly hydrate the form fields/inputs after a come back
    // if (!this.address.shouldTryValidation || !this.address.shouldTryValidation()) {
    //   return;
    // }
    // this.addressService.validate(this.address).subscribe(response => {
    //   this.address.address1 = response.address1;
    //   this.address.address2 = response.address2;
    //   this.address.city = response.city;
    //   this.address.state = response.state;
    //   this.address.zip = response.zip;
    // });
  }

  get fields() {
    if (!this.address1) {
      return [];
    }
    return [this.address1, this.address2, this.city, this.state, this.zip];
  }

  get stateName(): string {
    if (!this.state?.value) return null;
    return this.states.find(st => st.value === this.state.value).name;
  }
}

export interface IAddressForm {
  address1: FormControl<string>;
  address2: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zip: FormControl<string>;
}
