import { PlatformLocation } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';

import { ComponentLoader } from 'ngx-bootstrap/component-loader';
import { BsModalService, ModalContainerComponent } from 'ngx-bootstrap/modal';
import { constants } from '@xup-payments/xup-frontend-utils/constants';
import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 *
 * @param platformLocation
 */
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

/**
 *
 * @param event
 * @param form
 */
export function onDateFieldKeyChange(event, form) {
  const controlName = event.target.getAttribute('formControlName');
  if (isFinite(event.key)) {
    form.controls[controlName].markAsDirty();
  }
  form.controls[controlName].updateValueAndValidity();
}

/**
 *
 * @param event
 * @param form
 */
export function onDateFieldKeyChangePatchValue(event, form: UntypedFormGroup) {
  // If a printable character is entered the key length should be 1
  if (event.key.length === 1 || event.key === 'Enter') {
    const controlName = event.target.getAttribute('formControlName');
    let date = event.target.value;
    date = date.replaceAll('_', '');
    date = date.replaceAll('/', '');

    if (isFinite(date)) {
      if (
        form.controls[controlName].value === undefined ||
        form.controls[controlName].value === null ||
        form.controls[controlName].value === ''
      ) {
        const monthIndex = parseInt(date.slice(0, 2), 10) - 1;
        const day = parseInt(date.slice(2, 4), 10);
  
        if (date.length === 8 && monthIndex <= 11 && day <= 31) {
          const patch = new Date(date.slice(4, 8), monthIndex, day);
          form.controls[controlName].patchValue(patch);
        }
      }
      form.controls[controlName].markAsDirty();
      form.controls[controlName].markAsTouched();
    }
    form.controls[controlName].updateValueAndValidity({ emitEvent: false });
  }
}

/**
 *
 */
export function allowScrollInBody() {
  const element = document.querySelector('.modal-open');
  if (element) element.classList.remove('modal-open');
}

// BASE64 Encode and Decode with UTF-8 Characters support

/**
 *
 * @param str
 */
export function encodeBase64(str: string) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
}

/**
 *
 * @param base64
 */
export function decodeBase64(base64: string) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(base64), c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''),
  );
}

/**
 *
 * @param item
 */
export function isNumber(item) {
  return typeof item === 'number';
}

/**
 *
 * @param objectsList
 * @param field
 * @param direction
 */
export function sortByNumber(objectsList, field, direction) {
  return objectsList?.sort((o1, o2) =>
    direction === constants.ASC ? (o1[field] < o2[field] ? -1 : 1) : o1[field] < o2[field] ? 1 : -1,
  );
}

/**
 *
 * @param objectList
 * @param sortName
 * @returns Object[]
 */

/**
 *
 * @param objectList
 * @param sortName
 */
export function sortByPropertyName(objectList: any[], sortName: string) {
  return objectList.sort((o1, o2) => {
    if (o1[sortName] < o2[sortName]) {
      return -1;
    }
    if (o1[sortName] > o2[sortName]) {
      return 1;
    }
    return 0;
  });
}

/**
 *
 * @param modalService
 */
export function removeModalIfPresent(modalService: BsModalService) {
  try {
    // Casting modalService as any in order to access private loaders property
    (<any>modalService).loaders.forEach((loader: ComponentLoader<ModalContainerComponent>) => loader.instance.hide());
  } catch (error) {
    // Ignore error when it is coming from page refresh, due to nonexistent modal to be removed
    const REFRESH_ERROR = "Cannot read property 'clear' of undefined";
    if (error.message !== REFRESH_ERROR) throw error;
  } finally {
    allowScrollInBody();
  }
}

/**
 *
 * @param e
 */
export function empty(e) {
  switch (e) {
    case '':
    case 0:
    case '0':
    case null:
    case false:
    case typeof e === 'undefined':
      return true;
    default:
      return false;
  }
}

/**
 *
 * @param array
 */
export function clone(array) {
  return array ? JSON.parse(JSON.stringify(array)) : null;
}

/**
 *
 * @param url
 * @param keyName
 */
export function getQueryParamFromMalformedURL(url: string, keyName: string) {
  const results = new RegExp('[\\?&]' + keyName + '=([^&#]*)').exec(decodeURIComponent(url));
  if (!results) {
    return null;
  }
  const urlCodec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
  return urlCodec.decodeValue(results[1]) || null;
}
