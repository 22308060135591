import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { computed, observable } from 'mobx-angular';
import { UntypedFormGroup } from '@angular/forms';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { LoadingService } from '@eventhorizon/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { makeObservable, reaction } from 'mobx';
import { NetworkMonitorService } from '@eventhorizon/services/network-monitor.service';
import { ApplicationSlideText } from '@xup-payments/xup-frontend-utils/models';
import { hasFormAnyValueSet } from '@xup-payments/xup-frontend-utils/utils';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss'],
})
export class ApplicationPageComponent implements OnDestroy, OnInit, AfterViewInit {
  @ContentChild('innerSlide')
  public innerSlide: CarouselSlide;

  @Output()
  public prev = new EventEmitter<Number>();

  @Output()
  public next = new EventEmitter<void>();

  @Output()
  public loading = false;

  @Input()
  public validate = true;

  @Input()
  public width = 'col-md-6';

  @Input()
  public disableNextLoading = false;

  @Input()
  public index: number;

  @Input()
  public subIndex: number;

  @Input()
  public text: ApplicationSlideText;

  @Input()
  public headlineOverride: string;

  @Input()
  public smallHeadlineOverride: string;

  @Input()
  public showSaveAndContinue = true;

  @Input()
  public showCart = false;

  @Input()
  public showSkipSearch = false;

  @Input() applicationInfo = false;

  @Input() orderInfo = false;

  @Input()
  public showHeadline = true;

  public hasSubmitError = false;

  @Input()
  public showCheckoutFooter = false;

  @Input()
  public showProgressWizard = true;

  @Input()
  public tooltipTitleText = '';

  private notifier = new Subject();

  private destroyed: boolean = false;

  public continueUrl: string;

  public continueText: string;

  constructor(
    protected cd: ChangeDetectorRef,
    protected appStore: ApplicationStore,
    protected cartStore: CartStore,
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    public network: NetworkMonitorService,
    protected bsModalService: BsModalService,
  ) {
    makeObservable(this, {
      showHeadline: observable,
      hasSubmitError: observable,
      showSkipSearch: observable,
      headlineOverride: observable,
      smallHeadlineOverride: observable,
      showSaveAndContinue: observable,
      headline: computed,
      smallHeadline: computed,
    });
  }

  public ngOnInit() {
    this.continueUrl = this.activatedRoute.snapshot.queryParams.continue;
    this.continueText = this.activatedRoute.snapshot.queryParams.continueText;
    reaction(
      () => this.appStore.prefilled,
      value => {
        if (value) {
          if (this.innerSlide && this.innerSlide.form && hasFormAnyValueSet(this.innerSlide.form)) {
            this.innerSlide.form.markAllAsTouched();
          }
        }
      },
    );
  }

  ngAfterViewInit() {
    if (this.innerSlide && this.innerSlide.form) {
      if (!this.innerSlide.formElem) {
        console.warn('Component has a form without a formElem');
        return;
      }
      const keys = fromEvent(this.innerSlide.formElem.nativeElement, 'keyup');
      keys
        .pipe(takeUntil(this.notifier)) // Avoid memory leaks due to unfinished subscriptions
        .subscribe(async (key: KeyboardEvent) => {
          if (key.key === 'Enter' && this.innerSlide.form && this.innerSlide.form.valid) {
            this.onNext().then(() => {});
            return false;
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed = true;
  }

  public onOpen() {
    this.loadingService.isLoading.subscribe(loading => {
      this.loading = loading;
      this.onDetectChanges();
    });
    this.onDetectChanges();
    this.innerSlide.onOpen();
    this.loading = false;
    this.onDetectChanges();
  }

  /**
   * Check if the view hasn't been destroyed and detect changes.
   */
  private onDetectChanges(): void {
    if (!this.destroyed) {
      this.cd.detectChanges();
    }
  }

  public onSubmit(): Boolean {
    return this.innerSlide.onSubmit();
  }

  public async onPrev() {
    if (!(await this.innerSlide.preOnPrev())) {
      return;
    }
    this.prev.emit(1);
  }

  public async onNext() {
    if (this.isNextDisabled()) {
      if (this.innerSlide?.form) this.innerSlide.form.markAllAsTouched();
      return;
    }
    this.loading = true;
    if (!(await this.innerSlide.preOnNext())) {
      this.loading = false;
      return;
    }
    if (!this.validate) {
      this.next.emit();
    } else if (this.onSubmit()) {
      this.next.emit();
    } else {
      this.loading = false;
    }
  }

  public onSecondaryAction() {
    this.innerSlide.onSecondaryAction();
  }

  get headline(): string {
    return this.headlineOverride ? this.headlineOverride : this.text.headline;
  }

  get smallHeadline(): string {
    return this.smallHeadlineOverride ? this.smallHeadlineOverride : this.text.smallHeadline;
  }

  public hasHeadline(): boolean {
    return (this.text && !!this.text.headline) || !!this.headlineOverride;
  }

  public hasBackButton(): boolean {
    return this.text && !!this.text.backText;
  }

  public hasNextButton(): boolean {
    return this.text && !!this.text.nextText;
  }

  public hasFootNote(): boolean {
    return this.text && !!this.text.footNote;
  }

  public isNextDisabled(): boolean {
    if (this.isLoading()) {
      return true;
    }

    const innerForm: UntypedFormGroup = this.innerSlide.form;
    if (!innerForm) {
      return this.innerSlide.isNextDisabled();
    }
    return this.validate && (innerForm.invalid || this.innerSlide.isNextDisabled());
  }

  public getBackButtonTooltipText() {
    return this.text && this.text.tooltipBackButton ? this.text.tooltipBackButton : '';
  }

  public hasBackTootltip() {
    return this.innerSlide?.hasBackTootltip();
  }

  public isBackDisabled(): boolean {
    return this.innerSlide.isBackDisabled();
  }

  public applicationId() {
    return this.appStore.id;
  }

  public orderId() {
    return this.cartStore.orderId || this.appStore.orderId;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public isNotPrefilled(): boolean {
    return !this.appStore.prefilled;
  }
}
