<div class="d-flex flex-column gap-2">
  <app-callout-notification [notification]="'Any ecommerce websites where transactions occur should be added as an additional location. This does not include payment of invoices digitally.'"></app-callout-notification>
  <div class="locations-summary">
    <div class="summary-header">
      <div class="summary-header-text">
        <strong class="mb-2">All business locations</strong>
        <span>You may add up to 10 locations for your business.</span>
      </div>
      <button class="add-btn" [disabled]="isAddDisabled" (click)="handleAdd()">
        <span>Add New Location</span>
        <i class="fa fa-plus"></i>
      </button>
    </div>  
    <div class="summary-start" *ngIf="isInitialState; else list">
      <strong class="add-primary-label">Add Primary Location</strong>
      <button class="start-btn" (click)="handleStartClick()">
        <span>Let's Start</span>
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #list>
  <lib-locations-list 
    [locations]="locations"
    [showLocationIcon]="true"
    [showPrimaryIndicator]="true"
    [showStatusLabel]="true"
    [showMcc]="true"
    [showEditButton]="true"
    (edit)="handleEdit($event)"
    [showDeleteButton]="true"
    (delete)="handleDelete($event)"
    [showCartHeader]="true"
    [showCartPaymentColumn]="true"
  ></lib-locations-list>
</ng-template>

