<div class="pb-3 progress-container">
  <ol class="progress-indicator">
    <ng-container *ngFor="let section of progressConfig; let index = index">
      <li>
        <div
          class="mb-2 progress-indicator-section-container"
          [ngClass]="{
            'progress-indicator-section-container-active': isSectionInProgress(section),
            'progress-indicator-section-container-expanded': section.expanded
          }"
        >
          <div
            class="progress-indicator-section"
            [ngClass]="{ 'progress-indicator-section-bottom-radius': !section.expanded }"
            (click)="expand(index)"
          >
            <span
              class="progress-indicator-title"
              [ngClass]="{
                'progress-indicator-is-active': isSectionInProgress(section),
                'progress-indicator-is-complete': isSectionCompleted(section)
              }"
            >
              {{ section.name }}
            </span>
            <div class="progress-indicator-right">
              <div
                class="progress-indicator-chevron"
                [ngClass]="{ 'progress-indicator-chevron-expanded': section.expanded }"
              >
                <i class="fas fa-chevron-right"></i>
              </div>
              <div
                class="progress-indicator-circle"
                [ngClass]="{
                  'progress-indicator-circle-active': isSectionInProgress(section),
                  'progress-indicator-circle-complete': isSectionCompleted(section)
                }"
              >
                <div class="semi-circle"></div>
              </div>
            </div>
          </div>
          <div *ngIf="section.expanded" class="progress-indicator-steps">
            <ol>
              <ng-container *ngFor="let step of section.states; let subIndex = index">
                <li class="progress-sub-li" *ngIf="!step.hide">
                  <div
                    *ngIf="subIndex > 0"
                    class="progress-sub-line"
                    [ngClass]="{
                      'progress-sub-line-complete': step.completed || step.inProgress
                    }"
                  ></div>
                  <div class="d-flex align-items-center">
                    <div
                      class="progress-indicator-circle"
                      [ngClass]="{
                        'progress-indicator-circle-active': step.inProgress,
                        'progress-indicator-circle-complete': step.completed
                      }"
                    ></div>
                    <div
                      class="progress-sub-title"
                      [ngClass]="{
                        'progress-sub-title-active': step.state === currentStepSelected,
                        'progress-sub-title-clickable': step.completed || step.inProgress
                      }"
                      (click)="stepClick(step)"
                    >
                      {{ step.state }}
                    </div>
                  </div>
                </li>
              </ng-container>
            </ol>
          </div>
        </div>
      </li>
    </ng-container>
  </ol>
</div>
