import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ApplicationSlideText, messages } from '@eventhorizon/data/messages.data';

@Component({
  selector: 'app-additional-products-screen',
  templateUrl: './additional-products-screen.component.html',
  styleUrls: ['./additional-products-screen.component.scss'],
})
export class AdditionalProductsScreenComponent extends BasePageDriverComponent {
  public pageMessage: ApplicationSlideText = messages.additionalProductsSlide;

  constructor(
    protected rs: RoutingPathService,
    protected router: Router,
    protected route: ActivatedRoute,
    public store: ApplicationStore,
  ) {
    super(rs, router, route);
  }

  public setText(pinpadError: boolean) {
    this.pageMessage = pinpadError ? messages.pinpadErrorSlide : messages.additionalProductsSlide;
  }
}
