<div [class]="customClass">
  <div class="input-group" [formGroup]="form">
    <div [class]="getContainerClass()">
      <button
        class="input-element input-control input-action"
        id="minus-btn-{{ id }}"
        (click)="addQuantity(-step)"
        type="button"
        [disabled]="decrementDisabled || quantity - 1 < min"
        aria-label="minus-btn"
      >
        <i class="fal fa-minus"></i>
      </button>

      <input
        *ngIf="!mask"
        type="text"
        class="input-element input-quantity"
        size="3"
        autocomplete="off"
        value="quantity"
        [id]="id"
        [class.input-quantity-error]="form?.invalid && form?.touched"
        [formControlName]="controlName"
        [maxlength]="maxLength"
        (input)="onInputChange($event.target.value)"
        [readonly]="incrementDisabled && decrementDisabled"
        [attr.aria-labelledby]="controlName + '-label'"
        [attr.aria-label]="label ?? controlName"
      />
      <input
        *ngIf="mask"
        type="text"
        class="input-element input-quantity"
        size="3"
        autocomplete="off"
        value="quantity"
        [id]="id"
        [class.input-quantity-error]="form?.invalid && form?.touched"
        [textMask]="mask"
        [formControlName]="controlName"
        [maxlength]="maxLength"
        (input)="onInputChange($event.target.value)"
        [readonly]="incrementDisabled && decrementDisabled"
        [attr.aria-labelledby]="controlName + '-label'"
        [attr.aria-label]="label ?? controlName"
      />
      <button
        class="input-element input-control input-action"
        id="plus-btn-{{ id }}"
        (click)="addQuantity(+step)"
        type="button"
        [disabled]="incrementDisabled"
        aria-label="plus-btn"
      >
        <i class="fal fa-plus"></i>
      </button>
    </div>

    <label class="input-label" [for]="id">{{ label }}</label>
  </div>
</div>
