<form [formGroup]="form" class="mt-3">
  <input [id]="'percentage-valid' + id" type="hidden" formControlName="percentageValid" />
  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
      <input
        [id]="id"
        formControlName="firstName"
        class="form-control"
        type="text"
        autocomplete="given-name"
        [textMask]="masks.personalName"
        #focus
        [attr.aria-labelledby]="id + '-label'"
        title="input-firstName"
      />
      <label [id]="id + '-label'" class="form-label" [for]="id"> First Name </label>

      <app-field-error [control]="firstName"></app-field-error>
    </div>

    <div class="col-sm-12 col-md-6 mb-3 pb-0 position-relative">
      <input
        [id]="id + 1"
        formControlName="lastName"
        class="form-control"
        type="text"
        autocomplete="family-name"
        [textMask]="masks.personalName"
        [attr.aria-labelledby]="id + 1 + '-label'"
        title="input-lastName"
      />
      <label [id]="id + 1 + '-label'" class="form-label" [for]="id + 1"> Last Name </label>

      <app-field-error [control]="lastName"></app-field-error>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
      <input
        [id]="id + 5"
        formControlName="ssn"
        class="form-control"
        [type]="fieldTextType ? 'text' : 'password'"
        autocomplete="new-password"
        [textMask]="masks.ssnAlt"
        [attr.aria-labelledby]="id + 5 + '-label'"
        title="input-ssn"
      />
      <label [id]="id + 5 + '-label'" class="form-label" [for]="id + 5"> SSN </label>
      <i
        class="far show-pass-icon"
        [ngClass]="{
          'fa-eye-slash': !fieldTextType,
          'fa-eye': fieldTextType
        }"
        (click)="toggleFieldTextType()"
      ></i>

      <app-field-error [control]="ssn"></app-field-error>
    </div>

    <div class="col-sm-12 col-md-6 mb-3 pb-0 position-relative">
      <span class="calendar-icon">
        <i class="fal fa-calendar"></i>
      </span>
      <input
        [id]="id + 4"
        formControlName="dob"
        class="form-control calendar-input"
        type="text"
        autocomplete="bday"
        bsDatepicker
        appDateMask
        [minDate]="minDate"
        [maxDate]="today"
        [bsConfig]="brandDatePicker"
        (keyup)="onDateFieldKeyChangePatchValue($event, form)"
        [attr.aria-labelledby]="id + 4 + '-label'"
        title="input-dob"
      />
      <label [id]="id + 4 + '-label'" class="form-label" [for]="id + 4"> DOB (mm/dd/yyyy) </label>

      <app-field-error [control]="dob"></app-field-error>
    </div>
  </div>

  <div class="row" *ngIf="!isSoleProprietor()">
    <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
      <app-dropdown-field [field]="title" [options]="store.businessTitles" [id]="id + 7" label="Title"></app-dropdown-field>
    </div>
    <div class="col-sm-12 col-md-6 mb-3 pb-0 position-relative" *ngIf="!isTaxExempt()">
      <input
        [id]="id + 8"
        formControlName="ownership"
        class="form-control"
        type="text"
        autocomplete="on"
        [textMask]="masks.percentage"
        [attr.aria-labelledby]="id + 8 + '-label'"
        title="input-ownership"
      />
      <label [id]="id + 8 + '-label'" class="form-label" [for]="id + 8"> % of Ownership </label>

      <app-field-error [control]="ownership"></app-field-error>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
      <input
        [id]="id + 2"
        formControlName="phone"
        class="form-control"
        type="text"
        autocomplete="tel"
        [textMask]="masks.phone"
        [attr.aria-labelledby]="id + 2 + '-label'"
        title="input-phone"
      />
      <label [id]="id + 2 + '-label'" class="form-label" [for]="id + 2"> Personal Phone Number </label>

      <app-field-error [control]="phone"></app-field-error>
    </div>

    <div class="col-sm-12 col-md-6 mb-3 pb-0 position-relative">
      <input
        [id]="id + 3"
        formControlName="email"
        class="form-control"
        type="text"
        maxlength="70"
        autocomplete="email"
        [attr.aria-labelledby]="id + 3 + '-label'"
        title="input-email"
      />
      <label [id]="id + 3 + '-label'" class="form-label" [for]="id + 3"> Personal Email Address </label>

      <app-field-error [control]="email"></app-field-error>
    </div>
  </div>

  <div class="row" *ngIf="isSoleProprietor() || isTaxExempt()">
    <div class="col-sm-12 position-relative my-2">
      <label [id]="id + 6 + '-label'" class="checkbox-label" [for]="id + 6">
        <input
          type="checkbox"
          [id]="id + 6"
          class="form-option-input qa-contact-info-existing-client-customer"
          formControlName="sameAsBusiness"
          (change)="changeSameAsBusiness($event.target.checked)"
          [attr.aria-labelledby]="id + 6 + '-label'"
          title="input-sameAsBusiness"
        />&nbsp; Personal Address is same as Business Address
      </label>
    </div>
  </div>

  <div>
    <app-tg-address
      formGroupName="personalAddress"
      [isAssisted]="assistedOnboarding"
      [address]="applicant?.address"
    ></app-tg-address>
  </div>

  <div class="col-sm-12 p-0 pep-info text-md-left" *ngIf="!hidePoliticallyExposedPersons">
    <div class="row pep-info-text">
      Will any company owners, members of the board of directors, or transactions through your merchant account involve
      dealings with a politically exposed person?
      <app-tg-tooltip
        [tooltipText]="
          'A politically exposed person is an individual with a high-profile political role, or who has been entrusted with a prominent public function.'
        "
      ></app-tg-tooltip>
    </div>
    <label [id]="id + 9 + '-label'" class="pep-info-label">
      <input
      [id]="id + 9"
        class="ms-0"
        type="radio"
        name="hasPoliticalTies"
        [value]="true"
        formControlName="hasPoliticalTies"
        [attr.aria-labelledby]="id + 9 + '-label'"
        title="input-hasPoliticalTies"

         />
      Yes
    </label>
    <label [id]="id + 10 + '-label'" class="pep-info-label">
      <input
        [id]="id + 10"
        type="radio"
        name="hasPoliticalTies"
        [value]="false"
        formControlName="hasPoliticalTies"
        [attr.aria-labelledby]="id + 10 + '-label'"
        title="input-hasPoliticalTiesNo"/>
      No
    </label>
  </div>
  <app-field-error [control]="form?.controls?.hasPoliticalTies"></app-field-error>
  <div class="row mb-2">
    <div class="col-sm-12">
      <app-field-error [control]="percentageValid"></app-field-error>
    </div>
  </div>
</form>
