import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAccount } from '@eventhorizon/models/user-account';
import { Permission, Role } from '@eventhorizon/models/role';
import { UserAccountList } from '@eventhorizon/models/user-model';

@Injectable({ providedIn: 'root' })
export class UserService {
  readonly settingsStorageKey = '$kb$_userSettings';

  constructor(private http: HttpClient, @Inject('env') private environment) {}

  public getUsersByRole(role, params = {}): Observable<UserAccountList> {
    const options = {
      params,
    };
    return this.http.get<UserAccountList>(`${this.environment.api.url}/users/users?roles=${role}`, options);
  }

  public getUsersByMerchant(merchantId, params = {}): Observable<UserAccountList> {
    const options = {
      params,
    };
    return this.http.get<UserAccountList>(`${this.environment.api.url}/users/users/merchants/${merchantId}`, options);
  }

  public getRoles(filterType): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.environment.api.url}/users/roles?filterType=${filterType}`);
  }

  public getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${this.environment.api.url}/users/users/permissions`);
  }

  public getUser(id: string): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${this.environment.api.url}/users/users/${id}`);
  }

  public addUser(newUser: Partial<UserAccount>): Observable<UserAccount> {
    return this.http.post<UserAccount>(`${this.environment.api.url}/users/users`, newUser);
  }

  public updateUser(id: string, userInfo: Partial<UserAccount>): Observable<UserAccount> {
    return this.http.put<UserAccount>(`${this.environment.api.url}/users/users/${id}`, userInfo);
  }

  public deleteUser(id: string): Observable<UserAccount> {
    return this.http.delete<UserAccount>(`${this.environment.api.url}/users/users/${id}`);
  }

  public deleteMerchantUser(merchantId: string, id: string): Observable<UserAccount> {
    return this.http.delete<UserAccount>(`${this.environment.api.url}/users/users/merchants/${merchantId}`, {
      body: {
        userId: id,
      },
    });
  }

  getSetting(name: string): string {
    let setting;
    let settings = localStorage.getItem(this.settingsStorageKey);
    if (settings) {
      settings = JSON.parse(settings);
      setting = settings[name];
    }
    return setting;
  }

  setSetting(name: string, value) {
    let settings: any = localStorage.getItem(this.settingsStorageKey) || {};
    if (typeof settings === 'string') settings = JSON.parse(settings);
    settings[name] = value;
    localStorage.setItem(this.settingsStorageKey, JSON.stringify(settings));
  }

  removeSetting(name: string) {
    let settings: any = localStorage.getItem(this.settingsStorageKey);
    if (settings) {
      settings = JSON.parse(settings);
      delete settings[name];
      localStorage.setItem(this.settingsStorageKey, JSON.stringify(settings));
    }
  }

  clearSettings() {
    localStorage.removeItem(this.settingsStorageKey);
  }
}
