<div id="mainContent" tabindex="0" class="main-container container ps-4 pe-4">
  <div *ngIf="!loading">
    <div class="col-md-12">
      <div class="offramp-heading-container">
        <h3 class="offramp-subheading text-center">We look forward to helping you!</h3>
      </div>
    </div>
    <div class="col-md-12 offramp-content">
      <div class="info-text offramp-inner text-center">
        Your business is unique, so a standard payment solution may not meet your needs. In order to provide you with
        the best possible experience, our team would like to learn more about your payment processing needs.
      </div>
      <div class="info-text offramp-inner text-center">
        Feel free to call our {{ contentService?.bankInfoConfig?.name }} Merchant Services team directly at
        <strong class="offramp_bold_number"
          >&nbsp;<a href="tel:{{ contentService?.bankInfoConfig?.phoneNumber }}">{{
            contentService?.bankInfoConfig?.phoneNumber
          }}</a
          >. </strong
        >&nbsp;
        <span *ngIf="contentService?.bankInfoConfig?.weekdayPhoneHours && contentService?.bankInfoConfig?.chatHours">
          Our team is available {{ contentService?.bankInfoConfig?.weekdayPhoneHours }} for calls and
          {{ contentService?.bankInfoConfig?.chatHours }} via chat.
        </span>
      </div>
      <div class="offramp_callback text-center">
        You can also&nbsp;<strong class="offramp_bold">Request a call back</strong>&nbsp;by entering your contact
        information and one of our merchant experts will reach out to you.
      </div>
      <div class="info-text offramp-inner">
        <form [formGroup]="form" class="col-md-10">
          <div class="row">
            <div class="col-sm-12 col-md-6 mb-3 position-relative">
              <input
                [id]="id"
                formControlName="name"
                class="form-control qa-contact-info-first-name"
                type="text"
                autocomplete="given-name"
                [textMask]="masks.personalName"
                #focus
              />
              <label class="form-label" [for]="id">First Name </label>
              <app-field-error [control]="form.controls.name"></app-field-error>
            </div>

            <div class="col-sm-12 col-md-6 mb-3 position-relative">
              <input
                [id]="id + 1"
                formControlName="lastName"
                class="form-control qa-contact-info-last-name"
                type="text"
                autocomplete="family-name"
                [textMask]="masks.personalName"
              />
              <label class="form-label" [for]="id + 1">Last Name </label>
              <app-field-error [control]="form.controls.lastName"></app-field-error>
            </div>
          </div>
          <div class="col-sm-12 mb-3 position-relative">
            <input
              [id]="id + 2"
              formControlName="companyName"
              class="form-control qa-contact-info-company-name"
              type="text"
              autocomplete="on"
              [textMask]="masks.companyName"
            />
            <label class="form-label" [for]="id + 2">Company Name</label>
            <app-field-error [control]="form.controls.companyName"></app-field-error>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 mb-3 position-relative">
              <input
                [id]="id + 3"
                formControlName="email"
                class="form-control qa-contact-info-email"
                type="text"
                maxlength="70"
                autocomplete="email"
              />
              <label class="form-label" [for]="id + 3">Email</label>
              <app-field-error [control]="form.controls.email"></app-field-error>
            </div>
            <div class="col-sm-12 col-md-6 mb-3 position-relative">
              <input
                [id]="id + 4"
                formControlName="phoneNumber"
                class="form-control qa-contact-info-phone-number"
                type="text"
                autocomplete="tel"
                [textMask]="masks.phone"
              />
              <label class="form-label" [for]="id + 4">Phone</label>
              <app-field-error [control]="form.controls.phoneNumber"></app-field-error>
            </div>
          </div>
        </form>
      </div>
      <div class="row text-center buttons">
        <!-- <div class="col-md-6 col-sm-12 pe-0 ps-0">
          <button type="button" class="offramp-keybank-button primary-button btn-primary me-0" (click)="goBack()">
            Return to {{ bankInfoConfig?.name }}
          </button>
        </div> -->
        <div class="col-sm-12 ps-0">
          <button
            [disabled]="!form.valid"
            type="button"
            class="primary-button app-slide-button offramp-button btn-primary"
            (click)="submit()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="col-12 text-center">
    <div class="pb-4 pt-4">Submitting call back request</div>
    <div class="lds-dual-ring"></div>
  </div>
</div>
