import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DocumentsService } from '@eventhorizon/services/documents.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { downloadFile } from '@eventhorizon/util/file-downloader';
import {
  AlertCountsResponse,
  AlertStatusEnum,
  AlertSummary,
  AlertType,
  AlertTypeEnum,
  BankAccountVerificationDetail,
  BridgerAlertDetail, BridgerAlertDetailRequest,
  BusinessToPrincipalLinkAlertDetail,
  BusinessVerificationAlertDetail,
  MatchDetail,
  PrincipalVerificationAlertDetail,
  Risk,
  RiskAlertSummaryCSVRequest,
  RiskAlertSummaryRequest, RiskPassFailResponse,
  UserActionObjectType,
  WebsiteVerificationAlertDetail,
} from '@xup-payments/xup-frontend-utils/models';

@Injectable({
  providedIn: 'root',
})
export class RiskService {
  private objectTypes = new Map([
    [UserActionObjectType.BUSINESS_VERIFICATION_RISK_ALERT, AlertTypeEnum.BusinessVerification],
    [UserActionObjectType.PRINCIPAL_VERIFICATION_RISK_ALERT, AlertTypeEnum.PrincipalsVerification],
    [UserActionObjectType.BANK_VERIFICATION_RISK_ALERT, AlertTypeEnum.BankAccountVerification],
    [UserActionObjectType.BUSINESS_TO_PRINCIPAL_LINK_RISK_ALERT, AlertTypeEnum.BusinessToPrincipalsLinkResults],
    [UserActionObjectType.BUSINESS_OFAC_RISK_ALERT, AlertTypeEnum.BusinessOFAC],
    [UserActionObjectType.PRINCIPAL_OFAC_RISK_ALERT, AlertTypeEnum.PrincipalOFAC],
    [UserActionObjectType.MATCH_RISK_ALERT, AlertTypeEnum.MATCHCheck],
  ]);

  constructor(private http: HttpClient, private documentsService: DocumentsService,
    @Inject('env') private environment) {
  }

  public getAlertSummary(merchantId: string, params: RiskAlertSummaryRequest): Observable<AlertSummary> {
    const httpParams = new HttpParams({ fromObject: { ...params } });
    return this.http.get<AlertSummary>(`${this.environment.api.url}/risk/alert-summary/${merchantId}`, {
      params: httpParams,
    });
  }

  public async getAlertSummaryCsv(merchantId: string, httpParams: RiskAlertSummaryCSVRequest): Promise<void> {
    const params = new HttpParams({ fromObject: { ...httpParams } });
    const url = `${this.environment.api.url}/risk/alert-summary/csv/${merchantId}`;
    const fileName = 'Risk Alert Summary';
    await downloadFile(this.http, params, url, fileName);
  }

  public getAlertCount(merchantId: string, alertStatus: AlertStatusEnum): Observable<AlertCountsResponse> {
    const httpParams = new HttpParams({ fromObject: { status: alertStatus } });
    return this.http.get<AlertCountsResponse>(`${this.environment.api.url}/risk/alert-count/${merchantId}`, {
      params: httpParams,
    });
  }

  public getAlertTypes(): Observable<AlertType[]> {
    return this.http.get<AlertType[]>(`${this.environment.api.url}/risk/alert-summary/alert-type`);
  }

  public matchCheckAlertDetail(alertId: string): Observable<MatchDetail> {
    return this.http.get<MatchDetail>(`${this.environment.api.url}/risk/match-check-alert-detail/${alertId}`);
  }

  public bankAccountVerificationAlertDetail(alertId: string): Observable<BankAccountVerificationDetail> {
    return this.http.get<BankAccountVerificationDetail>(
      `${this.environment.api.url}/risk/bank-account-verification-alert-detail/${alertId}`,
    );
  }

  public businessVerificationAlertDetail(alertId: string): Observable<BusinessVerificationAlertDetail> {
    return this.http.get<BusinessVerificationAlertDetail>(
      `${this.environment.api.url}/risk/business-verification-alert-detail/${alertId}`,
    );
  }

  public principalVerificationAlertDetail(alertId: string): Observable<PrincipalVerificationAlertDetail> {
    return this.http.get<PrincipalVerificationAlertDetail>(`
    ${this.environment.api.url}/risk/principal-verification-alert-detail/${alertId}`);
  }

  public businessToPrincipalLinkAlertDetail(alertId: string): Observable<BusinessToPrincipalLinkAlertDetail> {
    return this.http.get<BusinessToPrincipalLinkAlertDetail>(`
    ${this.environment.api.url}/risk/business-to-principal-link-alert-detail/${alertId}`);
  }

  public websiteVerificationAlertDetail(alertId: string): Observable<WebsiteVerificationAlertDetail> {
    return this.http.get<WebsiteVerificationAlertDetail>(`
    ${this.environment.api.url}/risk/website-verification-alert-detail/${alertId}`);
  }

  public override(alertId: string, overrideReason: string): Observable<Risk> {
    return this.http.post<Risk>(`${this.environment.api.url}/risk/alert-override/${alertId}`, { overrideReason });
  }

  public terminationInquiry(locationId: string): Observable<RiskPassFailResponse> {
    return this.http.post<RiskPassFailResponse>(
      `${this.environment.api.url}/risk/location/${locationId}/terminationInquiry`,
      {},
    );
  }

  public instantIdCheck(applicationId: string): Observable<RiskPassFailResponse> {
    return this.http.post<RiskPassFailResponse>(
      `${this.environment.api.url}/risk/applications/${applicationId}/instantIdCheck`,
      {},
    );
  }

  public giactBankValidation(businessLocationId: string): Observable<RiskPassFailResponse> {
    return this.http.post<RiskPassFailResponse>(
      `${this.environment.api.url}/risk/location/${businessLocationId}/verifyBankAccount`,
      {},
    );
  }

  public bridgerAlertDetail(alertId: string, params: BridgerAlertDetailRequest): Observable<BridgerAlertDetail> {
    const httpParams = new HttpParams({ fromObject: { ...params } });
    return this.http.get<BridgerAlertDetail>(`${this.environment.api.url}/risk/bridger-alert-detail/${alertId}`, {
      params: httpParams,
    });
  }

  public downloadVoidCheck(businessLocationDocumentId: number): Observable<Blob> {
    return this.documentsService.downloadDocument(businessLocationDocumentId).pipe(
      tap((file: Blob) => {
        const link = document.createElement('a');
        link.setAttribute('style', 'display: none');
        link.href = URL.createObjectURL(file);
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }),
    );
  }

  public userActionObjectTypeToRiskAlertType(objectType: UserActionObjectType): AlertTypeEnum | string {
    const alertType = this.objectTypes.get(objectType);
    return alertType || '';
  }
}
