import { ChangeDetectorRef, Directive } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { touchAndValidate } from '@xup-payments/xup-frontend-utils/utils';
import { BankerCarouselSlide } from './banker-carousel-slide';
import { FormTypes } from './carousel-slide';
import { UntypedFormGroup } from '@angular/forms';

@Directive()
export class FormCarouselSlide<FormType extends FormTypes = UntypedFormGroup> extends BankerCarouselSlide<FormType> {
  constructor(protected bsModalService: BsModalService, protected cd: ChangeDetectorRef) {
    super(bsModalService, cd);
  }

  public validate() {
    if (this.form) {
      touchAndValidate(this.form);
      this.form.updateValueAndValidity();
      this.cd.detectChanges();
    }
  }

  public onSubmit(): boolean {
    this.validate();
    return this.form.valid;
  }

  public isValid(): Boolean {
    return this.form.valid;
  }

  public onOpen() {
    super.onOpen();
    this.cd.detectChanges();

    this.initialFocus();
  }

  public initialFocus() {
    try {
      document.querySelector('input').focus();
    } catch {}
    this.cd.detectChanges();
  }

  /**
   * Return true if successful.
   * Return false if not
   */
  public async save(): Promise<boolean> {
    return true;
  }
}
