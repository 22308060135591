<app-container *mobxAutorun>
  <app-application-page #page
    [text]="text"
    [stepTrackerConfig]="stepTrackerConfig"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
    (cartsUpdated)="onCartsUpdated($event)"
  >
    <app-tg-location-addons #innerSlide (openCart)="openCart()" [cart]="cart" (prev)="prev()" (next)="next()"></app-tg-location-addons>
  </app-application-page>
</app-container>
