import { ProductDetailComponent } from '@app/pages/product-detail/product-detail.component';
import { ApplicationGuard } from './guards/application.guard';
import { RouterModule, Routes } from '@angular/router';
import { ContactInfoScreenComponent } from '@app/pages/contact-info-screen/contact-info-screen.component';
import { PaymentInfoScreenComponent } from '@app/pages/payment-info-screen/payment-info-screen.component';
import { SoftwareScreenComponent } from '@app/pages/software-screen/software-screen.component';
import { CheckoutScreenComponent } from '@app/pages/checkout-screen/checkout-screen.component';
import { ShippingScreenComponent } from '@app/pages/shipping-screen/shipping-screen.component';
import {
  OrderConfirmationScreenComponent,
} from '@app/pages/order-confirmation-screen/order-confirmation-screen.component';
import { OfframpComponent } from '@eventhorizon/components/off-ramp/off-ramp.component';
import { BusinessTaxesScreenComponent } from '@app/pages/business-taxes-screen/business-taxes-screen.component';
import { BusinessInfoScreenComponent } from '@app/pages/business-info-screen/business-info-screen.component';
import {
  BusinessAddressesScreenComponent,
} from '@app/pages/business-addresses-screen/business-addresses-screen.component';
import { OwnersScreenComponent } from '@app/pages/owners-screen/owners-screen.component';
import { BankScreenComponent } from '@app/pages/bank-screen/bank-screen.component';
import { IdVerificationScreenComponent } from '@app/pages/id-verification-screen/id-verification-screen.component';
import { TermsScreenComponent } from '@app/pages/terms-screen/terms-screen.component';
import {
  ApplicationConfirmationScreenComponent,
} from '@app/pages/application-confirmation-screen/application-confirmation-screen.component';
import { ResumeScreenComponent } from '@app/pages/resume-screen/resume-screen.component';
import { ProductsScreenComponent } from '@app/pages/products-screen/products-screen.component';
import { OrderStatusScreenComponent } from '@app/pages/order-status-screen/order-status-screen.component';
import { Error404Component } from '@app/pages/error404/error404.component';
import { ErrorServerDownComponent } from '@app/pages/error-server-down/error-server-down.component';
import {
  ReferredMerchantScreenComponent,
} from '@app/pages/referred-merchant-screen/referred-merchant-screen.component';
import { IndustryScreenComponent } from '@app/pages/industry-screen/industry-screen.component';
import { BusinessTypeScreenComponent } from '@app/pages/business-type-screen/business-type-screen.component';
import { PaymentTypesScreenComponent } from '@app/pages/payment-types-screen/payment-types-screen.component';
import { LocationsScreenComponent } from '@app/pages/locations-screen/locations-screen.component';
import {
  AdditionalProductsScreenComponent,
} from '@app/pages/additional-products-screen/additional-products-screen.component';
import {
  IndustrySubcategoryScreenComponent,
} from '@app/pages/industry-subcategory-screen/industry-subcategory-screen.component';
import { PrefillScreenComponent } from '@app/pages/prefill-screen/prefill-screen.component';
import {
  RiskVerificationScreenComponent,
} from '@app/pages/risk-verification-screen/risk-verification-screen.component';
import { VerificationScreenComponent } from '@app/pages/verification-screen/verification-screen.component';
import { ContentPageComponent } from '@app/pages/content-page/content-page.component';
import { ROUTES } from '@xup-payments/xup-frontend-utils/constants';
import { environment } from 'src/environments/environment';
import { CallbackComponent } from '@app/pages/call-back/call-back.component';
import { ContactInfoResolver } from './resolvers/contact-info.resolver';

export const appRoutes: Routes = [
  { path: '', redirectTo: `/${ROUTES.INTRO_PAGE}`, pathMatch: 'full' },
  {
    path: ROUTES.CONTACT_INFO,
    component: ContactInfoScreenComponent,
    canActivate: [ApplicationGuard],
    resolve: { partnerInfo: ContactInfoResolver },
  },
  { path: ROUTES.BUSINESS_TYPE, component: BusinessTypeScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.INDUSTRY, component: IndustryScreenComponent },
  { path: ROUTES.INDUSTRY_SUBCATEGORY, component: IndustrySubcategoryScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.PAYMENT_TYPES, component: PaymentTypesScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.PAYMENT_INFO, component: PaymentInfoScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.LOCATIONS, component: LocationsScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.SOFTWARE, component: SoftwareScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.ADDITIONAL_PRODUCTS, component: AdditionalProductsScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.CHECKOUT, component: CheckoutScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.SHIPPING, component: ShippingScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.ORDER_CONFIRMATION, component: OrderConfirmationScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.BUSINESS_TAXES, component: BusinessTaxesScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.BUSINESS_INFO, component: BusinessInfoScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.BUSINESS_ADDRESSES, component: BusinessAddressesScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.OWNERS, component: OwnersScreenComponent },
  {
    path: ROUTES.BANK,
    component: BankScreenComponent,
    canActivate: [ApplicationGuard],
    resolve: { partnerInfo: ContactInfoResolver },
  },
  {
    path: ROUTES.ID_VERIFICATION,
    component: IdVerificationScreenComponent,
    canActivate: [ApplicationGuard],
  },
  { path: ROUTES.VERIFICATION, component: VerificationScreenComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.RISK_VERIFICATION, component: RiskVerificationScreenComponent },
  { path: ROUTES.TERMS, component: TermsScreenComponent, canActivate: [ApplicationGuard] },
  {
    path: ROUTES.APPLICATION_CONFIRMATION,
    component: ApplicationConfirmationScreenComponent,
    canActivate: [],
  },
  { path: ROUTES.RESUME, component: ResumeScreenComponent },
  { path: ROUTES.PRODUCTS, component: ProductsScreenComponent, canActivate: [ApplicationGuard] },
  { path: `${ROUTES.PRODUCTS}/:id`, component: ProductDetailComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.ORDER_STATUS, component: OrderStatusScreenComponent },
  { path: ROUTES.OFFRAMP, component: OfframpComponent, canActivate: [ApplicationGuard] },
  { path: ROUTES.CALLBACK, component: CallbackComponent, canActivate: [ApplicationGuard] },
  { path: `${ROUTES.PARTNER}/:slug`, component: ReferredMerchantScreenComponent },
  { path: ROUTES.NOT_FOUND, component: Error404Component },
  { path: ROUTES.ERROR, component: ErrorServerDownComponent },
  { path: ROUTES.PREFILL, component: PrefillScreenComponent },
  { path: ROUTES.PARTNER_PREFILL, component: PrefillScreenComponent },
  { path: '**', component: ContentPageComponent },
];
export const routing: any = RouterModule.forRoot(appRoutes, {
  useHash: false,
  enableTracing: !environment.production,
});
