<form [formGroup]="form">
  <input id="primary-location-selected" type="hidden" formControlName="primarySelected" />
  <div class="row">
    <div class="col-md-12 p-0">
      <span>
        <!-- TODO: Remove hidden once we accept multiple locations -->
        <button
          *ngFor="let l of store.businessLocations; let i = index"
          [ngClass]="{
            'address-tab': true,
            'address-tab-selected': selectedAddressTab === i + 1
          }"
          (click)="setActiveTab(i + 1)"
          [hidden]="selectedAddressTab !== i + 1"
        >
          <span>Location #{{ i + 1 }}</span>
        </button>
      </span>
    </div>
  </div>
  <span>
    <div *ngFor="let l of store.businessLocations; let i = index" [hidden]="selectedAddressTab !== i + 1">
      <!-- TODO: Remove ngIf once we accept multiple locations -->
      <app-tg-business-location
        *ngIf="selectedAddressTab === i + 1"
        #locationAddress
        [formGroupName]="'locationAddress' + i"
        [location]="l"
        [locations]="store.businessLocations"
        [legalAddress]="store.businessAddresses.legalAddress"
        [requirePrimary]="store.businessLocations.length > 1"
        [isAssisted]="isAssisted"
        [displayLocationDBA]="displayLocationDBA"
        (isPrimaryChanged)="primaryChanged()"
      ></app-tg-business-location>
    </div>
  </span>
</form>
