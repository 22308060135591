<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-12 col-md-8 mb-3 p-0 position-relative right-inner-addon">
      <span *ngIf="tooltipText">
        <app-tg-tooltip [tooltipText]="tooltipText"></app-tg-tooltip>
      </span>
      <input
        [id]="id + 1"
        formControlName="address1"
        class="form-control"
        type="text"
        autocomplete="address-line1"
        (blur)="validate()"
        [textMask]="masks.address1"
        #focus
        [attr.aria-labelledby]="id + 1 + '-label'"
        title="input-addres1"
      />
      <label [id]="id + 1 + '-label'" [for]="id + 1" class="form-label"> Street Address </label>
      <app-field-error [control]="address1"></app-field-error>
    </div>
    <div class="col-sm-12 col-md-4 mb-3 pb-0 position-relative">
      <input
        [id]="id + 2"
        formControlName="address2"
        class="form-control"
        type="text"
        autocomplete="address-line2"
        (blur)="validate()"
        [textMask]="masks.address2"
        [attr.aria-labelledby]="id + 2 + '-label'"
        title="input-address2"
      />
      <label [id]="id + 2 + '-label'" [for]="id + 2" class="form-label"> Apt / Suite </label>
      <app-field-error [control]="address2"></app-field-error>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-5 mb-3 p-0 position-relative">
      <input
        [id]="id + 3"
        formControlName="city"
        class="form-control"
        type="text"
        autocomplete="address-level2"
        (blur)="validate()"
        [textMask]="masks.city"
        [attr.aria-labelledby]="id + 3 + '-label'"
        title="input-city"
      />
      <label [id]="id + 3 + '-label'" [for]="id + 3" class="form-label"> City </label>

      <app-field-error [control]="city"></app-field-error>
    </div>

    <div class="col-sm-12 col-md-3 mb-3 pb-0 pe-0 position-relative">
      <app-dropdown-field [field]="state" [options]="states" [id]="id + 4" label="State"></app-dropdown-field>
    </div>

    <div class="col-sm-12 col-md-4 mb-3 pb-0 position-relative">
      <input
        [id]="id + 5"
        formControlName="zip"
        class="form-control"
        type="text"
        autocomplete="postal-code"
        (blur)="validate()"
        [textMask]="masks.zip"
        [attr.aria-labelledby]="id + 5 + '-label'"
        title="input-zip"
      />
      <label [id]="id + 5 + '-label'" [for]="id + 5" class="form-label"> Zip Code </label>

      <app-field-error [control]="zip"></app-field-error>
    </div>
  </div>
</div>
