import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import {
  IBusinessTypeForm,
} from '@eventhorizon/components/location-business-type/location-business-type.component';
import { ProductService } from '@eventhorizon/services/product.service';
import { IndustryController } from '@eventhorizon/controllers/industry.controller';
import {
  Address,
  BusinessInfoModel,
  BusinessLocationModel,
  Category,
  clearAddress,
  copyAddress,
  IBusinessLocationConfig,
  isAddressEmpty,
  isAddressEqual,
  masks,
} from '@xup-payments/xup-frontend-utils/models';
import { formatPhone, randomComponentId, touchAndValidate } from '@xup-payments/xup-frontend-utils/utils';
import { CommonValidator } from '@xup-payments/xup-frontend-utils/validation';
import { lastValueFrom } from 'rxjs';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { AddressComponent, IAddressForm } from '@eventhorizon/components/address/address.component';

@Component({
  selector: 'app-tg-business-location',
  templateUrl: './business-location.component.html',
  styleUrls: ['./business-location.component.scss'],
})
export class BusinessLocationComponent implements OnInit, AfterViewInit {
  public masks = masks;

  @ViewChild('focus') public focus: ElementRef;

  @Input()
  public location: BusinessLocationModel = new BusinessLocationModel();

  @Input()
  public legalAddress: Address;

  @Input()
  public locations: BusinessLocationModel[] = [];

  @Input()
  public requirePrimary = true;

  @Input()
    isAssisted = false;

  @Input()
  public config: IBusinessLocationConfig = {
      displayLocationCategory: false,
      displayPrimaryLocationRb: true,
      displayMccConfiguration: false,
      displayMccDescription: false,
      displayPhoneNumber: false,
    };

  @Input() public displayLocationDBA: boolean = true;

  public businessCategories: Category[];

  public form: FormGroup<IBusinessLocationForm>;

  public id = randomComponentId();

  public sameAsLegal: FormControl<boolean>;

  public isPrimary: FormControl<boolean>;

  public locationDBA: MobxFormControl<string>;

  public readonly SOLE_P_CODE = 'I';

  @ViewChild('address')
  private address: AddressComponent;

  @Output()
    isPrimaryChanged = new EventEmitter<boolean>();

  private businessCategory: MobxFormControl<string>;

  private businessPhone: MobxFormControl<string>;

  constructor(
    protected fb: FormBuilder,
    private store: ApplicationStore,
    protected productService: ProductService,
    protected industryController: IndustryController,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    this.form.setControl('address', this.address.form);
    if (this.address && !isAddressEmpty(this.address.address) && !isAddressEmpty(this.legalAddress)) {
      const sal = isAddressEqual(this.legalAddress, this.address.address);
      this.sameAsLegal.setValue(sal);
      this.address.readonly = sal;
    }
    if (!this.requirePrimary) this.location.isPrimary = true;
    const { organizationType } = this.store.businessInfo;

    if (organizationType && organizationType.code === this.SOLE_P_CODE && this.location.isPrimary) {
      copyAddress(this.store.businessAddresses.legalAddress, this.address.address);
      this.address.readonly = true;
    }
    this.setFormValuesByConfig();
  }

  async setFormValuesByConfig() {
    if (this.config.displayLocationCategory) {
      try {
        await lastValueFrom(this.productService.getCategories()).then(data => {
          this.businessCategories = this.industryController
            .transformCategories(data)
            .sort((objectA, objectB) => objectA.rank - objectB.rank);
        });
        this.businessCategory = new MobxFormControl(
          'businessCategory',
          () =>
            this.location.businessCategory && this.location.businessCategory.description
              ? this.location.businessCategory.name
              : null,
          v => {
            const category = {
              id: 1,
              name: v,
              displayName: v,
              description: v,
              rank: 1,
              imageUrl: '',
            };
            this.location.businessCategory = category;
            if (this.location.businessCategory.description) {
              this.location.businessCategory = category;
            }
          },
          Validators.required,
        );
        this.form.setControl('businessCategory', this.businessCategory);
      } catch (error) {
        console.error(error);
      }
    }

    if (this.config.displayPhoneNumber) {
      this.location.businessInfo = this.location.businessInfo ? this.location.businessInfo : new BusinessInfoModel();

      this.businessPhone = new MobxFormControl<string>(
        'businessPhone',
        () => formatPhone(this.location.businessInfo.phone),
        (v: string) => {
          this.location.businessInfo.phone = v;
        },
        CommonValidator.phone(true),
      );
      this.form.setControl('businessPhone', this.businessPhone);
    }
  }

  public buildForm(): FormGroup<IBusinessLocationForm> {
    if (this.form) {
      return this.form;
    }

    this.sameAsLegal = new FormControl(false);

    this.isPrimary = new MobxFormControl<boolean>(
      'isPrimary',
      () => (this.requirePrimary ? this.location.isPrimary : true),
      (v: boolean) => {
        if (this.requirePrimary) this.location.isPrimary = !!v;
      },
      [],
    );

    this.form = this.fb.group({
      sameAsLegal: this.sameAsLegal,
      isPrimary: this.isPrimary,
      address: new FormGroup<IAddressForm>({
        address1: new FormControl<string>(null),
        address2: new FormControl<string>(null),
        city: new FormControl<string>(null),
        state: new FormControl<string>(null),
        zip: new FormControl<string>(null),
      }),
    });

    if (this.displayLocationDBA) {
      this.locationDBA = new MobxFormControl<string>(
        'locationDBA',
        () => this.location.name,
        (v: string) => (this.location.name = v),
        [Validators.required],
      );
      this.form.setControl('locationDBA', this.locationDBA);
    }

    return this.form;
  }

  public changeSameAsLegal(value: boolean) {
    if (value) {
      this.address.readonly = true;
      copyAddress(this.legalAddress, this.address.address);
    } else {
      this.address.readonly = false;
      clearAddress(this.address.address);
    }
  }

  public changeIsPrimary() {
    for (let l = 0; l < this.locations.length; l += 1) {
      if (this.locations[l].isPrimary && this.location !== this.locations[l]) this.locations[l].isPrimary = false;
    }
    touchAndValidate(this.form);
    this.isPrimaryChanged.emit(this.location.isPrimary);
  }

  public isPrimaryDisabled() {
    let disabled = false;

    for (let l = 0; !disabled && l < this.locations.length; l += 1) {
      if (this.locations[l].isPrimary && this.location !== this.locations[l]) disabled = true;
    }
    return disabled;
  }
}

export interface IBusinessLocationForm {
  sameAsLegal: FormControl<boolean>;
  isPrimary: FormControl<boolean>;
  address: FormGroup<IAddressForm>;
  mcc?: FormGroup<IBusinessTypeForm>;
  businessCategory?: FormControl<string>;
  businessPhone?: FormControl<string>;
  locationDBA?: FormControl<string>;
}
