import { MaskitoOptions, maskitoUpdateElement } from '@maskito/core';
import { maskitoCaretGuard, maskitoEventHandler, maskitoNumberOptionsGenerator } from '@maskito/kit';

export const regexs = {
  phone: /[2-9]\d\d-\d{3}-\d{4}/,

  personalName: /^[a-zA-Z\s\-\.]{2,24}$/,

  companyName: /^[a-zA-Z0-9\x00-\x5A\x61-\x7B',}~\\]{2,120}$/,
  taxFilingName: /^[a-zA-Z0-9\x00-\x5A\x61-\x7B',}~\\]{2,120}$/,
  mcc: /^[a-zA-Z0-9\x00-\x5A\x61-\x7B',}~\\]{2,120}$/,

  address1: /^[\s\w]{1,120}$/,
  address2: /^[\s\w]{1,24}$/,
  poBox: /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i,
  city: /^[a-zA-Z\s\-]{1,24}$/,
  zip: /^[\d]{5}$/,

  date: /[01]\d[/][0123]\d[/][12]\d\d\d/,
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,3}$/,
  // eslint-disable-next-line max-len
  website:
    /^((((http(s)?):\/\/)|([www\.]|[WWW\.]))?(?!\.)([a-zA-Z0-9\-]*)(\.[a-zA-Z0-9\-]*){0,2}\.(com|org|net|mil|edu|biz|info|us|cc|co|gov|nyc|online|md|photography|COM|ORG|NET|MIL|EDU|BIZ|INFO|US|CC|CO|GOV|NYC|ONLINE|MD|PHOTOGRAPHY)(\.[a-z]{1,3})?)((\/?[^?]*?)\?.*)?$/,
};

// plugins used to prevent placing cursor to the right of a postfix symbol
// we want to prevent this behavior because it blocks inputs if the user misplace their cursor
export const generatePostfixPlugins = (postfixSymbol: string) => [
  maskitoCaretGuard((value) => [0, value.length - postfixSymbol.length]),
  maskitoEventHandler('blur', (element) => {
    if (element.value === postfixSymbol) maskitoUpdateElement(element, `0${postfixSymbol}`);
  }),
];

/**
 * Mask inputs into text-mask
 */
export const masks = {
  phone: {
    mask: [/[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  } as MaskitoOptions,

  passwordLength: {
    mask: Array(56).fill(/[\W\w]/),
  } as MaskitoOptions,

  ssnAlt: {
    mask: [/\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d/, /\d/, /\d/, /\d/],
  } as MaskitoOptions,

  taxIdAlt: {
    mask: [/\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d/, /\d/, /\d/, /\d/],
  } as MaskitoOptions,

  percentage: { mask: Array(3).fill(/\d/) } as MaskitoOptions,

  percentageSymbol: {
    ...maskitoNumberOptionsGenerator({
      postfix: '%',
      thousandSeparator: ',',
      precision: 4,
    }),
    plugins: generatePostfixPlugins('%'),
  } as MaskitoOptions,

  personalName: {
    mask: Array(24).fill(/[a-zA-Z\s\-\.]/),
  } as MaskitoOptions,

  companyName: {
    mask: Array(120).fill(/[a-zA-Z0-9\x00-\x5A\x61-\x7B',}~\\]/),
  } as MaskitoOptions,

  taxFilingName: {
    mask: Array(40).fill(/[a-zA-Z0-9\x00-\x5A\x61-\x7B',}~\\]/),
  } as MaskitoOptions,

  address1: { mask: Array(120).fill(/[\s\w]/) } as MaskitoOptions,
  address2: { mask: Array(24).fill(/[\s\w]/) } as MaskitoOptions,
  city: { mask: Array(24).fill(/[a-zA-Z\s\-]/) } as MaskitoOptions,
  zip: { mask: Array(5).fill(/\d/) } as MaskitoOptions,

  date: {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  } as MaskitoOptions,

  routingAlt: {
    mask: [/\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d|[*]/, /\d/, /\d/, /\d/, /\d/],
  } as MaskitoOptions,

  accountAlt: {
    mask: [
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d|[*]/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  } as MaskitoOptions,

  productQty: {
    mask: [/\d/, /\d/],
  } as MaskitoOptions,

  usCurrencyNoPrefix: maskitoNumberOptionsGenerator({
    thousandSeparator: ',',
    precision: 2,
  }),

  usCurrency: maskitoNumberOptionsGenerator({
    prefix: '$',
    thousandSeparator: ',',
    precision: 2,
  }),

  usCurrency4Digits: maskitoNumberOptionsGenerator({
    prefix: '$',
    thousandSeparator: ',',
    precision: 4,
  }),

  usCurrency5Digits: maskitoNumberOptionsGenerator({
    prefix: '$',
    thousandSeparator: ',',
    precision: 5,
  }),

  usCurrency6Digits: maskitoNumberOptionsGenerator({
    prefix: '$',
    thousandSeparator: ',',
    precision: 6,
  }),

  usCurrencyPerMonth: {
    ...maskitoNumberOptionsGenerator({
      prefix: '$',
      postfix: '/mo',
      thousandSeparator: ',',
      precision: 4,
    }),
    plugins: generatePostfixPlugins('/mo'),
  } as MaskitoOptions,

  amexMemberId: { mask: Array(10).fill(/\d/) } as MaskitoOptions,

  twoFactorAuth: {
    mask: Array(6).fill(/\d/),
  } as MaskitoOptions,

  toInt(value: any): any {
    if (!value) {
      return value;
    }
    return +value;
  },
};
